export default {
  "banner": {
    "viewDetails": "檢視詳細資料",
    "requiredCourses": "您的必修課程距到期日還有",
    "days": "天"
  },
  "header": {
    "plan": "我的計畫",
    "manager": "經理",
    "explore": "探索學習",
    "reporting": "報告",
    "myTeam": "我的團隊",
    "title": "Digital Learning Zone"
  },
  "footer": {
    "plan": "我的計畫",
    "manager": "經理",
    "explore": "探索學習",
    "tagline": "學習變得簡單了。從您開始。"
  },
  "welcome": {
    "hi": "您好",
    "intro1": "歡迎來到 Digital Learning Zone！DLZ 是一處個別化的學習平台，是萬豪學習的集中式存取點。為您的職責找到所需的培訓，並查閱個人與專業培養的學習機會。擁有自己的學習歷程，並開走上始培養技能的道路。",
    "intro2": "在您開始之前，我們需要知道您的一些基本資訊。",
    "letsGo": "我們走吧",
    "preferredLanguage": "您慣用的語言是？",
    "step": "步驟",
    "of": "／",
    "previous": "上一步",
    "next": "下一步",
    "startDate": "您何時開始在 Marriott International 或 Starwood 工作？",
    "selectYear": "選擇年份",
    "selectMonth": "選擇月份",
    "selectDay": "選擇日期",
    "selectFunction": "選擇職務",
    "selectInterests": "選擇興趣",
    "chooseYear": "選擇一個年份",
    "chooseMonth": "選擇一個月份",
    "chooseDay": "選擇一個日期",
    "whatDo": "您的工作職務為何？",
    "selected": "已選擇",
    "searchFunctions": "搜尋所有職務",
    "searchInterests": "搜尋所有興趣",
    "additionalJobFunctionsLabel": "請進一步說明您的工作內容",
    "managementLevelLabel": "您的員工等級為何？",
    "preferredBrandLabel": "如果您為多個品牌工作，請選擇所有適用您的項目。",
    "additionalJobFunctionsLabelExtra": "請檢視以下內容，並至少選擇一個額外工作責任。",
    "searchAdditionalFunctions": "搜尋所有額外的工作責任",
    "highGuestContactLabel": "您是否需要經常與賓客聯絡？",
    "yes": "是",
    "no": "否 "
  },
  "landing": {
    "started": "讓我們開始吧",
    "welcomeBack": "歡迎回來，",
    "greatWork": "做得好",
    "requiredCourses": "您的必修課程",
    "requiredCoursesDesc": "請在到期日之前完成課程。",
    "myPlan": "我的計畫",
    "courses": "課程",
    "courseComplete": "完成課程",
    "viewMore": "檢視更多必修課程",
    "viewMoreBtn": "檢視更多課程",
    "showing": "顯示",
    "of": "／",
    "heroTitle": "學習新知識。增強自己的能力。獲得適用您世界的技能。",
    "heroParagraph1": "輕鬆追蹤您的所有必修課程，並取得即時通知以完成課程。",
    "heroParagraph2": "享受根據您的工作和興趣客製化的學習體驗。",
    "recommendedCoursesTitle": "推薦課程",
    "recommendedCoursesDescNoContent": "您目前沒有任何推薦課程。請查看下方以探索其他學習機會。",
    "recommendedCoursesDesc": "查看您的主管或 HR 專員為您選擇了哪些課程。",
    "recommendedCoursesBtn": "檢視建議課程",
    "completedCoursesBtn": "檢視已完成的課程",
    "exploreTitle": "保持好奇心。",
    "exploreDesc": "選擇您感興趣的主題。",
    "jobFunctions": "懲處",
    "growthSkills": "成長技能",
    "leadershipSkills": "領導技能",
    "brands": "品牌",
    "languageLearning": "語言學習",
    "exploreBtn": "檢視課程",
    "managerRecommendation": "主管建議",
    "learningRecommendation": "學習建議",
    "marriottRecommendation": "MARRIOTT 建議",
    "completedCourses": "您已完成您的所有必修課程！",
    "investTitle": "擁有自己的學習歷程",
    "investParagraph1": "我們意識到在目前的情況許多方面都具有挑戰性 - DLZ 是為你提供的資源。無論你想保持最新的技能、學習新事物、管理壓力還是學會在虛擬團隊中工作。我們已收集好資源為你提供支援。",
    "updatedTopics": "更新的主題",
    "workingRemotely": "虛擬的團隊資源",
    "relatedCoursesNew": "按下方更新你的興趣,因為我們加入了新的主題和課程。",
    "relatedCourses": "請按下方選擇你的興趣。",
    "notificationText": "提供新版 mHUB 培訓。請在 Explore Learning 中選擇 mHUB。",
    "mHubHeroTitle1": "簡單。智慧。穩固。推介 mHUB。",
    "mHubHeroManagedText1": "mHUB 將學習工具、職業發展、Explore 折扣等功能集成到易於使用的平台中。",
    "mHubHeroFranchisedText1": "mHUB 將學習工具，Explore 折扣等功能集成到易於使用的平台中。",
    "mHubHeroVisitLink": "點選此處以檢視其他產品詳細資訊或附件。",
    "myGateway": "My Gateway",
    "gatewayReview": "請檢查並完成。",
    "gatewayProgress": "Gateway 進度",
    "complete": "完整",
    "reviewContent": "查看資源",
    "gatewayCardTitle": "Gateway 資源",
    "gatewayCardDesc": "存取您第一年及以後的基礎知識。",
    "gatewayCardDescGms": "存取您第一年及以後的基礎知識、行動項目、品牌資源以及按部門排序的資源。",
    "gatewayCardDescNew": "存取您新崗位第一年及以後的基礎知識。",
    "gatewayCardDescGmsNew": "存取您新崗位第一年及以後的基礎知識、行動項目、品牌資源以及按部門排序的資源。"
  },
  "courseBundle": {
    "completeBy": "完成者",
    "remainingCourses": "其餘的課程",
    "completedCourses": "已完成的課程",
    "remainingLabel": "此學習路徑有 {courseCount} 個課程。",
    "recommendButton": "建議給團隊",
    "startCourse": "開始課程",
    "durationMins": "分鐘",
    "completed": "已完成",
    "back": "返回",
    "additionalLangs1": "除了提供英文版本之外，本課程還提供以下語言版本：",
    "additionalLangs2": "在啟動課程後，請從下拉式功能表中選擇您的慣用語言。",
    "targetAudience": "目標對象",
    "all": "全部",
    "globally": "全球",
    "source": "來源",
    "courseType": "課程類型",
    "course": "課程"
  },
  "profile": {
    "save": "儲存",
    "edit": "編輯",
    "brand": "品牌",
    "brands": "品牌",
    "additionalWork": "所有額外的工作責任",
    "startDateLabel": "您何時開始在 Marriott International 或 Starwood 工作？",
    "preferredLanguageLabel": "您慣用的語言是？",
    "jobFunctionsLabel": "您的工作職責為何？",
    "interestsLabel": "您想要學習什麼？",
    "brandsLabel": "您為哪個品牌工作？",
    "additionalJobFunctionsLabel": "您負責執行哪些額外的工作職能？",
    "managementLevelLabel": "您的員工等級為何",
    "highGuestContactLabel": "您是否需要經常與賓客聯絡？"
  },
  "courseDetails": {
    "completeBy": "尚未完成",
    "recommendTitlte": "為您的團隊建議的課程",
    "courseName": "課程名稱",
    "sendRecommendation": "傳送建議"
  },
  "explore": {
    "relatedCourses": "以下是與您感興趣領域相關的課程。",
    "chooseInterests": "選擇您的興趣",
    "title": "探索學習",
    "chooseInterestsModal": "選擇興趣",
    "oppsTitle": "探索學習機會",
    "growWithUsTitle": "與企業一起成長",
    "growWithUsDesc": "Marriott 相信與您一同茁壯成長的職業生涯。我們也相信通常經驗越廣泛，就能提供更豐富的經驗。",
    "growWithUsBtn": "檢視與企業一起成長",
    "mdaTitle": "Marriott 發展學院",
    "mdaDesc": "Marriott 肯定成功與努力。我們尊敬並鼓勵領導力和卓越服務。我們也為成功推薦提供獎勵。",
    "mdaBtn": "檢視 MARRIOTT 發展學院",
    "selectInterests": "告訴我們您對哪些主題感興趣。",
    "otherRecommended": "其他建議課程",
    "searchByName": "搜索您的興趣"
  },
  "plan": {
    "completedRequired": "您已完成您的所有必修課程！",
    "requiredCourses": "必修課程",
    "reqDesc": "請在到期日之前完成它們。",
    "complete": "完成",
    "of": "／",
    "courses": "課程",
    "completedCourses": "已完成的課程",
    "completedDesc": "恭喜！您最近已完成以下課程。",
    "remaining": "還剩下",
    "myLearningTitle": "我已新增的學習",
    "myLearningSubTitle": "我已添加到「我的計劃」中的項目",
    "history": "歷史記錄",
    "myLearningHistoryLinkText": "查看我的全部歷史記錄",
    "jf": "您的工作職能",
    "awr": "您的附加工作職責 (AWR)",
    "jfAwrDesc": "重要事項：工作職能和 AWR 決定了分配至您「我的計畫」中所需的培訓。",
    "jfAwrEdit": "編輯您的個人資料",
    "jfAwrEnd": "以更新。",
    "tourPlan": "My Plan（我的計劃）包括您在截止日期前完成的所有必修和推薦課程",
    "tourBasics": "Marriott Basics （萬豪基礎知識）包含可幫助您開啟萬豪之旅的各種資源，並可在整個職業生涯中隨時供您取用。Marriott Basics 是您在 My Plan 中學習的補充內容。",
    "noCourses": "您完全跟上了此時到期的必修課，那麼隨時向前看並開始即將到來的必修課"
  },
  "team": {
    "title": "管理您的團隊",
    "subtitle": "培養他們的技能與職業生涯。",
    "functions": "職務",
    "viewTeam": "檢視團隊",
    "allCourses": "所有課程",
    "downloadExcel": "下載 Excel 報告"
  },
  "feedbackButton": {
    "havingTrouble": "建議"
  },
  "recommendation": {
    "dismiss": "您確定要關閉此建議？"
  },
  "table": {
    "filterTable": "Tableau des filtres"
  },
  "notifications": {
    "addAssignment2": "%{assignment_name} 的 %{assignment_type} 作業已添加到您的 %{page location}，且作業到期日為 %{due}。",
    "comingDueAssignment": "您 %{assignment_name} 的 %{assignment_type} 作業即將於 %{due} 到期。",
    "overdueAssignment": "您 %{assignment_name} 的 %{assignment_type} 作業現已逾期。",
    "addAssignment": "%{assignment_name} 的 %{assignment_type} 作業已添加到您的 %{page location}。",
    "profile": "已檢測到您的職位或地點有更新。請檢視個人資料以進行任何適用的品牌、職能或其他工作職責更新。準確的 DLZ 個人資料有助於確保您接收正確的學習作業。",
    "helptip": "所有通知都會在 30 天后過期。",
    "markRead": "標記為已讀",
    "delete": "刪除",
    "markAllRead": "全部標記為已讀",
    "settings": "設定",
    "tooltip": "閱讀通知將在 30 天內刪除",
    "reportComplete": "您的 DLZ 報告已完成。點選以下載",
    "dontShowThisAgain": "不再顯示此內容",
    "warningEnabled": "刪除警告已啟用。",
    "manageEmailNotifications": "管理電子郵件通知",
    "warningTooltip": "在選中後，每次刪除通知時都將看到警告。",
    "changeEmailManagedTooltip": "若要變更您的電子郵箱，請造訪 mHUB > HR Direct > 我的 > 我的公開資訊。按照說明更新您的聯絡方式。更新後的電子郵件可能需要 1-2 個工作日才能顯示。",
    "changeEmailFranchisedTooltip": "若要變更您的電子郵箱，請聯絡您的經理和／或特許加盟 EID Tool 管理員。更新後的電子郵箱可能需要 1-2 個工作日才能顯示。",
    "deleteAllNotifications": "刪除所有通知",
    "deleteIndividualHeader": "刪除通知",
    "deleteAllConfirmation": "您確定要刪除所有通知嗎？ 這些通知將被移至通知中心的「已刪除」區域，直到其過期為止。",
    "deleteIndividualConfirmation": "您確定要刪除此通知嗎？ 已刪除的通知會移至通知中心的「已刪除」區域，直到其過期為止。",
    "permanentDeleteIndividiualConfirmation": "您確定要永久刪除這份通知嗎？ 此操作無法撤回。",
    "permanentDeleteAllConfirmation": "您確定要永久刪除所有通知嗎？ 此操作無法撤回。",
    "notificationDeleted": "通知已被刪除。",
    "notificationsDeleted": "通知已被刪除。",
    "notificationRestored": "通知已被恢復。",
    "notificationsRestored": "通知已被恢復。",
    "enableDeletionWarning": "刪除警告已啟用",
    "warningDisabled": "刪除警告已禁用。",
    "viewAll": "檢視全部",
    "deleteAll": "刪除全部",
    "notificationCenterTitle": "通知中心",
    "markAsRead": "標記為已讀",
    "noDeletedNotifications": "您收悉的資訊均為最新！",
    "restore": "修復",
    "restoreAll": "恢復全部",
    "viewCourse": "檢視課程",
    "title": "通知",
    "noNewNotifications": "尚無任何通知。",
    "cancel": "取消",
    "manageNotificationsCopy": "管理電子郵件通知\n\n選擇是否接收每週總結逾期學習項目的電子郵件。選擇「Manage（管理）」以便修改首選項。"
  },
  "transcript": {
    "history": "我的歷史記錄",
    "completed": "已完成的作業",
    "assignmentName": "作業名稱",
    "type": "類型",
    "viewAssignment": "檢視作業",
    "assignmentSource": "作業來源",
    "requiredAssignments": "必做的作業",
    "recommendedAssignments": "推薦的作業",
    "voluntary": "自願",
    "trasscript": "成績單",
    "retraining": "再培訓",
    "completionDate": "完成日期",
    "assignmentType": "作業類型",
    "contentType": "內容類型",
    "contentId": "內容 ID"
  },
  "contentLaunch": {
    "review": "回顧學習內容",
    "start": "開始學習"
  },
  "survey": {
    "weValue": "我們期待聽聽您的意見。",
    "weValueDescription": "請回答五個關於您在酒店工作經驗的簡短問題。",
    "weValueButtonText": "接受本調查",
    "weValueG1Q1A1": "是",
    "weValueG1Q1A2": "否",
    "strongAgree": "強烈同意",
    "agree": "同意",
    "slightAgree": "稍微同意",
    "slightDisagree": "稍微反對",
    "disagree": "反對",
    "strongDisagree": "強烈反對",
    "weValueG1Q1Title": "您在上一次工作排班中是否有參加每日會議？ （Line-Up、What’s Up Meeting、Daily Rehearsal、 House Briefing、Authentic Moments 或 Daily EDITION）",
    "weValueG1Q2Title": "每日會議（Line-Up、What’s Up Meeting、Daily Rehearsal、 House Briefing、Authentic Moments 或 Daily EDITION）激勵我每天提供卓越服務。",
    "weValueG1Q3Title": "我的部門專注於減少疏漏問題，即使是賓客沒有上報的疏漏問題。",
    "weValueG1Q4Title": "我獲權解決賓客的問題，而無需事先徵得許可。",
    "weValueG1Q5Title": "我獲權在無需事先徵得許可的情況下讓賓客有倍感愉悅的體驗。",
    "weValueG2Q1Title": "我常看到我所在酒店的團隊成員竭盡所能，並以他們提供的關懷給賓客留下深刻印象。",
    "weValueG2Q2Title": "透過在這個部門裡的學習，讓我能夠提供卓越服務。",
    "weValueG2Q3Title": "我有信心憑藉我的知識與技能，來提供卓越服務。",
    "weValueG2Q4Title": "在我的部門，我們提供豪華的環境，乾淨且無疏漏問題。",
    "weValueG2Q5Title": "即使遇到與我部門無關的問題，我也獲權介入並解決賓客的問題。",
    "weValueG3Q1Title": "在我所在的酒店，團隊成員得到與賓客同等水準的照顧。",
    "weValueG3Q2Title": "我的部門體現了品牌的價值。",
    "weValueG3Q3Title": "當我出類拔萃地提供卓越的賓客體驗時，我的工作得到了認可和讚賞。",
    "weValueG3Q4Title": "我參與了我們部門改進工作的努力。",
    "weValueG3Q5Title": "在我的部門，我們總是把賓客放在首位。",
    "submitButton": "提交",
    "phasetwo": {
      "weValueG1Q1Title": "您在上一次工作排班中是否有參加每日會議？ （Line-Up、What's Up Meeting、Daily Rehearsal、House Briefing、Authentic Moments  或 Daily EDITION）？",
      "weValueG1Q2Title": "每日會議（Line-Up、What's Up Meeting、Daily Rehearsal、House Briefing、Authentic Moments 或 Daily EDITION）激勵團隊成員每天提供卓越服務。",
      "weValueG1Q3Title": "我所在的酒店裡，我們專注於減少疏漏問題，即使是賓客沒有上報的疏漏問題。",
      "weValueG1Q4Title": "我所在的酒店裡，我們獲權解決賓客的問題，而無需事先徵得許可。",
      "weValueG1Q5Title": "我所在的酒店裡，我們獲權在無需事先徵得許可的情況下讓賓客有倍感愉悅的體驗。",
      "weValueG2Q1Title": "我所在的酒店裡，我們常看到每個人竭盡所能，並以所提供的關懷給賓客留下深刻印象。",
      "weValueG2Q2Title": "酒店裡的學習機會使我們能夠提供豪華服務。",
      "weValueG2Q3Title": "我所在的酒店裡，我們有信心憑藉我們的知識與技能，來提供卓越服務。",
      "weValueG2Q4Title": "我所在的酒店裡，我們提供豪華的環境，乾淨且無疏漏問題。",
      "weValueG2Q5Title": "即使遇到與我們部門無關的問題，我們也獲權介入並解決賓客的問題。",
      "weValueG3Q1Title": "我所在的酒店裡，我們彼此間給予的關懷程度與我們為賓客提供的相同。",
      "weValueG3Q2Title": "我所在的酒店體現了品牌的價值。",
      "weValueG3Q3Title": "我所在的酒店裡，當我們出類拔萃地提供卓越的賓客體驗時，我們的工作得到了認可和讚賞。",
      "weValueG3Q4Title": "我所在的酒店裡，我們有機會投身參與到我們部門改進自身工作。",
      "weValueG3Q5Title": "我所在的酒店裡，我們總是把賓客放在第一位。"
    }
  },
  "gateway": {
    "planProgressComplete": "%{label} 完成",
    "gatewayProgressComplete": "GATEWAY 進度完成度",
    "heroMobileGatewayVideoText": "播放 Gateway 視訊",
    "basics": "基本知識",
    "brandResources": "品牌資源",
    "disciplineResources": "部門資源",
    "immersionResourcesHeader": "沉浸式資源",
    "brandCommunicationsHeaader": "品牌傳播",
    "brandContactsHeader": "品牌聯絡人",
    "franchisedActionItemsText": "行動項目可幫助您保持井然有序，並為您提供重要的入職培訓資訊。這些項目並非必需，但是對您可能會從特許經營管理公司收到的內容的補充。",
    "global": "全球",
    "continent": "洲別",
    "viewed": "已查看",
    "completed": "已完成",
    "heading": "Gateway",
    "basicsTab": "基礎知識",
    "onboardingTab": "行動項目",
    "brandTab": "品牌資源",
    "disciplinesTab": "按部門排序的資源",
    "basicsHeader": "歡迎使用 Marriott International",
    "basicsHeaderNewBrandOrRole": "歡迎，",
    "basicsText": "當您開始職業生涯時，我們知道有很多東西需要學。我們為您在工作的前些天挑選了部分基礎知識。其中某些項目可能會在迎新會或品牌入職方面進行更深入的回顧，但這將讓您容易入門。",
    "basicsTextGms": "當您開始職業生涯時，我們知道有很多東西需要學。我們為您在工作的前些天挑選了部分基礎知識。其中某些項目可能會在迎新會或品牌入職方面進行更深入的回顧，但這將讓您容易入門。完成後，轉到其他 Gateway 頁面，包括行動項目、品牌資源和按部門排序的資源。",
    "basicsTextFranchise": "我們知道有很多東西需要學，我們為您工作上的前些天挑選了部分基礎知識。這些項目可能作為對您的迎新會、品牌入職培訓或特許經營管理公司資訊的補充。",
    "basicsTextFranchiseGms": "我們知道有很多東西需要學，我們為您工作上的前些天挑選了部分基礎知識。這些項目可能作為對您的迎新會、品牌入職培訓或特許經營管理公司資訊的補充。完成後，轉到其他 Gateway 頁面，包括行動項目、品牌資源和按部門排序的資源。",
    "basicsTextNewToRole": "當您開始新崗位時，我們知道有很多東西需要學。我們為您在工作的前些天挑選了部分基礎知識。其中某些項目可能會在您指派的培訓中進行更深入的回顧，但這將讓您容易入門。",
    "basicsTextNewToRoleGms": "當您開始新崗位時，我們知道有很多東西需要學。我們為您在工作的前些天挑選了部分基礎知識。其中某些項目可能會在您指派的培訓中進行更深入的回顧，但這將讓您容易入門。完成後，轉到其他 Gateway 頁面，包括行動項目、品牌資源和按部門排序的資源。",
    "basicsTextNewToRoleFranchise": "我們知道有很多東西需要學，我們為您新崗位上的前些天挑選了部分基礎知識。這些項目可能作為對您特許經營管理公司資訊的補充。",
    "basicsTextNewToRoleFranchiseGms": "我們知道有很多東西需要學，我們為您新崗位上的前些天挑選了部分基礎知識。這些項目可能作為對您特許經營管理公司資訊的補充。完成後，轉到其他 Gateway 頁面，包括行動項目、品牌資源和按部門排序的資源。",
    "basicsTextNewToBrand": "當您開始 %{brandName} 品牌新崗位時，我們知道有很多東西需要學。我們為您在工作的前些天挑選了部分基礎知識。其中某些項目可能會在迎新會或品牌入職方面進行更深入的回顧，但這將讓您容易入門。",
    "basicsTextNewToBrandGms": "當您開始 %{brandName} 品牌新崗位時，我們知道有很多東西需要學。我們為您在工作的前些天挑選了部分基礎知識。其中某些項目可能會在迎新會或品牌入職方面進行更深入的回顧，但這將讓您容易入門。完成後，轉到其他 Gateway 頁面，包括行動項目、品牌資源和按部門排序的資源。",
    "basicsTextNewToBrandFranchise": "我們知道有很多東西需要學，我們為您新崗位上的前些天挑選了部分基礎知識。這些項目可能作為對您的迎新會、品牌入職培訓或特許經營管理公司資訊的補充。",
    "basicsTextNewToBrandFranchiseGms": "我們知道有很多東西需要學，我們為您在 %{brandName} 品牌新崗位上的前些天挑選了部分基礎知識。這些項目可能作為對您的迎新會、品牌入職培訓或特許經營管理公司資訊的補充。完成後，轉到其他 Gateway 頁面，包括行動項目、品牌資源和按部門排序的資源。",
    "disciplineHeader": "按部門排序的資源",
    "toDo": "待修",
    "optional": "選修",
    "overallComplete": "整體完成",
    "keyResourcesDesc": "詳細瞭解支援酒店日常營運、賓客體驗和業務需求的關鍵部門。",
    "franchiseFooter": "本材料包含可能提高遵守品牌標準的可能性的建議和推薦做法。作為特許人，萬豪不參與特許經營者酒店或業務營運任何方面的管理或營運。加盟商保留對其酒店日常營運的掌控權，並對自己的員工和僱傭行為負責。",
    "landingHeroTitle1": "歡迎使用 Marriott International。",
    "landingHeroTitle2": "在此開啟您的培訓旅程。",
    "landingHeroText": "The Gateway 作为一種工具，可引導您度過新崗位的第一年。它將為您提供成功所需的資訊與資源。",
    "landingHeroTitle1NewToRole": "歡迎使用 The Gateway。",
    "landingHeroTitle1NewToBrand": "歡迎使用 The Gateway。",
    "landingButtonText": "開始使用",
    "twoMonthslandingHeroTitle1": "歡迎回來！",
    "twoMonthslandingHeroText": "如果您還沒有這樣做，請在 The Gateway 中完成所有基礎知識！",
    "twoMonthslandingButtonText": "進入 Gateway",
    "twoMonthslandingHeroTitle1NewToBrand": "歡迎回來！",
    "twoMonthslandingHeroTextNewToBrand": "期許您在 %{brandName} 的前幾天裡諸事順利。不要忘記繼續您的培訓旅程並在 The Gateway 中存取您需要的所有內容。",
    "twoMonthslandingButtonTextNewToBrand": "繼續",
    "threeMonthslandingHeroTitle1": "您正參與其中！",
    "threeMonthslandingHeroText": "您需要瞭解的有關 Marriott International 的所有資訊盡在 The Gateway 之中。",
    "threeMonthslandingButtonText": "進入 Gateway",
    "threeMonthslandingHeroTitle1NewToRole": "您正參與其中！",
    "threeMonthslandingHeroTextNewToRole": "要想在新崗位上中取得成功，您需要知道的一切盡在 The Gateway。",
    "threeMonthslandingButtonTextNewToRole": "進入 Gateway",
    "threeMonthslandingHeroTitle1NewToBrand": "歡迎回來！",
    "threeMonthslandingHeroTextNewToBrand": "祝賀您完成了您新品牌的前 %{numDays} 天工作。您可隨時存取您所需的所有行動項目、品牌和部門資源。",
    "threeMonthslandingButtonTextNewToBrand": "進入 Gateway",
    "fourMonthslandingHeroTitle1": "做得很好！基礎知識您過關了！",
    "fourMonthslandingHeroText": "獲取您需要瞭解的有關 Marriott International 的所有資訊，盡在 The Gateway 之中。",
    "fourMonthslandingButtonText": "進入 Gateway",
    "fourMonthslandingHeroTitle1NewToRole": "做得很好！基礎知識您過關了！",
    "fourMonthslandingHeroTextNewToRole": "要想在新崗位上中取得成功，獲得您需要的一切盡在 The Gateway。",
    "fourMonthslandingButtonTextNewToRole": "進入 Gateway",
    "fourMonthslandingHeroTitle1NewToBrand": "The Gateway - 簽到",
    "fourMonthslandingHeroTextNewToBrand": "當您接近完成 %{brandName} 品牌的第一年時，如果您還沒有完成，請完成您的所有行動項目並利用 Digital Learning Zone 中提供的開發資源。點選『繼續』以繼續您的旅程。",
    "fourMonthslandingButtonTextNewToBrand": "繼續",
    "gmSecondVisitlandingHeroTitle1": "歡迎回來！",
    "gmSecondVisitlandingHeroText": "期許您在前些天裡諸事順利。不要忘記繼續您的培訓旅程，以幫助您在新崗位中成長。",
    "gmSecondVisitlandingButtonText": "繼續",
    "gmSecondVisitlandingHeroTextNewToRole": "期許您在新崗位中的前些天裡諸事順利。不要忘記繼續您的培訓旅程並在 The Gateway 中存取您需要的所有內容。",
    "gmSecondVisitlandingHeroTextNewToBrand": "期許您在 %{brandName} 的前些天裡諸事順利。不要忘記繼續您的培訓旅程並在 The Gateway 中存取您需要的所有內容。",
    "gmThirdVisitlandingHeroTitle1": "歡迎回來！",
    "gmThirdVisitlandingHeroText": "祝賀您完成了新聘旅程的前 %{numDays} 天工作。您可隨時存取您所需的所有行動項目、品牌和部門資源。",
    "gmThirdVisitlandingButtonText": "進入 Gateway",
    "gmThirdVisitlandingHeroTextNewToRole": "祝賀您完成了新崗位的前 %{numDays} 天工作。您可隨時存取您所需的所有行動項目、品牌和部門資源。",
    "gmThirdVisitlandingHeroTextNewToBrand": "祝賀您完成了您新品牌的前 %{numDays} 天工作。您可隨時存取您所需的所有行動項目、品牌和部門資源。",
    "gmSevenMonthslandingHeroTitle1": "看起來您有更多的行動項目要完成。",
    "gmSevenMonthslandingHeroText": "當您接近完成第一年時，不要忘記完成您的所有行動項目並利用 Digital Learning Zone 中提供的開發資源。點選『繼續』以繼續您的旅程",
    "gmSevenMonthslandingButtonText": "繼續",
    "gmSevenMonthslandingHeroTitle1NewToRole": "The Gateway - 簽到",
    "gmSevenMonthslandingHeroTextNewToRole": "當您接近完成新崗位的第一年時，如果您還沒有完成，請完成您的所有行動項目並利用 Digital Learning Zone 中提供的開發資源。點選『繼續』以繼續您的旅程",
    "gmSevenMonthslandingHeroTitle1NewToBrand": "The Gateway - 簽到",
    "gmSevenMonthslandingHeroTextNewToBrand": "當您接近完成 %{brandName} 品牌的第一年時，如果您還沒有完成，請完成您的所有行動項目並利用 Digital Learning Zone 中提供的開發資源。點選『繼續』以繼續您的旅程。",
    "gmOneYearlandingHeroTitle1": "祝賀 %{brandName} 品牌培訓滿 1 年！",
    "gmOneYearlandingHeroTitle1NewToRole": "祝賀您在新崗位上滿 1 年！",
    "gmOneYearlandingHeroTitle1NewToBrand": "祝賀您在 %{brandName} 品牌的新崗位上滿 1 年！",
    "gmOneYearlandingHeroText": "The Gateway 也許將告一段落，但美好未來還在後頭。您可隨時從 The Gateway 選項卡存取各種資源。",
    "gmOneYearlandingButtonText": "查看 Gateway"
  },
  "resources": {
    "iptTooltip": "使用逗號分隔多個地點",
    "iptAllFunctionTooltip": "取消選中以選擇特定的崗位職能部門。保持選中狀態以查看所選地點的所有職能部門的培訓",
    "iptAllAWRTooltip": "取消選中以選擇特定的附加工作職責。保持選中狀態以查看所選地點的所有附加工作職責的培訓",
    "editJFTooltip": "使用逗號分隔多個條目"
  },
  "Notification Center: ": "標記為未讀",
  "Notification Center: Empty": "您收悉的資訊均為最新！",
  "Notification Center: Individual Delete": "您確定要刪除此通知嗎？ 已刪除的通知會移至通知中心的「已刪除」區域，直到其過期為止。",
  "Notification Center: Delete All": "您確定要刪除所有通知嗎？ 這些通知將被移至通知中心的「已刪除」區域，直到其過期為止。",
  "Notification Center: Mark All As Read": "全部標記為已讀",
  "Notification Center: Restore All": "恢復全部",
  "Notification Center: Delete Confirmation": "刪除",
  "Warning Message Text: Remove Job Function or AWR": "是否移除工作職務或額外工作職責 (AWR)？\r\n透過「提交」，即表示您將從 %{count} 位學習者中移除 %{Function and/or AWR}。",
  "Warning Message Text: Add Job Function or AWR": "是否添加工作職務或額外工作職責 (AWR)?\r\n透過「提交」，即表示您將向 %{count} 位學習者新增 %{Function and/or AWR}。",
  "Button": "提交",
  "Success Message Text: Add Job Function and/or AWR": "成功\r\n您已將 %{Job Function and/or AWR} 新增至隸屬於 %{location} 的發出申請之員工。\r\n\r\n您是否想為 %{location} 編輯其他工作職務或其他工作職責？",
  "Message Text:AWR": "成功\r\n您已將其他工作職責 %{ __} 新增至隸屬於 %{ __} 的發出申請之員工。\r\n\r\n您是否想為 %{location, property, learner(s)} 編輯其他工作職務或其他工作職責？",
  "Success Message Text: Remove Job Function": "成功\r\n您已將 %{Job Function and/or AWR} 從隸屬於 %{location} 的發出申請之員工中移除。\r\n\r\n您是否想為 %{location} 編輯其他工作職務或其他工作職責？",
  "Success Message Text: Remove AWR": "成功\r\n您已將其他工作職責 %{ __} 從隸屬於 %{ __} 的發出申請之員工中移除。\r\n\r\n您是否想為 %{location, property, learner(s)} 編輯其他工作職務或其他工作職責？",
  "Tool Tip - Managed Change Email": "若要變更您的電子郵箱，請造訪 mHUB > HR Direct > Me（我） > My Public Info（我的公開資料）。請按照說明更新您的聯絡資訊。更新後的電子郵箱可能需要 1-2 個工作日才能顯示。",
  "Tool Tip - Franchised  Change Email": "若要變更您的電子郵箱，請聯絡您的經理和／或特許加盟 EID Tool 管理員。更新後的電子郵箱可能需要 1-2 個工作日才能顯示。",
  "User Profile: Email Opt-in": "不，謝謝",
  "User Profile: Email Frequency": "每週摘要",
  "Generic Notification Email Subject Line:": "DLZ 中有新通知",
  "Generic Body Text (<150 Characters)": "請造訪 DLZ 以瞭解詳情。",
  "All Emails: Signature": "Digital Learning Zone——學習讓一切變得更簡單。從您開始。",
  "All Emails: Footer": "您可以隨時取消訂閱/選擇退出電子郵件通知。請造訪 Digital Learning Zone/Profile（個人檔案）以便更新您的首選項。",
  "Overdue Emails: Subject Line: ": "DLZ 作業需要您留意",
  "Overdue Emails: Body Test": "您有逾期作業。請登入至 DLZ 以查閱您的作業。",
  "exploreLearning": {
    "sharpenYourSkillsTitle": "磨练您的技能",
    "sharpenYourSkillsSubTitle": "構建您的知識和技能，以提升您今後的職業生涯。",
    "growYourCareerTitle": "發展您的職業生涯",
    "growYourCareerSubTitle": "藉助支援您獨特興趣和目標的專業技能或交叉培訓，來規劃您的下一步行動。",
    "additionalResourcesTitle": "其他資源",
    "additionalResourcesSubTitle": "探索 getAbstract® 與 CultureWizard™，瞭解 DLZ 如何繼續幫助您的成長。",
    "tourHeader": "歡迎使用更新版 DLZ。現在，您可在需要時搜尋以便找到所需的任何內容。還有，您將會發現更新後的探索學習和更加個人化的「我的計劃」。請盡情享用！",
    "tourBtnSkip": "離開",
    "tourBtnNext": "下一頁 (6/6)",
    "tourSearchBar": "使用全新、更強大的搜尋功能，在您需要時找到您需要的東西。",
    "tourBtnBack": "返回",
    "tourMyPlan": "「我的計劃」現在包含了您從搜尋和探索中添加的項目，因此您可擁有只屬於您自己的學習旅程！",
    "tourExploreLearningLink": "您仍可從這裡進入「探索學習」！",
    "tourExploreLearningProfile": "充分利用您的學習經驗並定期查看您的個人資料，尤其是工作職能，以使您的「我的計劃」保持最新。",
    "mhubTraining": "mHUB 訓練",
    "growthSkills": "成長技能",
    "leadershipSkills": "領導力技能",
    "virtualTeamResources": "虛擬團隊資源",
    "jobFunctions": "工作職能",
    "brands": "品牌",
    "languageLearning": "語言學習",
    "become": "成為",
    "emeaMentoringProgram": "EMEA 指導計劃",
    "learningPathways": "學習途徑",
    "mda": "MDA",
    "takeCareGrow": "TakeCare GROW",
    "getAbstract": "getAbstract®",
    "cultureWizard": "CultureWizard™",
    "takeCareCommunityHub": "TakeCare Community Hub",
    "mhubTrainingDesc": "查找有關如何使用 mHUB 的基於角色的、學習路徑和特定主題的課程。",
    "growthSkillsDesc": "獲得跨各種職能和主題的實用知識。",
    "leadershipSkillsDesc": "瞭解如何管理和指導團隊並成為更高效的領導者。",
    "virtualTeamResourcesDesc": "學習在虛擬團隊中培養協作與包容文化的技巧。",
    "jobFunctionsDesc": "擴展您當前工作職能的知識並瞭解他人。",
    "brandsDesc": "詳細瞭解 Marriott International 的多元化品牌。",
    "languageLearningDesc": "與全球受眾進行高效溝通。",
    "becomeDesc": "探索職業成長和發展的整體方法：反映、計劃、行動、細微調整。",
    "emeaMentoringProgramDesc": "透過 EMEA 指導計劃釋放您的潛力。",
    "learningPathwaysDesc": "探索為您的領導力水準策劃的特定學習機會。",
    "mdaDesc": "透過 Marriott Development Academy 培養領導力技能和職能技能。",
    "takeCareGrowDesc": "找出旨在建設具有包容性和啟發性的社區的資源。",
    "getAbstractDesc": "每一天透過書籍摘要學習新知識，幫您做出更好的決定。",
    "cultureWizardDesc": "收集有價值的知識，以便在不同文化之間進行有效溝通。",
    "takeCareCommunityHubDesc": "探索 TakeCare 團隊建設活動、學習計劃和工具。",
    "searchAllLearning": "搜尋全部學習",
    "searchBarPlaceHolder": "學無止境。",
    "clearFilters": "清除篩選器",
    "noResults": "無結果",
    "hasBeenUnpinned": "{Variable-tile_Name} 已取消固定。",
    "hasBeenPinned": "{Variable-tile_Name} 已固定。",
    "lpRemovedFromPlan": "學習途徑已從您的計劃中移除。",
    "courseRemovedFromPlan": "課程已從您的計劃中移除。",
    "lpAddedToPlan": "學習途徑已添加到您的計劃中。",
    "courseAddedToPlan": "課程已添加到您的計劃中。",
    "clearSelection": "清空所選內容",
    "removeFromMyPlan": "從我的計劃中移除",
    "addToMyPlan": "添加到我的計劃",
    "loadingResults": "正在載入",
    "viewDetails": "檢視詳細資料",
    "clearAll": "清除全部",
    "noOptions": "沒有可用的選項",
    "viewLess": "檢視更少",
    "viewMore": "檢視更多",
    "recentSearch": "最近的搜尋",
    "exploreLearningTitle": "探索學習",
    "exploreLearningHelpText": "不確定您正在尋找什麼？",
    "exploreMore": "探索更多。",
    "viewAllResults": "檢視全部 <#> 結果",
    "searchSubTitle": "使用關鍵字、標題或內容 ID 搜尋課程。",
    "pinnedLearningTitle": "我的固定學習",
    "landingSubTitle": "展開您的學習歷程。探索您的各種興趣——時時提供新主題和課程。",
    "columnTitle": "稱謂",
    "columnDesc": "描述",
    "columnType": "類型",
    "columnAddedToPlan": "添加（已添加）到計劃",
    "columnComplete": "完成",
    "columnDetails": "詳細資料",
    "additionalSearchFilters": "其他搜尋篩選器",
    "tourBtnClose": "關閉",
    "tourBtnLast": "關閉",
    "resultsFor": " 的結果",
    "resultsFortheFilter": "篩選器 {Filter Name} 的結果",
    "no": "否",
    "results": "結果",
    "courseTooltipTitle": "可能包含一個或多個活動的個人學習項目。<br />課程可於線上開展，例如視訊或電子學習，也可在教室中開展。",
    "gatewayToolTipTitle": "為品牌新員工、管理層新人和總經理新人提供的數字資源。",
    "speciallizationOrPathwayTooltipTitle": "一套專注於特定主題的課程。",
    "taskTooltipTitle": "完成一項活動（通常是在職）的說明，然後標註您已完成。",
    "webSiteTooltipTitle": "任何網站，包括 Marriott 網站或外部網站。"
  },
  "dontShowAgain": "不再顯示此內容",
  "deletionWarningEnabled": "已啟用刪除警告",
  "deletionSelected": "在選中後，每次刪除通知時都將看到警告。",
  "manageEmailNotifications": "管理電子郵件通知",
  "seeHowDLZContinue": "瞭解 DLZ 如何繼續幫助您的成長。",
  "Notification Center:Overdue": "您有一項新培訓，已在 {Variable date ddmmyy} 到期",
  "accesDenied": {
    "title": "您的學習之旅正在進行中！",
    "subtitle": "正在創建您的訪問權限，您的 EID 激活後 1-2 天即可使用。如果超過 2 天，請聯絡 mHUB 服務中心。"
  },
  "jfTour": {
    "title": "您現在可以直接從「我的計畫」中查看和編輯您選擇的工作職能和附加工作職責 (AWR)。"
  },
  "onboardingPlan": {
    "requiredDescription": "以下為您在「我的計畫」中所選時間段須完成的課程。與您的領導合作，安排時間完成適應新崗位的必修課程。您將瞭解各式各樣的話題，如您遇到疑問，請務必與您的領導和同伴討論。   ",
    "day1": "第 14 天到期",
    "day2": "第 30 天到期",
    "day3": "第 60 天到期",
    "day4": "第 90 天到期",
    "day5": "90 天後到期 "
  },
  "onboardingLanding": {
    "gettingStartedDescription": "Digital Learning Zone 是您學習與發展專業能力的切入點。在下方您會找到為您量身定製的內容以助您入門。您所有的學習材料均可在「我的計畫」中找到。  ",
    "overallLearningProgress": "整體學習進度",
    "learningProgressLabel": "學習進度",
    "learningProgressDescription": "以下是您透過所需學習取得進展的方法。這一進度條包括您所有需要學習的狀態。繼續保持良好表現！"
  },
  "Recommended Courses ": "在您入職 90 天以後，您將收到您經理建議的其它課程。您也可以自行探索您感興趣的話題。從現在起，專注於您的基礎學習。",
  "survey": {
    "v2": {
      "weValueButtonText": "參加調查",
      "lbiIntro": "歡迎來到有關豪華酒店行為的測試！",
      "lbiDescription": `<p>將請您閱讀一些您可能在酒店遇到的簡短情境。請閱讀每個情境，並選擇您會如何回應每個情境。當您回應時，請記住以下幾點：</p>
      <ul>
          <li>
              <p>您可能會發現可供選擇的答案中不止一個看起來是正確的。選擇您最有可能在工作中採取的回應。</p>
          </li>
          <li>
              <p>假設沒有溝通障礙。您說的語言與情境中顧客或員工說的語言相同。</p>
          </li>
          <li>
              <p>該情境可能要求您想像自己所擔任的工作職務與目前的不同。根據您作為豪華酒店專業人員的經驗，盡您所知回答問題。</p>
          </li>
      </ul>
      <p><br></p>
      <p>請注意：您參與本測試完全出於自願（您可以選擇不參與），且您的回答將嚴格保密。此次測試的結果將針對群體而非特定個人報告。研究團隊將評估個人回答，不會與其他人的經理或主管分享。</p>
      <p>完成此測試即表示您同意收集和處理您的個人回應和資料（例如：服務年資、性別、職稱），以及將您的個人資料跨境轉移至萬豪酒店集團。這符合萬豪員工資料私隱聲明，您也可以在萬豪的資料私隱政策中找到更多資訊和聯絡人以供參考。</p>
      `,
      "checkin": {
        q1Title: "您走過大廳，注意到有三名年幼兒童的家庭。這家人有很多行李，一天的長途旅行令他們看起來很緊張，孩子們也在抱怨，明顯坐立不安。您會怎麼做？",
        q2Title: "您剛開始在一個很陌生的地區工作。顧客詢問該區域的活動建議。顧客已閱讀過幾本旅遊指南，但希望能找到更個人化的旅遊指南。您會怎麼做？",
        q3Title: "您走過大廳時，聽到顧客在講電話，得知他們到機場的汽車服務延遲了。您的酒店沒有房車或暫時無法提供房車可使用。您會怎麼做？",
        q4Title: "您正走過大廳，完成經理分配給您的緊急任務。您注意到同事正努力完成一個通常由兩個人完成的任務。您會怎麼做？",
        q5Title: "您是酒店餐廳的主持人。顧客在等待預訂餐桌時要求查看菜單。看完菜單後，顧客感到很沮喪，因為另一位同事建議酒店餐廳給他們，但是菜單上沒有符合他們的飲食需求。您會怎麼做？",
        q6Title: "在擁擠的泳池區，有一大群顧客找不到座位。因此，他們把毛巾和個人物品鋪出來，這樣會擋住主通道，對嘗試進入泳池區的顧客和員工造成危害和干擾。該區域需要清理，但您不想得失那群組。您會怎麼做？",
        q1R1Title: "協助家庭搬運行李，指引他們前往登記櫃台，並向他們推薦附近一些適合家庭活動的休閒場所。提及酒店的家庭設施。",
        q1R2Title: "主動幫他們搬運行李，並引導全家辦理登記入住手續。然後，為父母安排放鬆的服務或款待，並為孩子安排有趣的活動。",
        q1R3Title: "引導父母親辦理登記入住手續，然後在父母辦理登機手續時親自帶孩子到座位區享用小食。讓服務台同事向家長介紹酒店的遊樂區和兒童活動，讓他們感到放心。",
        q1R4Title: "將家人引向登記櫃台，以便辦理登記入住手續。向父母介紹適合孩子的區域和服務，讓孩子享受愉快的住宿體驗。",
        q2R1Title: "詢問顧客喜歡甚麼，然後與同事合作，為他們制定有趣的活動清單。此外，建議附近一間不錯的餐廳。",
        q2R2Title: "與顧客討論他們喜歡甚麼。與同事一起製作一份特別有趣的物品清單。此外，請檢查顧客的 Bonvoy 個人檔案，並將個人化禮遇送至他們的客房。",
        q2R3Title: "透過分享您最喜歡在該地區做的一些事情，讓這項活動更具個人化。透過分享更多的體驗細節，讓您的建議脫穎而出。然後，把當地美食送到房間。",
        q2R4Title: "讓顧客知道您剛到這個地區，沒有太多想法。向他們道歉，並建議他們與了解更多資訊的同事交談。然後，詢問他們是否希望將當地美食送到房間。",
        q3R1Title: "當顧客還在使用電話時，將自己或同事的私人車輛停在酒店門口，讓客人知道您將親自送他們去機場。",
        q3R2Title: "當顧客還在使用電話中時，整理一份酒店批准的汽車服務和電話號碼清單。當顧客不在使用電話時，向他們出示清單，並提議協助安排交通。",
        q3R3Title: "等到顧客不在使用電話時，並告訴他們您的酒店不提供交通服務，但您很樂意協助協調其他交通選擇。",
        q3R4Title: "當顧客仍在使用電話時，為顧客尋找替代交通工具。當顧客不在使用電話時，告知他們您已安排好前往機場的交通，很快便會抵達。",
        q4R1Title: "去找您的同事說您會幫助他們，但您需要先完成您的緊急任務。承諾盡快回來協助他們。請確保與您的經理討論此情況。",
        q4R2Title: "首先，快速找另一位同事接手您被指派的任務。然後，立即協助您的同事。完成後，請告知您的經理您找到其他人來完成您的任務，以便您能夠幫助同事。",
        q4R3Title: "立即停止並協助您的同事。提供協助後，請回到您指派的任務，然後讓主管知道您暫停任務以協助您的同事。",
        q4R4Title: "找需要幫助的同事，解釋您正處於緊急任務中。停止這兩項任務，集思廣益，想出共同解決這兩項任務的方法，然後一起完成這兩項任務。",
        q5R1Title: "向顧客道歉，並讓他們知道您會找經理。請確保解釋情況，讓經理協助顧客滿足他們的食物需求。",
        q5R2Title: "向顧客道歉並詢問他們的食物偏好。與廚房團隊溝通，為他們製作一道特別的菜餚。此外，還可以贈送開胃菜或飲品以彌補過失。",
        q5R3Title: "道歉並告知顧客您了解。與其取消預訂，不如主動與廚師溝通，看看他們能否為客人做一些特別的菜餚。給他們一些免費招待或折扣，以補償給他們帶來的麻煩。",
        q5R4Title: "道歉並告訴顧客，您可以幫助他們預訂在附近有他們喜愛食物的餐廳。如有需要，提供額外資訊或協助。",
        q6R1Title: "為空間不足向顧客道歉，並告訴他們，只要他們注意路過的其他人，歡迎他們留在原地。",
        q6R2Title: "請告訴顧客他們不能擋住通道，他們需要另找位置或等待空位。同時，請您的同事們從宴會廳外搬來更多的座位給他們使用。",
        q6R3Title: "讓顧客知道他們不能待在目前的位置，他們必須暫時離開，直至你們找到其他座位。同時在酒吧/餐廳提供空間。 ",
        q6R4Title: "向顧客道歉解決座位問題。詢問他們是否可以把自己的東西放在一起，為路過的人建立小通道。主動幫助他們，確保每個人都感到自在。為他們帶來的不便，從酒吧/餐廳為他們送上一份特別款待。"
      }
    }
  },
};