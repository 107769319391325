export default {
  "banner": {
    "viewDetails": "Visualizza dettagli",
    "requiredCourses": "I corsi obbligatori devono essere completati entro",
    "days": "giorni"
  },
  "header": {
    "plan": "Mio programma",
    "manager": "MANAGER",
    "explore": "ESPLORA I CORSI",
    "reporting": "REPORTING",
    "myTeam": "MIO TEAM",
    "title": "Digital Learning Zone"
  },
  "footer": {
    "plan": "MIO PROGRAMMA",
    "manager": "MANAGER",
    "explore": "ESPLORA I CORSI",
    "tagline": "La formazione diventa semplice. A partire da te."
  },
  "welcome": {
    "hi": "Gentile",
    "intro1": "Benvenuto nella Digital Learning Zone! DLZ, una piattaforma per l'apprendimento personalizzato, è il principale punto di accesso ai corsi di formazione di Marriott. Qui trovi tutti i corsi di formazione obbligatori per il tuo ruolo e hai accesso a molte opportunità di apprendimento per il tuo sviluppo personale e professionale. Scegli il tuo percorso di formazione e inizia a sviluppare le tue competenze da subito.",
    "intro2": "Prima di iniziare, raccontaci qualcosa di te.",
    "letsGo": "PARTIAMO",
    "preferredLanguage": "Qual è la tua lingua preferita?",
    "step": "PASSAGGIO",
    "of": "DI",
    "previous": "PRECEDENTE",
    "next": "SUCCESSIVO",
    "startDate": "Quando hai iniziato a lavorare per Marriott International o Starwood?",
    "selectYear": "SELEZIONA ANNO",
    "selectMonth": "SELEZIONA MESE",
    "selectDay": "SELEZIONA DATA",
    "selectFunction": "SELEZIONA FUNZIONE",
    "selectInterests": "SELEZIONA INTERESSI",
    "chooseYear": "SCEGLI ANNO",
    "chooseMonth": "SCEGLI MESE",
    "chooseDay": "SCEGLI GIORNO",
    "whatDo": "Qual è la tua funzione?",
    "selected": "SELEZIONATO",
    "searchFunctions": "Cerca tutte le funzioni",
    "searchInterests": "Cerca tutti gli interessi",
    "additionalJobFunctionsLabel": "Qualche informazione in più sulle tue mansioni",
    "managementLevelLabel": "Qual il tuo livello dipendente?",
    "preferredBrandLabel": "Indica tutti i marchi per cui lavori.",
    "additionalJobFunctionsLabelExtra": "SCEGLI ALMENO UN'ALTRA responsabilità professionale.",
    "searchAdditionalFunctions": "Cerca tutte le altre responsabilità professionali",
    "highGuestContactLabel": "Hai un livello elevato di interazione coi clienti?",
    "yes": "Sì",
    "no": "No "
  },
  "landing": {
    "started": "Iniziamo",
    "welcomeBack": "Bentornato/a,",
    "greatWork": "Ottimo lavoro!",
    "requiredCourses": "I tuoi corsi obbligatori",
    "requiredCoursesDesc": "Completa i corsi entro la data di scadenza.",
    "myPlan": "Mio programma",
    "courses": "Corsi",
    "courseComplete": "CORSO COMPLETATO",
    "viewMore": "VISUALIZZA PIÙ CORSI OBBLIGATORI",
    "viewMoreBtn": "VISUALIZZA PIÙ CORSI",
    "showing": "VISUALIZZAZIONE DI",
    "of": "SU",
    "heroTitle": "Impara qualcosa di nuovo. Amplia i tuoi orizzonti. Acquisisci abilità pertinenti.",
    "heroParagraph1": "Traccia con facilità di tutti i corsi obbligatori e ricevi promemoria in tempo reale.",
    "heroParagraph2": "Trova opportunità di formazione su misura in funzione del tuo ruolo e dei tuoi interessi.",
    "recommendedCoursesTitle": "Corsi consigliati",
    "recommendedCoursesDescNoContent": "Al momento non sono presenti corsi consigliati. Scopri altre opportunità di formazione di seguito.",
    "recommendedCoursesDesc": "Guarda che cosa hanno scelto per te il tuo manager o il referente HR.",
    "recommendedCoursesBtn": "VISUALIZZA CORSI CONSIGLIATI",
    "completedCoursesBtn": "VISUALIZZA CORSI COMPLETATI",
    "exploreTitle": "Sii curioso.",
    "exploreDesc": "Scegli gli argomenti che ti interessano.",
    "jobFunctions": "Materia",
    "growthSkills": "Crescita personale",
    "leadershipSkills": "Stili di leadership",
    "brands": "Marchi",
    "languageLearning": "Lingue straniere",
    "exploreBtn": "VISUALIZZA CORSI",
    "managerRecommendation": "CONSIGLI DEL MANAGER",
    "learningRecommendation": "CONSIGLI PER LA FORMAZIONE",
    "marriottRecommendation": "CONSIGLI DI MARRIOTT",
    "completedCourses": "Hai completato tutti i corsi obbligatori.",
    "investTitle": "Percorsi di formazione su misura",
    "investParagraph1": "Ci rendiamo conto che le circostanze attuali sono difficili la DLZ è una risorsa per te. Se vuoi, puoi mantenere aggiornate le tue abilità, imparare qualcosa di nuovo, gestire lo stress o imparare a lavorare in un team virtuale. Abbiamo raccolto diverse risorse per darvi supporto.",
    "updatedTopics": "Argomenti aggiornati",
    "workingRemotely": "Risorse del team virtuale",
    "relatedCoursesNew": "Clicca qui sotto per aggiornare i tuoi interessi, poiché abbiamo aggiunto nuovi argomenti e corsi.",
    "relatedCourses": "Clicca qui sotto per selezionare i tuoi interessi.",
    "notificationText": "Nuova formazione su mHUB disponibile. Selezioni mHUB in Explore Learning.",
    "mHubHeroTitle1": "Semplice. Intelligente. Sicura. Presentazione di mHUB.",
    "mHubHeroManagedText1": "mHUB integra strumenti per l'apprendimento, sviluppo di carriera, sconti per Explore e molto altro, tutto in un'unica piattaforma di facile utilizzo.",
    "mHubHeroFranchisedText1": "mHUB integra strumenti per l'apprendimento, sconti per Explore e molto altro, tutto in un'unica piattaforma di facile utilizzo.",
    "mHubHeroVisitLink": "Faccia clic qui per visualizzare ulteriori dettagli o allegati relativi all'offerta.",
    "myGateway": "My Gateway",
    "gatewayReview": "Rivedi e completa.",
    "gatewayProgress": "The Gateway - Progressi",
    "complete": "COMPLETATO",
    "reviewContent": "Visualizza risorse",
    "gatewayCardTitle": "The Gateway - Risorse",
    "gatewayCardDesc": "Accedi alle nozioni di base per il primo anno e quelli successivi.",
    "gatewayCardDescGms": "Accedi alle nozioni di base, alle azioni che devi completare e a tutte le risorse sul marchio e sulle varie materie per il primo anno e quelli successivi.",
    "gatewayCardDescNew": "Accedi alle nozioni di base per il primo anno nel tuo nuovo ruolo.",
    "gatewayCardDescGmsNew": "Accedi alle nozioni di base, alle azioni che devi completare e a tutte le risorse sul marchio e sulle varie materie per il primo anno e quelli successivi nel tuo nuovo ruolo."
  },
  "courseBundle": {
    "completeBy": "COMPLETA ENTRO IL",
    "remainingCourses": "Corsi restanti",
    "completedCourses": "Corsi completati",
    "remainingLabel": "Ci sono {courseCount} corsi nel percorso di apprendimento.",
    "recommendButton": "Consigli per il team",
    "startCourse": "Inizia corso",
    "durationMins": "MINUTI",
    "completed": "Completato",
    "back": "Indietro",
    "additionalLangs1": "Oltre all'inglese, questo corso è ora disponibile in",
    "additionalLangs2": "Seleziona la lingua preferita dal menu a discesa dopo l'avvio del corso.",
    "targetAudience": "Pubblico di riferimento",
    "all": "Tutti",
    "globally": "In tutto il mondo",
    "source": "Origine",
    "courseType": "Tipo di corso",
    "course": "Corso"
  },
  "profile": {
    "save": "Salva",
    "edit": "Modifica",
    "brand": "Marchio",
    "brands": "Marchi",
    "additionalWork": "Tutte le altre responsabilità professionali",
    "startDateLabel": "Quando hai iniziato a lavorare per Marriott International o Starwood?",
    "preferredLanguageLabel": "Qual è la tua lingua preferita?",
    "jobFunctionsLabel": "Qual è il tuo ruolo?",
    "interestsLabel": "Che cosa vuoi imparare?",
    "brandsLabel": "Per quali marchi lavori?",
    "additionalJobFunctionsLabel": "Quali altre funzioni svolgi?",
    "managementLevelLabel": "Qual è il tuo livello dipendente?",
    "highGuestContactLabel": "Hai un livello elevato di interazione coi clienti?"
  },
  "courseDetails": {
    "completeBy": "Da completare",
    "recommendTitlte": "Corso consigliato al tuo team",
    "courseName": "Nome del corso",
    "sendRecommendation": "Invia consiglio"
  },
  "explore": {
    "relatedCourses": "Ecco i corsi che corrispondono ai tuoi interessi.",
    "chooseInterests": "Scegli i tuoi interessi",
    "title": "Esplora i corsi",
    "chooseInterestsModal": "Scegli gli interessi",
    "oppsTitle": "Esplora le opportunità di formazione",
    "growWithUsTitle": "Crescere con noi",
    "growWithUsDesc": "Marriott crede in una carriera che cresce con te. Crediamo inoltre che esperienze di più ampia portata possano spesso condurre ad un maggiore arricchimento.",
    "growWithUsBtn": "VISUALIZZA CRESCERE CON NOI",
    "mdaTitle": "Marriott Development Academy",
    "mdaDesc": "Marriott premia il successo e l’impegno. Stimiamo e incoraggiamo la leadership e il servizio di qualità. Concediamo inoltre bonus per ottime referenze.",
    "mdaBtn": "VISUALIZZA MARRIOTT DEVELOPMENT ACADEMY",
    "selectInterests": "Indica gli argomenti che ti interessano.",
    "otherRecommended": "Altri corsi consigliati",
    "searchByName": "Ricerchi i suoi interessi"
  },
  "plan": {
    "completedRequired": "Hai completato tutti i corsi obbligatori.",
    "requiredCourses": "Corsi obbligatori",
    "reqDesc": "Completa i corsi entro le date di scadenza.",
    "complete": "COMPLETATI",
    "of": "DI",
    "courses": "CORSI",
    "completedCourses": "Corsi completati",
    "completedDesc": "Congratulazioni! Seguono i corsi completati di recente.",
    "remaining": "restanti",
    "myLearningTitle": "Risorse di formazione aggiunte ",
    "myLearningSubTitle": "Elementi aggiunti al tuo piano",
    "history": "Cronologia",
    "myLearningHistoryLinkText": "Visualizza cronologia completa",
    "jf": "Le tue mansioni lavorative",
    "awr": "Le tue responsabilità professionali aggiuntive (AWR)",
    "jfAwrDesc": "Importante: le mansioni lavorative e le responsabilità professionali aggiuntive determinano i corsi di formazione obbligatori inclusi nell'area Mio programma. ",
    "jfAwrEdit": "MODIFICA il tuo profilo",
    "jfAwrEnd": "per aggiornare le informazioni.",
    "tourPlan": "L'area Mio programma include tutti i corsi che devono essere completati e consigliati in ordine di scadenza",
    "tourBasics": "La sezione Nozioni di base per Marriott include tutte le risorse necessarie per avviare il tuo percorso formativo in Marriott e può essere consultata a qualsiasi punto nel corso della tua carriera. Le risorse in Nozioni di base per Marriott sono in aggiunta ai corsi di formazione inclusi nell'area Mio programma.",
    "noCourses": "Al momento sei alla pari con tutti i corsi di formazione richiesti, ma puoi vedere cosa ti aspetta e iniziare i corsi obbligatori programmati."
  },
  "team": {
    "title": "Gestisci il tuo team",
    "subtitle": "Migliora le capacità e favorisci la crescita professionale",
    "functions": "Funzioni",
    "viewTeam": "Visualizza team",
    "allCourses": "Tutti i corsi",
    "downloadExcel": "Scarica report Excel"
  },
  "feedbackButton": {
    "havingTrouble": "Suggerimenti"
  },
  "recommendation": {
    "dismiss": "Ignorare questo consiglio?"
  },
  "table": {
    "filterTable": "フィルターテーブル"
  },
  "notifications": {
    "addAssignment2": "È stata aggiunta un'assegnazione %{assignment_type} per il corso %{assignment_name} alla pagina %{page location} con scadenza il giorno %{due}.",
    "comingDueAssignment": "L'assegnazione %{assignment_type} per il corso %{assignment_name} deve essere completata entro il giorno %{due}.",
    "overdueAssignment": "È passata la scadenza dell'assegnazione %{assignment_type} per il corso %{assignment_name}.",
    "addAssignment": "È stata aggiunta un'assegnazione %{assignment_type} per il corso %{assignment_name} alla pagina %{page location}.",
    "profile": "La sede o il titolo della tua posizione lavorativa è stato aggiornato. Ti invitiamo ad accedere al tuo Profilo per apportare le modifiche necessarie in Marchio, Funzione o Altre responsabilità professionali. La presenza di informazioni accurate nel profilo DLZ garantisce che tu riceva le assegnazioni appropriate per i corsi di formazione.",
    "helptip": "Tutte le notifiche scadono dopo 30 giorni.",
    "markRead": "Segna come già letta",
    "delete": "Elimina",
    "markAllRead": "Segna tutte come già lette",
    "settings": "Impostazioni",
    "tooltip": "Le notifiche lette saranno eliminate dopo 30 giorni.",
    "reportComplete": "Report DLZ generato. Fai clic per scaricarlo",
    "dontShowThisAgain": "Non mostrare più",
    "warningEnabled": "L'avviso di eliminazione è stato abilitato.",
    "manageEmailNotifications": "Gestione delle notifiche tramite e-mail",
    "warningTooltip": "Se selezionato, appare un messaggio di avviso ogni volta che viene eliminata una notifica.",
    "changeEmailManagedTooltip": "Per modificare l'email, accedi a mHUB > HR Direct > Io > Le mie informazioni pubbliche. Attieniti alle indicazioni per aggiornare i dati di contatto. Le informazioni aggiornate nell'email appariranno entro 1-2 giorni lavorativi.",
    "changeEmailFranchisedTooltip": "Per modificare l'email, rivolgiti al tuo responsabile o all'amministratore dello strumento EID Tool per il franchising. Le informazioni aggiornate nell'email appariranno entro 1-2 giorni lavorativi.",
    "deleteAllNotifications": "Elimina tutte le notifiche",
    "deleteIndividualHeader": "Elimina notifica",
    "deleteAllConfirmation": "Sei sicuro di voler eliminare tutte le notifiche? Tutte le notifiche saranno spostate nella sezione Eliminate del Centro notifiche fino alla scadenza.",
    "deleteIndividualConfirmation": "Sei sicuro di voler eliminare questa notifica? Le notifiche eliminate saranno spostate nella sezione Eliminate del Centro notifiche fino alla scadenza.",
    "permanentDeleteIndividiualConfirmation": "Sei sicuro di voler eliminare questa notifica in modo permanente? Questa azione non può essere annullata.",
    "permanentDeleteAllConfirmation": "Sei sicuro di voler eliminare tutte le notifiche in modo permanente? Questa azione non può essere annullata.",
    "notificationDeleted": "La notifica è stata eliminata.",
    "notificationsDeleted": "Le notifiche sono state eliminate.",
    "notificationRestored": "La notifica è stata ripristinata.",
    "notificationsRestored": "Le notifiche sono state ripristinate.",
    "enableDeletionWarning": "Avviso di eliminazione abilitato",
    "warningDisabled": "L'avviso di eliminazione è stato disabilitato.",
    "viewAll": "Visualizza tutte",
    "deleteAll": "Elimina tutte",
    "notificationCenterTitle": "Centro notifiche",
    "markAsRead": "Segna come già letta",
    "noDeletedNotifications": "Tutto aggiornato!",
    "restore": "Ripristina",
    "restoreAll": "Ripristina tutte",
    "viewCourse": "Visualizza corso",
    "title": "Notifiche",
    "noNewNotifications": "Non sono presenti notifiche.",
    "cancel": "Annulla",
    "manageNotificationsCopy": "Gestione delle notifiche tramite email\n\nIndica se vuoi ricevere un'email settimanale contenente un riepilogo delle proposte di formazione per le quali è trascorsa la scadenza. Seleziona Gestisci per modificare le preferenze."
  },
  "transcript": {
    "history": "Cronologia",
    "completed": "Assegnazioni completate",
    "assignmentName": "Nome assegnazione",
    "type": "Tipo",
    "viewAssignment": "Visualizza assegnazione",
    "assignmentSource": "Fonte assegnazione",
    "requiredAssignments": "Assegnazioni richieste",
    "recommendedAssignments": "Assegnazioni consigliate",
    "voluntary": "Su base volontaria",
    "trasscript": "Trascrizione",
    "retraining": "Corso di aggiornamento",
    "completionDate": "Data di completamento",
    "assignmentType": "Tipo di assegnazione",
    "contentType": "Tipo di contenuto",
    "contentId": "ID contenuto"
  },
  "contentLaunch": {
    "review": "Esamina corsi di formazione",
    "start": "Inizia la formazione"
  },
  "survey": {
    "weValue": "Ci piacerebbe conoscere la tua opinione.",
    "weValueDescription": "Ti invitiamo a rispondere a cinque brevi domande sulla tua esperienza di lavoro presso questa struttura.",
    "weValueButtonText": "Partecipa al sondaggio",
    "weValueG1Q1A1": "Sì",
    "weValueG1Q1A2": "No",
    "strongAgree": "Sono decisamente d'accordo",
    "agree": "Sono d'accordo",
    "slightAgree": "Sono abbastanza d'accordo",
    "slightDisagree": "Non sono del tutto d'accordo",
    "disagree": "Non sono d'accordo",
    "strongDisagree": "Non sono decisamente d'accordo",
    "weValueG1Q1Title": "Hai partecipato a un meeting giornaliero nel corso del tuo ultimo turno di lavoro? (Line-Up, What’s Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments o Daily EDITION)",
    "weValueG1Q2Title": "I meeting giornalieri (Line-Up, What's Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments o Daily EDITION) per me sono una fonte di ispirazione che mi aiuta a offrire ogni giorno servizi di lusso.",
    "weValueG1Q3Title": "Tutti nel mio reparto si prodigano per sopperire a qualsiasi mancanza, anche se non segnalata dagli ospiti.",
    "weValueG1Q4Title": "Sento di aver l'autorità necessaria per risolvere i problemi degli ospiti senza dover chiedere prima il permesso.",
    "weValueG1Q5Title": "Sento di aver l'autorità necessaria per scegliere come deliziare gli ospiti senza dover chiedere prima il permesso.",
    "weValueG2Q1Title": "Presso la struttura in cui lavoro noto spesso membri del team che fanno di tutto e di più per lasciare una buona impressione agli ospiti.",
    "weValueG2Q2Title": "Quello che ho imparato in questo reparto mi ha aiutato a offrire un servizio di lusso.",
    "weValueG2Q3Title": "Sento di possedere le conoscenze e le capacità necessarie per offrire un servizio di lusso.",
    "weValueG2Q4Title": "Nel mio reparto abbiamo creato un ambiente di lusso impeccabile, senza alcuna mancanza.",
    "weValueG2Q5Title": "Anche se incontro un problema che non è di competenza del mio reparto, sento di poter intervenire e occuparmi delle preoccupazioni degli ospiti.",
    "weValueG3Q1Title": "Presso la struttura in cui lavoro, i membri del team ricevono lo stesso livello di attenzione degli ospiti.",
    "weValueG3Q2Title": "Il mio reparto incarna i valori del marchio.",
    "weValueG3Q3Title": "Quando mi prodigo per creare un'esperienza di lusso per gli ospiti, il mio lavoro viene riconosciuto e apprezzato.",
    "weValueG3Q4Title": "Sono coinvolto in tutte le attività del reparto volte a migliorare il modo in cui lavoriamo.",
    "weValueG3Q5Title": "Nel mio reparto l'ospite è sempre al primo posto.",
    "submitButton": "Invia",
    "phasetwo": {
      "weValueG1Q1Title": "Hai partecipato a un meeting giornaliero nel corso del tuo ultimo turno di lavoro? (Line-Up, What's Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments  o Daily EDITION)?",
      "weValueG1Q2Title": "I meeting giornalieri (Line-Up, What's Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments o Daily EDITION) sono una fonte di ispirazione che aiuta i membri dei team a offrire ogni giorno servizi di lusso.",
      "weValueG1Q3Title": "Presso la struttura in cui lavoro ci prodighiamo per sopperire a qualsiasi mancanza, anche se non segnalata dagli ospiti.",
      "weValueG1Q4Title": "Presso la struttura in cui lavoro sentiamo di aver l'autorità necessaria per risolvere i problemi degli ospiti senza dover chiedere prima il permesso.",
      "weValueG1Q5Title": "Presso la struttura in cui lavoro sentiamo di aver l'autorità necessaria per scegliere come deliziare gli ospiti senza dover chiedere prima il permesso.",
      "weValueG2Q1Title": "Presso la struttura in cui lavoro facciamo spesso di tutto e di più per lasciare una buona impressione agli ospiti.",
      "weValueG2Q2Title": "È anche grazie alle opportunità di imparare offerte presso la struttura in cui lavoro che possiamo offrire un servizio di lusso.",
      "weValueG2Q3Title": "Presso la struttura in cui lavoro sentiamo di possedere le conoscenze e le capacità necessarie per offrire un servizio di lusso.",
      "weValueG2Q4Title": "Presso la struttura in cui lavoro abbiamo creato un ambiente di lusso impeccabile, senza alcuna mancanza.",
      "weValueG2Q5Title": "Anche se incontriamo un problema che non è di competenza del nostro reparto, sentiamo di poter intervenire e occuparci delle preoccupazioni degli ospiti.",
      "weValueG3Q1Title": "Presso la struttura in cui lavoro riceviamo da parte dei nostri colleghi lo stesso livello di attenzione che offriamo agli ospiti.",
      "weValueG3Q2Title": "La struttura in cui lavoro incarna i valori del marchio.",
      "weValueG3Q3Title": "Presso la struttura in cui lavoro, quando ci prodighiamo per creare un'esperienza di lusso per gli ospiti, il nostro lavoro viene riconosciuto e apprezzato.",
      "weValueG3Q4Title": "Presso la struttura in cui lavoro ci viene offerta la possibilità di essere coinvolti in tutte le attività del reparto volte a migliorare il modo in cui lavoriamo.",
      "weValueG3Q5Title": "Presso la struttura in cui lavoro l'ospite è sempre al primo posto."
    }
  },
  "gateway": {
    "planProgressComplete": "%{label} COMPLETATO",
    "gatewayProgressComplete": "PROGRESSI SUL PERCORSO THE GATEWAY",
    "heroMobileGatewayVideoText": "Guarda il video The Gateway",
    "basics": "NOZIONI DI BASE",
    "brandResources": "RISORSE SUL MARCHIO",
    "disciplineResources": "RISORSE SUDDIVISE PER MATERIA",
    "immersionResourcesHeader": "Risorse introduttive",
    "brandCommunicationsHeaader": "Comunicazioni sul marchio",
    "brandContactsHeader": "Contatti per il marchio",
    "franchisedActionItemsText": "Le azioni da completare forniscono una guida per organizzarti meglio e informazioni introduttive chiave. Questi componenti non sono obbligatori e sono da considerarsi in aggiunta alle informazioni ricevute dalla ditta che gestisce il franchising.",
    "global": "GLOBALE",
    "continent": "CONTINENTE",
    "viewed": "VISUALIZZATO",
    "completed": "COMPLETATO",
    "heading": "The Gateway",
    "basicsTab": "NOZIONI DI BASE",
    "onboardingTab": "Azioni da completare",
    "brandTab": "Risorse sul marchio",
    "disciplinesTab": "RISORSE SUDDIVISE PER MATERIA",
    "basicsHeader": "Benvenuto/a a Marriott International",
    "basicsHeaderNewBrandOrRole": "Benvenuto/a,",
    "basicsText": "Sappiamo che c'è molto da imparare all'inizio di una carriera. Abbiamo selezionato alcune informazioni di base che possono esserti utili per i tuoi primi giorni sul lavoro. È possibile che alcuni di questi argomenti saranno approfonditi ulteriormente nel corso delle sessioni di orientamento o di presentazione del marchio, ma puoi incominciare da qui.",
    "basicsTextGms": "Sappiamo che c'è molto da imparare all'inizio di una carriera. Abbiamo selezionato alcune informazioni di base che possono esserti utili per i tuoi primi giorni sul lavoro. È possibile che alcuni di questi argomenti saranno approfonditi ulteriormente nel corso delle sessioni di orientamento o di presentazione del marchio, ma puoi incominciare da qui. Una volta che hai completato questa sezione, puoi passare alle altre pagine di The Gateway, come Azioni da completare, Risorse sul marchio e Risorse suddivise per materia.",
    "basicsTextFranchise": "Dato che sappiamo che c'è molto da imparare, abbiamo selezionato alcune informazioni di base che possono esserti utili per i tuoi primi giorni sul lavoro. Considera queste informazioni in aggiunta a quelle ricevute nel corso delle sessioni di orientamento o di presentazione del marchio o fornite dalla ditta che gestisce il franchising.",
    "basicsTextFranchiseGms": "Dato che sappiamo che c'è molto da imparare, abbiamo selezionato alcune informazioni di base che possono esserti utili per i tuoi primi giorni sul lavoro. Considera queste informazioni in aggiunta a quelle ricevute nel corso delle sessioni di orientamento o di presentazione del marchio o fornite dalla ditta che gestisce il franchising. Una volta che hai completato questa sezione, puoi passare alle altre pagine di The Gateway, come Azioni da completare, Risorse sul marchio e Risorse suddivise per materia.",
    "basicsTextNewToRole": "Sappiamo che c'è molto da imparare quando si assume un nuovo ruolo. Abbiamo selezionato alcune informazioni di base che possono esserti utili per i tuoi primi giorni sul lavoro. È possibile che alcuni di questi argomenti saranno approfonditi ulteriormente nel corso di sessioni di formazione, ma puoi incominciare da qui.",
    "basicsTextNewToRoleGms": "Sappiamo che c'è molto da imparare quando si assume un nuovo ruolo. Abbiamo selezionato alcune informazioni di base che possono esserti utili per i tuoi primi giorni sul lavoro. È possibile che alcuni di questi argomenti saranno approfonditi ulteriormente nel corso di sessioni di formazione, ma puoi incominciare da qui. Una volta che hai completato questa sezione, puoi passare alle altre pagine di The Gateway, come Azioni da completare, Risorse sul marchio e Risorse suddivise per materia.",
    "basicsTextNewToRoleFranchise": "Dato che sappiamo che c'è molto da imparare, abbiamo selezionato alcune informazioni di base che possono esserti utili per i tuoi primi giorni nel nuovo ruolo. Considera queste informazioni in aggiunta a quelle fornite dalla ditta che gestisce il franchising.",
    "basicsTextNewToRoleFranchiseGms": "Dato che sappiamo che c'è molto da imparare, abbiamo selezionato alcune informazioni di base che possono esserti utili per i tuoi primi giorni nel nuovo ruolo. Considera queste informazioni in aggiunta a quelle fornite dalla ditta che gestisce il franchising. Una volta che hai completato questa sezione, puoi passare alle altre pagine di The Gateway, come Azioni da completare, Risorse sul marchio e Risorse suddivise per materia.",
    "basicsTextNewToBrand": "Sappiamo che c'è molto da imparare quando si assume un nuovo ruolo con %{brandName}. Abbiamo selezionato alcune informazioni di base che possono esserti utili per i tuoi primi giorni sul lavoro. È possibile che alcuni di questi argomenti saranno approfonditi ulteriormente nel corso delle sessioni di orientamento o di presentazione del marchio, ma puoi incominciare da qui.",
    "basicsTextNewToBrandGms": "Sappiamo che c'è molto da imparare quando si assume un nuovo ruolo con %{brandName}. Abbiamo selezionato alcune informazioni di base che possono esserti utili per i tuoi primi giorni sul lavoro. È possibile che alcuni di questi argomenti saranno approfonditi ulteriormente nel corso delle sessioni di orientamento o di presentazione del marchio, ma puoi incominciare da qui. Una volta che hai completato questa sezione, puoi passare alle altre pagine di The Gateway, come Azioni da completare, Risorse sul marchio e Risorse suddivise per materia.",
    "basicsTextNewToBrandFranchise": "Dato che sappiamo che c'è molto da imparare, abbiamo selezionato alcune informazioni di base che possono esserti utili per i tuoi primi giorni nel nuovo ruolo. Considera queste informazioni in aggiunta a quelle ricevute nel corso delle sessioni di orientamento o di presentazione del marchio o fornite dalla ditta che gestisce il franchising.",
    "basicsTextNewToBrandFranchiseGms": "Dato che sappiamo che c'è molto da imparare, abbiamo selezionato alcune informazioni di base che possono esserti utili per i tuoi primi giorni nel nuovo ruolo con %{brandName}. Considera queste informazioni in aggiunta a quelle ricevute nel corso delle sessioni di orientamento o di presentazione del marchio o fornite dalla ditta che gestisce il franchising. Una volta che hai completato questa sezione, puoi passare alle altre pagine di The Gateway, come Azioni da completare, Risorse sul marchio e Risorse suddivise per materia.",
    "disciplineHeader": "Risorse suddivise per materia",
    "toDo": "OBBLIGATORIO",
    "optional": "FACOLTATIVO",
    "overallComplete": "% DI COMPLETAMENTO TOTALE",
    "keyResourcesDesc": "Informazioni sugli aspetti principali a supporto delle attività operative quotidiane, dell'esperienza degli ospiti e delle esigenze commerciali.",
    "franchiseFooter": "Il materiale fornito contiene raccomandazioni e pratiche consigliate che possono contribuire a una maggiore conformità agli standard del marchio. In quanto franchisor, Marriott non interferisce in nessun aspetto delle attività di gestione e operative di un hotel o di un'azienda affiliata. Gli affiliati mantengono il controllo sulle attività operative quotidiane dei loro hotel e sono responsabili dei loro dipendenti e delle loro prassi di lavoro.",
    "landingHeroTitle1": "Benvenuto/a a Marriott International.",
    "landingHeroTitle2": "Il tuo percorso inizia qui.",
    "landingHeroText": "The Gateway è lo strumento che ti accompagnerà nel corso del primo anno in questo nuovo ruolo, mettendo a tua disposizione le informazioni e le risorse di cui hai bisogno per assicurare il tuo successo.",
    "landingHeroTitle1NewToRole": "Benvenuto/a a The Gateway.",
    "landingHeroTitle1NewToBrand": "Benvenuto/a a The Gateway.",
    "landingButtonText": "Inizia",
    "twoMonthslandingHeroTitle1": "Bentornato/a!",
    "twoMonthslandingHeroText": "Se non lo hai ancora fatto, consulta tutto il materiale nella sezione Nozioni di base in The Gateway!",
    "twoMonthslandingButtonText": "Accedi a The Gateway",
    "twoMonthslandingHeroTitle1NewToBrand": "Bentornato/a!",
    "twoMonthslandingHeroTextNewToBrand": "Ci auguriamo che tutto sia andato bene nei tuoi primi giorni con %{brandName}. Non dimenticarti di proseguire nel tuo percorso e accedere a tutto il necessario in The Gateway.",
    "twoMonthslandingButtonTextNewToBrand": "Prosegui",
    "threeMonthslandingHeroTitle1": "Sei sulla buona strada!",
    "threeMonthslandingHeroText": "Tutto quello che devi sapere su Marriott International lo trovi in The Gateway.",
    "threeMonthslandingButtonText": "Accedi a The Gateway",
    "threeMonthslandingHeroTitle1NewToRole": "Sei sulla buona strada!",
    "threeMonthslandingHeroTextNewToRole": "Tutto quello che devi sapere per avere successo nel tuo nuovo ruolo lo trovi in The Gateway.",
    "threeMonthslandingButtonTextNewToRole": "Accedi a The Gateway",
    "threeMonthslandingHeroTitle1NewToBrand": "Bentornato/a!",
    "threeMonthslandingHeroTextNewToBrand": "Congratulazioni per aver completato i primi %{numDays} giorni con il njuovo marchio. Puoi accedere alle azioni che devi completare e a tutte le risorse sul marchio e sulle varie materie quando ne hai bisogno.",
    "threeMonthslandingButtonTextNewToBrand": "Accedi a The Gateway",
    "fourMonthslandingHeroTitle1": "Ottimo! Hai completato tutto il materiale nella sezione Nozioni di base.",
    "fourMonthslandingHeroText": "Accedi a tutto quello che devi sapere su Marriott International in The Gateway.",
    "fourMonthslandingButtonText": "Accedi a The Gateway",
    "fourMonthslandingHeroTitle1NewToRole": "Ottimo! Hai completato tutto il materiale nella sezione Nozioni di base.",
    "fourMonthslandingHeroTextNewToRole": "Accedi a tutto quello che devi sapere per avere successo nel tuo nuovo ruolo in The Gateway.",
    "fourMonthslandingButtonTextNewToRole": "Accedi a The Gateway",
    "fourMonthslandingHeroTitle1NewToBrand": "The Gateway - Punto di controllo",
    "fourMonthslandingHeroTextNewToBrand": "Se non lo hai ancora fatto, non dimenticarti di completare tutte le azioni in sospeso prima della fine del tuo primo anno con %{brandName} e di usare le risorse di sviluppo disponibili qui nella Digital Learning Zone. Fai clic su Continua per procedere nel percorso.",
    "fourMonthslandingButtonTextNewToBrand": "Continua",
    "gmSecondVisitlandingHeroTitle1": "Bentornato/a!",
    "gmSecondVisitlandingHeroText": "Ci auguriamo che tutto sia andato bene nei tuoi primi giorni. Non dimenticarti di proseguire nel tuo percorso per continuare a crescere nel tuo nuovo ruolo.",
    "gmSecondVisitlandingButtonText": "Prosegui",
    "gmSecondVisitlandingHeroTextNewToRole": "Ci auguriamo che tutto sia andato bene nei tuoi primi nel nuovo ruolo. Non dimenticarti di proseguire nel tuo percorso e accedere a tutto il necessario in The Gateway.",
    "gmSecondVisitlandingHeroTextNewToBrand": "Ci auguriamo che tutto sia andato bene nei tuoi primi giorni con %{brandName}. Non dimenticarti di proseguire nel tuo percorso e accedere a tutto il necessario in The Gateway.",
    "gmThirdVisitlandingHeroTitle1": "Bentornato/a!",
    "gmThirdVisitlandingHeroText": "Congratulazioni per aver completato i primi %{numDays} giorni nella tua nuova posizione lavorativa. Puoi accedere alle azioni che devi completare e a tutte le risorse sul marchio e sulle varie materie quando ne hai bisogno.",
    "gmThirdVisitlandingButtonText": "Accedi a The Gateway",
    "gmThirdVisitlandingHeroTextNewToRole": "Congratulazioni per aver completato i primi %{numDays} giorni nel tuo nuovo ruolo. Puoi accedere alle azioni che devi completare e a tutte le risorse sul marchio e sulle varie materie quando ne hai bisogno.",
    "gmThirdVisitlandingHeroTextNewToBrand": "Congratulazioni per aver completato i primi %{numDays} giorni con il nuovo marchio. Puoi accedere alle azioni che devi completare e a tutte le risorse sul marchio e sulle varie materie quando ne hai bisogno.",
    "gmSevenMonthslandingHeroTitle1": "Sembra che ci siano ancora delle azioni che devi completare.",
    "gmSevenMonthslandingHeroText": "Non dimenticarti di completare tutte le azioni in sospeso prima della fine del tuo primo anno e di usare le risorse di sviluppo disponibili qui nella Digital Learning Zone. Fai clic su Continua per procedere nel percorso.",
    "gmSevenMonthslandingButtonText": "Continua",
    "gmSevenMonthslandingHeroTitle1NewToRole": "The Gateway - Punto di controllo",
    "gmSevenMonthslandingHeroTextNewToRole": "Se non lo hai ancora fatto, non dimenticarti di completare tutte le azioni in sospeso prima della fine del tuo primo anno nel nuovo ruolo e di usare le risorse di sviluppo disponibili qui nella Digital Learning Zone. Fai clic su Continua per procedere nel percorso.",
    "gmSevenMonthslandingHeroTitle1NewToBrand": "The Gateway - Punto di controllo",
    "gmSevenMonthslandingHeroTextNewToBrand": "Se non lo hai ancora fatto, non dimenticarti di completare tutte le azioni in sospeso prima della fine del tuo primo anno con %{brandName} e di usare le risorse di sviluppo disponibili qui nella Digital Learning Zone. Fai clic su Continua per procedere nel percorso.",
    "gmOneYearlandingHeroTitle1": "Congratulazioni! È 1 anno che stai lavorando con %{brandName}!",
    "gmOneYearlandingHeroTitle1NewToRole": "Congratulazioni per aver completato 1 anno nel nuovo ruolo!",
    "gmOneYearlandingHeroTitle1NewToBrand": "Congratulazioni per aver completato 1 anno nel nuovo ruolo con %{brandName}!",
    "gmOneYearlandingHeroText": "Anche se il percorso The Gateway finisce qui, il meglio deve ancora venire. Puoi accedere alle risorse dalla scheda The Gateway in qualsiasi momento.",
    "gmOneYearlandingButtonText": "Visualizza The Gateway"
  },
  "resources": {
    "iptTooltip": "Usa virgole per separare sedi diverse",
    "iptAllFunctionTooltip": "Deseleziona per poter specificare mansioni lavorative specifiche. Lascia questa opzione selezionata per visualizzare il materiale di formazione per tutte le mansioni per la sede indicata.",
    "iptAllAWRTooltip": "Deseleziona per poter specificare altre responsabilità professionali specifiche. Lascia questa opzione selezionata per visualizzare il materiale di formazione per tutte le altre responsabilità professionali per la sede indicata.",
    "editJFTooltip": "Usa virgole per separare voci diverse"
  },
  "Notification Center: ": "Segna come non ancora letta",
  "Notification Center: Empty": "Tutto aggiornato!",
  "Notification Center: Individual Delete": "Sei sicuro di voler eliminare questa notifica? Le notifiche eliminate saranno spostate nella sezione Eliminate del Centro notifiche fino alla scadenza.",
  "Notification Center: Delete All": "Sei sicuro di voler eliminare tutte le notifiche? Tutte le notifiche saranno spostate nella sezione Eliminate del Centro notifiche fino alla scadenza.",
  "Notification Center: Mark All As Read": "Segna tutte come già lette",
  "Notification Center: Restore All": "Ripristina tutte",
  "Notification Center: Delete Confirmation": "Elimina",
  "Warning Message Text: Remove Job Function or AWR": "Rimuovere la mansione lavorativa o responsabilità professionale aggiuntiva (AWR)?\r\nInviando la richiesta, la mansione %{Function and/or AWR} sarà rimossa da %{count} partecipanti.",
  "Warning Message Text: Add Job Function or AWR": "Aggiungere una mansione lavorativa o responsabilità professionale aggiuntiva (AWR)?\r\nInviando la richiesta, la mansione %{Function and/or AWR} sarà aggiunta a %{count} partecipanti.",
  "Button": "Invia",
  "Success Message Text: Add Job Function and/or AWR": "Operazione riuscita\r\nLa mansione %{Job Function and/or AWR} è stata aggiunta ai dipendenti indicati presso %{location}. \r\n\r\nVorresti modificare mansioni lavorative o responsabilità professionali aggiuntive per %{location}?",
  "Message Text:AWR": "Operazione riuscita\r\nLe responsabilità professionali aggiuntive %{ __} sono state aggiunte ai dipendenti indicati presso %{ __}.\r\n\r\nVorresti modificare mansioni lavorative o responsabilità professionali aggiuntive per %{location, property, learner(s)}?",
  "Success Message Text: Remove Job Function": "Operazione riuscita\r\nLa mansione %{Job Function and/or AWR} è stata rimossa dai dipendenti indicati presso %{location}. \r\n\r\nVorresti modificare mansioni lavorative o responsabilità professionali aggiuntive per %{location}?",
  "Success Message Text: Remove AWR": "Operazione riuscita\r\nLa responsabilità professionale aggiuntiva %{ __} è stata rimossa dai dipendenti indicati presso %{ __}.\r\n\r\nVorresti modificare mansioni lavorative o responsabilità professionali aggiuntive per %{location, property, learner(s)}?",
  "Tool Tip - Managed Change Email": "Per modificare l'email, accedi a mHUB > HR Direct > Io > Le mie informazioni pubbliche. Attieniti alle indicazioni per aggiornare le informazioni di contatto. Le informazioni aggiornate nell'email appariranno entro 1-2 giorni lavorativi.",
  "Tool Tip - Franchised  Change Email": "Per modificare l'email, rivolgiti al tuo responsabile o all'amministratore dello strumento EID Tool per il franchising. Le informazioni aggiornate nell'email appariranno entro 1-2 giorni lavorativi.",
  "User Profile: Email Opt-in": "No, grazie",
  "User Profile: Email Frequency": "Riepilogo settimanale",
  "Generic Notification Email Subject Line:": "Sono presenti nuove notifiche in DLZ.",
  "Generic Body Text (<150 Characters)": "Visita DLZ per accedere alle informazioni.",
  "All Emails: Signature": "Digital Learning Zone - Imparare è facile. A partire da te.",
  "All Emails: Footer": "Puoi annullare la sottoscrizione e decidere di non ricevere più notifiche via email in qualsiasi momento. Accedi al tuo profilo in Digital Learning Zone per aggiornare le preferenze.",
  "Overdue Emails: Subject Line: ": "In DLZ sono presenti delle assegnazioni per te",
  "Overdue Emails: Body Test": "Hai delle assegnazioni scadute. Accedi a DLZ per vedere le tue assegnazioni.",
  "exploreLearning": {
    "sharpenYourSkillsTitle": "Affinamento delle competenze",
    "sharpenYourSkillsSubTitle": "Acquisisci le conoscenze e le competenze di cui hai bisogno per progredire nella tua carriera, ora e in futuro.",
    "growYourCareerTitle": "Avanzamento di carriera",
    "growYourCareerSubTitle": "Pianifica la prossima mossa valutando quali competenze specializzate o corsi di formazione incrociata ti aiuterebbero ad approfondire i tuoi interessi specifici e raggiungere i tuoi obiettivi.",
    "additionalResourcesTitle": "Altre risorse",
    "additionalResourcesSubTitle": "Explora getAbstract® e CultureWizard™ e scopri come le risorse in DLZ possono promuovere la tua crescita.",
    "tourHeader": "Ti diamo il benvenuto alla versione aggiornata di DLZ. Qui puoi trovare tutto ciò di cui hai bisogno, quando ne hai bisogno. Abbiamo inoltre aggiornato la funzione Esplora i corsi e reso il tuo piano più personalizzabile. Fai il tour!",
    "tourBtnSkip": "Ignora",
    "tourBtnNext": "Chiudi (6/6)",
    "tourSearchBar": "Trova quello di cui hai bisogno, quando ne hai bisogno, con la nuova funzione di ricerca, ancora più potente.",
    "tourBtnBack": "Indietro",
    "tourMyPlan": "Ora il tuo percorso di formazione è ancora più su misura, in quanto puoi aggiungere elementi al tuo piano mediante le funzioni Cerca ed Esplora.",
    "tourExploreLearningLink": "La funzione Esplora i corsi sarà comunque sempre accessibile da qui!",
    "tourExploreLearningProfile": "Puoi sfruttare al meglio le opportunità di formazione accedendo regolarmente al tuo profilo, ad esempio per verificare le mansioni lavorative, in modo da mantenere sempre aggiornato il tuo piano.",
    "mhubTraining": "Formazione mHUB",
    "growthSkills": "Crescita personale",
    "leadershipSkills": "Competenze manageriali",
    "virtualTeamResources": "Risorse per team virtuali",
    "jobFunctions": "Mansioni lavorative",
    "brands": "Marchi",
    "languageLearning": "Lingue straniere",
    "become": "Diventa",
    "emeaMentoringProgram": "Programma di affiancamento per l'area EMEA",
    "learningPathways": "Percorsi formativi",
    "mda": "MDA",
    "takeCareGrow": "TakeCare GROW",
    "getAbstract": "getAbstract®",
    "cultureWizard": "CultureWizard™",
    "takeCareCommunityHub": "Centro della comunità TakeCare",
    "mhubTrainingDesc": "Percorsi formativi in base al ruolo e corsi su argomenti specifici su come usare mHUB.",
    "growthSkillsDesc": "Accesso a conoscenze pratiche su vari argomenti e per svariate mansioni lavorative.",
    "leadershipSkillsDesc": "Indicazioni su come gestire e preparare un team e diventare un leader più efficace.",
    "virtualTeamResourcesDesc": "Tecniche che promuovono la collaborazione e una cultura inclusiva all'interno di team virtuali.",
    "jobFunctionsDesc": "Risorse per approfondire le conoscenze sulla propria mansione lavorativa e su quelle degli altri.",
    "brandsDesc": "Informazioni sui vari marchi di Marriott International.",
    "languageLearningDesc": "Strumenti per comunicare in modo efficace con un pubblico internazionale.",
    "becomeDesc": "Un approccio completo volto allo sviluppo della carriera e alla crescita personale costituito da quattro fasi: riflessione, pianificazione, azione e affinamento.",
    "emeaMentoringProgramDesc": "Lo scopo del programma di affiancamento per l'area EMEA è aiutarti a realizzare il tuo potenziale.",
    "learningPathwaysDesc": "Opportunità di formazione specifiche, accuratamente scelte in base al tuo livello come responsabile.",
    "mdaDesc": "Lo scopo della Marriott Development Academy (MDA) è aiutarti a sviluppare capacità funzionali e manageriali.",
    "takeCareGrowDesc": "Fonti di ispirazione e risorse per la promozione dell'inclusività all'interno della comunità.",
    "getAbstractDesc": "Riassunti di libri che ti aiutano a imparare ogni giorno qualcosa di nuovo, per poter prendere decisioni migliori.",
    "cultureWizardDesc": "Informazioni preziose per una comunicazione efficace tra culture diverse.",
    "takeCareCommunityHubDesc": "Attività di gruppo, programmi di formazione e strumenti per l'iniziativa TakeCare.",
    "searchAllLearning": "Cerca tra tutti i corsi",
    "searchBarPlaceHolder": "C\\'è sempre qualcosa da imparare.",
    "clearFilters": "Rimuovi filtri",
    "noResults": "Nessun risultato",
    "hasBeenUnpinned": "{Variable-tile_Name} non è più contrassegnato.",
    "hasBeenPinned": "{Variable-tile_Name} è stato contrassegnato.",
    "lpRemovedFromPlan": "Il percorso formativo è stato rimosso dal piano.",
    "courseRemovedFromPlan": "Il corso è stato rimosso dal piano.",
    "lpAddedToPlan": "Il percorso formativo è stato aggiunto al piano.",
    "courseAddedToPlan": "Il corso è stato aggiunto al piano.",
    "clearSelection": "Annulla selezione",
    "removeFromMyPlan": "Rimuovi dal piano",
    "addToMyPlan": "Aggiungi al piano",
    "loadingResults": "Caricamento in corso",
    "viewDetails": "Visualizza dettagli",
    "clearAll": "Cancella tutto",
    "noOptions": "Nessuna opzione disponibile",
    "viewLess": "Mostra meno",
    "viewMore": "Mostra di più",
    "recentSearch": "Ricerche recenti",
    "exploreLearningTitle": "Esplora i corsi",
    "exploreLearningHelpText": "Non sai cosa stai cercando di preciso?",
    "exploreMore": "Esplora altre opportunità.",
    "viewAllResults": "Visualizza tutti i <#> risultati",
    "searchSubTitle": "Cerca corsi specificando parole chiave, titoli o ID contenuto.",
    "pinnedLearningTitle": "Corsi contrassegnati",
    "landingSubTitle": "Percorsi formativi su misura. Esplora i tuoi interessi, con nuovi argomenti e corsi continuamente aggiunti.",
    "columnTitle": "Titolo",
    "columnDesc": "Descrizione",
    "columnType": "Tipo",
    "columnAddedToPlan": "Aggiungi/Aggiunto al piano",
    "columnComplete": "Completato",
    "columnDetails": "Dettagli",
    "additionalSearchFilters": "Filtri di ricerca aggiuntivi",
    "tourBtnClose": "Chiudi",
    "tourBtnLast": "Chiudi",
    "resultsFor": "Risultati per ",
    "resultsFortheFilter": "Risultati per il filtro {Filter Name}",
    "no": "No",
    "results": "Risultati",
    "courseTooltipTitle": "Una singola proposta di formazione che può contenere una o più attività.<br />Un corso può essere online, ad esempio sotto forma di video o contenuto eLearning, o in presenza all'interno di una classe.",
    "gatewayToolTipTitle": "Risorse digitali per i nuovi assunti, per i nuovi dirigenti e per i direttori generali che si avvicinano per la prima volta a un marchio.",
    "speciallizationOrPathwayTooltipTitle": "Un insieme di corsi incentrati su un particolare argomento.",
    "taskTooltipTitle": "Istruzioni per portare a termine un'attività (di solito sul lavoro) e possibilità di indicare quando è stata completata.",
    "webSiteTooltipTitle": "Qualsiasi sito Web, tra cui quelli di Marriott ed esterni."
  },
  "dontShowAgain": "Non mostrare più",
  "deletionWarningEnabled": "Avviso di eliminazione abilitato",
  "deletionSelected": "Se selezionato, appare un messaggio di avviso ogni volta che viene eliminata una notifica.",
  "manageEmailNotifications": "Gestione delle notifiche tramite e-mail",
  "seeHowDLZContinue": "Scopri come le risorse in DLZ possono promuovere la tua crescita.",
  "Notification Center:Overdue": "È presente una nuova proposta di formazione la cui scadenza era il {Variable date ddmmyy}.",
  "accesDenied": {
    "title": "Stiamo preparando l'accesso al tuo percorso di formazione! ",
    "subtitle": "Il tuo account è in fase di creazione e sarà disponibile un paio di giorni dopo l'attivazione dell'EID. Se passano più di 2 giorni, contatta il Centro di assistenza mHUB."
  },
  "jfTour": {
    "title": "Ora puoi vedere e modificare le mansioni lavorative e le responsabilità professionali aggiuntive (AWR) selezionate direttamente nell'area Mio programma."
  },
  "onboardingPlan": {
    "requiredDescription": "Di seguito sono riportati i corsi che devono essere completati nel periodo di tempo che hai selezionato in Mio programma. Collabora con il tuo responsabile per programmare il tempo necessario per completare la formazione richiesta per il passaggio al tuo nuovo ruolo. La formazione si articola su una varietà di argomenti, quindi è importante che in caso di domande tu ti rivolga al tuo responsabile e ai tuoi colleghi durante il percorso.   ",
    "basicsDescription": "Dato che sappiamo che c'è molto da imparare, abbiamo selezionato alcune nozioni di base che possono esserti utili per i tuoi primi giorni sul lavoro. Considera queste informazioni in aggiunta a quelle ricevute nel corso delle sessioni di orientamento o di presentazione del marchio o fornite dalla ditta che gestisce il franchising. Sono risorse che risulteranno preziose nel corso della tua collaborazione con noi e ti aiuteranno a inserire nuovi membri nel tuo team.",
    "recommendedDescription": "Dopo i primi 90 giorni, troverai altri corsi che sono stati consigliati dal tuo responsabile. Puoi anche esaminare argomenti di tuo interesse in totale autonomia. Per il momento, concentrati sulla formazione di base. ",
    "day1": "Scadenza in 14 giorni",
    "day2": "Scadenza in 30 giorni",
    "day3": "Scadenza in 60 giorni",
    "day4": "Scadenza in 90 giorni",
    "day5": "Scadenza oltre 90 giorni "
  },
  "onboardingLanding": {
    "gettingStartedDescription": "La Digital Learning Zone è il tuo punto di partenza per la formazione e lo sviluppo professionali. Di seguito troverai contenuti selezionati che ti aiuteranno a iniziare il tuo percorso. Tutti i contenuti della formazione saranno sempre disponibili nella sezione Mio programma.  ",
    "overallLearningProgress": "Progresso nella formazione globale",
    "learningProgressLabel": "Progresso nella formazione",
    "learningProgressDescription": "Qui sono indicati i tuoi progressi all'interno del programma di formazione obbligatorio. Questa barra rappresenta lo stato di tutti i corsi di formazione obbligatori. Ottimo lavoro! Continua così."
  },
  "poc": {
    "dlz": "Perché questo corso di formazione risulti completato, i responsabili o i coordinatori della formazione devono contrassegnarlo come completato in mHUB. I dettagli della procedura da seguire sono riportati nella Guida all'implementazione.",
    "mhub": "Franchising: se preferisci continuare a usare un programma di pulizia di base già in uso, includi il nome e i dettagli del programma nella sezione dei commenti quando contrassegni la formazione come completata"
  },
  "Recommended Courses ": "Dopo i primi 90 giorni, troverai altri corsi che sono stati consigliati dal tuo responsabile. Puoi anche esaminare argomenti di tuo interesse in totale autonomia. Per il momento, concentrati sulla formazione di base. ",
  "survey": {
    "v2": {
      "weValueButtonText": "Partecipa al sondaggio",
      "lbiIntro": "Benvenuto nel Check-in Luxury Behavior!",
      "lbiDescription": `<p>Di seguito ti verrà chiesto di leggere alcuni brevi scenari che potrebbero verificarsi nella tua proprietà. Leggi ogni scenario e scegli in che modo ti comporteresti in ciascuna situazione. Nel dare la tua risposta, tieni a mente quanto segue:</p>
      <ul>
          <li>
              <p>Più di una tra le risposte disponibili potrebbe sembrarti corretta. Scegli quella che più probabilmente applicheresti sul lavoro.</p>
          </li>
          <li>
              <p>Supponiamo che non ci siano barriere comunicative: parli la stessa lingua dell’ospite o del dipendente del resort presente nello scenario.</p>
          </li>
          <li>
              <p>Lo scenario potrebbe chiederti di immaginarti in un ruolo lavorativo diverso da quello in cui ti trovi attualmente. Rispondi al meglio delle tue conoscenze, sulla base della tua esperienza come professionista dell’ospitalità di lusso.</p>
          </li>
      </ul>
      <p><br></p>
      <p>Nota bene: La tua partecipazione a questo check-in è completamente volontaria (puoi scegliere di non partecipare) e le tue risposte saranno totalmente riservate. I risultati di questo check-in saranno riportati per gruppi di persone e non per singoli individui. Le risposte individuali saranno valutate dal team di ricerca e non saranno condivise con i Manager of Others o i superiori.</p>
      <p>Completando questo check-in, acconsenti alla raccolta e al trattamento delle tue risposte e dei tuoi dati personali (ad es., durata del servizio, sesso, qualifica professionale), nonché al trasferimento transfrontaliero dei tuoi dati personali al Gruppo Marriott. Questa procedura è conforme all’Informativa sulla privacy dei dati dei dipendenti Marriott; puoi trovare ulteriori informazioni e contatti di riferimento nell’Informativa Marriott sulla privacy dei dati.</p>
      `,
      "checkin": {
        q1Title: "Stai attraversando la lobby e noti una famiglia con tre bambini piccoli. La famiglia ha molte valigie, sembra stressata da una lunga giornata di viaggio, i bambini si lamentano e sono visibilmente irrequieti. Cosa fai?",
        q2Title: "Hai appena iniziato a lavorare in una proprietà situata in una zona che ti è poco familiare. Un ospite ti chiede suggerimenti su cosa fare nei dintorni: ha letto diverse guide ma sta cercando qualcosa di più personalizzato. Cosa fai? ",
        q3Title: "Stai attraversando la lobby quando senti un ospite parlare al telefono e scopri che il suo servizio auto per l’aeroporto è in ritardo. La tua proprietà non ha un’auto oppure l’auto non è disponibile. Cosa fai? ",
        q4Title: "Stai attraversando la lobby per andare a svolgere un compito urgente che ti è stato assegnato dal tuo manager. Noti che un collega ha difficoltà a portare a termine un compito che è solitamente svolto da due persone. Cosa fai? ",
        q5Title: "Sei un host nel ristorante dell’hotel. Un ospite chiede di vedere il menu mentre attende il suo tavolo prenotato. Dopo aver consultato il menu, l’ospite è frustrato perché il ristorante dell’hotel gli è stato suggerito da un altro collega, ma tra le pietanze non c’è niente che soddisfi le sue esigenze alimentari. Cosa fai?",
        q6Title: "Un gruppo numeroso di ospiti nell’affollata area della piscina non ha trovato posti a sedere vicini gli uni agli altri. Di conseguenza, hanno posato i propri asciugamani ed effetti personali in modo tale da bloccare un passaggio principale, creando un pericolo per gli ospiti e i dipendenti che cercano di accedere all’area della piscina. L’area deve essere liberata, ma non vuoi indisporre il gruppo. Cosa fai? ",
        q1R1Title: "Aiuti la famiglia con il bagaglio, la indirizzi verso il banco check-in e suggerisci alcuni luoghi nelle vicinanze in cui sia possibile rilassarsi con attività adatte alle famiglie. Elenchi i servizi per famiglie presenti nella proprietà.",
        q1R2Title: "Ti offri di aiutare con il bagaglio e conduci la famiglia al banco check-in. Quindi, predisponi un servizio rilassante o un omaggio per i genitori e delle attività divertenti per i bambini da svolgere in camera.",
        q1R3Title: "Conduci i genitori al banco check-in quindi, mentre i genitori effettuano il check-in, porti personalmente i bambini in un’area salotto e offri loro uno spuntino. Chiedi alla reception di informare i genitori in merito alle aree gioco e alle attività per bambini a loro disposizione nella proprietà. ",
        q1R4Title: "Indirizzi la famiglia verso il banco check-in per agevolare la procedura. Elenchi ai genitori le aree e i servizi dedicati ai bambini, di modo che i figli possano avere un piacevole soggiorno.",
        q2R1Title: "Chiedi all’ospite quali sono i suoi interessi, quindi collabori con un collega per stilare un elenco di attività divertenti. Inoltre, gli suggerisci un buon ristorante nelle vicinanze.",
        q2R2Title: "Parli con l’ospite dei suoi interessi. Collabori con un collega per stilare un elenco di attività divertenti basato sulle sue preferenze. Inoltre, controlli il profilo Bonvoy dell’ospite e mandi un omaggio personalizzato alla sua camera.",
        q2R3Title: "Offri un parere personale condividendo alcune delle attività nella zona che hai apprezzato maggiormente da quando sei arrivato. Fai risaltare i tuoi suggerimenti condividendo ulteriori dettagli delle tue esperienze. Quindi, mandi una gustosa specialità locale alla sua camera.",
        q2R4Title: "Fai sapere all’ospite che sei appena arrivato in questa zona e non hai molte idee. Porgi le tue scuse e gli suggerisci di parlare con un tuo collega più informato. Quindi, chiedi gli chiedi se gradisce ricevere in camera una specialità locale. ",
        q3R1Title: "Mentre l’ospite è ancora al telefono, porti il tuo veicolo personale o quello del tuo collega davanti all’ingresso anteriore della proprietà e informi l’ospite che lo porterai personalmente all’aeroporto.",
        q3R2Title: "Mentre l’ospite è ancora al telefono, stili un elenco di servizi auto e numeri di telefono approvati dalla proprietà. Appena l’ospite chiude la telefonata, gli fornisci l’elenco e ti offri di aiutarlo a organizzare il trasporto.",
        q3R3Title: "Attendi che il cliente chiuda la telefonata e gli comunichi che la proprietà non offre servizi di trasporto, ma che sarai lieto di organizzare altre opzioni di trasferimento. ",
        q3R4Title: "Mentre l’ospite è ancora al telefono, trovi un mezzo di trasporto alternativo. Appena chiude la telefonata, lo informi che hai organizzato il trasferimento per l’aeroporto e che l’auto arriverà a breve.",
        q4R1Title: "Vai dal tuo collega e gli dici che lo aiuterai, ma che devi prima portare a termine il tuo compito urgente. Prometti di tornare presto per dargli il tuo supporto. Ti assicuri di discutere della situazione con il tuo manager.",
        q4R2Title: "In primo luogo, trovi rapidamente un altro collega a cui assegnare il tuo compito. Quindi, aiuti immediatamente il collega che ha bisogno di assistenza. Quando hai finito, comunichi al tuo manager che hai trovato qualcun altro che portasse a termine il tuo compito mentre tu aiutavi il collega. ",
        q4R3Title: "Ti fermi e aiuti subito il tuo collega. Quando hai finito, torni al compito che ti è stato assegnato, quindi comunichi al tuo manager che hai lasciato momentaneamente in sospeso il compito per aiutare un collega.",
        q4R4Title: "Vai dal tuo collega in difficoltà e gli spieghi che sei impegnato con un compito urgente. Interrompi entrambi i compiti, cercate insieme un modo per svolgere entrambi gli incarichi e li portate a termine insieme.",
        q5R1Title: "Porgi le tue scuse all’ospite e lo informi che chiamerai il manager. Ti assicuri di spiegare chiaramente la situazione, di modo che il manager possa trovare una soluzione per le esigenze alimentari dell’ospite.",
        q5R2Title: "Porgi le tue scuse all’ospite e chiedi informazioni sulle sue preferenze alimentari. Chiedi alla brigata in cucina di preparare un piatto appositamente per l’ospite. Inoltre, gli offri in omaggio un antipasto o una bevanda per compensare l’errore.",
        q5R3Title: "Porgi le tue scuse e assicuri all’ospite che comprendi la situazione. Invece di annullare la prenotazione, ti offri di parlare con lo chef per verificare la possibilità di preparare qualcosa di speciale per l’ospite. Gli offri un omaggio o uno sconto per compensare l’inconveniente.",
        q5R4Title: "Porgi le tue scuse e ti offri di aiutare l’ospite a prenotare un tavolo in un ristorante vicino che soddisfi le sue preferenze alimentari. Se necessario, offri informazioni aggiuntive o aiuti l’ospite a raggiungere il ristorante.",
        q6R1Title: "Porgi le tue scuse agli ospiti per la mancanza di spazio e li informi che possono rimanere dove si trovano, a condizione che facciano attenzione al passaggio delle altre persone. ",
        q6R2Title: "Informi gentilmente gli ospiti che non possono bloccare il passaggio e che dovranno trovare un altro posto o aspettare che si liberi lo spazio. Nel frattempo, incarichi i tuoi colleghi di portare altre sedie dalla sala da ballo all’area della piscina. ",
        q6R3Title: "Informi gli ospiti che non possono restare in quella zona e che devono spostarsi temporaneamente mentre tu cerchi di procurare loro altre sedie. Nel frattempo, offri loro la possibilità di stazionare al bar/ristorante. ",
        q6R4Title: "Porgi le tue scuse agli ospiti per la mancanza di posti a sedere. Chiedi se possono riunire i propri effetti personali per creare un piccolo passaggio per le altre persone. Ti offri di aiutarli, assicurandoti che tutti si sentano a proprio agio. Mandi loro un omaggio speciale dal bar/ristorante per rimediare all’inconveniente."
      }
    }
  },
};