import userStore from './UserStore';
import { decorate, observable, action, computed } from 'mobx';
import moment from 'moment'
import lookupStore from './LookupStore';

import CMDBApi from '../api/CMDBApi';

const getTransformedResource = (course) => {
  return {
    id: course.id,
    title: course.title,
    duration: course.duration,
    completionType: course.completionType,
    description: course.shortDescription || (typeof course.description === 'string' && course.description.split('.')[0] + '.'),
    fullDescription: course.description,
    shortDescription: course.shortDescription,
    skipCourseDetails: course.extra.skipCourseDetails,
    newToBrand: course.extra.newToBrand,
    newToRole: course.extra.newToRole,
    newHire: course.extra.newHire,
    disciplines: course.disciplines,
    markCompleteOnLaunch: course.completionType === 'Mark complete on launch',
    isCompleted: course.meta && course.meta.isCompleted,
    dueDate: course.dueDate,
    audience: course.audience,
    source: course.source,
    type: course.type,
    status: course.status,
    url: course.url,
    courseNumber: course.sourceId,
    isRecommended: course.assignmentTypes.includes('2'),
    isManagerRecommended: course.meta && course.meta.isManagerRecommended,
    recommendationType: course.meta && course.meta.recommendationType,
    isRequired: (course.assignmentTypes.includes('1') && course.status === 'Active - Assigned') || course.isRequiredAdditionalCourse,
    completedDate: (course.meta && course.meta.completedOn) ? moment(course.meta.completedOn, 'MMM/D/YYYY').format('MMM/D/YYYY') : null,
    uuid: course.uuid,
    isVideoUrl: course.extra.isVideoUrl,
    descriptionInLightbox: course.extra.descriptionInLightbox,
    files: course.meta ? course.meta.files : [],
    order: course.extra.order ? parseInt(course.extra.order) : 999,
    brandLabels: course.brands.map(b => lookupStore.brandsById[parseInt(b, 10)]),
    brands: course.brands.map(b => parseInt(b, 10)),
    mustDo: course.extra.mustDo
  };
}

const orderResources = (a, b) => {
  return a.order - b.order;
}

class GatewayStore {
  gatewayResources = { basics: [], disciplineResources: [], activities: [], brandResources: [], communicationsResources: [] };
  gatewayBrandContacts = [];
  gatewayBrandCopy = [];
  gatewayContentGroups = [];

  async getGatewayResources() {
    const { data } = await CMDBApi.getGatewayResources();
    this.gatewayResources = data;
  }

  async getGatewayContentGroups() {
    const { data } = await CMDBApi.getContentGroups('gateway')
    this.gatewayContentGroups = data;
  }

  async getGatewayBrandContacts() {
    const { data } = await CMDBApi.getProjectContent('gateway', 'Brand Contact', true, true);

    let contacts = { global: [], continent: [] };
    data.forEach(d => {
      if (d.content.contactType === 'continent') {
        contacts.continent.push(d.content);
      } else if (d.content.contactType === 'global') {
        contacts.global.push(d.content);
      }
    });

    this.gatewayBrandContacts = contacts;
  }

  async getGatewayBrandCopy() {
    const { data } = await CMDBApi.getProjectContent('gateway', 'Gateway Copy', true, false);

    if(data.length > 1) {
      const brandKey = userStore.profile.brandKey ? parseInt(userStore.profile.brandKey) : 31;
      const copy = data.find(d => d.content.brands.includes(brandKey));
      this.gatewayBrandCopy = copy.content;
    } else {
      this.gatewayBrandCopy = data.length > 0 ? data[0].content : '';
    }
  }

  async setUserCompletion(resourceId, isCompleted, completionId) {
    const completion = {
      id: completionId ? completionId : undefined,
      courseId: resourceId,
      completed: isCompleted
    }
    await CMDBApi.createOrUpdateUserCompletion(completion);
  }

  get gatewayBrandImageFolderKey() {
    if (!userStore.profile.brandKey) {
      return 'marriott';
    }

    const keyToFolder = {
      '31': 'marriott',
      '23': 'rc',
      '1': 'ac',
      '2': 'aloft',
      '18': 'autograph',
      '3': 'marriott',
      '4': 'courtyard',
      '5': 'delta',
      '6': 'design',
      '7': 'marriott',
      '8': 'element',
      '10': 'fairfield',
      '11': 'fourpoints',
      '12': 'gaylord',
      '54': 'jw',
      '13': 'lemeridien',
      '19': 'marriott', //marriottConferenceCenters,   // TODO
      '9': 'marriottExecutive',
      '15': 'marriott',
      '16': 'marriottVacation',
      '17': 'moxy',
      '20': 'protea',
      '21': 'renaissance',
      '22': 'residence',
      '24': 'sheraton',
      '25': 'springhill',
      '26': 'sr',
      '14': 'luxury',
      '63': 'rc',
      '27': 'towneplace',
      '28': 'tribute',
      '32': 'marriott', //vistana, // TODO
      '30': 'westin',
      '29': 'w-hotels'
    };

    return keyToFolder[userStore.profile.brandKey];
  }

  get basics() {
    //Continent Leadership Message goes first
    let basics = (this.gatewayResources.basics || []).map(getTransformedResource).sort(orderResources);

    const { user } = userStore;
    const newToRole = _.get(user, 'dlz2.profile.newToRole');
    const newToBrand = _.get(user, 'dlz2.profile.newToBrand');

    basics = _.uniqBy(basics.filter(b => {
      return true;
    }), 'title');

    const clmResourceIndex = basics.findIndex(b => b.title === 'Continent Leadership Message');

    if (clmResourceIndex === -1) {
      return basics;
    } else {
      let orderedBasics = basics.splice(clmResourceIndex, 1);
      orderedBasics = orderedBasics.concat(basics);
      return orderedBasics;
    }

  }

  get brandResources() {
    const resources = this.gatewayResources.brandResources.map(getTransformedResource).sort(orderResources);
    return resources.sort((a, b) => a.brands.toString() - b.brands.toString());
  }

  get disciplineResources() {
    return this.gatewayResources.disciplineResources.map(getTransformedResource).sort(orderResources);
  }

  get communicationsResources() {
    const resources = this.gatewayResources.communicationsResources.map(getTransformedResource).sort(orderResources);
    return resources.sort((a, b) => a.brands.toString() - b.brands.toString());
  }

  get activities() {
    const getCompletePercentage = (resources, mustDoValue) => {
      const completed = resources.reduce((acc, cur) => cur.extra.mustDo === mustDoValue ? (cur.meta.isCompleted ? acc + 1 : acc) : acc, 0);
      const assigned = resources.reduce((acc, cur) => cur.extra.mustDo === mustDoValue ? acc + 1 : acc, 0);

      return assigned === 0 ? 100 : Math.round((completed / assigned) * 100);
    }

    const getTotalCompletePercentage = (resources, mustDoValue) => {
      const completed = resources.reduce((acc, cur) => cur.meta.isCompleted ? acc + 1 : acc, 0);
      const assigned = resources.length;

      return assigned === 0 ? 100 : Math.round((completed / assigned) * 100);
    }
    let sections = [];

    this.gatewayContentGroups.forEach(cg => {
      let resources = [];
      cg.courseOrderMeta.forEach(courseId => {
        const resource = (this.gatewayResources.activities || []).find(r => r.id === courseId);
        if (resource) {
          resources.push(resource);
        }
      });

      resources.sort((a, b) => {
        return a.extra.order - b.extra.order;
      });
      console.log(resources);

      sections.push({
        title: cg.name,
        startDay: cg.extra.startDay,
        endDay: cg.extra.endDay,
        resources: resources,
        toDo: resources.filter(r => r.extra.mustDo),
        optional: resources.filter(r => !r.extra.mustDo),
        order: cg.extra.order,
        optionalCompletePercentage: getCompletePercentage(resources, false),
        requiredCompletePercentage: getCompletePercentage(resources, true),
        totalCompletePercentage: getTotalCompletePercentage(resources)
      });
    });

    return sections.sort((a, b) => {
      return a.order - b.order;
    });
  }

  get completionStats() {
    let required = 0, completed = 0;

    (this.gatewayResources.activities || []).forEach(a => {
      if(a.extra.mustDo) {
        required++;
        completed = a.meta.isCompleted ? completed + 1 : completed;
      }
    });

    (this.gatewayResources.disciplineResources || []).forEach(a => {
      if(a.extra.mustDo) {
        required++;
        completed = a.meta.isCompleted ? completed + 1 : completed;
      }
    });

    (this.gatewayResources.brandResources|| []).forEach(a => {
      if(a.extra.mustDo) {
        required++;
        completed = a.meta.isCompleted ? completed + 1 : completed;
      }
    });

    (this.gatewayResources.communicationsResources|| []).forEach(a => {
      if(a.extra.mustDo) {
        required++;
        completed = a.meta.isCompleted ? completed + 1 : completed;
      }
    });

    return {
      required,
      completed,
      percentComplete: required === 0 ? 100 : Math.round((completed / required) * 100)
    }

  }


}

decorate(GatewayStore, {
  gatewayBrandCopy: observable,
  gatewayResources: observable,
  gatewayContentGroups: observable,
  gatewayBrandContacts: observable,
  gatewayBrandImageFolderKey: computed,
  basics: computed,
  activities: computed,
  disciplineResources: computed,
  brandResources: computed,
  communicationsResources: computed,
  getGatewayBrandCopy: action,
  getGatewayBrandContacts: action,
  getGatewayResources: action,
  getGatewayContentGroups: action,
  setUserCompletion: action
});

export default new GatewayStore();