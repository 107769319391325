// TODO: get logo (not found in https://marrstar.ent.box.com/s/ny28l0xutgzz14h70630qaplnhb8n4qk/folder/53567530890?page=1)
import { themePrimaryColorsBrand } from '../style-constants';


// TODO: set colors (not found in Global Colors file)
export default {
  extendedTheme: () => ({
    logo: new Image().src = 'http://d1ibh6b9498ljl.cloudfront.net/dlz2/images/themes/bulgari/44d9d114-a7ea-4e3a-8f1d-42f5cea9a878.png',
    palette: {
      primary: {
        main: themePrimaryColorsBrand.BG
      },
      secondary: {
        main: '#303e47'
      },
      primaryBtnHover: {
        main: '#9D105C',
      },
      buttonText: {
        main: '#1c1c1c'
      },
      interaction: {
        main: '#b83775'
      },
      text: {
        primary: '#1c1c1c'
      },
      link: {
        primary: '#1c1c1c'
      }
    },
    typography: {
      useNextVariants: true,
      fontFamily: [
        '"ProximaNova-Regular"',
        '"Roboto"',
        '"Helvetica"',
        '"Arial"',
        'sans-serif'
      ].join(','),
    },
  }),
  typography: (theme, remCalc) => {
    return {
      h1: {
        fontFamily: 'GalaxiePolaris-Bold',
        color: '#1c1c1c',
        [theme.breakpoints.up('sm')]: {
          fontSize: remCalc(54),
          lineHeight: remCalc(60)
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: remCalc(34),
          lineHeight: 1.24
        }
      },
      h2: {
        fontFamily: 'GalaxiePolaris-Bold',
        color: '#1c1c1c',
        [theme.breakpoints.up('sm')]: {
          fontSize: remCalc(40),
          lineHeight: 1.15
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: remCalc(30),
          lineHeight: 1.2
        }
      },
      
    }
  },
  extraTypography: (theme, remCalc) => {
    return {
      hero: {
        fontFamily: 'GalaxiePolaris-Bold',
        color: '#1c1c1c',
        [theme.breakpoints.up('sm')]: {
          fontSize: remCalc(44),
          lineHeight: 1.09
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: remCalc(44),
          lineHeight: 1.09
        }
      },
      card: {
        fontFamily: 'GalaxiePolaris-Bold',
        fontWeight: 'bold',
        color: '#1c1c1c',
        [theme.breakpoints.up('sm')]: {
          fontSize: remCalc(38),
          lineHeight: 1.16
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: remCalc(28),
          lineHeight: 1.21
        }
      }
    }
  }
  
};
