import { decorate, observable, action } from 'mobx';
import Ployglot from 'node-polyglot';
import lang from '../lang';
import { merge } from 'lodash';
import LookupStore from './LookupStore';
import CMDBApi from '../api/CMDBApi';

const { defaultLanguageCode, getLanguagePack } = lang;

const supportedLanguages = [{
  key: 'af',
  value: 'Afrikaans'
},
{
  key: 'ar',
  value: 'Arabic'
},
{
  key: 'zh-CN',
  value: 'Chinese, Simplified'
},
{
  key: 'zh-TW',
  value: 'Chinese, Traditional'
},
{
  key: 'fr-FR',
  value: 'French, France'
},
{
  key: 'de',
  value: 'German'
},
{
  key: 'en',
  value: 'English'
},
{
  key: 'hi',
  value: 'Hindi'
},
{
  key: 'id',
  value: 'Indonesian'
},
{
  key: 'it',
  value: 'Italian'
},
{
  key: 'ja',
  value: 'Japanese'
},
{
  key: 'ko',
  value: 'Korean'
},
{
  key: 'pt-BR',
  value: 'Portuguese (Brazil)'
},
{
  key: 'ru',
  value: 'Russian'
},
{
  key: 'es',
  value: 'Spanish, Universal/Mexico'
},
{
  key: 'th',
  value: 'Thai'
},
{
  key: 'tr',
  value: 'Turkish'
},
{
  key: 'vi',
  value: 'Vietnamese'
},
{
  key: 'zu',
  value: 'Zulu'
}];	

const defaultLanguagePack = getLanguagePack(defaultLanguageCode).default;

const getMergedLanguagePack = (languageCode) => {
  const languagePack = getLanguagePack(languageCode).default;
  const merged = {};
  merge(merged, defaultLanguagePack, languagePack);

  return merged;
};

const getPolygotInstance = (languageCode, languagePacks = []) => {
  let languagePack = languageCode === defaultLanguageCode ?
    defaultLanguagePack :
    getMergedLanguagePack(languageCode);

  Object.keys(languagePacks).forEach(pack => {
    languagePack = merge(languagePack, languagePacks[pack].extra[languageCode]);
  });

  const polyglot = new Ployglot({
    locale: languageCode,
    phrases: languagePack
  });

  return polyglot;
}

class LanguageStore {
  constructor() {
    this.lang = getPolygotInstance('en');
    this.languagePacks = {};
  }

  async setLanguage(languageCode) {
    const code = languageCode || defaultLanguageCode;
    this.code = code;
    this.lang = getPolygotInstance(code);
  }

  languageCodeToString(languageCode) {
    const code = supportedLanguages.find(l => l.key === languageCode).value;
    const found = LookupStore.languages.find(l => l.code === code);
    if (found) {
      return found.name;
    } else {
      return code;
    }
  }

  async getRemoteLanguagePack(project, force = false) {
    if (!force && this.languagePacks && this.languagePacks[project] != null) {
      return this.languagePacks[project];
    }

    this.loadingLanguagePack = true;
    const { data } = await CMDBApi.getLanguagePack(project, this.code);
    this.languagePacks[project] = data;

    this.lang = getPolygotInstance(this.code, this.languagePacks);
    this.loadingLanguagePack = false;
  }
}

decorate(LanguageStore, {
  lang: observable,
  code: observable,
  languagePacks: observable,
  setLanguage: action,
  loadingLanguagePack: observable,
  updatingLanguagePack: observable,
  languagePacks: observable,

  getRemoteLanguagePack: action,
});

export default new LanguageStore();