import React, { Component } from 'react';
import { CssBaseline } from '@material-ui/core';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import '../components/icons/FontAwesome';
import ScrollToTop from './ScrollToTop';

import IdleTimer from 'react-idle-timer';

import asyncComponent from '../components/core/asyncComponent';
import ThemeSelector from '../styles/ThemeSelector';

import { Provider } from 'mobx-react';
import userStore from '../stores/UserStore';
import lookupStore from '../stores/LookupStore';
import authStore from '../stores/AuthStore';
import languageStore from '../stores/LanguageStore';
import configStore from '../stores/ConfigStore';
import courseStore from '../stores/CourseStore';
import courseBundleStore from '../stores/CourseBundleStore';
import reportingStore from '../stores/ReportingStore';
import reportingV2Store from '../stores/ReportingV2Store';
import teamStore from '../stores/TeamStore';
import notificationStore from '../stores/NotificationStore';
import searchStore from '../stores/SearchStore';
import iptStore from '../stores/IPTStore';
import projectStore from '../stores/ProjectStore';
import gatewayStore from '../stores/GatewayStore'
import awrStore from '../stores/AwrStore';
import userNotificationStore from '../stores/UserNotificationStore';
import surveyStore from '../stores/SurveyStore';
import toplineStore from '../stores/ToplineStore';
import exploreStore from '../stores/ExploreStore';
import becomeStore from '../stores/BecomeStore';
import api from '../api/LDApi';

import { idleTimeWarnLogout, idleTimeLogout } from '../config';
import userHistoryStore from '../stores/UserHistoryStore';

const App = asyncComponent(() => import(/* webpackChunkName: "app" */ './App'));

class Root extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showingIdle: false
    };

    console.log(idleTimeLogout);
  }

  onAction() {
    api.keepAlive();
    notificationStore.clearTemporaryNotifcations()
  }

  onIdleWarn() {
    this.setState({ showingIdle: true });
    notificationStore.createTemporaryNotification({ duration: (60 * 1000), type: 'error', onClick: this.onAction.bind(this), description: 'You will be logged out due to inactivity in 1 minute or less. Click here to extend your session.' })
  }

  onIdle() {
    window.location.href = '/logout';
  }

  render() {
    return (
      <Provider
        userStore={userStore}
        lookupStore={lookupStore}
        authStore={authStore}
        configStore={configStore}
        languageStore={languageStore}
        courseStore={courseStore}
        teamStore={teamStore}
        searchStore={searchStore}
        courseBundleStore={courseBundleStore}
        reportingStore={reportingStore}
        reportingV2Store={reportingV2Store}
        notificationStore={notificationStore}
        iptStore={iptStore}
        projectStore={projectStore}
        gatewayStore={gatewayStore}
        userHistoryStore={userHistoryStore}
        userNotificationStore={userNotificationStore}
        awrStore={awrStore}
        surveyStore={surveyStore}
        becomeStore={becomeStore}
        toplineStore={toplineStore}
        exploreStore={exploreStore}
      >
        <ThemeSelector>
          <CssBaseline />
          <Router>
            <ScrollToTop>
              <Switch>
                <Route
                  path="/"
                  component={App}
                />
              </Switch>
            </ScrollToTop>
          </Router>

          { idleTimeWarnLogout && (
            <IdleTimer
              ref={ref => { this.idleTimerWarn = ref }}
              element={document}
              onIdle={this.onIdleWarn.bind(this)}
              debounce={500}
              timeout={idleTimeWarnLogout} />
          )}
          { idleTimeLogout && (
            <IdleTimer
              ref={ref => { this.idleTimer = ref }}
              element={document}
              onIdle={this.onIdle.bind(this)}
              debounce={500}
              timeout={idleTimeLogout} />
          )}
        </ThemeSelector>
      </Provider>
    );
  }
};

export default Root;