import { remCalc } from '../util/style-util';

export const themePrimaryColorsBrand = {
  "AK": '#88c9dc',
  "AL": '#d11579',
  "AR": '#787358',
  "BG": '#d11579',
  "CC": '#ff9662',
  "CY": '#a66914',
  "Corporate": '#ff9662',
  "DE": '#252487',
  "DS": '#f9e900',
  "EB": '#1c1c1c',
  "EL": '#017a93',
  "FFI": '#51748b',
  "FP": '#9CC4C8',
  "GE": '#926e49',
  "JW Marriott": '#e4beb6',
  "LC": '#753c52',
  "MD": '#54b2b0',
  "MEA": '#413000',
  "MHRS": '#a70023',
  "MVCI": '#078276',
  "OX": '#ce0099',
  "PR": '#002D6D',
  "RHR": '#004D67',
  "RIBM": '#34657f',
  "RITZ": '#c6ddf3',
  "Ritz Carlton Reserve": '#ff9662',
  "SHS": '#0f807f',
  "SI": '#f7d6b4',
  "TPS": '#1c1c1c',
  "TX": '#fff426',
  "VB": '#ff9662',
  "WH": '#de0094',
  "WI": '#bad9c9',
  "XR": '#856649',
  "XE":'#ff9662'
};

//Global (non-theme) colors
export const globalColors = {
  blackOnlyForButtons: '#000',
  white: '#FFF',
  digitalBlack: '#1c1c1c',
  mediumBlackIconOnly: '#494949',
  mediumGrey: '#707070',
  lightestGrey: '#f4f4f4',
  greyDisabled: '#d2d2d2'
};


//Global (non-theme) styles
export const globalStyles = {
  h3: {
    fontFamily: 'ProximaNova-Semibold',
    color: globalColors.digitalBlack,
    fontSize: remCalc(24),
    fontWeight: 600,
    lineHeight: 1.33
  },
  h4: {
    fontFamily: 'ProximaNova-Semibold',
    color: globalColors.digitalBlack,
    fontSize: remCalc(21),
    fontWeight: 600,
    lineHeight: 'normal'
  },
  h5: {
    fontFamily: 'ProximaNova-Semibold',
    color: globalColors.digitalBlack,
    fontSize: remCalc(18),
    fontWeight: 600,
    lineHeight: 'normal'
  },
  button: {
    fontFamily: 'ProximaNova-Semibold',
    fontSize: remCalc(16),
    fontWeight: 600,
    lineHeight: 'normal',
    color: globalColors.digitalBlack,
    textTransform: 'uppercase',
    letterSpacing: remCalc(.5)
  },
  label1: {
    fontFamily: 'ProximaNova-Semibold',
    fontSize: remCalc(14),
    fontWeight: 600,
    lineHeight: 'normal',
    letterSpacing: 1,
    color: globalColors.digitalBlack,
    textTransform: 'uppercase',
  },
  label2: {
    fontFamily: 'ProximaNova-Semibold',
    lineHeight: 'normal',
    fontSize: remCalc(12),
    fontWeight: 600,
    letterSpacing: 1,
    color: globalColors.digitalBlack,
    textTransform: 'uppercase',
  },
  body1: {
    fontFamily: 'ProximaNova-Regular',
    fontSize: remCalc(16),
    color: globalColors.mediumBlackIconOnly,
    lineHeight: 1.38
  },
  body2: {
    fontFamily: 'ProximaNova-Regular',
    fontSize: remCalc(14),
    color: globalColors.mediumBlackIconOnly,
    lineHeight: 1.29
  },
  body3: {
    fontFamily: 'ProximaNova-Regular',
    fontSize: remCalc(12),
    color: globalColors.mediumBlackIconOnly,
    lineHeight: 1.33
  },
  widthWrapper: (theme) => {
    return (
      {
        maxWidth: remCalc(1248),
        margin: '0 auto',
        [theme.breakpoints.down('md')]: {
          padding: remCalc(0, 16)
        }
      }
    )
  },
  responsiveContainer: (theme) => {
    return {
      margin: '0 auto',
      [theme.breakpoints.up('lg')]: {
        width: remCalc(1248),
      },
      [theme.breakpoints.between('sm', 'md')]: {
        width: remCalc(600),
      },
      [theme.breakpoints.down('xs')]: {
        width: remCalc(343),
      },
      '@media print' : {
        width: '100vw'
      }
    }
  }
}