export default {
  "banner": {
    "viewDetails": "Смотреть подробнее",
    "requiredCourses": "Обязательные для вас курсы должны быть пройдены до",
    "days": "дней"
  },
  "header": {
    "plan": "Мой план",
    "manager": "МЕНЕДЖЕР",
    "explore": "ИЗУЧИТЬ ОБУЧЕНИЕ",
    "reporting": "ОТЧЕТЫ",
    "myTeam": "МОЯ КОМАНДА",
    "title": "Digital Learning Zone"
  },
  "footer": {
    "plan": "МОЙ ПЛАН",
    "manager": "МЕНЕДЖЕР",
    "explore": "ИЗУЧИТЬ ОБУЧЕНИЕ",
    "tagline": "Обучение – это теперь так просто. Это начинается с вас"
  },
  "welcome": {
    "hi": "Привет",
    "intro1": "Добро пожаловать в Digital Learning Zone! DLZ является персонализируемой учебной платформой, центральной точкой доступа к системам обучения Marriott. Найдите обязательное обучение для вашей должности и изучите возможности для обучения в целях вашего личностного и профессионального роста. Управляйте своим процессом обучения и вступите на путь наращивания своих навыков.",
    "intro2": "Перед тем, как начать, нам нужно узнать некоторую информацию о вас.",
    "letsGo": "ПОЕХАЛИ!",
    "preferredLanguage": "Ваш предпочтительный язык?",
    "step": "ШАГ",
    "of": "ИЗ",
    "previous": "НАЗАД",
    "next": "Далее",
    "startDate": "Когда вы поступили на работу в Marriott International или в Starwood?",
    "selectYear": "ВЫБРАТЬ ГОД",
    "selectMonth": "ВЫБРАТЬ МЕСЯЦ",
    "selectDay": "ВЫБРАТЬ ДАТУ",
    "selectFunction": "ВЫБРАТЬ ДОЛЖНОСТЬ",
    "selectInterests": "ВЫБРАТЬ ИНТЕРЕСЫ",
    "chooseYear": "ВЫБРАТЬ ГОД",
    "chooseMonth": "ВЫБРАТЬ МЕСЯЦ",
    "chooseDay": "ВЫБРАТЬ ДЕНЬ",
    "whatDo": "В какой должности вы работаете",
    "selected": "ВЫБРАНО",
    "searchFunctions": "Поиск по всем должностям",
    "searchInterests": "Выбрать все интересы",
    "additionalJobFunctionsLabel": "Сообщите нам подробнее о роде своих занятий",
    "managementLevelLabel": "Каков ваш уровень сотрудника?",
    "preferredBrandLabel": "Если вы работаете более, чем для одного бренда, выберите все, что применимо к вам.",
    "additionalJobFunctionsLabelExtra": "Пожалуйста, изучите следующую информацию и ВЫБЕРИТЕ ХОТЯ БЫ ОДНУ дополнительную служебную обязанность.",
    "searchAdditionalFunctions": "Искать по всем дополнительным служебным обязанностям",
    "highGuestContactLabel": "Вы являетесь главным контактным лицом гостя?",
    "yes": "Да",
    "no": "Нет "
  },
  "landing": {
    "started": "ПРИСТУПИМ",
    "welcomeBack": "Приветствуем вас снова!",
    "greatWork": "Отличная работа!",
    "requiredCourses": "Обязательные для вас курсы",
    "requiredCoursesDesc": "Пожалуйста, завершите прохождение курсов до наступления установленной даты.",
    "myPlan": "Мой план",
    "courses": "Курс",
    "courseComplete": "КУРС ПРОЙДЕН",
    "viewMore": "ПРОСМОТРЕТЬ ДОПОЛНИТЕЛЬНЫЕ ОБЯЗАТЕЛЬНЫЕ КУРСЫ",
    "viewMoreBtn": "ПРОСМОТРЕТЬ ДОПОЛНИТЕЛЬНЫЕ КУРСЫ",
    "showing": "ПОКАЗАНО",
    "of": "ИЗ",
    "heroTitle": "Изучать что-то новое. Наращивать свои возможности. Получать навыки, необходимые для вашего мира.",
    "heroParagraph1": "С легкостью отслеживать все обязательные курсы и получать в реальном времени уведомления для их прохождения.",
    "heroParagraph2": "Использовать персонализированные формы обучения с учетом вашей должности и интересов.",
    "recommendedCoursesTitle": "Рекомендуемые курсы",
    "recommendedCoursesDescNoContent": "На данный момент у вас нет рекомендуемых курсов. Изучите приведенную ниже информацию для рассмотрения других возможностей для обучения.",
    "recommendedCoursesDesc": "Посмотрите, что для вас выбрал ваш менеджер или специалист отдела трудовых ресурсов.",
    "recommendedCoursesBtn": "ПРОСМОТРЕТЬ РЕКОМЕНДУЕМЫЕ КУРСЫ",
    "completedCoursesBtn": "ПРОСМОТРЕТЬ ПРОЙДЕННЫЕ КУРСЫ",
    "exploreTitle": "Не утрачивайте любознательность.",
    "exploreDesc": "Выберите интересующие вас темы.",
    "jobFunctions": "Предмет",
    "growthSkills": "Навыки роста",
    "leadershipSkills": "Лидерские навыки",
    "brands": "Бренды",
    "languageLearning": "Изучение языков",
    "exploreBtn": "НОВЫЕ КУРСЫ",
    "managerRecommendation": "РЕКОМЕНДАЦИЯ МЕНЕДЖЕРА",
    "learningRecommendation": "РЕКОМЕНДАЦИЯ СЛУЖБЫ ОБУЧЕНИЯ",
    "marriottRecommendation": "РЕКОМЕНДАЦИЯ MARRIOTT",
    "completedCourses": "Вы прошли все обязательные для вас курсы!",
    "investTitle": "Управляйте своим процессом обучения",
    "investParagraph1": "Мы понимаем, что нынешние обстоятельства являются сложными во многих отношениях  и DLZ здесь, как ресурс для вашего развития. Будь то  поддержка актуальности ваших навыков, изучение чего -то нового , управление стрессом или же обучение работы в виртуальной команде. Мы собрали ресурсы, чтобы поддержать ваc.",
    "updatedTopics": "Обновленные темы!",
    "workingRemotely": "Виртуальные командные ресурсы",
    "relatedCoursesNew": "Нажмите ниже, чтобы обновить свои интересы, так  как мы добавили новые темы и курсы.",
    "relatedCourses": "Пожалуйста, нажмите ниже, чтобы выбрать интересующие вас темы.",
    "notificationText": "Предлагается новое обучение mHUB. Выберите mHUB в разделе Explore Learning.",
    "mHubHeroTitle1": "Просто. Умно. Надежно. Представляем mHUB.",
    "mHubHeroManagedText1": "mHUB объединяет в единую и удобную платформу средства обучения, карьерного роста, скидки Explore и многое другое.",
    "mHubHeroFranchisedText1": "mHUB объединяет в единую и удобную платформу средства обучения, скидки Explore и многое другое.",
    "mHubHeroVisitLink": "Щелкните здесь для просмотра дополнительных подробностей предложения и вложений.",
    "myGateway": "My Gateway",
    "gatewayReview": "Пожалуйста, рассмотрите и продолжите.",
    "gatewayProgress": "Ход работ на Gateway",
    "complete": "ЗАВЕРШИТЬ",
    "reviewContent": "Просмотреть ресурсы",
    "gatewayCardTitle": "Ресурсы Gateway",
    "gatewayCardDesc": "Получите доступ к своим Базовым принципам за первый год и далее.",
    "gatewayCardDescGms": "Получите доступ к своим Базовым принципам, Пунктам действий, Ресурсам бренда и Ресурсам по отдельным Предметам за первый год и далее.",
    "gatewayCardDescNew": "Получите доступ к своим Базовым принципам за первый год в новой должности.",
    "gatewayCardDescGmsNew": "Получите доступ к своим Базовым принципам, Пунктам действий, Ресурсам бренда и Ресурсам по отдельным Предметам за первый год в новой должности и далее."
  },
  "courseBundle": {
    "completeBy": "ВЫПОЛНЕНО КЕМ",
    "remainingCourses": "Оставшиеся курсы",
    "completedCourses": "Пройденные курсы",
    "remainingLabel": "В этом учебном направлении насчитывается {courseCount} учебных курсов.",
    "recommendButton": "Рекомендуется для команды",
    "startCourse": "Начать курс",
    "durationMins": "МИН.",
    "completed": "Выполнено",
    "back": "Назад",
    "additionalLangs1": "Этот курс, помимо варианта на английском языке, теперь предлагается на",
    "additionalLangs2": "После запуска курса выберите свой предпочтительный язык из раскрывающегося меню.",
    "targetAudience": "Целевая аудитория",
    "all": "Все",
    "globally": "По всему миру:",
    "source": "Источник",
    "courseType": "Тип курса",
    "course": "Курс"
  },
  "profile": {
    "save": "Сохранить",
    "edit": "Изменить",
    "brand": "Бренд",
    "brands": "Бренды",
    "additionalWork": "Все дополнительные рабочие обязанности",
    "startDateLabel": "Когда вы поступили на работу в Marriott International или в Starwood?",
    "preferredLanguageLabel": "Ваш предпочтительный язык?",
    "jobFunctionsLabel": "В чем состоят ваши должностные обязанности?",
    "interestsLabel": "Что вы хотите изучить?",
    "brandsLabel": "На какой(ие) бренд(ы) вы работаете?",
    "additionalJobFunctionsLabel": "Какие дополнительные должностные обязанности вы выполняете?",
    "managementLevelLabel": "Укажите ваш уровень сотрудника",
    "highGuestContactLabel": "Вы являетесь главным контактным лицом гостя?"
  },
  "courseDetails": {
    "completeBy": "Подлежит заполнению",
    "recommendTitlte": "Порекомендовать курс вашей команде",
    "courseName": "Название курса",
    "sendRecommendation": "Отправить рекомендацию"
  },
  "explore": {
    "relatedCourses": "Следующие курсы связаны с интересующими вас областями.",
    "chooseInterests": "Выберите свои интересы",
    "title": "Изучить обучение",
    "chooseInterestsModal": "Выберите интересы",
    "oppsTitle": "Изучите возможности для обучения",
    "growWithUsTitle": "Развивайтесь вместе с нами",
    "growWithUsDesc": "Мы в Marriott считаем, что карьера должна расцветать вместе с нами. Мы также уверены, что разнообразный опыт обогащает.",
    "growWithUsBtn": "ПРОСМОТРИТЕ СЮЖЕТ «РАСТИТЕ ВМЕСТЕ С НАМИ»",
    "mdaTitle": "Академия развития Marriott",
    "mdaDesc": "Marriott считает, что достижения и преданность сотрудников необходимо поощрять. Мы высоко ценим лидерство и способность обслуживать на исключительном уровне. Мы также награждаем за успешные рекомендации.",
    "mdaBtn": "ОЗНАКОМИТЬСЯ С АКАДЕМИЕЙ РАЗВИТИЯ MARRIOTT",
    "selectInterests": "Сообщите нам о заинтересовавших вас темах.",
    "otherRecommended": "Другие рекомендуемые курсы",
    "searchByName": "Искать интересующие вас темы"
  },
  "plan": {
    "completedRequired": "Вы прошли все обязательные для вас курсы!",
    "requiredCourses": "Обязательные курсы",
    "reqDesc": "Пожалуйста, пройдите их до установленных предельных сроков.",
    "complete": "ПРОЙДЕНО",
    "of": "ИЗ",
    "courses": "КУРСОВ",
    "completedCourses": "Пройденные курсы",
    "completedDesc": "Поздравляем! Вы недавно закончили перечисленные ниже курсы.",
    "remaining": "оставшиеся",
    "myLearningTitle": "Добавленное мной обучение ",
    "myLearningSubTitle": "Позиции, которые я добавил в Мой План",
    "history": "История",
    "myLearningHistoryLinkText": "Просмотреть мою полную Историю",
    "jf": "Ваша(и) должность(и)",
    "awr": "Ваши дополнительные рабочие обязанности (AWR)",
    "jfAwrDesc": "Важно: должность и дополнительные рабочие обязанности определяют обязательные для вас виды обучения, назначенные вам в разделе Мой план. ",
    "jfAwrEdit": "РЕДАКТИРОВАТЬ ваш профиль",
    "jfAwrEnd": "для его обновления.",
    "tourPlan": "Мой план включает все обязательные и рекомендуемые курсы для завершения к установленной дате",
    "tourBasics": "Основы Marriott включают ресурсы, необходимые для начала вашего пути в Marriott, при этом они будут доступны в любое время в течение всей вашей карьеры. Основы Marriott являются дополнением к вашему обучению в разделе Мой план.",
    "noCourses": "На данный момент времени вы выполнили все обязательное обучение, поэтому можете продолжить идти вперед и начать предстоящее обязательное обучение"
  },
  "team": {
    "title": "Управляйте своей командой",
    "subtitle": "Способствуйте развитию их навыков и карьерному росту.",
    "functions": "Должностные обязанности",
    "viewTeam": "Просмотреть команду",
    "allCourses": "Все курсы",
    "downloadExcel": "Загрузить отчет в формате Excel"
  },
  "feedbackButton": {
    "havingTrouble": "Предложения"
  },
  "recommendation": {
    "dismiss": "Вы уверены в том, что хотите оказаться от этой рекомендации?"
  },
  "table": {
    "filterTable": "Tabla de filtro"
  },
  "notifications": {
    "addAssignment2": "На вашей странице %{page location} было добавлено задание %{assignment_type} типа по курсу %{assignment_name} со сроком выполнения %{due}.",
    "comingDueAssignment": "Срок исполнения вашего задания %{assignment_type} типа по курсу %{assignment_name} истекает %{due}.",
    "overdueAssignment": "Срок исполнения вашего задания %{assignment_type} типа по курсу %{assignment_name} истек.",
    "addAssignment": "На вашей странице %{page location} было добавлено задание %{assignment_type} типа по курсу %{assignment_name}.",
    "profile": "Было обнаружено изменение названия вашей должности или местоположения. Пожалуйста, рассмотрите свой Профиль и выполните все необходимые изменения Бренда, Должности или Дополнительных рабочих обязанностей. Точная информация в профиле DLZ поможет вам получать требуемые учебные задания.",
    "helptip": "Все уведомления истекают через 30 дней.",
    "markRead": "Отметить как прочитанное",
    "delete": "Удалить",
    "markAllRead": "Отметить все как прочитанное",
    "settings": "Параметры",
    "tooltip": "Прочитанные уведомления будут удалены через 30 дней",
    "reportComplete": "Составление вашего отчета DLZ завершено. Щелкните для загрузки",
    "dontShowThisAgain": "Не показывать это снова",
    "warningEnabled": "Предупреждение об удалении было включено.",
    "manageEmailNotifications": "Управлять уведомлениями электронной почты",
    "warningTooltip": "Если эта функция выбрана, вы будете получать предупреждение при каждой попытке удаления.",
    "changeEmailManagedTooltip": "Для изменения вашего адреса электронной почты посетите mHUB > HR Direct > Я > Моя открытая информация. Следуйте указаниям для обновления ваших контактных данных. Обновленный адрес электронной почты может быть отображен через 1-2 рабочих дня.",
    "changeEmailFranchisedTooltip": "Для изменения вашего адреса электронной почты обратитесь к своему руководителю и/или к администратору EID Tool вашей франшизы. Обновленный адрес электронной почты будет отображен через 1-2 рабочих дня.",
    "deleteAllNotifications": "Удалить все уведомления",
    "deleteIndividualHeader": "Удалить уведомление",
    "deleteAllConfirmation": "Вы действительно хотите удалить все уведомления? Они будут перемещены в раздел «Удаленные» Центра уведомлений и будут находиться там до истечения срока их действия.",
    "deleteIndividualConfirmation": "Вы действительно хотите удалить это уведомление? Удаленные уведомления перемещаются в раздел «Удаленные» Центра уведомлений и находятся там до истечения срока действия.",
    "permanentDeleteIndividiualConfirmation": "Вы действительно хотите необратимо удалить это уведомление? Это действие является необратимым.",
    "permanentDeleteAllConfirmation": "Вы действительно хотите необратимо удалить все уведомления? Это действие является необратимым.",
    "notificationDeleted": "Уведомление было удалено.",
    "notificationsDeleted": "Уведомления были удалены.",
    "notificationRestored": "Уведомление было восстановлено.",
    "notificationsRestored": "Уведомления были восстановлены.",
    "enableDeletionWarning": "Предупреждение об удалении включено",
    "warningDisabled": "Предупреждение об удалении было выключено.",
    "viewAll": "Смотреть все",
    "deleteAll": "Удалить все",
    "notificationCenterTitle": "Центр уведомлений",
    "markAsRead": "Отметить как прочитанное",
    "noDeletedNotifications": "Вы выполнили все необходимое!",
    "restore": "Восстановить",
    "restoreAll": "Восстановить все",
    "viewCourse": "Просмотреть курс",
    "title": "Уведомления",
    "noNewNotifications": "У вас нет уведомлений.",
    "cancel": "Отменить",
    "manageNotificationsCopy": "Управлять уведомлениями электронной почты\n\nВыберите, требуется ли получать еженедельные сообщения электронной почты со сводкой просроченных вами учебных заданий. Выберите «Управлять» для изменения ваших настроек."
  },
  "transcript": {
    "history": "Моя история",
    "completed": "Выполненные задания",
    "assignmentName": "Название задания",
    "type": "Тип",
    "viewAssignment": "Просмотреть задание",
    "assignmentSource": "Источник задания",
    "requiredAssignments": "Обязательные задания",
    "recommendedAssignments": "Рекомендуемые задания",
    "voluntary": "Добровольное",
    "trasscript": "Расшифровка",
    "retraining": "Повышение квалификации",
    "completionDate": "Дата завершения",
    "assignmentType": "Тип задания",
    "contentType": "Тип контента",
    "contentId": "Идентификатор контента"
  },
  "contentLaunch": {
    "review": "Обзор обучения",
    "start": "Начать обучение"
  },
  "survey": {
    "weValue": "Нам хочется узнать ваше мнение.",
    "weValueDescription": "Пожалуйста, ответьте на пять простых вопросов касательно вашего опыта работы в вашем отеле.",
    "weValueButtonText": "Пройти опрос",
    "weValueG1Q1A1": "Да",
    "weValueG1Q1A2": "Нет",
    "strongAgree": "Абсолютно согласен",
    "agree": "Согласен",
    "slightAgree": "Частично согласен",
    "slightDisagree": "Скорее не согласен(на)",
    "disagree": "Не согласен",
    "strongDisagree": "Абсолютно не согласен",
    "weValueG1Q1Title": "Присутствовали ли вы на ежедневном совещании в последнюю отработанную вами смену? (Line-Up, What’s Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments или Daily EDITION)",
    "weValueG1Q2Title": "Ежедневное совещание (Line-Up, What's Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments или Daily EDITION) вдохновляет меня на оказание эксклюзивных услуг каждый день.",
    "weValueG1Q3Title": "Мое подразделение нацелено на сокращение количества дефектов, даже если о них не сообщали наши гости.",
    "weValueG1Q4Title": "У меня имеются полномочия для решения возникших у гостей проблем, не запрашивая для этого предварительного разрешения.",
    "weValueG1Q5Title": "У меня имеются полномочия очаровывать гостей, не запрашивая для этого предварительного разрешения.",
    "weValueG2Q1Title": "Я регулярно наблюдаю, как члены команды на моем объекте делают все возможное и невозможное, чтобы произвести впечатление на гостей оказываемым им обслуживанием.",
    "weValueG2Q2Title": "То, чему я научился в этом подразделении, позволяет мне оказывать эксклюзивные услуги.",
    "weValueG2Q3Title": "Я уверен в своих знаниях и навыках для оказания эксклюзивных услуг.",
    "weValueG2Q4Title": "В нашем подразделении мы создаем эксклюзивную среду, чистую и лишенную дефектов.",
    "weValueG2Q5Title": "Даже если я сталкиваюсь с проблемой, не относящейся к моему подразделению, я все равно вправе вмешаться и взять на себя решение проблемы гостя.",
    "weValueG3Q1Title": "На моем объекте к членам команды относятся с тем же уровнем заботы, что и к гостям.",
    "weValueG3Q2Title": "Мое подразделение является воплощением ценностей бренда.",
    "weValueG3Q3Title": "Когда я делаю все возможное и невозможное для создание изысканных впечатлений у гостя, мою работу признают и ценят.",
    "weValueG3Q4Title": "Я принимаю участие в усилиях моего подразделения по улучшению нашей работы.",
    "weValueG3Q5Title": "В нашем подразделении мы всегда ставим интересы гостя превыше всего.",
    "submitButton": "Отправить",
    "phasetwo": {
      "weValueG1Q1Title": "Присутствовали ли вы на ежедневном совещании в последнюю отработанную вами смену? (Line-Up, What’s Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments  или Daily EDITION)?",
      "weValueG1Q2Title": "Ежедневное совещание (Line-Up, What's Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments или Daily EDITION) вдохновляет членов команды на оказание эксклюзивных услуг каждый день.",
      "weValueG1Q3Title": "На моем объекте мы нацелены на сокращение количества дефектов, даже если о них не сообщали наши гости.",
      "weValueG1Q4Title": "На моем объекте у нас имеются полномочия для решения возникших у гостей проблем, не запрашивая для этого предварительного разрешения.",
      "weValueG1Q5Title": "На моем объекте у нас имеются полномочия для того, чтобы радовать гостей, не запрашивая для этого предварительного разрешения.",
      "weValueG2Q1Title": "На моем объекте мы регулярно делаем все возможное и невозможное, чтобы произвести впечатление на гостей нашим обслуживанием.",
      "weValueG2Q2Title": "Изучение возможностей на нашем объекте позволяет нам оказывать эксклюзивные услуги.",
      "weValueG2Q3Title": "На моем объекте мы чувствуем себя уверенными в своих знаниях и навыках для оказания эксклюзивных услуг.",
      "weValueG2Q4Title": "На моем объекте мы создаем эксклюзивную среду, чистую и лишенную дефектов.",
      "weValueG2Q5Title": "Даже если мы сталкиваемся с проблемой, не относящейся к нашему подразделению, мы все равно вправе вмешаться и взять на себя решение проблемы гостя.",
      "weValueG3Q1Title": "На моем объекте мы проявляем заботу друг о друге на том же уровне, что и в отношении гостей.",
      "weValueG3Q2Title": "Мой объект является воплощением ценностей бренда.",
      "weValueG3Q3Title": "На моем объекте, когда мы делаем все возможное и невозможное для создание изысканных впечатлений у гостя, нашу работу признают и ценят.",
      "weValueG3Q4Title": "На моем объекте у нас есть возможность принимать участие в деятельности нашего подразделения по улучшению своей работы.",
      "weValueG3Q5Title": "На моем объекте мы всегда уделяем первостепенное внимание нашим гостям."
    }
  },
  "gateway": {
    "planProgressComplete": "%{label} ВЫПОЛНЕНО",
    "gatewayProgressComplete": "GATEWAY ХОД ВЫПОЛНЕНИЯ",
    "heroMobileGatewayVideoText": "Воспроизвести видеосюжет Gateway",
    "basics": "БАЗОВЫЕ ПРИНЦИПЫ",
    "brandResources": "РЕСУРСЫ БРЕНДА",
    "disciplineResources": "РЕСУРСЫ ПО ПРЕДМЕТАМ",
    "immersionResourcesHeader": "Ресурсы с погружением",
    "brandCommunicationsHeaader": "Коммуникации бренда",
    "brandContactsHeader": "Контакты бренда",
    "franchisedActionItemsText": "Позиции из перечня действий помогают вам действовать системно и содержат основную ознакомительную информацию. Эти позиции не являются обязательными и приведены в дополнение к тому, что вы можете получить от управляющей компании вашей франшизы.",
    "global": "ПО ВСЕМУ МИРУ",
    "continent": "КОНТИНЕНТ",
    "viewed": "ПРОСМОТРЕННЫЕ",
    "completed": "ВЫПОЛНЕННЫЕ",
    "heading": "Gateway",
    "basicsTab": "БАЗОВЫЕ ПРИНЦИПЫ",
    "onboardingTab": "Пункты действий",
    "brandTab": "Ресурсы бренда",
    "disciplinesTab": "РЕСУРСЫ ПО ОТДЕЛЬНЫМ ПРЕДМЕТАМ",
    "basicsHeader": "Добро пожаловать в Marriott International",
    "basicsHeaderNewBrandOrRole": "Добро пожаловать,",
    "basicsText": "Мы знаем, что сейчас, в начале вашей карьеры, вам предстоит многому научиться. Мы выбрали некоторые из основных тем для ваших нескольких первых дней в должности. Возможно, некоторые из этих тем будут более подробно изучены в ходе инструктажа или ознакомления с брендом, но именно они помогут вам сейчас начать работу.",
    "basicsTextGms": "Мы знаем, что сейчас, в начале вашей карьеры, вам предстоит многому научиться. Мы выбрали некоторые из основных тем для ваших нескольких первых дней в должности. Возможно, некоторые из этих тем будут более подробно изучены в ходе инструктажа или ознакомления с брендом, но именно они помогут вам сейчас начать работу. По завершении изучения переходите к другим страницам портала Gateway, включая Пункты действий, Ресурсы бренда и Ресурсы по отдельным Предметам.",
    "basicsTextFranchise": "Мы понимаем, что вам предстоит многому научиться, и выбрали некоторые из основных тем для ваших первых дней в должности. Эти пункты могут дополнять ваш инструктаж или ознакомление с брендом, или информацию, предоставленную управляющей компанией вашей франшизы.",
    "basicsTextFranchiseGms": "Мы понимаем, что вам предстоит многому научиться, и выбрали некоторые из основных тем для ваших первых дней в должности. Эти пункты могут дополнять ваш инструктаж или ознакомление с брендом, или информацию, предоставленную управляющей компанией вашей франшизы. По завершении изучения переходите к другим страницам портала Gateway, включая Пункты действий, Ресурсы бренда и Ресурсы по отдельным Предметам.",
    "basicsTextNewToRole": "Мы знаем, что сейчас, в начале работы в вашей новой должности, вам предстоит многому научиться. Мы выбрали некоторые из основных тем для ваших нескольких первых дней в должности. Возможно, некоторые из этих тем будут более подробно изучены в ходе назначенного вам обучения, но именно они помогут вам сейчас начать работу.",
    "basicsTextNewToRoleGms": "Мы знаем, что сейчас, в начале работы в вашей новой должности, вам предстоит многому научиться. Мы выбрали некоторые из основных тем для ваших нескольких первых дней в должности. Возможно, некоторые из этих тем будут более подробно изучены в ходе назначенного вам обучения, но именно они помогут вам сейчас начать работу. По завершении изучения переходите к другим страницам портала Gateway, включая Пункты действий, Ресурсы бренда и Ресурсы по отдельным Предметам.",
    "basicsTextNewToRoleFranchise": "Мы понимаем, что вам предстоит многому научиться, и выбрали некоторые из основных тем для ваших первых дней в вашей новой должности. Эти пункты могут дополнять информацию, предоставленную управляющей компанией вашей франшизы.",
    "basicsTextNewToRoleFranchiseGms": "Мы понимаем, что вам предстоит многому научиться, и выбрали некоторые из основных тем для ваших первых дней в вашей новой должности. Эти пункты могут дополнять информацию, предоставленную управляющей компанией вашей франшизы. По завершении изучения переходите к другим страницам портала Gateway, включая Пункты действий, Ресурсы бренда и Ресурсы по отдельным Предметам.",
    "basicsTextNewToBrand": "Мы знаем, что сейчас, в начале работы с брендом %{brandName}, вам предстоит многому научиться. Мы выбрали некоторые из основных тем для ваших нескольких первых дней в должности. Возможно, некоторые из этих тем будут более подробно изучены в ходе инструктажа или ознакомления с брендом, но именно они помогут вам сейчас начать работу.",
    "basicsTextNewToBrandGms": "Мы знаем, что сейчас, в начале работы с брендом %{brandName}, вам предстоит многому научиться. Мы выбрали некоторые из основных тем для ваших нескольких первых дней в должности. Возможно, некоторые из этих тем будут более подробно изучены в ходе инструктажа или ознакомления с брендом, но именно они помогут вам сейчас начать работу. По завершении изучения переходите к другим страницам портала Gateway, включая Пункты действий, Ресурсы бренда и Ресурсы по отдельным Предметам.",
    "basicsTextNewToBrandFranchise": "Мы понимаем, что вам предстоит многому научиться, и выбрали некоторые из основных тем для ваших первых дней в вашей новой должности. Эти пункты могут дополнять ваш инструктаж или ознакомление с брендом, или информацию, предоставленную управляющей компанией вашей франшизы.",
    "basicsTextNewToBrandFranchiseGms": "Мы понимаем, что вам предстоит многому научиться, и выбрали некоторые из основных тем для ваших первых дней в вашей новой должности с брендом %{brandName}. Эти пункты могут дополнять ваш инструктаж или ознакомление с брендом, или информацию, предоставленную управляющей компанией вашей франшизы. По завершении изучения переходите к другим страницам портала Gateway, включая Пункты действий, Ресурсы бренда и Ресурсы по отдельным Предметам.",
    "disciplineHeader": "Ресурсы по отдельным предметам",
    "toDo": "ОСТАЕТСЯ СДЕЛАТЬ",
    "optional": "ФАКУЛЬТАТИВНО",
    "overallComplete": "ВСЕ ВЫПОЛНЕНО",
    "keyResourcesDesc": "Узнайте подробнее об основных предметах, оказывающих поддержку повседневной работе отеля, впечатлениям гостя и деловым потребностям.",
    "franchiseFooter": "Этот материал содержит рекомендации и предлагаемые методы работы, которые могут повысить вероятность соблюдения Стандартов бренда. Выступая франшизодателем, компания Marriott не вмешивается в руководство или управление любым аспектом деятельности отеля франшизополучателя или в его деловые операции. Франшизополучатели управляют текущими операциями своих отелей и несут ответственность за своих сотрудников и методы найма.",
    "landingHeroTitle1": "Добро пожаловать в Marriott International.",
    "landingHeroTitle2": "Ваш путь начинается здесь.",
    "landingHeroText": "The Gateway представляет собой инструмент, который будет направлять вас в течение первого года в вашей новой должности. Он предоставит вам информацию и ресурсы, необходимые для достижения успеха.",
    "landingHeroTitle1NewToRole": "Добро пожаловать в The Gateway.",
    "landingHeroTitle1NewToBrand": "Добро пожаловать в The Gateway.",
    "landingButtonText": "С чего начать",
    "twoMonthslandingHeroTitle1": "Приветствуем вас снова!",
    "twoMonthslandingHeroText": "Если вы еще не выполнили этого, пройдите все пункты Основ в The Gateway!",
    "twoMonthslandingButtonText": "Войдите в систему Gateway",
    "twoMonthslandingHeroTitle1NewToBrand": "Приветствуем вас снова!",
    "twoMonthslandingHeroTextNewToBrand": "Мы надеемся, что ваши первые дни в %{brandName} прошли отлично. Не забывайте о продвижении по своему пути и обращайтесь к The Gateway за всей необходимой информацией.",
    "twoMonthslandingButtonTextNewToBrand": "Продолжайте свой путь",
    "threeMonthslandingHeroTitle1": "Вы на правильном пути!",
    "threeMonthslandingHeroText": "В The Gateway вы найдете все, что вам нужно знать о компании Marriott International.",
    "threeMonthslandingButtonText": "Войдите в систему Gateway",
    "threeMonthslandingHeroTitle1NewToRole": "Вы на правильном пути!",
    "threeMonthslandingHeroTextNewToRole": "В The Gateway вы найдете все, что вам нужно знать о том, чтобы добиться успеха в вашей новой должности.",
    "threeMonthslandingButtonTextNewToRole": "Войдите в систему Gateway",
    "threeMonthslandingHeroTitle1NewToBrand": "Приветствуем вас снова!",
    "threeMonthslandingHeroTextNewToBrand": "Поздравляем вас с завершением первых %{numDays} рабочих дней с вашим новым брендом. Вы можете всегда, когда это потребуется, получить доступ ко всем своим Ресурсам по Пунктам действий, Бренду и Предметам.",
    "threeMonthslandingButtonTextNewToBrand": "Войдите в систему Gateway",
    "fourMonthslandingHeroTitle1": "Отличная работа! Вы прошли все пункты Основ!",
    "fourMonthslandingHeroText": "При помощи The Gateway вы получите доступ ко всему, что вам нужно знать о компании Marriott International.",
    "fourMonthslandingButtonText": "Войдите в систему Gateway",
    "fourMonthslandingHeroTitle1NewToRole": "Отличная работа! Вы прошли все пункты Основ!",
    "fourMonthslandingHeroTextNewToRole": "Получите при помощи The Gateway доступ ко всему, что вам нужно для успешной работы в вашей новой должности.",
    "fourMonthslandingButtonTextNewToRole": "Войдите в систему Gateway",
    "fourMonthslandingHeroTitle1NewToBrand": "The Gateway - вход в систему",
    "fourMonthslandingHeroTextNewToBrand": "Сейчас, по мере завершения вашего первого года с вашим новым брендом %{brandName}, и если вы еще не сделали это, выполните все ваши Пункты действий и пользуйтесь развивающими ресурсами, которые предлагаются здесь, в Digital Learning Zone. Щелкните 'Продолжить', чтобы продолжить движение по выбранному пути.",
    "fourMonthslandingButtonTextNewToBrand": "Продолжить",
    "gmSecondVisitlandingHeroTitle1": "Приветствуем вас снова!",
    "gmSecondVisitlandingHeroText": "Надеемся, что ваши первые дни прошли отлично. Не забывайте о продвижении по своему пути, которое поможет вам добиться роста в вашей новой должности.",
    "gmSecondVisitlandingButtonText": "Продолжайте свой путь",
    "gmSecondVisitlandingHeroTextNewToRole": "Надеемся, что ваши первые дни в вашей новой должности прошли отлично. Не забывайте о продвижении по своему пути и обращайтесь к The Gateway за всей необходимой информацией.",
    "gmSecondVisitlandingHeroTextNewToBrand": "Мы надеемся, что ваши первые дни в %{brandName} прошли отлично. Не забывайте о продвижении по своему пути и обращайтесь к The Gateway за всей необходимой информацией.",
    "gmThirdVisitlandingHeroTitle1": "Приветствуем вас снова!",
    "gmThirdVisitlandingHeroText": "Поздравляем вас с завершением первых %{numDays} дней вашего нового карьерного пути. При необходимости вы всегда сможете получить доступ ко всем своим Ресурсам по Пунктам действий, Бренду и Предметам.",
    "gmThirdVisitlandingButtonText": "Войдите в систему Gateway",
    "gmThirdVisitlandingHeroTextNewToRole": "Поздравляем вас с завершением первых %{numDays} дней в вашей новой должности. При необходимости вы всегда сможете получить доступ ко всем своим Ресурсам по Пунктам действий, Бренду и Предметам.",
    "gmThirdVisitlandingHeroTextNewToBrand": "Поздравляем вас с завершением первых %{numDays} дней работы с вашим новым брендом. При необходимости вы всегда сможете получить доступ ко всем своим Ресурсам по Пунктам действий, Бренду и Предметам.",
    "gmSevenMonthslandingHeroTitle1": "Похоже, вам следует выполнить дополнительные Пункты действий.",
    "gmSevenMonthslandingHeroText": "Сейчас, по мере завершения вашего первого года, не забудьте выполнить все ваши Пункты действий и воспользоваться развивающими ресурсами, которые предлагаются здесь, в Digital Learning Zone. Щелкните 'Продолжить', чтобы продолжить движение по выбранному пути",
    "gmSevenMonthslandingButtonText": "Продолжить",
    "gmSevenMonthslandingHeroTitle1NewToRole": "The Gateway - вход в систему",
    "gmSevenMonthslandingHeroTextNewToRole": "Сейчас, по мере завершения вашего первого года в вашей новой должности и если вы еще не сделали это, выполните все ваши Пункты действий и пользуйтесь развивающими ресурсами, которые предлагаются здесь, в Digital Learning Zone. Щелкните 'Продолжить', чтобы продолжить движение по выбранному пути",
    "gmSevenMonthslandingHeroTitle1NewToBrand": "The Gateway - вход в систему",
    "gmSevenMonthslandingHeroTextNewToBrand": "Сейчас, по мере завершения вашего первого года с вашим новым брендом %{brandName}, и если вы еще не сделали это, выполните все ваши Пункты действий и пользуйтесь развивающими ресурсами, которые предлагаются здесь, в Digital Learning Zone. Щелкните 'Продолжить', чтобы продолжить движение по выбранному пути.",
    "gmOneYearlandingHeroTitle1": "Поздравляем вас с 1-й годовщиной работы в %{brandName}!",
    "gmOneYearlandingHeroTitle1NewToRole": "Поздравляем вас с завершением 1 года работы в вашей новой должности!",
    "gmOneYearlandingHeroTitle1NewToBrand": "Поздравляем вас с завершением 1 года работы с вашим новым брендом %{brandName}!",
    "gmOneYearlandingHeroText": "Совместный путь с The Gateway может завершиться, но все лучшее еще впереди. Вы можете в любое время обращаться к ресурсам на вкладке в The Gateway.",
    "gmOneYearlandingButtonText": "Просмотреть Gateway"
  },
  "resources": {
    "iptTooltip": "При указании нескольких отелей разделите их запятыми",
    "iptAllFunctionTooltip": "Отмените флажок для выбора конкретной(ых) Должности(ей). Оставьте флажок для просмотра обучения для всех должностей в выбранном отеле",
    "iptAllAWRTooltip": "Отмените флажок для выбора конкретных Дополнительных рабочих обязанностей. Оставьте флажок для просмотра обучения для всех Дополнительных рабочих обязанностей в выбранном отеле",
    "editJFTooltip": "Разделяйте множественные записи запятыми"
  },
  "Notification Center: ": "Отметить как непрочитанное",
  "Notification Center: Empty": "Вы выполните все необходимое!",
  "Notification Center: Individual Delete": "Вы действительно хотите удалить это уведомление? Удаленные уведомления перемещаются в раздел «Удаленные» Центра уведомлений и находятся там до истечения срока действия.",
  "Notification Center: Delete All": "Вы действительно хотите удалить все уведомления? Они будут перемещены в раздел «Удаленные» Центра уведомлений и будут находиться там до истечения срока их действия.",
  "Notification Center: Mark All As Read": "Отметить все как прочитанное",
  "Notification Center: Restore All": "Восстановить все",
  "Notification Center: Delete Confirmation": "Удалить",
  "Warning Message Text: Remove Job Function or AWR": "Удалить должность или дополнительную рабочую обязанность (AWR)?\r\nНажимая «Отправить», вы начинаете процесс удаления %{Function and/or AWR} из %{count} Обучаемых.",
  "Warning Message Text: Add Job Function or AWR": "Добавить должность или дополнительную рабочую обязанность (AWR)?\r\nНажимая «Отправить», вы начинаете процесс добавления %{Function and/or AWR} к %{count} Обучаемым.",
  "Button": "Отправить",
  "Success Message Text: Add Job Function and/or AWR": "Успех\r\nВы добавили %{Job Function and/or AWR} к запрошенному(ым) сотруднику(ам) в %{location}. \r\n\r\nВы хотите отредактировать дополнительные должности или дополнительные рабочие обязанности для %{location}?",
  "Message Text:AWR": "Успех\r\nВы добавили дополнительные рабочие обязанности %{ __} к запрошенному(ым) сотруднику(ам) в %{ __}.\r\n\r\nХотите отредактировать дополнительные должности или дополнительные рабочие обязанности для %{location, property, learner(s)}?",
  "Success Message Text: Remove Job Function": "Успех\r\nВы удалили  %{Job Function and/or AWR} у запрошенного(ых) сотрудника(ов) в %{location}. \r\n\r\nХотите отредактировать дополнительные должности или дополнительные рабочие обязанности для %{location}?",
  "Success Message Text: Remove AWR": "Успех\r\nВы удалили дополнительную рабочую обязанность%{ __} у запрошенного(ых) сотрудника(ов) в %{ __} .\r\n\r\nХотите отредактировать дополнительные должности или дополнительные рабочие обязанности для %{location, property, learner(s)}?",
  "Tool Tip - Managed Change Email": "Для изменения вашего адреса электронной почты посетите mHUB > HR Direct > Я > Моя открытая информация. Следуйте указаниям для обновления вашей контактной информации. Обновленный адрес электронной почты будет отображен через 1-2 рабочих дня.",
  "Tool Tip - Franchised  Change Email": "Для изменения вашего адреса электронной почты обратитесь к своему руководителю или к администратору EID Tool вашей франшизы. Обновленный адрес электронной почты будет отображен через 1-2 рабочих дня.",
  "User Profile: Email Opt-in": "Спасибо, нет",
  "User Profile: Email Frequency": "Еженедельная сводка",
  "Generic Notification Email Subject Line:": "У вас имеются новые уведомления в DLZ",
  "Generic Body Text (<150 Characters)": "Пожалуйста, посетите DLZ для просмотра подробной информации.",
  "All Emails: Signature": "Digital Learning Zone - Обучение стало таким простым. Все начинается с вас.",
  "All Emails: Footer": "Вы можете в любое время отменить подписку/отказаться от уведомлений по электронной почте. Посетите Digital Learning Zone/Профиль для обновления ваших настроек.",
  "Overdue Emails: Subject Line: ": "Задания в DLZ требуют вашего внимания",
  "Overdue Emails: Body Test": "У вас имеются просроченные задания. Пожалуйста, войдите в DLZ для просмотра своих заданий.",
  "exploreLearning": {
    "sharpenYourSkillsTitle": "Доведите свои навыки до совершенства",
    "sharpenYourSkillsSubTitle": "Наращивайте знания и навыки в помощь вашей карьере – сейчас и в будущем.",
    "growYourCareerTitle": "Добейтесь карьерного роста",
    "growYourCareerSubTitle": "Планируйте свой следующий шаг при помощи специализированных навыков или перекрестного обучения, поддерживающих ваши уникальные интересы и цели.",
    "additionalResourcesTitle": "Дополнительные ресурсы",
    "additionalResourcesSubTitle": "Изучите getAbstract® и CultureWizard™. Ознакомьтесь с тем, как DLZ может и далее способствовать вашему росту.",
    "tourHeader": "Добро пожаловать в обновленный раздел DLZ. Теперь вы можете выполнять поиск и находить все, что вам нужно и когда вам нужно. Кроме того, вас ждет обновленный раздел Изучить обучение и глубже персонализированный раздел Мой План. Ознакомьтесь!",
    "tourBtnSkip": "Отказаться",
    "tourBtnNext": "Закрыть (6/6)",
    "tourSearchBar": "Найдите то, что вам нужно и когда вам нужно при помощи новой и более эффективной функции Поиск.",
    "tourBtnBack": "Назад",
    "tourMyPlan": "В Моем Плане теперь содержатся позиции, добавленные из разделов Поиск и Изучение, и вы теперь можете полностью контролировать ход своего обучения!",
    "tourExploreLearningLink": "Вы можете по-прежнему войти в Изучить обучение и отсюда!",
    "tourExploreLearningProfile": "Максимально эффективно используйте возможности по обучению, для чего регулярно проверяйте свой профиль и актуализируйте раздел Мой План.",
    "mhubTraining": "Обучение mHUB",
    "growthSkills": "Наращивание навыков",
    "leadershipSkills": "Навыки лидера",
    "virtualTeamResources": "Виртуальные групповые ресурсы",
    "jobFunctions": "Должностные обязанности",
    "brands": "Бренды",
    "languageLearning": "Обучение языкам",
    "become": "Стань.",
    "emeaMentoringProgram": "Программа наставничества для региона EMEA",
    "learningPathways": "Пути обучения",
    "mda": "MDA",
    "takeCareGrow": "TakeCare GROW",
    "getAbstract": "getAbstract®",
    "cultureWizard": "CultureWizard™",
    "takeCareCommunityHub": "Узел TakeCare для общественности",
    "mhubTrainingDesc": "Найдите направления обучения с учетом вашей роли и специализированные тематические курсы по порядку работы с mHUB.",
    "growthSkillsDesc": "Получите практические знания для различных функций и по разным темам.",
    "leadershipSkillsDesc": "Узнайте, как управлять командой и осуществлять коучинг, а также как стать более эффективным лидером.",
    "virtualTeamResourcesDesc": "Изучите методы содействия культуре сотрудничества и инклюзивности в виртуальных командах.",
    "jobFunctionsDesc": "Расширьте знания о своей текущей должности и ознакомьтесь с другими.",
    "brandsDesc": "Ознакомьтесь подробнее с разнообразными брендами Marriott International.",
    "languageLearningDesc": "Эффективно общайтесь с мировой аудиторией.",
    "becomeDesc": "Откройте для себя комплексный подход к карьерному росту и развитию: обдумайте, составьте план, действуйте, уточните.",
    "emeaMentoringProgramDesc": "Раскройте свой потенциал с Программой наставничества региона EMEA.",
    "learningPathwaysDesc": "Изучите конкретные возможности обучения, специально подготовленные для руководителей вашего уровня.",
    "mdaDesc": "Закрепите навыки лидера и функциональные навыки при помощи Marriott Development Academy.",
    "takeCareGrowDesc": "Определите ресурсы, предназначенные для построения инклюзивного и вдохновленного сообщества.",
    "getAbstractDesc": "Ежедневно узнавайте новое из аннотаций книг, помогающих вам принимать лучшие решения.",
    "cultureWizardDesc": "Накопите ценные знания для эффективных коммуникаций с представителями разнообразных культур.",
    "takeCareCommunityHubDesc": "Ознакомьтесь с мероприятиями по тим-билдингу TakeCare, учебными программами и инструментами.",
    "searchAllLearning": "Выполнить поиск по всем учебным курсам",
    "searchBarPlaceHolder": "Всегда можно изучить учить что-то новое.",
    "clearFilters": "Отменить фильтры",
    "noResults": "Результатов нет",
    "hasBeenUnpinned": "{Variable-tile_Name} откреплено.",
    "hasBeenPinned": "{Variable-tile_Name} прикреплено.",
    "lpRemovedFromPlan": "Учебная программа удалена из вашего плана.",
    "courseRemovedFromPlan": "Курс был удален из вашего плана.",
    "lpAddedToPlan": "Учебная программа добавлена к вашему плану.",
    "courseAddedToPlan": "Курс был добавлен в ваш план.",
    "clearSelection": "Отменить выбор",
    "removeFromMyPlan": "Удалить из Моего плана",
    "addToMyPlan": "Добавить в Мой план",
    "loadingResults": "Загрузка",
    "viewDetails": "Просмотреть подробности",
    "clearAll": "Очистить все",
    "noOptions": "Варианты выбора отсутствуют",
    "viewLess": "Отобразить меньше",
    "viewMore": "Отобразить больше",
    "recentSearch": "Недавние поиски",
    "exploreLearningTitle": "Рассмотреть обучение",
    "exploreLearningHelpText": "Не уверены, что вам нужно?",
    "exploreMore": "Рассмортеть подробнее.",
    "viewAllResults": "Просмотреть все <#> результаты",
    "searchSubTitle": "Выполнить поиск курсов по ключевым словам, заголовкам или идентификаторам контента.",
    "pinnedLearningTitle": "Мои закрепленные учебные курсы",
    "landingSubTitle": "Управляйте своим процессом обучения. Просматривайте интересующую вас информацию — новые темы и курсы добавляются постоянно.",
    "columnTitle": "Название",
    "columnDesc": "Описание",
    "columnType": "Тип",
    "columnAddedToPlan": "Добавить(добавлено) в план",
    "columnComplete": "Завершено",
    "columnDetails": "Подробнее",
    "additionalSearchFilters": "Дополнительные фильтры поиска",
    "tourBtnClose": "Закрыть",
    "tourBtnLast": "Закрыть",
    "resultsFor": "Результаты для ",
    "resultsFortheFilter": "Результаты для фильтра {Filter Name}",
    "no": "Нет",
    "results": "Результаты",
    "courseTooltipTitle": "Задания индивидуального обучения может включать одно или несколько занятий.<br />Курс может проводиться в онлайн-формате, например, в форме видео или электронного обучения, или занятия в учебной аудитории.",
    "gatewayToolTipTitle": "Цифровые ресурсы для вновь нанятых сотрудников, сотрудников, переведенных на руководящие должности, и для генеральных менеджеров, новых для этого бренда.",
    "speciallizationOrPathwayTooltipTitle": "Набор специализированных курсов по конкретной теме.",
    "taskTooltipTitle": "Указания по проведению занятия (обычно на рабочем месте) с последующей отметкой о выполнении.",
    "webSiteTooltipTitle": "Любой веб-сайт, включая сайты Marriott или внешние веб-сайты."
  },
  "dontShowAgain": "Не показывать это снова",
  "deletionWarningEnabled": "Предупреждение об удалении включено",
  "deletionSelected": "Если эта функция выбрана, вы будете получать предупреждение при каждой попытке удаления.",
  "manageEmailNotifications": "Управлять уведомлениями электронной почты",
  "seeHowDLZContinue": "Ознакомьтесь с тем, как DLZ может и далее способствовать вашему росту.",
  "Notification Center:Overdue": "Вам назначен один новый учебный курс, который требовалось выполнить к {Переменная дата ддммгг}",
  "accesDenied": {
    "title": "Выполняется подготовка для доступа к вашему процессу обучения! ",
    "subtitle": "Ваш доступ находится в процессе создания и будет предоставлен через 1-2 дня после активации вашего электронного ИН. Если этот процесс займет более 2 дней, пожалуйста свяжитесь с сервисным центром mHUB."
  },
  "jfTour": {
    "title": "Теперь вы можете просматривать и править свои выбранные должности и дополнительные рабочие обязанности (AWR) непосредственно из раздела Мой план."
  },
  "onboardingPlan": {
    "requiredDescription": "Ниже перечислены курсы, обязательные для выполнения за период времени, выбранный вами в разделе Мой план. Проведите работу со своим лидером, чтобы запланировать время для завершения вашего обязательного обучения по мере продвижения в вашей новой должности. Вы будете изучать самые разнообразные темы, и в ходе этой работы обязательно обращайтесь к своему лидеру и к коллегам со всеми вопросами, возникающими на этом пути.   ",
    "basicsDescription": "Мы понимаем, что вам предстоит многому научиться, и выбрали некоторые из основных тем для ваших первых дней в должности. Эти пункты могут дополнять ваш инструктаж или ознакомление с брендом, или информацию, предоставленную управляющей компанией вашей франшизы. Они представляют собой важные ресурсы для использования в течение вашей карьеры в нашей компании и будут полезными при проведении вами вводного ознакомления новых членов команды.",
    "recommendedDescription": "По истечении ваших первых 90 дней вы найдете дополнительные курсы, рекомендуемые вашим менеджером. Вы также можете изучать темы, которые заинтересовали вас лично. Но пока вам следует сосредоточиться на изучении основ. ",
    "day1": "Срок выполнения через 14 дней",
    "day2": "Срок выполнения через 30 дней",
    "day3": "Срок выполнения через 60 дней",
    "day4": "Срок выполнения через 90 дней",
    "day5": "Срок выполнения более чем через 90 дней "
  },
  "onboardingLanding": {
    "gettingStartedDescription": "Digital Learning Zone является вашей отправной точкой в отношении профессионального обучения и развития. Ниже вы найдете специально отобранные материалы, призванные помочь вам на начальной стадии. Все элементы вашего обучения могут быть найдены в разделе Мой план.  ",
    "overallLearningProgress": "Результаты обучения в целом",
    "learningProgressLabel": "Результаты обучения",
    "learningProgressDescription": "Здесь показаны результаты выполнения ваших обязательных учебных заданий.  Эта строка состояния показывает состояние всех ваших обязательных учебных заданий.  Продолжайте свою успешную работу!"
  },
  "poc": {
    "dlz": "Для завершения этого обучения Менеджеры или Координаторы обучения должны выполнить отметку о завершении этого обучения в mHUB. Подробности о порядке выполнения этого действия см. в Руководстве по практическому исполнению.",
    "mhub": "Франшизы:  если вы приняли решение использовать собственную программу по обучению основам уборки, то при простановке отметки о выполнении укажите в разделе примечаний название этой программы и подробности"
  },
  "Recommended Courses ": "По истечении ваших первых 90 дней вы найдете дополнительные курсы, рекомендуемые вашим менеджером. Вы также можете изучать темы, которые заинтересовали вас лично. Но пока вам следует сосредоточиться на изучении основ. ",
  "survey": {
    "v2": {
      "weValueButtonText": "Проводить исследование",
      "lbiIntro": "Добро пожаловать на регистрацию в отеле Luxury Behavior!",
      "lbiDescription": `<p>Ниже вам будет предложено прочитать несколько коротких сценариев, с которыми вы можете столкнуться в вашем отеле. Прочитайте каждый сценарий и выберите, как бы вы отреагировали на каждую ситуацию. При ответе помните о следующем:</p>
      <ul>
          <li>
              <p>Вы можете найти несколько правильных ответов на выбор. Выберите реакцию, которую вы, скорее всего, проявите на работе.</p>
          </li>
          <li>
              <p>Предположим, что нет никаких коммуникационных барьеров. Язык, на котором вы говорите, совпадает с языком, на котором говорит гость или сотрудник в сценарии.</p>
          </li>
          <li>
              <p>Сценарий может попросить вас представить себя на другой должности, отличной от той, на которой вы сейчас работаете. Ответьте на вопрос максимально точно, основываясь на своем опыте работы в качестве специалиста в сфере гостеприимства класса люкс.</p>
          </li>
      </ul>
      <p><br></p>
      <p>Обратите внимание: Ваше участие в этой встрече является полностью добровольным (Вы можете отказаться от участия), и ваши ответы будут строго конфиденциальными. Результаты этой проверки будут представлены для групп людей, а не для конкретных лиц. Индивидуальные ответы будут оцениваться исследовательской группой и не будут передаваться руководителям или должностным лицам.</p>
      <p>При прохождении этой регистрации вы соглашаетесь на сбор и обработку ваших персональных ответов и данных (например, продолжительность работы в компании, пол, должность), а также на трансграничную передачу ваших персональных данных Marriott Group. Это соответствует Заявлению о конфиденциальности данных сотрудников компании Marriott, которое также содержит дополнительную информацию и контактные данные для справки в Политике конфиденциальности данных компании Marriott.</p>
      `,
      "checkin": {
        q1Title: "Вы проходите через лобби и замечаете семью с тремя маленькими детьми. У членов семьи много чемоданов, они выглядят напряженными после долгого дня путешествий, а дети плачут и заметно нервничают. Ваши действия в этой ситуации?",
        q1R1Title: "Вы поможете семье с багажом, направите их в направлении стойки регистрации и предложите несколько расположенных поблизости мест для отдыха с развлечениями для всей семьи. Вы упомянете семейные удобства отеля.",
        q1R2Title: "Вы предложите им помочь с багажом и проводите семью до стойки регистрации. Затем вы организуете расслабляющую услугу или угощение для родителей и веселые мероприятия для детей в их номере. ",
        q1R3Title: "Вы направите родителей на стойку регистрации, затем лично отведете детей в зону отдыха с закусками на время регистрации родителей. Вы попросите сотрудников стойки ресепшн сообщить родителям об игровых зонах и мероприятиях для детей в отеле для их комфорта.",
        q1R4Title: "Вы направите семью к стойке регистрации для быстрого оформления заезда. Вы упомянете о детских зонах и услугах для родителей, чтобы дети могли приятно провести время.",
        q2Title: "Вы только что начали работать в отеле в новом для вас районе. Гость просит порекомендовать ему, чем можно заняться в этом районе. Гость прочитал несколько путеводителей, но ему требуется что-то более персонализированное. Ваши действия в этой ситуации? ",
        q3Title: "Вы проходите через вестибюль и слышите, как гость разговаривает по телефону, и узнаете, что его трансфер на автомобиле в аэропорт опаздывает. В вашем отеле либо нет собственного транспорта, либо он недоступен. Ваши действия в этой ситуации?",
        q4itle: "Вы проходите через лобби, чтобы выполнить срочную задачу, порученную вам вашим руководителем. Вы замечаете, что коллега пытается выполнить задачу, которая обычно выполняется двумя сотрудниками. Ваши действия в этой ситуации? ",
        q5Title: "Вы — администратор ресторана отеля. Гость просит посмотреть меню, пока он ждет зарезервированный столик. Ознакомившись с меню, гость разочарован, потому что ресторан отеля был порекомендован ему другим коллегой, но в меню нет блюд, которые удовлетворили бы его гастрономические потребности. Ваши действия в этой ситуации?",
        q6Title: "Рядом с переполненным бассейном вы видите большую группу гостей, которые не смогли найти места рядом друг с другом. В результате они разложили свои полотенца и личные вещи так, что они заблокировали главную дорожку, создавая риски и неудобства для других гостей и сотрудников, пытающихся войти в зону бассейна. Эта зона должна быть очищена, но вы не хотите расстраивать группу. Ваши действия в этой ситуации?",
        q2R1Title: "Вы спросите гостя, что ему нравится, а затем составите список интересных занятий вместе с коллегой. Кроме того, вы предложите хороший ресторан поблизости.",
        q2R2Title: "Вы выясните у гостя, что ему нравится. Совместно с коллегой вы составите специальный список интересных занятий только для этого гостя. Кроме того, вы проверите профиль гостя в Bonvoy и отправите персонализированное угощение ему в номер.",
        q2R3Title: "Вы используете личный подход, поделившись своими любимыми занятиями в этом районе. Вы обратите внимание на свои предложения, поделившись дополнительной информацией о своем опыте. Затем вы отправите в номер гостя вкусное местное угощение.",
        q2R4Title: "Вы сообщите гостю, что вы недавно живете в этом районе и не можете предложить разнообразные идеи. Вы извинитесь и предложите гостю поговорить с коллегой, который знает больше. Затем вы спросите, хочет ли гость, чтобы в его номер отправили местные угощения.",
        q3R1Title: "Пока гость все еще разговаривает по телефону, вы подгоните свой личный автомобиль или личный автомобиль своего коллеги ко входу в отель и сообщите гостю, что вы лично отвезете его в аэропорт.",
        q3R2Title: "Пока гость все еще разговаривает по телефону, вы составите список утвержденных отелем служб такси и их номера телефонов. Когда гость закончит говорить по телефону, вы покажете ему список и предложите организовать трансфер.",
        q3R3Title: "Вы дождетесь завершения гостем телефонного разговора и сообщите ему, что ваш отель не предоставляет транспортные услуги, но вы с радостью поможете организовать трансфер. ",
        q3R4Title: "Пока гость все еще разговаривает по телефону, вы найдете альтернативный вариант трансфера для гостя. После того, как гость закончит говорить по телефону, вы сообщите ему, что вы организовали ему трансфер в аэропорт, и что автомобиль скоро прибудет.",
        q4R1Title: "Вы подойдете к коллеге и скажете, что вы ему поможете, но вам нужно сначала выполнить свою срочную задачу. Пообещаете быстро вернуться, чтобы помочь ему. Обязательно обсудите ситуацию со своим руководителем.",
        q4R2Title: "Прежде всего вы быстро найдете другого сотрудника, который возьмет на себя назначенную вам задачу. Затем вы незамедлительно поможете коллеге, которому нужна помощь. После выполнения работы вы сообщите своему руководителю, что вы нашли другого сотрудника для выполнения вашей задачи, чтобы помочь своему коллеге.",
        q4R3Title: "Вы остановитесь и незамедлительно поможете своему коллеге. После оказания помощи вы вернетесь к выполнению возложенной на вас задачи, а затем сообщите своему руководителю, что вы откладывали выполнение своей задачи, чтобы помочь своему коллеге.",
        q4R4Title: "Вы подойдете к коллеге, которому нужна помощь, и объясните, что вы сейчас выполняете срочную задачу. Вы остановите выполнение обеих задач, обсудите способы совместного решения этих задач, а затем выполните обе задачи вместе.",
        q5R1Title: "Вы извинитесь перед гостем и сообщите ему, что вы позовете менеджера. Вы старательно объясните ситуацию, чтобы менеджер мог помочь гостю в удовлетворении его гастрономических потребностей.",
        q5R2Title: "Вы извинитесь перед гостем и распросите его о его предпочтениях в еде. Вы убедите сотрудников кухни приготовить для гостя специальное блюдо. Вы также принесете гостю бесплатную закуску или напиток, чтобы компенсировать ошибку.",
        q5R3Title: "Вы извинитесь и сообщите гостю, что понимаете сложившуюся ситуацию. Вместо отмены заказанного столика вы предложите поговорить с шеф-поваром, чтобы узнать, может ли он приготовить что-то особенное для гостя. Вы предложите гостю бесплатное угощение или скидку, чтобы компенсировать эту неприятность.",
        q5R4Title: "Вы принесете извинения и сообщите гостю, что вы можете помочь ему забронировать столик в расположенном поблизости ресторане, где есть блюда, которые ему нравятся. При необходимости вы сообщите дополнительную информацию или предложите помощь, чтобы гость смог туда добраться.",
        q6R1Title: "Вы извинитесь перед гостями за нехватку места и скажете, что они могут оставаться там, где находятся, - при условии, что они будут внимательны к другим людям, проходящим мимо. ",
        q6R2Title: "Вы вежливо сообщите гостям, что они не могут блокировать проход, и что им нужно найти другое место или подождать, пока другие места не освободятся. Тем временем вы попросите коллег принести с собой дополнительные стулья из банкетного зала для их использования на свежем воздухе. ",
        q6R3Title: "Вы сообщите гостям, что они не могут здесь оставаться, и что они должны временно переместиться, пока вы ищете для них дополнительные места. На это время предложите им разместиться в баре/ресторане. ",
        q6R4Title: "Извинитесь перед гостями за проблему с местами. Вы попросите гостей сложить их вещи так, чтобы обеспечить небольшой проход для других людей, проходящих мимо. Вы предложите гостям помощь, чтобы они чувствовали себя комфортно. Вы отправите им особое угощение из бара/ресторана для компенсации их неудобств."
      }
    }
  },
};