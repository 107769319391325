import ReactGA from 'react-ga4';
import { decorate, observable, action } from 'mobx';

import Api from '../api/CMDBApi';

function shuffle(array) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

class SurveyStore {
  surveyState = 'not-loaded'; // loading, loaded, submitting, submitted, error, complete, started
  currentSurvey = null;

  surveyProgress = {};
  surveyMeta = {};

  async getSurvey() {
    if (this.surveyState === 'loading' || this.surveyState === 'loaded') {
      return;
    }

    try {
      const self = this;
      this.surveyState = 'loading';
      const { data: survey } = await Api.getSurvey();
      if (survey && survey.extra.questions) {
        survey.extra.questions = shuffle(survey.extra.questions);
        survey.extra.questions.forEach(q => {
          if (q.values) {
            q.values = shuffle(q.values);
          }
        });
      }
      this.currentSurvey = survey;
      setTimeout(() => {
        self.surveyState = 'loaded';
        self.surveyMeta.timePresented = new Date();
      }, 1500);

      return survey;
    } catch (e) {
      this.surveyState = 'error';
      console.log(e);
    }
  }

  startSurvey() {
    this.surveyState = 'started';
    this.surveyMeta.timeStarted = new Date();
  }

  recordAnswer(question, answer) {
    const self = this;
    const backup = this.surveyProgress;
    this.surveyProgress = {};
    setTimeout(() => {
      self.surveyProgress = Object.assign(backup, { [question.uuid]: answer });
    });
    this.surveyMeta[question.uuid] = new Date();
  }

  recordAnswerMany(question, answer) {
    const self = this;
    const backup = this.surveyProgress;
    this.surveyProgress = {};
    setTimeout(() => {
      let existing = backup[question.uuid];
      if (!existing) {
        existing = [];
      }
      if (existing.indexOf(answer) === -1) {
        existing.push(answer);
      } else {
        existing.splice(existing.indexOf(answer), 1);
      }
      self.surveyProgress = Object.assign(backup, { [question.uuid]: existing });
      console.log(self.surveyProgress);
    });
    this.surveyMeta[question.uuid] = new Date();
  }

  async submitSurvey() {
    try {
      this.surveyState = 'submitting';
      this.surveyMeta.timeSubmitted = new Date();
      const { data: survey } = await Api.submitSurvey(this.currentSurvey.id, { ...this.surveyProgress, meta: this.surveyMeta, survey: this.currentSurvey });

      Api.surveyEvent(this.currentSurvey.uuid, {
        ...this.surveyProgress, meta: this.surveyMeta, survey: this.currentSurvey
      });

      this.surveyState = 'submitted';
      this.currentSurvey = null;
      this.surveyProgress = {};
      this.surveyMeta = {};
    } catch (e) {
      this.surveyState = 'error';
      console.log(e);
    }
  }

  async dismissSurvey() {
    try {
      this.surveyState = 'submitting';
      this.surveyMeta.timeDismissed = new Date();
      const { data: survey } = await Api.dismissSurvey(this.currentSurvey.id, {
        extra: {
          dismissed: true,
          ...this.surveyMeta
        }
      });
      this.surveyMeta = {};
      this.surveyState = 'submitted';
    } catch (e) {
      this.surveyState = 'error';
      console.log(e);
    }
  }

  sendGAEvent(surveyId, payload) {
    if (payload.category) {
      ReactGA.event(payload);
    }
    Api.surveyEvent(surveyId, payload);
  }
}

decorate(SurveyStore, {
  surveyState: observable,
  currentSurvey: observable,
  surveySubmitting: observable,
  surveyProgress: observable,
  startSurvey: action,
  getSurvey: action,
  submitSurvey: action,
  dismissSurvey: action,
  sendGAEvent: action,
  recordAnswer: action
});

export default new SurveyStore();
