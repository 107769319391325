export default {
  "banner": {
    "viewDetails": "Bekyk besonderhede",
    "requiredCourses": "Jou vereiste kursusse moet ingelewer word oor",
    "days": "dae"
  },
  "header": {
    "plan": "My plan",
    "manager": "BESTUURDER",
    "explore": "VERKEN DIE LEERPROSES",
    "reporting": "VERSLAGGEWING",
    "myTeam": "MY SPAN",
    "title": "Digital Learning Zone"
  },
  "footer": {
    "plan": "MY PLAN",
    "manager": "BESTUURDER",
    "explore": "VERKEN DIE LEERPROSES",
    "tagline": "Leer eenvoudig gemaak. Dit begin by jou."
  },
  "welcome": {
    "hi": "Hallo",
    "intro1": "Welkom by die Digital Learning Zone! Die DLZ is ’n verpersoonlikte leerplatform wat ’n sentrale toegangspunt tot Marriott-leer is. Vind vereiste opleiding vir jou rol en verken leergeleenthede vir jou persoonlike en professionele ontwikkeling. Wees baas van jou leerreis en begin om jou vaardighede te laat groei.",
    "intro2": "Voordat jy begin, moet ons ’n aantal dinge oor jou weet.",
    "letsGo": "KOM ONS BEGIN",
    "preferredLanguage": "Wat is jou verkose taal?",
    "step": "STAP",
    "of": "VAN",
    "previous": "VORIGE",
    "next": "VOLGENDE",
    "startDate": "Wanneer het jy by Marriott International of Starwood begin werk?",
    "selectYear": "KIES JAAR",
    "selectMonth": "KIES MAAND",
    "selectDay": "KIES DATUM",
    "selectFunction": "KIES FUNKSIE",
    "selectInterests": "KIES BELANGSTELLINGS",
    "chooseYear": "KIES ’N JAAR",
    "chooseMonth": "KIES ’N MAAND",
    "chooseDay": "KIES ’N DAG",
    "whatDo": "Wat is jou werksfunksie",
    "selected": "GEKOSE",
    "searchFunctions": "Deursoek alle funksies",
    "searchInterests": "Deursoek alle belangstellings",
    "additionalJobFunctionsLabel": "Vertel ons meer van wat jy doen",
    "managementLevelLabel": "Wat is jou vennootvlak?",
    "preferredBrandLabel": "As jy vir meer as een handelsmerk werk, kies asseblief almal wat op jou van toepassing is.",
    "additionalJobFunctionsLabelExtra": "Kyk na die volgende en KIES MINSTENS EEN bykomende werksplig.",
    "searchAdditionalFunctions": "Deursoek alle bykomende werkspligte",
    "highGuestContactLabel": "Kom jy dikwels met gaste in aanraking?",
    "yes": "Ja",
    "no": "Nee "
  },
  "landing": {
    "started": "Kom ons begin",
    "welcomeBack": "Welkom terug,",
    "greatWork": "Goeie werk",
    "requiredCourses": "Jou vereiste kursusse",
    "requiredCoursesDesc": "Voltooi asseblief kursusse voor die inleweringsdatum.",
    "myPlan": "My plan",
    "courses": "Kursusse",
    "courseComplete": "KURSUS VOLTOOI",
    "viewMore": "BEKYK NOG MEER VEREISTE KURSUSSE",
    "viewMoreBtn": "BEKYK NOG MEER KURSUSSE",
    "showing": "WYS TANS",
    "of": "VAN",
    "heroTitle": "Leer iets nuuts. Bemagtig jouself. Verkry vaardighede wat op jou wêreld van toepassing is.",
    "heroParagraph1": "Hou maklik tred met al jou vereiste kursusse en kry intydse kennisgewings om dit te voltooi.",
    "heroParagraph2": "Geniet pasgemaakte leerervarings gebaseer op jou werk en belangstellings.",
    "recommendedCoursesTitle": "Aanbevole kursusse",
    "recommendedCoursesDescNoContent": "Jy het op hierdie stadium geen aanbevole kursusse nie. Kyk asseblief hier onder om ander leermoontlikhede te verken.",
    "recommendedCoursesDesc": "Kyk wat jou bestuurder of mensehulpbronspesialis vir jou gekies het.",
    "recommendedCoursesBtn": "BEKYK AANBEVOLE KURSUSSE",
    "completedCoursesBtn": "BEKYK VOLTOOIDE KURSUSSE",
    "exploreTitle": "Bly nuuskierig.",
    "exploreDesc": "Kies die onderwerpe waarin jy geïnteresseerd is.",
    "jobFunctions": "Dissipline",
    "growthSkills": "Groeivaardighede",
    "leadershipSkills": "Leierskapvaardighede",
    "brands": "Handelsmerke",
    "languageLearning": "Taalleer",
    "exploreBtn": "BEKYK KURSUSSE",
    "managerRecommendation": "BESTUURDER SE AANBEVELING",
    "learningRecommendation": "LEERAANBEVELING",
    "marriottRecommendation": "MARRIOT-AANBEVELING",
    "completedCourses": "Jy het al jou vereiste kursusse voltooi!",
    "investTitle": "Wees baas van jou leerreis",
    "investParagraph1": "Ons besef dat huidige omstandighede baie uitdagend is.  Die DLZ kan gebruik word as ‘n hulpbron om jou vaardighede op datum te hou, iets nuuts te leer, jou stres te bestuur of te leer om in ‘n virtuele span te werk.  Ons het hulpbronne ingesamel om jou te help en te ondersteun.",
    "updatedTopics": "Opgedateerde onderwerpe",
    "workingRemotely": "Virtuele Spanbronne",
    "relatedCoursesNew": "Ons het nuwe onderwerpe en kursusse bygevoeg.  Klik op die skakel hieronder om jou belangstellings op te dateer. ",
    "relatedCourses": "Klik asseblief hieronder om jou belangstellings te kies.",
    "notificationText": "Nuwe mHUB-opleiding beskikbaar. Kies mHUB in Explore Learning.",
    "mHubHeroTitle1": "Eenvoudig. Slim. Veilig. Ontmoet mHUB.",
    "mHubHeroManagedText1": "mHUB integreer werktuie vir leer, loopbaanontwikkeling, Explore-kortings en meer in een platform wat maklik is om te gebruik.",
    "mHubHeroFranchisedText1": "mHUB integreer werktuie vir leer, Explore-kortings en meer in een platform wat maklik is om te gebruik.",
    "mHubHeroVisitLink": "Klik hier om bykomende aanbodbesonderhede of -aanhegsels te bekyk.",
    "myGateway": "My Gateway",
    "gatewayReview": "Gaan asseblief na en voltooi.",
    "gatewayProgress": "Gateway-vooruitgang",
    "complete": "VOLTOOID",
    "reviewContent": "Bekyk hulpbronne",
    "gatewayCardTitle": "Gateway-hulpbronne",
    "gatewayCardDesc": "Kry toegang tot jou basiese beginsels vir die eerste jaar en verder.",
    "gatewayCardDescGms": "Kry toegang tot jou basiese beginsels, aksie-items, handelsmerkhulpbronne en departementele hulpbronne vir die eerste jaar en verder.",
    "gatewayCardDescNew": "Kry toegang tot jou basiese beginsels vir die eerste jaar in jou nuwe rol.",
    "gatewayCardDescGmsNew": "Kry toegang tot jou basiese beginsels, aksie-items, handelsmerkhulpbronne en departementele hulpbronne vir die eerste jaar in jou nuwe rol en verder."
  },
  "courseBundle": {
    "completeBy": "VOLTOOI TEEN",
    "remainingCourses": "Oorblywende kursusse",
    "completedCourses": "Voltooide kursusse",
    "remainingLabel": "Daar is {courseCount} kursusse in hierdie leerroete.",
    "recommendButton": "Aanbeveel aan span",
    "startCourse": "Begin kursus",
    "durationMins": "MINUTE",
    "completed": "Voltooid",
    "back": "Terug",
    "additionalLangs1": "Behalwe in Engels, is hierdie kursus nou ook beskikbaar in",
    "additionalLangs2": "Kies asseblief jou verkose taal uit die aftrekkieslys na die aanvang van die kursus.",
    "targetAudience": "Teikengehoor",
    "all": "Almal",
    "globally": "wêreldwyd",
    "source": "Bron",
    "courseType": "Kursustipe",
    "course": "Kursus"
  },
  "profile": {
    "save": "Berg",
    "edit": "Wysig",
    "brand": "Handelsmerk",
    "brands": "Handelsmerke",
    "additionalWork": "Alle bykomende werkverantwoordelikhede",
    "startDateLabel": "Wanneer het jy by Marriott International of Starwood begin werk?",
    "preferredLanguageLabel": "Wat is jou verkose taal?",
    "jobFunctionsLabel": "Wat is jou werksrol?",
    "interestsLabel": "Wat wil jy graag leer?",
    "brandsLabel": "Vir watter handelsmerk(e) werk jy?",
    "additionalJobFunctionsLabel": "Watter bykomende werkfunksies vervul jy?",
    "managementLevelLabel": "Wat is jou medewerkervlak?",
    "highGuestContactLabel": "Kom jy dikwels met gaste in aanraking?"
  },
  "courseDetails": {
    "completeBy": "Moet voltooi word",
    "recommendTitlte": "Beveel kursus aan jou span aan",
    "courseName": "Kursusnaam",
    "sendRecommendation": "Stuur aanbeveling"
  },
  "explore": {
    "relatedCourses": "Hier is die kursusse wat verband hou met jou belangstellingsvelde.",
    "chooseInterests": "Kies jou belangstellings",
    "title": "Verken die leerproses",
    "chooseInterestsModal": "Kies belangstellings",
    "oppsTitle": "Verken leergeleenthede",
    "growWithUsTitle": "Groei saam met ons",
    "growWithUsDesc": "Marriot glo in ’n loopbaan wat saam met jou floreer. Ons glo ook dat wyer ervarings dikwels ’n meer verrykende ervaring bring.",
    "growWithUsBtn": "BEKYK GROEI SAAM MET ONS",
    "mdaTitle": "Marriott Development Academy",
    "mdaDesc": "Marriott erken sukses en toewyding. Ons eer leierskap en uitstaande diens en moedig dit aan. Ons gee ook bonusse vir suksesvolle verwysings.",
    "mdaBtn": "BEKYK MARRIOTT DEVELOPMENT ACADEMY",
    "selectInterests": "Vertel ons watter onderwerpe jou interesseer.",
    "otherRecommended": "Ander aanbevole kursusse",
    "searchByName": "Deursoek jou belangstellings."
  },
  "plan": {
    "completedRequired": "Jy het al jou vereiste kursusse voltooi!",
    "requiredCourses": "Vereiste kursusse",
    "reqDesc": "Voltooi dit asseblief voor die inleweringsdatums.",
    "complete": "VOLTOOI",
    "of": "VAN",
    "courses": "KURSUSSE",
    "completedCourses": "Voltooide kursusse",
    "completedDesc": "Jy het onlangs die kursusse hier onder voltooi.",
    "remaining": "oorblywend",
    "myLearningTitle": "My Toegevoegde Leerproses ",
    "myLearningSubTitle": "Items wat ek by My Plan gevoeg het.",
    "history": "Geskiedenis",
    "myLearningHistoryLinkText": "Sien my volledige geskiedenis",
    "jf": "Jou werkfunksie(s)",
    "awr": "Jou bykomende werkverantwoordelikhede (BWV’e)",
    "jfAwrDesc": "Belangrik: werkfunksie en BWV bepaal jou vereiste opleidings wat op My Plan aan jou toegewys is. ",
    "jfAwrEdit": "WYSIG jou profiel",
    "jfAwrEnd": "om op te dateer.",
    "tourPlan": "My Plan sluit al jou vereiste en aanbevole kursusse in wat teen sperdatum voltooi moet word",
    "tourBasics": "Marriott Basics sluit hulpbronne in waarmee jy jou Marriott-reis kan begin, en jy kan enige tyd in jou loopbaan toegang daartoe kry. Marriott Basics is aanvullend tot jou leer in My Plan.",
    "noCourses": "Jy is op datum met leer wat tans vereis word, kyk gerus vorentoe en maak ’n begin aan komende vereiste leer"
  },
  "team": {
    "title": "Bestuur jou span",
    "subtitle": "Laat hulle vaardighede en loopbane groei.",
    "functions": "Funksies",
    "viewTeam": "Bekyk span",
    "allCourses": "Alle kursusse",
    "downloadExcel": "Laai Excel-verslag af"
  },
  "feedbackButton": {
    "havingTrouble": "Voorstelle"
  },
  "recommendation": {
    "dismiss": "Is jy seker jy wil hierdie aanbeveling kanselleer?"
  },
  "table": {
    "filterTable": "تصفية الجدول"
  },
  "notifications": {
    "addAssignment2": "’n %{assignment_type}-taak vir %{assignment_name} is by jou %{page location} gevoeg en moet teen %{due} ingedien word.",
    "comingDueAssignment": "Jou %{assignment_type}-taak vir %{assignment_name} moet binnekort ingedien word, op %{due}.",
    "overdueAssignment": "Jou %{assignment_type}-taak vir %{assignment_name} is nou laat.",
    "addAssignment": "’n %{assignment_type}-taak vir %{assignment_name} is by jou %{page location} gevoeg.",
    "profile": "’n Opdatering van jou werktitel of -ligging is bespeur. Gaan asseblief jou Profiel na om toepaslike opdaterings aan Handelsmerk, Funksie of Bykomende werkverantwoordelikhede te maak. Akkurate DLZ-profielinligting help seker maak dat jy die korrekte leertake ontvang.",
    "helptip": "Alle kennisgewings verstryk ná 30 dae.",
    "markRead": "Merk as gelees",
    "delete": "Skrap",
    "markAllRead": "Merk almal as gelees",
    "settings": "Instellings",
    "tooltip": "Geleesde kennisgewings sal oor 30 dae geskrap word",
    "reportComplete": "Jou DLZ-verslag is klaar. Klik om af te laai",
    "dontShowThisAgain": "Moet dit nie weer wys nie",
    "warningEnabled": "Skrapwaarskuwing is geaktiveer.",
    "manageEmailNotifications": "Bestuur e-poskennisgewings",
    "warningTooltip": "Wanneer gekies, sal jy elke keer wat jy ’n kennisgewing skrap, ’n waarskuwing sien.",
    "changeEmailManagedTooltip": "Om jou e-pos te verander, besoek mHUB > HR Direct > Ek > My Openbare Inligting. Volg die aanwysings om jou kontakbesonderhede by te werk. Dit kan 1-2 werksdae neem vir die bygewerkte e-posadres om vertoon te word.",
    "changeEmailFranchisedTooltip": "Om jou e-pos te verander, kontak jou Bestuurder en/of jou administrateur vir die Konsessie-EID Tool. Dit mag 1-2 werksdae neem vir die bygewerkte e-pos om vertoon te word.",
    "deleteAllNotifications": "Skrap alle kennisgewings",
    "deleteIndividualHeader": "Skrap kennisgewing",
    "deleteAllConfirmation": "Is jy seker jy wil alle kennisgewings skrap? Hulle sal na die Geskrap-afdeling op die kennisgewingsentrum verskuif word totdat hulle verstryk.",
    "deleteIndividualConfirmation": "Is jy seker jy wil hierdie kennisgewing skrap? Kennisgewings wat geskrap is, word na die Geskrap-afdeling op die kennisgewingsentrum verskuif totdat hulle verstryk.",
    "permanentDeleteIndividiualConfirmation": "Is jy seker jy wil hierdie kennisgewing permanent skrap? Hierdie handeling kan nie ongedaan gemaak word nie.",
    "permanentDeleteAllConfirmation": "Is jy seker jy wil alle kennisgewings permanent skrap? Hierdie handeling kan nie ongedaan gemaak word nie.",
    "notificationDeleted": "Kennisgewing is geskrap.",
    "notificationsDeleted": "Kennisgewings is geskrap.",
    "notificationRestored": "Kennisgewing is herstel.",
    "notificationsRestored": "Kennisgewings is herstel.",
    "enableDeletionWarning": "Skrapwaarskuwing geaktiveer",
    "warningDisabled": "Skrapwaarskuwing is gedeaktiveer.",
    "viewAll": "Bekyk alles",
    "deleteAll": "Skrap alles",
    "notificationCenterTitle": "Kennisgewingsentrum",
    "markAsRead": "Merk as gelees",
    "noDeletedNotifications": "Jy is op datum!",
    "restore": "Herstel",
    "restoreAll": "Herstel alles",
    "viewCourse": "Bekyk kursus",
    "title": "Kennisgewings",
    "noNewNotifications": "Jy het geen kennisgewings nie.",
    "cancel": "Kanselleer",
    "manageNotificationsCopy": "Bestuur e-poskennisgewings\n\nKies of jy weeklikse e-posse wil ontvang wat jou agterstallige leeritems opsom. Kies “Bestuur” om jou voorkeure aan te pas."
  },
  "transcript": {
    "history": "My geskiedenis",
    "completed": "Voltooide take",
    "assignmentName": "Taaknaam",
    "type": "Tipe",
    "viewAssignment": "Bekyk taak",
    "assignmentSource": "Taakbron",
    "requiredAssignments": "Vereiste take",
    "recommendedAssignments": "Aanbevole take",
    "voluntary": "Vrywillig",
    "trasscript": "Afskrif",
    "retraining": "Heropleiding",
    "completionDate": "Voltooidatum",
    "assignmentType": "Taaktipe",
    "contentType": "Inhoudtipe",
    "contentId": "Inhoud-ID"
  },
  "contentLaunch": {
    "review": "Gaan leer na",
    "start": "Begin leer"
  },
  "survey": {
    "weValue": "Ons wil graag van jou hoor.",
    "weValueDescription": "Beantwoord asseblief vyf vinnige vrae oor hoe jy jou werk by jou eiendom ervaar.",
    "weValueButtonText": "Doen die opname",
    "weValueG1Q1A1": "Ja",
    "weValueG1Q1A2": "Nee",
    "strongAgree": "Stem sterk saam",
    "agree": "Stem saam",
    "slightAgree": "Stem ietwat saam",
    "slightDisagree": "Verskil ietwat",
    "disagree": "Verskil",
    "strongDisagree": "Verskil sterk",
    "weValueG1Q1Title": "Het jy tydens jou laaste werkskof ’n daaglikse vergadering bygewoon? (Line-Up, What’s Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments, of Daily EDITION)",
    "weValueG1Q2Title": "Die daaglikse vergadering (Line-Up, What's Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments, of Daily EDITION) inspireer my om elke dag luukse diens te skep.",
    "weValueG1Q3Title": "My departement fokus daarop om foute te verminder, selfs dié wat nie deur die gaste aangemeld word nie.",
    "weValueG1Q4Title": "Ek word bemagtig om gaste se probleme op te los sonder om eers verlof te vra.",
    "weValueG1Q5Title": "Ek word bemagtig om gaste te behaag sonder om eers verlof te vra.",
    "weValueG2Q1Title": "Ek sien gereeld dat spanlede by my eiendom moeite doen om die gaste te beïndruk met die sorg wat hulle lewer.",
    "weValueG2Q2Title": "Dit wat ek in hierdie departement geleer het, het my gehelp om luukse diens te lewer.",
    "weValueG2Q3Title": "Ek het vertroue in my kennis en vaardighede om luukse diens te lewer.",
    "weValueG2Q4Title": "In my departement lewer ons ’n luukse omgewing wat skoon en foutvry is.",
    "weValueG2Q5Title": "Selfs wanneer ek voor ’n probleem te staan kom wat nie met my departement verband hou nie, is ek bemagtig om tussenbeide te tree en die gas se probleem te hanteer.",
    "weValueG3Q1Title": "Spanlede in my eiendom ontvang dieselfde vlak van sorg as die gas.",
    "weValueG3Q2Title": "My departement gee gestalte aan die waardes van die handelsmerk.",
    "weValueG3Q3Title": "Wanneer ek moeite doen om ’n luukse gaste-ervaring te skep, word my werk erken en waardeer.",
    "weValueG3Q4Title": "Ek is betrokke by ons departement se pogings om ons werk te verbeter.",
    "weValueG3Q5Title": "In my departement kom die gas altyd eerste.",
    "submitButton": "Dien in",
    "phasetwo": {
      "weValueG1Q1Title": "Het jy tydens jou laaste werkskof ’n daaglikse vergadering bygewoon? (Line-Up, What's Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments, of Daily EDITION)?",
      "weValueG1Q2Title": "Die daaglikse vergadering (Line-Up, What's Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments, of Daily EDITION) inspireer spanlede om elke dag luukse diens te skep.",
      "weValueG1Q3Title": "Ons fokus by my eiendom daarop om foute te verminder, selfs dié wat nie deur die gaste aangemeld word nie.",
      "weValueG1Q4Title": "Ons word by my eiendom bemagtig om gaste se probleme op te los sonder om eers toestemming te vra.",
      "weValueG1Q5Title": "Ons word by my eiendom bemagtig om gaste te behaag sonder om eers toestemming te vra.",
      "weValueG2Q1Title": "Ons doen by my eiendom gereeld moeite om die gaste te beïndruk met die sorg wat ons lewer.",
      "weValueG2Q2Title": "Leergeleenthede by die eiendom het ons in staat gestel om luukse diens te lewer.",
      "weValueG2Q3Title": "Ons het by my eiendom vertroue in ons kennis en vaardighede om luukse diens te lewer.",
      "weValueG2Q4Title": "Ons lewer by my eiendom ’n luukse omgewing wat skoon en foutvry is.",
      "weValueG2Q5Title": "Selfs wanneer ons voor ’n probleem te staan kom wat nie met ons departement verband hou nie, is ons bemagtig om tussenbeide te tree en die gas se probleem te hanteer.",
      "weValueG3Q1Title": "Ons ontvang by my eiendom dieselfde vlak van sorg van mekaar as wat ons aan die gaste voorsien.",
      "weValueG3Q2Title": "My eiendom gee gestalte aan die waardes van die handelsmerk.",
      "weValueG3Q3Title": "Wanneer ons by my eiendom moeite doen om ’n luukse gaste-ervaring te skep, word ons werk erken en waardeer.",
      "weValueG3Q4Title": "Ons het by my eiendom die geleentheid om by ons departement se pogings betrokke te wees om ons eie werk te verbeter.",
      "weValueG3Q5Title": "Ons plaas by my eiendom die gas altyd eerste."
    }
  },
  "gateway": {
    "planProgressComplete": "%{label} VOLTOOID",
    "gatewayProgressComplete": "GATEWAY-VORDERING VOLTOOID",
    "heroMobileGatewayVideoText": "Speel Gateway-video",
    "basics": "BASIESE BEGINSELS",
    "brandResources": "HANDELSMERKHULPBRONNE",
    "disciplineResources": "DEPARTEMENTELE HULPBRONNE",
    "immersionResourcesHeader": "Verdiepingshulpbronne",
    "brandCommunicationsHeaader": "Handelsmerkkommunikasie",
    "brandContactsHeader": "Handelsmerkkontakte",
    "franchisedActionItemsText": "Aksie-items help jou om georganiseerd te bly en gee jou sleutelinligting oor oriëntasie. Hierdie items word nie vereis nie en is aanvullend tot wat jy van jou franchise-bestuurmaatskappy mag ontvang.",
    "global": "GLOBAAL",
    "continent": "KONTINENT",
    "viewed": "BEKYK",
    "completed": "VOLTOOID",
    "heading": "Gateway",
    "basicsTab": "DIE BASIESE BEGINSELS",
    "onboardingTab": "Aksie-items",
    "brandTab": "Handelsmerkhulpbronne",
    "disciplinesTab": "DEPARTEMENTELE HULPBRONNE",
    "basicsHeader": "Welkom by Marriott International",
    "basicsHeaderNewBrandOrRole": "Welkom",
    "basicsText": "Ons weet daar is baie om te leer soos jy jou loopbaan begin. Ons het ’n paar basiese beginsels vir jou eerste paar dae in jou werk gekies. Sommige van hierdie items kan tydens oriëntasies of handelsmerkinduksie meer breedvoerig bespreek word, maar hier sal jy ’n begin kan maak.",
    "basicsTextGms": "Ons weet daar is baie om te leer soos jy jou loopbaan begin. Ons het ’n paar basiese beginsels vir jou eerste paar dae in jou werk gekies. Sommige van hierdie items kan tydens oriëntasies of handelsmerkinduksie meer breedvoerig bespreek word, maar hier sal jy ’n begin kan maak. Sodra jy klaar is, gaan voort na die ander Gateway-bladsye, insluitend die aksie-items, handelsmerkhulpbronne en departementele hulpbronne.",
    "basicsTextFranchise": "Ons weet daar is baie om te leer en ons het ’n paar basiese beginsels vir jou eerste paar dae in jou werk gekies. Hierdie items mag dalk aanvullend wees tot jou oriëntasies, handelsmerkinduksie of inligting vanaf jou franchise-bestuurmaatskappy.",
    "basicsTextFranchiseGms": "Ons weet daar is baie om te leer en ons het ’n paar basiese beginsels vir jou eerste paar dae in jou werk gekies. Hierdie items mag dalk aanvullend wees tot jou oriëntasies, handelsmerkinduksie of inligting vanaf jou franchise-bestuurmaatskappy. Sodra jy klaar is, gaan voort na die ander Gateway-bladsye, insluitend die aksie-items, handelsmerkhulpbronne en departementele hulpbronne.",
    "basicsTextNewToRole": "Ons weet daar is baie om te leer soos jy jou nuwe rol begin. Ons het ’n paar basiese beginsels vir jou eerste paar dae in jou werk gekies. Sommige van hierdie items kan in jou toegewysde opleiding meer breedvoerig bespreek word, maar hier sal jy ’n begin kan maak.",
    "basicsTextNewToRoleGms": "Ons weet daar is baie om te leer soos jy jou nuwe rol begin. Ons het ’n paar basiese beginsels vir jou eerste paar dae in jou werk gekies. Sommige van hierdie items kan in jou toegewysde opleiding meer breedvoerig bespreek word, maar hier sal jy ’n begin kan maak. Sodra jy klaar is, gaan voort na die ander Gateway-bladsye, insluitend die aksie-items, handelsmerkhulpbronne en departementele hulpbronne.",
    "basicsTextNewToRoleFranchise": "Ons weet daar is baie om te leer en ons het ’n paar basiese beginsels vir jou eerste paar dae in jou nuwe rol gekies. Hierdie items mag dalk aanvullend wees tot inligting vanaf jou franchise-bestuurmaatskappy.",
    "basicsTextNewToRoleFranchiseGms": "Ons weet daar is baie om te leer en ons het ’n paar basiese beginsels vir jou eerste paar dae in jou nuwe rol gekies. Hierdie items mag dalk aanvullend wees tot inligting vanaf jou franchise-bestuurmaatskappy. Sodra jy klaar is, gaan voort na die ander Gateway-bladsye, insluitend die aksie-items, handelsmerkhulpbronne en departementele hulpbronne.",
    "basicsTextNewToBrand": "Ons weet daar is baie om te leer soos jy jou nuwe rol by %{brandName} begin. Ons het ’n paar basiese beginsels vir jou eerste paar dae in jou werk gekies. Sommige van hierdie items kan tydens oriëntasies of handelsmerkinduksie meer breedvoerig bespreek word, maar hier sal jy ’n begin kan maak.",
    "basicsTextNewToBrandGms": "Ons weet daar is baie om te leer soos jy jou nuwe rol by %{brandName} begin. Ons het ’n paar basiese beginsels vir jou eerste paar dae in jou werk gekies. Sommige van hierdie items kan tydens oriëntasies of handelsmerkinduksie meer breedvoerig bespreek word, maar hier sal jy ’n begin kan maak. Sodra jy klaar is, gaan voort na die ander Gateway-bladsye, insluitend die aksie-items, handelsmerkhulpbronne en departementele hulpbronne.",
    "basicsTextNewToBrandFranchise": "Ons weet daar is baie om te leer en ons het ’n paar basiese beginsels vir jou eerste paar dae in jou nuwe rol gekies. Hierdie items mag dalk aanvullend wees tot jou oriëntasies, handelsmerkinduksie of inligting vanaf jou franchise-bestuurmaatskappy.",
    "basicsTextNewToBrandFranchiseGms": "Ons weet daar is baie om te leer en ons het ’n paar basiese beginsels vir jou eerste paar dae in jou nuwe rol by %{brandName} gekies. Hierdie items mag dalk aanvullend wees tot jou oriëntasies, handelsmerkinduksie of inligting vanaf jou franchise-bestuurmaatskappy. Sodra jy klaar is, gaan voort na die ander Gateway-bladsye, insluitend die aksie-items, handelsmerkhulpbronne en departementele hulpbronne.",
    "disciplineHeader": "Departementele hulpbronne",
    "toDo": "OM TE DOEN",
    "optional": "OPSIONEEL",
    "overallComplete": "HEELTEMAL VOLTOOID",
    "keyResourcesDesc": "Vind meer uit omtrent sleuteldepartemente wat daaglikse eiendomsbedryf, gaste-ervaring en sakebehoeftes steun.",
    "franchiseFooter": "Hierdie materiaal bevat aanbevelings en voorgestelde praktyke wat die waarskynlikheid van voldoening aan handelsmerkstandaarde sal verhoog. As ’n franchisegewer raak Marriott nie betrokke by die bestuur of bedryf van enige aspek van ’n franchise-houer se hotel of sakebedryf nie. Franchise-houers behou beheer oor die daaglikse bedryf van hul hotelle en is verantwoordelik vir hul eie werknemers en indiensnemingspraktyke.",
    "landingHeroTitle1": "Welkom by Marriott International.",
    "landingHeroTitle2": "Begin jou reis hier.",
    "landingHeroText": "The Gateway is ’n hulpmiddel om jou deur jou eerste jaar in jou nuwe rol te lei. Dit sal vir jou inligting en hulpbronne gee wat jy nodig het om suksesvol te wees.",
    "landingHeroTitle1NewToRole": "Welkom by The Gateway.",
    "landingHeroTitle1NewToBrand": "Welkom by The Gateway.",
    "landingButtonText": "Kom aan die gang",
    "twoMonthslandingHeroTitle1": "Welkom terug!",
    "twoMonthslandingHeroText": "As jy dit nog nie gedoen het nie, voltooi al jou basiese beginsels in The Gateway!",
    "twoMonthslandingButtonText": "Gaan by Gateway in",
    "twoMonthslandingHeroTitle1NewToBrand": "Welkom terug!",
    "twoMonthslandingHeroTextNewToBrand": "Hoop jou eerste dae by %{brandName} was wonderlik. Moenie vergeet om jou reis voort te sit en in The Gateway toegang te kry tot alles wat jy nodig het nie",
    "twoMonthslandingButtonTextNewToBrand": "Gaan voort",
    "threeMonthslandingHeroTitle1": "Jy is goed op pad!",
    "threeMonthslandingHeroText": "Alles wat jy omtrent Marriott International moet weet, is in The Gateway.",
    "threeMonthslandingButtonText": "Gaan by Gateway in",
    "threeMonthslandingHeroTitle1NewToRole": "Jy is goed op pad!",
    "threeMonthslandingHeroTextNewToRole": "Alles wat jy moet weet om in jou nuwe rol suksesvol te wees, is in The Gateway.",
    "threeMonthslandingButtonTextNewToRole": "Gaan by Gateway in",
    "threeMonthslandingHeroTitle1NewToBrand": "Welkom terug!",
    "threeMonthslandingHeroTextNewToBrand": "Geluk dat jy die eerste %{numDays} dae met jou nuwe handelsmerk voltooi het. Jy kan al jou aksie-items, handelsmerk- en departementele hulpbronne kry net wanneer jy dit nodig het.",
    "threeMonthslandingButtonTextNewToBrand": "Gaan by Gateway in",
    "fourMonthslandingHeroTitle1": "Welgedaan! Jy het dit deur jou basiese beginsels gemaak!",
    "fourMonthslandingHeroText": "Kry in The Gateway toegang tot alles wat jy omtrent Marriott International moet weet.",
    "fourMonthslandingButtonText": "Gaan by Gateway in",
    "fourMonthslandingHeroTitle1NewToRole": "Welgedaan! Jy het dit deur jou basiese beginsels gemaak!",
    "fourMonthslandingHeroTextNewToRole": "Kry in The Gateway toegang tot alles wat jy moet weet om in jou nuwe rol suksesvol te wees.",
    "fourMonthslandingButtonTextNewToRole": "Gaan by Gateway in",
    "fourMonthslandingHeroTitle1NewToBrand": "The Gateway – Aanmelding",
    "fourMonthslandingHeroTextNewToBrand": "Soos jy nader kom aan die voltooiing van jou eerste jaar by %{brandName}, voltooi al jou aksie-items en gebruik die ontwikkelinghulpbronne wat hier in die Digital Learning Zone beskikbaar is, as jy dit nie reeds gedoen het nie. Klik op ‘Gaan voort’ om jou reis voort te sit.",
    "fourMonthslandingButtonTextNewToBrand": "Gaan voort",
    "gmSecondVisitlandingHeroTitle1": "Welkom terug!",
    "gmSecondVisitlandingHeroText": "Hoop jou eerste dae was wonderlik. Moenie vergeet om jou reis wat jou in jou nuwe rol help groei, voort te sit nie.",
    "gmSecondVisitlandingButtonText": "Gaan voort",
    "gmSecondVisitlandingHeroTextNewToRole": "Hoop jou eerste dae in jou nuwe rol was wonderlik. Moenie vergeet om jou reis voort te sit en in The Gateway toegang te kry tot alles wat jy nodig het nie.",
    "gmSecondVisitlandingHeroTextNewToBrand": "Hoop jou eerste dae by %{brandName} was wonderlik. Moenie vergeet om jou reis voort te sit en in The Gateway toegang te kry tot alles wat jy nodig het nie.",
    "gmThirdVisitlandingHeroTitle1": "Welkom terug!",
    "gmThirdVisitlandingHeroText": "Geluk dat jy die eerste %{numDays} dae van jou nuwe werkreis voltooi het. Jy kan toegang tot al jou aksie-items, handelsmerk- en departementhulpbronne kry net wanneer jy dit nodig het.",
    "gmThirdVisitlandingButtonText": "Gaan by Gateway in",
    "gmThirdVisitlandingHeroTextNewToRole": "Geluk dat jy die eerste %{numDays} dae in jou nuwe rol voltooi het. Jy kan toegang tot al jou aksie-items, handelsmerk- en departementhulpbronne kry net wanneer jy dit nodig het.",
    "gmThirdVisitlandingHeroTextNewToBrand": "Geluk dat jy die eerste %{numDays} dae by jou nuwe handelsmerk voltooi het. Jy kan toegang tot al jou aksie-items, handelsmerk- en departementhulpbronne kry net wanneer jy dit nodig het.",
    "gmSevenMonthslandingHeroTitle1": "Dit lyk of jy nog meer aksie-items het om te voltooi.",
    "gmSevenMonthslandingHeroText": "Soos jy nader aan die voltooiing van jou eerste jaar kom, moenie vergeet om al jou aksie-items te voltooi en die ontwikkelingshulpbronne te gebruik wat hier in die Digital Learning Zone beskikbaar is nie. Klik op ‘Gaan voort’ om jou reis voort te sit.",
    "gmSevenMonthslandingButtonText": "Gaan voort",
    "gmSevenMonthslandingHeroTitle1NewToRole": "The Gateway – Aanmelding",
    "gmSevenMonthslandingHeroTextNewToRole": "Soos jy nader kom aan die voltooiing van jou eerste jaar in jou nuwe rol, voltooi al jou aksie-items en gebruik die ontwikkelingshulpbronne wat hier in die Digital Learning Zone beskikbaar is, as jy dit nie reeds gedoen het nie. Klik op ‘Gaan voort’ om jou reis voort te sit.",
    "gmSevenMonthslandingHeroTitle1NewToBrand": "The Gateway – Aanmelding",
    "gmSevenMonthslandingHeroTextNewToBrand": "Soos jy nader kom aan die voltooiing van jou eerste jaar by %{brandName}, voltooi al jou aksie-items en gebruik die ontwikkelinghulpbronne wat hier in die Digital Learning Zone beskikbaar is, as jy dit nie reeds gedoen het nie. Klik op ‘Gaan voort’ om jou reis voort te sit.",
    "gmOneYearlandingHeroTitle1": "Geluk met 1 jaar by %{brandName}!",
    "gmOneYearlandingHeroTitle1NewToRole": "Geluk met 1 jaar in jou nuwe rol!",
    "gmOneYearlandingHeroTitle1NewToBrand": "Geluk met 1 jaar in jou nuwe rol by %{brandName}!",
    "gmOneYearlandingHeroText": "The Gateway-reis is dalk verby, maar die beste lê nog voor. Jy kan enige tyd vanaf die Gateway-oortjie toegang kry tot die hulpbronne.",
    "gmOneYearlandingButtonText": "Bekyk Gateway"
  },
  "resources": {
    "iptTooltip": "Gebruik kommas om meerdere liggings te skei",
    "iptAllFunctionTooltip": "Ontmerk om spesifieke werkfunksie(s) te kies. Hou gemerk om opleiding vir alle funksies by die geselekteerde ligging te sien",
    "iptAllAWRTooltip": "Ontmerk om spesifieke bykomende werksverantwoordelikhede te kies. Hou gemerk om opleiding vir alle bykomende werkverantwoordelikhede by die geselekteerde ligging te sien",
    "editJFTooltip": "Gebruik kommas om meerdere invoere te skei"
  },
  "Notification Center: ": "Merk as Nie Gelees",
  "Notification Center: Empty": "Jy is op datum!",
  "Notification Center: Individual Delete": "Is jy seker jy wil hierdie kennisgewing skrap? Kennisgewings wat geskrap is, word na die Geskrap-afdeling op die kennisgewingsentrum verskuif totdat hulle verstryk.",
  "Notification Center: Delete All": "Is jy seker jy wil alle kennisgewings skrap? Hulle sal na die Geskrap-afdeling op die kennisgewingsentrum verskuif word totdat hulle verstryk.",
  "Notification Center: Mark All As Read": "Merk alles as Gelees",
  "Notification Center: Restore All": "Herstel alles",
  "Notification Center: Delete Confirmation": "Skrap",
  "Warning Message Text: Remove Job Function or AWR": "Verwyder Werkfunksie of Ekstra Werkverantwoordelikheid (AWR)?\r\nDeur in te dien, is jy op die punt om %{Function and/or AWR} van %{count} leerders te verwyder.",
  "Warning Message Text: Add Job Function or AWR": "Voeg Werkfunksie of Ekstra Werkverantwoordelikheid (AWR) by?\r\nDeur in te dien, is jy op die punt om %{Function and/or AWR} by %{count} leerders by te voeg.",
  "Button": "Dien in",
  "Success Message Text: Add Job Function and/or AWR": "Sukses\r\nJy het die %{Job Function and/or AWR} by die gevraagde medewerker(s) by %{location} bygevoeg. \r\n\r\nWil jy ekstra Werkfunksies of Ekstra Werkverantwoordelikhede vir %{location} wysig?",
  "Message Text:AWR": "Sukses\r\nJy het die Ekstra Werkverantwoordelikhede %{ __} by die gevraagde medewerker(s) by %{ __} bygevoeg.\r\n\r\nWil jy ekstra Werkfunksies of Ekstra Werkverantwoordelikhede vir %{location, property, learner(s)} wysig?",
  "Success Message Text: Remove Job Function": "Sukses\r\nJy het die  %{Job Function and/or AWR} van die gevraagde medewerker(s) by %{location} verwyder. \r\n\r\nWil jy ekstra Werkfunksies of Ekstra Werkverantwoordelikhede vir %{location} wysig?",
  "Success Message Text: Remove AWR": "Sukses\r\nJy het die Ekstra Werkverantwoordelikheid %{ __} van die gevraagde medewerker(s) by %{ __}  verwyder.\r\n\r\nWil jy ekstra Werkfunksies of Ekstra Werkverantwoordelikhede vir %{location, property, learner(s)} wysig?",
  "Tool Tip - Managed Change Email": "Om jou e-pos te verander, besoek mHUB > HR Direct > Ek > My Openbare Inligting. Volg die aanwysings om jou kontakbesonderhede by te werk. Dit mag 1-2 werksdae neem vir die bygewerkte e-pos om vertoon te word.",
  "Tool Tip - Franchised  Change Email": "Om jou e-pos te verander, kontak jou Bestuurder en/of jou Konsessie-EID Tool administrateur. Dit mag 1-2 werksdae neem vir die bygewerkte e-pos om vertoon te word.",
  "User Profile: Email Opt-in": "Nee, dankie",
  "User Profile: Email Frequency": "Weeklikse opsomming",
  "Generic Notification Email Subject Line:": "Jy het nuwe kennisgewings in die DLZ",
  "Generic Body Text (<150 Characters)": "Besoek asseblief die DLZ om die besonderhede te sien.",
  "All Emails: Signature": "Digital Learning Zone – Leerproses maklik gemaak. Dit begin by jou.",
  "All Emails: Footer": "Jy kan te enige tyd uitteken op/onttrek van e-poskennisgewings. Besoek die Digital Learning Zone/Profiel om jou voorkeure by te werk.",
  "Overdue Emails: Subject Line: ": "DLZ Opdragtre Vereis Jou Aandag",
  "Overdue Emails: Body Test": "Jy het Agterstallige Take. Teken asseblief by die DLZ in om jou take te hersien.",
  "exploreLearning": {
    "sharpenYourSkillsTitle": "Verskerp jou vaardighede",
    "sharpenYourSkillsSubTitle": "Bou jou kennis en vaardighede om jou loopbaan nou en in die toekoms ’n hupstoot te gee.",
    "growYourCareerTitle": "Laat jou loopbaan groei",
    "growYourCareerSubTitle": "Beplan jou volgende stap met gespesialiseerde vaardighede of kruisopleiding wat jou unieke belangstellings en doelwitte steun.",
    "additionalResourcesTitle": "Bykomende hulpbronne",
    "additionalResourcesSubTitle": "Verken getAbstract® en CultureWizard™. Sien hoe die DLZ jou kan help om aan te hou groei.",
    "tourHeader": "Welkom by die opgedateerde DLZ. Nou kan jy dit wat jy ook al nodig het, soek wanneer jy dit ook al nodig kry. Plus, jy sal ’n opgedateerde Verken die Leerproses en ’n meer verpersoonlikte My Plan vind. Doen die toer!",
    "tourBtnSkip": "Wys weg",
    "tourBtnNext": "Maak toe (6/6)",
    "tourSearchBar": "Vind wat jy nodig het, wanneer jy dit nodig het, met ’n nuwe en meer robuuste Soekkenmerk.",
    "tourBtnBack": "Terug",
    "tourMyPlan": "My Plan het nou items wat jy vanaf Soektog en Verken bygevoeg het sodat jy jou leerreis nou volledig kan eien!",
    "tourExploreLearningLink": "Jy kan nog steeds van hier af by Verken die Leerproses uitkom!",
    "tourExploreLearningProfile": "Maak die beste gebruik qvan jou leerervaring en gaan jou profiel, veral werksfunksies, gereeld na om jou My Plan op datum te hou.",
    "mhubTraining": "mHUB-opleiding",
    "growthSkills": "Groeivaardighede",
    "leadershipSkills": "Leierskapvaardighede",
    "virtualTeamResources": "Virtuele spanhulpbronne",
    "jobFunctions": "Werksfunksies",
    "brands": "Handelsmerke",
    "languageLearning": "Leer tale",
    "become": "In Wording",
    "emeaMentoringProgram": "EMEA-mentorprogram",
    "learningPathways": "Leerroetes",
    "mda": "MDA",
    "takeCareGrow": "TakeCare GROW",
    "getAbstract": "getAbstract®",
    "cultureWizard": "CultureWizard™",
    "takeCareCommunityHub": "TakeCare-gemeenskapsentrum",
    "mhubTrainingDesc": "Vind rolgebaseerde leerpaaie en onderwerpspesifieke kursusse oor hoe om mHUB te gebruik.",
    "growthSkillsDesc": "Win praktiese kennis oor verskeie funksies en onderwerpe in.",
    "leadershipSkillsDesc": "Ontdek hoe om ’n span te bestuur en op te lei en ’n meer effektiewe leier te word.",
    "virtualTeamResourcesDesc": "Leer tegnieke om ’n samewerkende en inklusiewe kultuur in virtuele spanne te kweek.",
    "jobFunctionsDesc": "Brei kennis van jou huidige werksfunksie uit en vind meer oor ander uit.",
    "brandsDesc": "Vind meer uit omtrent die diverse handelsmerke van Marriott International.",
    "languageLearningDesc": "Kommunikeer doeltreffend met ’n globale gehoor.",
    "becomeDesc": "Ontdek ’n totale benadering tot loopbaangroei en -ontwikkeling: dink na, beplan, tree op, verfyn.",
    "emeaMentoringProgramDesc": "Ontsluit jou potensiaal met die EMEA-mentorprogram.",
    "learningPathwaysDesc": "Verken spesifieke leergeleenthede wat vir jou leierskapvlak saamgestel is.",
    "mdaDesc": "Bou leierskapvaardighede en funksionele vaardighede met Marriott Development Academy.",
    "takeCareGrowDesc": "Identifiseer hulpbronne wat ontwerp is om ’n inklusiewe en geïnspireerde gemeenskap te bou.",
    "getAbstractDesc": "Leer elke dag iets nuuts met boekopsommings wat jou help om beter besluite te maak.",
    "cultureWizardDesc": "Samel nuttige inligting in om doeltreffend oor diverse kulture te kommunikeer.",
    "takeCareCommunityHubDesc": "Ontdek TakeCare-spanbou-aktiwiteite, -leerprogramme en -hulpmiddels.",
    "searchAllLearning": "Deursoek alle Leer",
    "searchBarPlaceHolder": "Daar is altyd meer om te leer.",
    "clearFilters": "Maak filters skoon",
    "noResults": "Geen resultate nie",
    "hasBeenUnpinned": "{Variable-tile_Name} is losgespeld.",
    "hasBeenPinned": "{Variable-tile_Name} is vasgespeld.",
    "lpRemovedFromPlan": "Leerroete is uit jou plan verwyder.",
    "courseRemovedFromPlan": "Kursus is uit jou plan verwyder.",
    "lpAddedToPlan": "Leerroete is by jou plan gevoeg.",
    "courseAddedToPlan": "Kursus is by jou plan gevoeg.",
    "clearSelection": "Maak seleksie skoon",
    "removeFromMyPlan": "Verwyder uit my plan",
    "addToMyPlan": "Voeg by my plan",
    "loadingResults": "Laai tans",
    "viewDetails": "Bekyk besonderhede",
    "clearAll": "Maak alles skoon",
    "noOptions": "Geen opsies beskikbaar nie",
    "viewLess": "Sien minder",
    "viewMore": "Sien meer",
    "recentSearch": "Onlangse soektogte",
    "exploreLearningTitle": "Verken Leer",
    "exploreLearningHelpText": "Nie seker waarna jy soek nie?",
    "exploreMore": "Verken meer.",
    "viewAllResults": "Sien alle <#>-resultate",
    "searchSubTitle": "Soek vir kursusse met behulp van sleutelwoorde, titels of inhoud-ID.",
    "pinnedLearningTitle": "My vasgespelde leer",
    "landingSubTitle": "Wees baas van jou leerreis. Verken jou belangstellings – daar word voortdurend nuwe onderwerpe en kursusse bygevoeg.",
    "columnTitle": "Opskrif",
    "columnDesc": "Beskrywing",
    "columnType": "Tipe",
    "columnAddedToPlan": "Voeg by plan/by plan gevoeg",
    "columnComplete": "Voltooi",
    "columnDetails": "Besonderhede",
    "additionalSearchFilters": "Bykomende soekfilters",
    "tourBtnClose": "Maak toe",
    "tourBtnLast": "Maak toe",
    "resultsFor": "Resultate vir ",
    "resultsFortheFilter": "Resultate vir die filter {Filter Name}",
    "no": "Nee",
    "results": "Resultate",
    "courseTooltipTitle": "’n Individuele leeritem wat een of meer aktiwiteite kan bevat.<br />’n Kursus kan moontlik aanlyn wees, soos ’n video of e-leer, of kan in ’n klaskamer gebeur.",
    "gatewayToolTipTitle": "Digitale hulpbronne vir dié wat nuut aangestel is, dié wat nuut in bestuur is en algemene bestuurders wat nuut by hul handelsmerk is.",
    "speciallizationOrPathwayTooltipTitle": "’n Stel kursusse wat op ’n sekere onderwerp fokus.",
    "taskTooltipTitle": "Instruksies om ’n aktiwiteit te voltooi (gewoonlik by die werk) en dit dan te merk wanneer jy dit voltooi het.",
    "webSiteTooltipTitle": "Enige webwerf, insluitend Marriott-werwe of eksterne webwerwe."
  },
  "dontShowAgain": "Moet dit nie weer wys nie",
  "deletionWarningEnabled": "Skrapwaarskuwing geaktiveer",
  "deletionSelected": "Wanneer gekies, sal jy elke keer wat jy ’n kennisgewing skrap, ’n waarskuwing sien.",
  "manageEmailNotifications": "Bestuur e-poskennisgewings",
  "seeHowDLZContinue": "Sien hoe die DLZ jou kan help om aan te hou groei.",
  "Notification Center:Overdue": "Jy het een nuwe opleiding wat op {Variable date ddmmyy} ingelewer moes word",
  "accesDenied": {
    "title": "Toegang tot jou Leerreis word verwerk! ",
    "subtitle": "Jou toegang word tans geskep en behoort 1-2 dae ná jou EID aktief is, beskikbaar te wees. As dit al langer as 2 dae is, kontak die mHUB-dienssentrum asseblief."
  },
  "jfTour": {
    "title": "Jy kan nou jou geselekteerde werkfunksies en bykomende werkverantwoordelikhede (BWV) direk vanaf jou My Plan sien."
  },
  "onboardingPlan": {
    "requiredDescription": "Hier onder is die vereiste kursusse wat in die tydperk wat jy onder My Plan gekies het, voltooi moet word. Werk saam met jou leier om tyd te skeduleer om jou vereiste leerwerk te voltooi soos jy jou nuwe rol vervul. Jy sal oor ’n verskeidenheid van onderwerpe leer, maak seker dat jy na jou leier en medewerkers uitreik met enige vrae wat jy dalk het.   ",
    "basicsDescription": "Ons weet daar is baie om te leer, en ons het ’n paar basiese beginsels vir jou eerste paar dae by die werk gekies. Hierdie items sal dalk aanvullend wees tot jou oriëntasies, handelsmerkinduksie of inligting vanaf jou franchise-bestuurmaatskappy. Dit sal wonderlike hulpbronne dwarsdeur jou loopbaan by ons wees en nuttig wanneer jy nuwe spanlede in diens neem.",
    "day1": "Sperdatum is dag 14",
    "day2": "Sperdatum is dag 30",
    "day3": "Sperdatum is dag 60",
    "day4": "Sperdatum is dag 90",
    "recommendedDescription": "Na jou eerste 90 dae sal jy bykomende kursusse vind wat jou bestuurder aanbeveel het. Jy kan ook self onderwerpe wat jou interesseer, verken. Fokus vir nou op jou basiese leerwerk. ",
    "day5": "Moet ná 90 dae voltooi wees "
  },
  "onboardingLanding": {
    "gettingStartedDescription": "Die Digital Learning Zone is jou wegspringpunt vir professionele leer en ontwikkeling. Hier onder sal jy inhoud vind wat saamgestel is om jou aan die gang te kry. Al jou leerwerk kan altyd onder My Plan gevind word.  ",
    "overallLearningProgress": "Algehele leerproses",
    "learningProgressLabel": "Leervordering",
    "learningProgressDescription": "Hier is hoe jy deur jou vereiste leerproses vorder. Hierdie vorderingsbalk sluit die status van al jou vereiste leer in. Hou aan met die goeie werk!"
  },
  "poc": {
    "dlz": "Vir hierdie leer om volledig te wees, moet Bestuurders of Leerkoördineerders voltooiing vir hierdie leer in mHUB merk. Besonderhede oor hoe om dit te doen, kan in die Implementeringsgids gevind word.",
    "mhub": "Franchise-houers:  indien jy kies om jou eie basiese skoonmaakprogram te gebruik, sluit asseblief die programnaam en -besonderhede in die opmerkingafdeling in wanneer jy voltooiing afmerk"
  },
  "Recommended Courses ": "Na jou eerste 90 dae sal jy bykomende kursusse vind wat jou bestuurder aanbeveel het. Jy kan ook self onderwerpe wat jou interesseer, verken. Fokus vir nou op jou basiese leerwerk. ",
  "survey": {
    "submitButton": "Dien in",
    "v2": {
      "weValueButtonText": "Neem die opname",
      "lbiIntro": "Welkom by die Luxury Behavior- registrasie-ervaring-opname!",
      "lbiDescription": `<p>U sal hier onder gevra word om ’n paar kort scenario’s deur te lees wat by u fasiliteit mag voorkom. Lees asseblief elke scenario deur en kies hoe u op elke situasie sal reageer.  Hou die volgende in gedagte wanneer u antwoord:</p>
      <ul>
          <li>
              <p>U mag oontlik vind dat meer as een van die antwoorde waaruit u kan kies, korrek lyk.  Kies die antwoord wat u heel waarskynlik in die werksopset sal gee.</p>
          </li>
          <li>
              <p>Veronderstel dat daar geen kommunikasieprobleem is nie.  Die taal wat u praat is dieselfde as die taal wat die gas of medewerker in die scenario praat. </p>
          </li>
          <li>
              <p>Die scenario mag vereis dat u uself in ’n ander pos indink as die een waarin u tans is.  Beantwoord die vraag na die beste van u kennis, gebaseer op u ervaring as ’n luukse gasvryheidskundige.</p>
          </li>
      </ul>
      <p><br></p>
      <p>Let wel:  U deelname aan hierdie registrasie-ervaring-opname is heeltemal vrywillig (u kan kies om nie deel te neem nie), en u antwoorde sal streng vertroulik wees.  Resultate van hierdie registrasie-ervaring-opname sal vir groepe mense en nie vir spesifieke individue nie, gerapporteer word. Individuele antwoorde sal deur die navorsingspan geëvalueer word en sal nie aan bestuurders van ander persone of leiers beskikbaar gestel word nie. </p>
      <p>Deur hierdie registrasie-ervaring-opname te voltooi, stem u in tot die versameling en verwerking van u persoonlike antwoorde en data (bv. dienstydperk, geslag, posbenaming) sowel as tot die oorgrensoordrag van u persoonlike data na die Marriott Group.  Dit is ooreenkomstig die Marriott- dataprivaatheidsverklaring vir medewerkers, waar u ook bykomende inligting en kontakpersone vir verwysing na Marriott se dataprivaatheidsbeleide sal vind.</p>
      `,
      "checkin": {
        q1Title: "U stap deur die voorportaal en sien ’n gesin met drie klein kinders.  Die gesin het baie bagasie, lyk gespanne na ’n lang dag se reis, en die kinders is huilerig en is sigbaar rusteloos.  Wat sou u doen? ",
        q2Title: "U het pas by ’n fasiiteit begin werk in ’n omgewing wat vir u nuut is. ’n Gas vra vir voorstelle van wat om in die omgewing te doen.  Die gas het verskeie gidsboeke gelees, maar wil iets meer persoonlik hê.  Wat sou u doen? ",
        q3Title: "U stap deur die voorportaal wanneer u ’n gas oor die telefoon hoor praat en verneem dat hul vervoerdiens na die lughawe laat is. U fasiliteit het óf nie ’n huismotor nie, of dit is nie beskikbaar nie.  Wat sou u doen? ",
        q4Title: "U stap deur die voorportaal onderweg om ’n dringende taak uit te voer wat u bestuurder aan u opgedra het.  U sien ’n kollega wat sukkel om ’n taak uit te voer wat gewoonlik deur twee mense uitgevoer word.  Wat sou u doen? ",
        q5Title: "U is ’n gasheer by die hotelrestaurant.  ’n Gas vra om die spyskaart te sien terwyl hulle wag vir hul bespreekte tafel.  Nadat die gas na die spyskaart gekyk het, is die gas gefrustreerd omdat die hotelrestaurant deur ’n ander kollega aan hulle aanbeveel is, maar daar niks op die spyskaart is wat aan hul dieetbehoeftes voldoen nie.  Wat sou u doen? ",
        q6Title: "Daar is ’n groot groep gaste wat nie by mekaar sitplek in die stampvol swembadarea kon kry nie.  Gevolglik het hulle hul handdoeke en persoonlike besittings so neergesit dat dit ’n hoofloopgang versper, wat ’n gevaar en ontwrigting vir gaste en medewerkers skep wat toegang tot die swembadarea probeer kry.  Die area moet ontruim word, maar u wil nie die groep ontstel nie.  Wat sou u doen? ",
        q1R1Title: "Help die gesin met hul bagasie, wys hulle waar die ontvangstoonbank is en stel ’n paar nabygeleë plekke wat hulle sal laat ontspan, met gesinsvriendelike aktiwiteite voor.  Noem die fasiliteit se gesinsgeriewe.",
        q1R3Title: "Begelei die ouers na die ontvangstoonbank, neem dan die kinders persoonlik na ’n sit-area met versnaperinge terwyl die ouers registreer. Laat die ontvangstoonbank die ouers inlig oor die speelareas en kinderaktiwiteite by die fasiliteit vir hul gerief. ",
        q1R2Title: "Bied aan om hulle te help met hul bagasie en begelei die gesin na die ontvangstoonbank. Reël dan vir ’n diens wat hulle sal laat ontspan of ’n bederf vir die ouers en prettige aktiwiteite vir die kinders in hul kamer.",
        q1R4Title: "Wys die gesin waar die ontvangstoonbank vir ’n vinnige aanmeldproses is. Noem die kindervriendelike areas en dienste aan die ouers sodat die kinders ’n aangename verblyf kan geniet.",
        q2R1Title: "Vra die gas wat hulle geniet om te doen, en werk dan saam met ’n kollega om ’n lys saam te stel van prettige aktiwiteite wat hulle kan doen. Stel ook ’n lekker, nabygeleë restaurant voor.",
        q2R2Title: "Praat met die gas oor waarvan hulle hou. Werk saam met ’n kollega om ’n spesiale lys van prettige goed net vir hulle saam te stel. Gaan ook die gas se Bonvoy-profiel na en stuur ’n persoonlike bederfie na hul kamer.",
        q2R3Title: "Maak dit persoonlik deur vir hulle van u eie gunstelingaktiwiteite in die omgewing tot dusver, te vertel. Laat u voorstelle uitstaan deur van bykomende besonderhede oor u ervarings te vertel. Stuur dan ’n smaaklike, plaaslike lekkerny na hul kamer.",
        q2R4Title: "Laat die gas weet dat u nuut in die omgewing is en nie baie voorstelle het nie. Vra om verskoning en stel voor dat hulle met u kollega wat meer weet, gesels. Vra dan of hulle daarvan sal hou dat ’n plaaslike lekkerny na hul kamer gestuur word.",
        q3R1Title: "Terwyl die gas nog met die telefoonoproep besig is, bring u u eie of u kollega se persoonlike voertuig na die voorkant van die fasiliteit en lig die gas in dat u hulle persoonlik na hul lughawe sal neem.",
        q3R2Title: "Terwyl die gas nog met die telefoonoproep besig is, stel ’n lys van fasiliteit-goedgekeurde vervoerdienste en telefoonnommers saam. Sodra die gas die oproep beëindig, gee vir hom of haar die lys en bied aan om te help om die vervoer te reël.",
        q3R3Title: "Wag totdat die gas die oproep beëindig en lig hom of haar in dat u fasiliteit nie vervoerdienste bied nie, maar dat u graag sal help om ander vervoeropsies te koördineer. ",
        q3R4Title: "Terwyl die gas nog met die telefoonoproep besig is, vind alternatiewe vervoer vir die gas. Sodra die persoon die oproep beëindig, laat weet die gas dat u gereël het vir hul vervoer na die lughawe en dat die motor binnekort sal opdaag.",
        q4R1Title: "Gaan na u kollega en sê dat u sal help, maar u moet eers u dringende taak uitvoer Belowe om vinnig terug te keer om hulle te help. Maak seker dat u die situasie met u bestuurder bespreek.",
        q4R2Title: "Soek eers vinnig ’n ander kollega om u toegewese taak oor te neem. Help dan dadelik u kollega wat hulp nodig het. Wanneer die taak voltooi is, lig u bestuurder in dat u iemand anders gekry het om u taak uit te voer sodat u u kollega kon help. ",
        q4R3Title: "Stop en help dadelik u kollega. Nadat u gehelp het, keer terug na u toegewese taak, en laat u bestuurder dan weet dat u u taak tydelik gestaak het om u kollega te help.",
        q4R4Title: "Gaan na u kollega wat hulp nodig het, en verduidelik dat u met ’n dringende taak besig is. Staak albei take, dink aan ’n manier om albei u take gesamentlik op te los, en voltooi dan albei take gesamentlik.",
        q5R1Title: "Vra die gas om verskoning en sê dat u die bestuurder sal roep. Maak seker dat u die situasie verduidelik sodat die bestuurder die gas met hul kosbehoeftes kan help.",
        q5R2Title: "Vra die gas om verskoning en vra uit oor hul kosvoorkeure. Praat met die kombuisspan om ’n spesiale gereg net vir hulle te maak. Gee ook vir hulle ’n komplimentêre voorgereg of drankie om te vergoed vir die fout.",
        q5R3Title: "Vra om verskoning en sê vir die gas dat u verstaan. In plaas daarvan om die bespreking te kanselleer, bied aan om met die sjef te praat en te kyk of hulle iets spesiaals vir die gas kan maak. Gee aan hulle ’n komplimentêre bederf of afslag om vir die ongerief te vergoed.",
        q5R4Title: "Vra om verskoning en sê vir die gas dat u hulle kan help om ’n tafel by ’n nabygeleë restaurant met kos waarvan hulle hou, te bespreek. Bied verdere inligting aan of help hulle om daar te kom, indien nodig.",
        q6R1Title: "Vra die gaste om verskoning vir die gebrek aan spasie en sê vir hulle dat hulle welkom is om te bly waar hulle is, mits hulle ag slaan op ander persone wat daar verbystap. ",
        q6R2Title: "Sê op ’n mooi manier vir die gaste dat hulle nie die loopgang mag blokkeer nie, en dat hulle ’n ander plek moet vind of wag totdat spasie beskikbaar word. Roep intussen u kollegas om ekstra stoele van die balsaal na buite te bring vir hulle om te gebruik. ",
        q6R3Title: "Lig die gaste in dat hulle nie kan bly sit waar hulle tans is nie en dat hulle tydelik moet skuif terwyl u ekstra stoele vind. Bied hulle in die tussentyd plek by die kroeg/restaurant aan. ",
        q6R4Title: "Vra die gaste om verskoning vir die gebrek aan sitplek. Vra of hulle hul goed bymekaar kan sit om steeds ’n klein loopgang toe laat vir ander wat verbystap. Bied aan om hulle te help en maak seker dat almal gemaklik is. Stuur vir hulle ’n spesiale lekkerny van die kroeg/restaurant om te vergoed vir hul ongerief."
      }
    }
  }
};