import { decorate,  observable, action, computed } from 'mobx';
import _ from 'lodash';
import moment from 'moment';
import CMDBApi from '../api/CMDBApi';
import CourseStore from './CourseStore';

class UserHistoryStore {
  userHistoryData = [];
  isLoading = false;

  get totalRows() {
    return this.userHistoryData.length || 0;
  }

  async fetchUserHistory(startDate) {
    try {
      this.isLoading = true;
      const { data } = await CMDBApi.fetchUserHistory(startDate);
      const nonCourseCompletions = CourseStore.allRawCourses.filter(c => c.type.indexOf('Learning') !== -1 && c.meta && c.meta.isCompleted).map(course => {
        return {
          id: course.id,
          assignmentName: course.name,
          isRequired: course.isRequired ? 'Yes' : 'No',
          source: course.source,
          sourceId: course.sourceId,
          type: course.type,
          recertification: '',
          completionDate: moment(course.meta.completedOnDate),
          completionDate2: moment(course.meta.completedOnDate).unix(),
          status: course.iptStatus,
          isActive: course.iptStatus !== 'Not Active'
        };
      });

      this.userHistoryData = _.orderBy([...data.map(d => ({ ...d, completionDate2: moment(d.completionDate).unix() })), ...nonCourseCompletions], 'completionDate2', 'desc');
      console.log(this.userHistoryData);
    } catch (e) {
      this.userHistoryData = []
      console.log("error caught while fetching user history data :", e);
    } finally {
      this.isLoading = false;
    }
  }

  setLoading(loading) {
    this.isLoading = loading;
  }
}

decorate(UserHistoryStore, {
  totalRows: computed,
  userHistoryData: observable,
  isLoading:  observable,
  fetchUserHistory: action,
  setLoading: action
});


export default new UserHistoryStore();


