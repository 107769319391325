export default {
  "banner": {
    "viewDetails": "Exibir detalhes",
    "requiredCourses": "Seus cursos obrigatórios vencem em",
    "days": "dias"
  },
  "header": {
    "plan": "Meu plano",
    "manager": "GERENTE",
    "explore": "EXPLORAR APRENDIZADO",
    "reporting": "RELATÓRIOS",
    "myTeam": "MINHA EQUIPE",
    "title": "Digital Learning Zone"
  },
  "footer": {
    "plan": "MEU PLANO",
    "manager": "GERENTE",
    "explore": "EXPLORAR APRENDIZADO",
    "tagline": "O aprendizado ficou simples. Começa com você."
  },
  "welcome": {
    "hi": "Oi",
    "intro1": "Bem-vindo(a) à Digital Learning Zone! A DLZ é uma plataforma de aprendizado personalizado, que é um ponto de acesso central para o aprendizado na Marriott. Busque o treinamento necessário à sua função e descubra oportunidades de aprendizado para seu desenvolvimento pessoal e profissional. Assegure a sua jornada de aprendizado e comece já a aprimorar suas habilidades.",
    "intro2": "Antes de você começar, precisamos saber algumas coisas sobre você.",
    "letsGo": "VAMOS LÁ",
    "preferredLanguage": "Qual o seu idioma de preferência?",
    "step": "PASSO",
    "of": "DE",
    "previous": "ANTERIOR",
    "next": "PRÓXIMO",
    "startDate": "Quando você começou a trabalhar na Marriott International ou na Starwood?",
    "selectYear": "SELECIONE YEAR (ANO)",
    "selectMonth": "SELECIONE MONTH (MÊS)",
    "selectDay": "SELECIONE DATE (DATA)",
    "selectFunction": "SELECIONE FUNCTION (FUNÇÃO)",
    "selectInterests": "SELECIONE INTERESTS (INTERESSES)",
    "chooseYear": "ESCOLHA UM ANO",
    "chooseMonth": "ESCOLHA UM MÊS",
    "chooseDay": "ESCOLHA UM DIA",
    "whatDo": "Qual é sua função?",
    "selected": "SELECIONADO",
    "searchFunctions": "Pesquisar todas as funções",
    "searchInterests": "Pesquise All Interests (Todos os interesses)",
    "additionalJobFunctionsLabel": "Conte-nos mais sobre o que você faz",
    "managementLevelLabel": "Qual é o seu nível hierárquico?",
    "preferredBrandLabel": "Se você trabalha para mais de uma marca, selecione todas que se aplicam a você.",
    "additionalJobFunctionsLabelExtra": "Analise as opções a seguir e SELECIONE PELO MENOS UMA responsabilidade de trabalho adicional.",
    "searchAdditionalFunctions": "Pesquisar todas as responsabilidades de trabalho adicionais",
    "highGuestContactLabel": "Você desempenha função de contato frequente com hóspedes?",
    "yes": "Sim",
    "no": "Não "
  },
  "landing": {
    "started": "Vamos começar",
    "welcomeBack": "Bem-vindo de volta,",
    "greatWork": "Ótimo trabalho",
    "requiredCourses": "Seus cursos obrigatórios",
    "requiredCoursesDesc": "Conclua os cursos antes da data de vencimento.",
    "myPlan": "Meu plano",
    "courses": "Cursos",
    "courseComplete": "CURSO CONCLUÍDO",
    "viewMore": "EXIBIR MAIS CURSOS OBRIGATÓRIOS",
    "viewMoreBtn": "EXIBIR MAIS CURSOS",
    "showing": "MOSTRANDO",
    "of": "DE",
    "heroTitle": "Aprenda algo novo. Capacite-se. Adquira habilidades que se encaixam no seu cotidiano.",
    "heroParagraph1": "Monitore com facilidade todos os seus cursos obrigatórios e receba notificações em tempo real para concluí-os.",
    "heroParagraph2": "Descubra experiências personalizadas de aprendizagem com base no seu trabalho e nos seus interesses.",
    "recommendedCoursesTitle": "Cursos recomendados",
    "recommendedCoursesDescNoContent": "Você não tem nenhum curso recomendado no momento. Explore abaixo outras oportunidades de aprendizagem.",
    "recommendedCoursesDesc": "Veja as oportunidades que o seu gerente ou responsável de RH escolheu para você.",
    "recommendedCoursesBtn": "EXIBIR CURSOS RECOMENDADOS",
    "completedCoursesBtn": "EXIBIR CURSOS CONCLUÍDOS",
    "exploreTitle": "Estimule sua curiosidade.",
    "exploreDesc": "Selecione os tópicos nos quais você tem interesse.",
    "jobFunctions": "Disciplina",
    "growthSkills": "Habilidades de desenvolvimento pessoal",
    "leadershipSkills": "Habilidades de liderança",
    "brands": "Marcas",
    "languageLearning": "Aprendizagem de idioma",
    "exploreBtn": "EXIBIR CURSOS",
    "managerRecommendation": "RECOMENDAÇÃO DO GERENTE",
    "learningRecommendation": "RECOMENDAÇÃO DE APRENDIZAGEM",
    "marriottRecommendation": "RECOMENDAÇÃO DA MARRIOTT",
    "completedCourses": "Você completou todos os seus cursos obrigatórios!",
    "investTitle": "Assegure a sua jornada de aprendizado",
    "investParagraph1": "Sabemos que as circunstâncias atuais são desafiadoras em muitos aspectos - o DLZ está aqui como um recurso para você. Se você quer manter suas habilidades em dia, aprenda algo novo,gerencie seu estresse ou aprenda a trabalhar em uma equipe virtual. Reunimos recursos para ajudá-lo.",
    "updatedTopics": "Tópicos atualizados",
    "workingRemotely": "Recursos da equipe virtual",
    "relatedCoursesNew": "Clique abaixo para atualizar seus interesses, pois adicionamos novos tópicos e cursos.",
    "relatedCourses": "Clique abaixo para selecionar seus interesses.",
    "notificationText": "Novo treinamento em mHUB disponível. Escolha o mHUB em Explore Learning.",
    "mHubHeroTitle1": "Simples. Inteligente. Seguro. Apresentando o mHUB.",
    "mHubHeroManagedText1": "O mHUB integra ferramentas de aprendizagem, desenvolvimento de carreira, descontos Explore e muito mais em apenas uma plataforma fácil de usar.",
    "mHubHeroFranchisedText1": "O mHUB integra ferramentas de aprendizagem, de Explore descontos e muito mais em apenas uma plataforma fácil de usar.",
    "mHubHeroVisitLink": "Clique aqui para exibir os detalhes ou anexos adicionais da oferta.",
    "myGateway": "My Gateway",
    "gatewayReview": "Revise e conclua.",
    "gatewayProgress": "Progresso do Gateway",
    "complete": "CONCLUIR",
    "reviewContent": "Visualizar recursos",
    "gatewayCardTitle": "Recursos do Gateway",
    "gatewayCardDesc": "Acesse as tarefas básicas durante o primeiro ano e depois.",
    "gatewayCardDescGms": "Acesse as tarefas básicas, itens de ação, recursos de marca e de disciplina durante o primeiro ano e depois.",
    "gatewayCardDescNew": "Acesse as tarefas básicas durante o primeiro ano em sua nova função.",
    "gatewayCardDescGmsNew": "Acesse as tarefas básicas, itens de ação, recursos de marca e de disciplina durante o primeiro ano em sua nova função e depois."
  },
  "courseBundle": {
    "completeBy": "CONCLUIR ATÉ",
    "remainingCourses": "Cursos restantes",
    "completedCourses": "Cursos concluídos",
    "remainingLabel": "Há {courseCount} cursos neste caminho de aprendizagem.",
    "recommendButton": "Recomendar para a equipe",
    "startCourse": "Iniciar curso",
    "durationMins": "MINUTOS",
    "completed": "Concluído",
    "back": "Voltar",
    "additionalLangs1": "Além de ser ofertado em inglês, este curso agora está disponível em",
    "additionalLangs2": "Escolha seu idioma de preferência do menu suspenso após a inicialização do curso.",
    "targetAudience": "Público-alvo",
    "all": "Todas",
    "globally": "globalmente",
    "source": "Fonte",
    "courseType": "Tipo do curso",
    "course": "Curso"
  },
  "profile": {
    "save": "Salvar",
    "edit": "Editar",
    "brand": "Marca",
    "brands": "Marcas",
    "additionalWork": "Todas as responsabilidades adicionais do trabalho",
    "startDateLabel": "Quando você começou a trabalhar na Marriott International ou na Starwood?",
    "preferredLanguageLabel": "Qual o seu idioma de preferência?",
    "jobFunctionsLabel": "Qual é a sua função?",
    "interestsLabel": "O que você quer aprender?",
    "brandsLabel": "Para qual marca ou marcas você trabalha?",
    "additionalJobFunctionsLabel": "Quais funções adicionais você desempenha?",
    "managementLevelLabel": "Quase seu nível de associado?",
    "highGuestContactLabel": "Você desempenha função de contato frequente com hóspedes?"
  },
  "courseDetails": {
    "completeBy": "A ser concluído",
    "recommendTitlte": "Recomendar curso para a sua equipe",
    "courseName": "Nome do curso",
    "sendRecommendation": "Enviar recomendação"
  },
  "explore": {
    "relatedCourses": "Aqui estão os cursos relacionados a suas áreas de interesse.",
    "chooseInterests": "Escolher seus interesses",
    "title": "Explorar aprendizado",
    "chooseInterestsModal": "Escolher interesses",
    "oppsTitle": "Explorar oportunidades de aprendizagem",
    "growWithUsTitle": "Cresça conosco",
    "growWithUsDesc": "A Marriott acredita em uma carreira que floresce junto com você. Também acreditamos que experiências mais amplas muitas vezes oferecem uma experiência mais enriquecedora.",
    "growWithUsBtn": "EXIBIR CRESÇA CONOSCO",
    "mdaTitle": "Marriott Development Academy",
    "mdaDesc": "A Marriott reconhece o sucesso e o comprometimento. Reverenciamos e incentivamos a liderança e o serviço excepcional. Também concedemos bônus para indicações bem-sucedidas.",
    "mdaBtn": "EXIBIR MARRIOTT DEVELOPMENT ACADEMY",
    "selectInterests": "Conte-nos quais são seus tópicos de interesse.",
    "otherRecommended": "Outros cursos recomendados",
    "searchByName": "Procure por seus interesses"
  },
  "plan": {
    "completedRequired": "Você completou todos os seus cursos obrigatórios!",
    "requiredCourses": "Cursos obrigatórios",
    "reqDesc": "Conclua-os antes de suas datas de vencimento.",
    "complete": "CONCLUIR",
    "of": "DE",
    "courses": "CURSOS",
    "completedCourses": "Cursos concluídos",
    "completedDesc": "Parabéns! Você concluiu os seguintes cursos.",
    "remaining": "restante",
    "myLearningTitle": "Meu aprendizado adicionado ",
    "myLearningSubTitle": "Itens que adicionei ao Meu plano",
    "history": "Histórico",
    "myLearningHistoryLinkText": "Ver meu histórico completo",
    "jf": "Sua(s) função(ões)",
    "awr": "Suas responsabilidades adicionais do trabalho (AWR)",
    "jfAwrDesc": "Importante: a função de trabalho e as AWR determinam seus treinamentos obrigatórios atribuídos em Meu plano. ",
    "jfAwrEdit": "EDITE seu perfil",
    "jfAwrEnd": "para atualizá-lo.",
    "tourPlan": "O Meu plano inclui todos os cursos obrigatórios e recomendados que você deve concluir por data de conclusão",
    "tourBasics": "O Marriott Basics inclui recursos para você dar início à sua jornada na Marriott e pode ser acessado em qualquer momento de sua carreira. O Marriott Basics é complementar ao seu aprendizado no Meu plano.",
    "noCourses": "Você já concluiu todo o aprendizado obrigatório no momento. Fique à vontade para se adiantar e iniciar os próximos aprendizados obrigatórios"
  },
  "team": {
    "title": "Gerenciar sua equipe",
    "subtitle": "Desenvolver habilidades e carreiras da sua equipe.",
    "functions": "Funções",
    "viewTeam": "Exibir equipe",
    "allCourses": "Todos os cursos",
    "downloadExcel": "Baixar relatório do Excel"
  },
  "feedbackButton": {
    "havingTrouble": "Sugestões"
  },
  "recommendation": {
    "dismiss": "Tem certeza que deseja recusar esta recomendação?"
  },
  "table": {
    "filterTable": "Таблица фильтра"
  },
  "notifications": {
    "addAssignment2": "Uma tarefa %{assignment_type} para %{assignment_name} foi adicionada a %{page location} e expirará em %{due}.",
    "comingDueAssignment": "Sua tarefa %{assignment_type} para %{assignment_name} expirará em breve em %{due}.",
    "overdueAssignment": "Sua tarefa %{assignment_type} para %{assignment_name} está atrasada.",
    "addAssignment": "Uma tarefa %{assignment_type} para %{assignment_name} foi adicionada a %{page location}.",
    "profile": "Foi detectada uma atualização em seu cargo ou localização. Revise seu perfil para atualizar a marca, a função ou as responsabilidades adicionais de seu trabalho. Informações precisas do perfil DLZ ajudam a garantir que você receba as tarefas de aprendizado corretas.",
    "helptip": "Todas as notificações expiram após 30 dias.",
    "markRead": "Marcar como lido",
    "delete": "Excluir",
    "markAllRead": "Marcar tudo como lido",
    "settings": "Configurações",
    "tooltip": "Notificações lidas serão excluídas em 30 dias",
    "reportComplete": "Seu relatório de DLZ está finalizado. Clique para baixar",
    "dontShowThisAgain": "Não mostrar novamente",
    "warningEnabled": "O aviso de exclusão foi ativado.",
    "manageEmailNotifications": "Gerenciar notificações por e-mail",
    "warningTooltip": "Quando selecionado, você verá um aviso sempre que excluir uma notificação.",
    "changeEmailManagedTooltip": "Para alterar seu e-mail, acesse mHUB > HR Direct > Eu > Minhas informações públicas. Siga as instruções para atualizar seus detalhes de contato. Pode levar de um a dois dias úteis para que o e-mail atualizado seja exibido.",
    "changeEmailFranchisedTooltip": "Para alterar seu e-mail, entre em contato com seu Gerente e/ou Administrador de franquia EID Tool. Pode levar 1 a 2 dias úteis para que o e-mail atualizado seja exibido.",
    "deleteAllNotifications": "Excluir todas as notificações",
    "deleteIndividualHeader": "Excluir notificações",
    "deleteAllConfirmation": "Tem certeza de que deseja excluir todas as notificações? Elas serão movidas para a seção Excluído no Centro de notificações até que expirem.",
    "deleteIndividualConfirmation": "Tem certeza de que deseja excluir essa notificação? Notificações excluídas são movidas para a seção Excluído no Centro de notificações até que expirem.",
    "permanentDeleteIndividiualConfirmation": "Tem certeza de que deseja excluir permanentemente esta notificação? Esta ação não pode ser revertida.",
    "permanentDeleteAllConfirmation": "Tem certeza de que deseja excluir permanentemente todas as notificações? Esta ação não pode ser revertida.",
    "notificationDeleted": "A notificação foi excluída.",
    "notificationsDeleted": "As notificações foram excluídas.",
    "notificationRestored": "A notificação foi restaurada.",
    "notificationsRestored": "As notificações foram restauradas.",
    "enableDeletionWarning": "Aviso de exclusão ativado",
    "warningDisabled": "O aviso de exclusão foi desativado.",
    "viewAll": "Exibir tudo",
    "deleteAll": "Excluir tudo",
    "notificationCenterTitle": "Centro de notificações",
    "markAsRead": "Marcar como lida",
    "noDeletedNotifications": "Você está atualizado!",
    "restore": "Restaurar",
    "restoreAll": "Restaurar tudo",
    "viewCourse": "Visualizar curso",
    "title": "Notificações",
    "noNewNotifications": "Você não possui notificações.",
    "cancel": "Cancelar",
    "manageNotificationsCopy": "Gerenciar notificações por e-mail\n\nSelecione se deseja receber e-mails semanais que resumam seus itens de aprendizado em atraso. Selecione \"Gerenciar\" para modificar suas preferências."
  },
  "transcript": {
    "history": "Meu histórico",
    "completed": "Tarefas concluídas",
    "assignmentName": "Nome da tarefa",
    "type": "Digite",
    "viewAssignment": "Visualizar tarefas",
    "assignmentSource": "Origem da tarefa",
    "requiredAssignments": "Tarefas obrigatórias",
    "recommendedAssignments": "Tarefas recomendadas",
    "voluntary": "Voluntário",
    "trasscript": "Transcrição",
    "retraining": "Reciclagem",
    "completionDate": "Data de conclusão",
    "assignmentType": "Tipo de tarefa",
    "contentType": "Tipo de conteúdo",
    "contentId": "ID de conteúdo"
  },
  "contentLaunch": {
    "review": "Revisar aprendizado",
    "start": "Iniciar aprendizado"
  },
  "survey": {
    "weValue": "Adoraríamos receber sua opinião.",
    "weValueDescription": "Responda a cinco perguntas rápidas sobre sua experiência trabalhando para sua propriedade.",
    "weValueButtonText": "Responda à pesquisa",
    "weValueG1Q1A1": "Sim",
    "weValueG1Q1A2": "Não",
    "strongAgree": "Concordo totalmente",
    "agree": "Concordo",
    "slightAgree": "Concordo um pouco",
    "slightDisagree": "Discordo um pouco",
    "disagree": "Discordo",
    "strongDisagree": "Discordo totalmente",
    "weValueG1Q1Title": "Você participou de uma reunião diária no último turno em que trabalhou? (Line-Up, What's Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments ou Daily EDITION)",
    "weValueG1Q2Title": "A reunião diária (Line-Up, What's Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments ou Daily EDITION) me inspira a oferecer um serviço de luxo todos os dias.",
    "weValueG1Q3Title": "Meu departamento está focado em reduzir erros, mesmo aqueles não relatados pelos hóspedes.",
    "weValueG1Q4Title": "Tenho o poder de resolver os problemas dos hóspedes sem ter que pedir permissão primeiro.",
    "weValueG1Q5Title": "Tenho o poder de agradar os hóspedes sem ter que pedir permissão primeiro.",
    "weValueG2Q1Title": "Vejo regularmente os membros da equipe na minha propriedade indo além para impressionar os hóspedes com o cuidado que eles oferecem.",
    "weValueG2Q2Title": "Meu aprendizado neste departamento me permitiu oferecer um serviço de luxo.",
    "weValueG2Q3Title": "Sinto-me confiante com meus conhecimentos e habilidades para oferecer serviços de luxo.",
    "weValueG2Q4Title": "No meu departamento, oferecemos um ambiente de luxo, limpo e sem defeitos.",
    "weValueG2Q5Title": "Mesmo quando encontro um problema que não está relacionado ao meu departamento, tenho o poder de intervir e assumir a preocupação do hóspede.",
    "weValueG3Q1Title": "Na minha propriedade, os membros da equipe recebem o mesmo nível de cuidado que o hóspede.",
    "weValueG3Q2Title": "Meu departamento incorpora os valores da marca.",
    "weValueG3Q3Title": "Quando vou além para criar uma experiência de luxo para o hóspede, meu trabalho é reconhecido e apreciado.",
    "weValueG3Q4Title": "Estou envolvido nos esforços do nosso departamento para melhorar nosso trabalho.",
    "weValueG3Q5Title": "No meu departamento, sempre colocamos o hóspede em primeiro lugar.",
    "submitButton": "Enviar",
    "phasetwo": {
      "weValueG1Q1Title": "Você participou de uma reunião diária no último turno em que trabalhou? Line-Up, What's Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments ou Daily EDITION?",
      "weValueG1Q2Title": "A reunião diária (Line-Up, What's Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments ou Daily EDITION) inspira os membros da equipe a criar um serviço de luxo todos os dias.",
      "weValueG1Q3Title": "Na minha propriedade, focamos na redução de defeitos, mesmo aqueles não relatados pelos hóspedes.",
      "weValueG1Q4Title": "Na minha propriedade, somos capacitados para resolver as questões dos hóspedes sem antes precisar pedir permissão.",
      "weValueG1Q5Title": "Na minha propriedade, somos capacitados para agradar os hóspedes sem antes precisar pedir permissão.",
      "weValueG2Q1Title": "Na minha propriedade, normalmente, vamos além e superamos as expectativas para impressionar os hóspedes com o cuidado que oferecemos.",
      "weValueG2Q2Title": "As oportunidades de aprendizado na propriedade nos permitiram entregar serviços de luxo.",
      "weValueG2Q3Title": "Na minha propriedade, nós nos sentimos confiantes com nosso conhecimento e habilidades para entregar serviços de luxo.",
      "weValueG2Q4Title": "Na minha propriedade, entregamos um ambiente de luxo, limpo e sem defeitos.",
      "weValueG2Q5Title": "Mesmo quando nos deparamos com uma questão não relacionada a nosso próprio departamento, somos capacitados para nos envolver e tomar a frente da preocupação do hóspede.",
      "weValueG3Q1Title": "Na minha propriedade, recebemos o mesmo nível de cuidado uns dos outros que proporcionamos aos hóspedes.",
      "weValueG3Q2Title": "Minha propriedade incorpora os valores da marca.",
      "weValueG3Q3Title": "Na minha propriedade, quando vamos além e superamos as expectativas para criar uma experiência de luxo para o hóspede, nosso trabalho é reconhecido e valorizado.",
      "weValueG3Q4Title": "Na minha propriedade, temos a oportunidade de estar envolvidos nos esforços de nosso departamento para melhorar nosso próprio trabalho.",
      "weValueG3Q5Title": "Na minha propriedade, sempre colocamos o hóspede em primeiro lugar."
    }
  },
  "gateway": {
    "planProgressComplete": "%{label} CONCLUÍDO",
    "gatewayProgressComplete": "PROGRESSO DO GATEWAY CONCLUÍDO",
    "heroMobileGatewayVideoText": "Reproduzir vídeo do Gateway",
    "basics": "TAREFAS BÁSICAS",
    "brandResources": "RECURSOS DE MARCA",
    "disciplineResources": "RECURSOS DE DISCIPLINA",
    "immersionResourcesHeader": "Recursos de imersão",
    "brandCommunicationsHeaader": "Comunicações da marca",
    "brandContactsHeader": "Contatos da marca",
    "franchisedActionItemsText": "Os itens de ação ajudam você a se manter organizado e fornecem informações importantes de integração. Esses itens não são obrigatórios e são complementares ao que você pode receber de sua empresa de gerenciamento de franquia.",
    "global": "Promoções",
    "continent": "CONTINENTE",
    "viewed": "VISUALIZADO",
    "completed": "CONCLUÍDO",
    "heading": "Gateway",
    "basicsTab": "TAREFAS BÁSICAS",
    "onboardingTab": "Itens de ação",
    "brandTab": "Recursos de marca",
    "disciplinesTab": "RECURSOS DE DISCIPLINA",
    "basicsHeader": "Bem-vindo à Marriott International",
    "basicsHeaderNewBrandOrRole": "Bem-vindo,",
    "basicsText": "Ao iniciar sua carreira, sabemos que há muito a aprender. Selecionamos algumas das tarefas básicas para seus primeiros dias no trabalho. Alguns destes itens podem ser analisados com mais detalhes nas orientações ou na integração da marca, mas isso ajudará você a começar.",
    "basicsTextGms": "Ao iniciar sua carreira, sabemos que há muito a aprender. Selecionamos algumas das tarefas básicas para seus primeiros dias no trabalho. Alguns destes itens podem ser analisados com mais detalhes nas orientações ou na integração da marca, mas isso ajudará você a começar. Assim que terminar, vá para as outras páginas do Gateway, incluindo os itens de ação e recursos de marca e de disciplina.",
    "basicsTextFranchise": "Sabemos que há muito a aprender e selecionamos algumas das tarefas básicas para seus primeiros dias no trabalho. Estes itens podem ser complementares às suas orientações, integração da marca ou informações da sua administradora de franquia.",
    "basicsTextFranchiseGms": "Sabemos que há muito a aprender e selecionamos algumas das tarefas básicas para seus primeiros dias no trabalho. Estes itens podem ser complementares às suas orientações, integração da marca ou informações da sua administradora de franquia. Assim que terminar, vá para as outras páginas do Gateway, incluindo os itens de ação e recursos de marca e de disciplina.",
    "basicsTextNewToRole": "Ao iniciar sua nova função, sabemos que há muito a aprender. Selecionamos algumas das tarefas básicas para seus primeiros dias no trabalho. Alguns destes itens podem ser analisados com mais detalhes no seu treinamento atribuído, mas isso ajudará você a começar.",
    "basicsTextNewToRoleGms": "Ao iniciar sua nova função, sabemos que há muito a aprender. Selecionamos algumas das tarefas básicas para seus primeiros dias no trabalho. Alguns destes itens podem ser analisados com mais detalhes no seu treinamento atribuído, mas isso ajudará você a começar. Assim que terminar, vá para as outras páginas do Gateway, incluindo os itens de ação e recursos de marca e de disciplina.",
    "basicsTextNewToRoleFranchise": "Sabemos que há muito a aprender e selecionamos algumas das tarefas básicas para seus primeiros dias na nova função. Esses itens podem ser complementares às informações da sua administradora de franquia.",
    "basicsTextNewToRoleFranchiseGms": "Sabemos que há muito a aprender e selecionamos algumas das tarefas básicas para seus primeiros dias na nova função. Esses itens podem ser complementares às informações da sua administradora de franquia. Assim que terminar, vá para as outras páginas do Gateway, incluindo os itens de ação e recursos de marca e de disciplina.",
    "basicsTextNewToBrand": "Ao iniciar sua nova função na %{brandName}, sabemos que há muito a aprender. Selecionamos algumas das tarefas básicas para seus primeiros dias no trabalho. Alguns destes itens podem ser analisados com mais detalhes nas orientações ou na integração da marca, mas isso ajudará você a começar.",
    "basicsTextNewToBrandGms": "Ao iniciar sua nova função na %{brandName}, sabemos que há muito a aprender. Selecionamos algumas das tarefas básicas para seus primeiros dias no trabalho. Alguns destes itens podem ser analisados com mais detalhes nas orientações ou na integração da marca, mas isso ajudará você a começar. Assim que terminar, vá para as outras páginas do Gateway, incluindo os itens de ação e recursos de marca e de disciplina.",
    "basicsTextNewToBrandFranchise": "Sabemos que há muito a aprender e selecionamos algumas das tarefas básicas para seus primeiros dias na nova função. Estes itens podem ser complementares às suas orientações, integração da marca ou informações da sua administradora de franquia.",
    "basicsTextNewToBrandFranchiseGms": "Sabemos que há muito a aprender, então selecionamos algumas das tarefas básicas para seus primeiros dias na nova função na %{brandName}. Estes itens podem ser complementares às suas orientações, integração da marca ou informações da sua administradora de franquia. Assim que terminar, vá para as outras páginas do Gateway, incluindo os itens de ação e recursos de marca e de disciplina.",
    "disciplineHeader": "Recursos de disciplina",
    "toDo": "TAREFAS PENDENTES",
    "optional": "OPCIONAIS",
    "overallComplete": "CONCLUSÃO GERAL",
    "keyResourcesDesc": "Saiba mais sobre as principais disciplinas que oferecem suporte às operações diárias da propriedade, à experiência do hóspede e às necessidades de negócios.",
    "franchiseFooter": "Este material contém recomendações e sugestões de práticas que podem aumentar a probabilidade de conformidade com os Padrões da marca. Como franqueadora, a Marriott não se envolve na gestão ou operação de qualquer aspecto do hotel ou operação comercial de um franqueado. Os franqueados mantêm o controle das operações diárias dos hotéis e são responsáveis pelos próprios funcionários e práticas de contratação.",
    "landingHeroTitle1": "Bem-vindo à Marriott International.",
    "landingHeroTitle2": "Comece a sua jornada aqui.",
    "landingHeroText": "The Gateway é uma ferramenta para guiá-lo durante o primeiro ano em sua nova função. Ele fornecerá as informações e os recursos de que você precisa para ter sucesso.",
    "landingHeroTitle1NewToRole": "Bem-vindo ao The Gateway.",
    "landingHeroTitle1NewToBrand": "Bem-vindo ao The Gateway.",
    "landingButtonText": "Iniciar",
    "twoMonthslandingHeroTitle1": "Bem-vindo de volta!",
    "twoMonthslandingHeroText": "Se você ainda não fez isto, conclua todas as suas tarefas básicas no Gateway.",
    "twoMonthslandingButtonText": "Entrar no Gateway",
    "twoMonthslandingHeroTitle1NewToBrand": "Bem-vindo de volta!",
    "twoMonthslandingHeroTextNewToBrand": "Esperamos que seus primeiros dias com a %{brandName} tenham sido ótimos. Não se esqueça de prosseguir com sua jornada para ter acesso a tudo o que você precisa no Gateway",
    "twoMonthslandingButtonTextNewToBrand": "Vá em frente",
    "threeMonthslandingHeroTitle1": "Você está no caminho certo.",
    "threeMonthslandingHeroText": "Tudo o que você precisa saber sobre a Marriott International está no Gateway.",
    "threeMonthslandingButtonText": "Entrar no Gateway",
    "threeMonthslandingHeroTitle1NewToRole": "Você está no caminho certo.",
    "threeMonthslandingHeroTextNewToRole": "Tudo o que você precisa saber para ter sucesso em sua nova função está no Gateway.",
    "threeMonthslandingButtonTextNewToRole": "Entrar no Gateway",
    "threeMonthslandingHeroTitle1NewToBrand": "Bem-vindo de volta!",
    "threeMonthslandingHeroTextNewToBrand": "Parabéns por concluir seus primeiros %{numDays} dias em sua nova marca. Você poderá acessar todos os seus itens de ação, recursos de marca e de disciplina sempre que precisar.",
    "threeMonthslandingButtonTextNewToBrand": "Entrar no Gateway",
    "fourMonthslandingHeroTitle1": "Bom trabalho! Você concluiu as tarefas básicas.",
    "fourMonthslandingHeroText": "Tenha acesso a tudo o que você precisa saber sobre a Marriott International no Gateway.",
    "fourMonthslandingButtonText": "Entrar no Gateway",
    "fourMonthslandingHeroTitle1NewToRole": "Bom trabalho! Você concluiu as tarefas básicas.",
    "fourMonthslandingHeroTextNewToRole": "Tenha acesso a tudo o que você precisa para ter sucesso em sua nova função no Gateway.",
    "fourMonthslandingButtonTextNewToRole": "Entrar no Gateway",
    "fourMonthslandingHeroTitle1NewToBrand": "The Gateway – Check-in",
    "fourMonthslandingHeroTextNewToBrand": "Ao se aproximar da conclusão de seu primeiro ano com a %{brandName}, se ainda não fez isto, conclua todos os seus itens de ação e utilize os recursos de desenvolvimento disponíveis aqui no Digital Learning Zone. Clique em \"Continuar\" para avançar em sua jornada.",
    "fourMonthslandingButtonTextNewToBrand": "Continuar",
    "gmSecondVisitlandingHeroTitle1": "Bem-vindo de volta!",
    "gmSecondVisitlandingHeroText": "Esperamos que seus primeiros dias tenham sido ótimos. Não se esqueça de continuar sua jornada para crescer em sua nova função.",
    "gmSecondVisitlandingButtonText": "Vá em frente",
    "gmSecondVisitlandingHeroTextNewToRole": "Esperamos que seus primeiros dias em sua nova função tenham sido ótimos. Não se esqueça de prosseguir com sua jornada para ter acesso a tudo o que você precisa no Gateway.",
    "gmSecondVisitlandingHeroTextNewToBrand": "Esperamos que seus primeiros dias com a %{brandName} tenham sido ótimos. Não se esqueça de prosseguir com sua jornada para ter acesso a tudo o que você precisa no Gateway",
    "gmThirdVisitlandingHeroTitle1": "Bem-vindo de volta!",
    "gmThirdVisitlandingHeroText": "Parabéns por concluir seus primeiros %{numDays} dias de sua nova jornada de contratação. Você poderá acessar todos os seus itens de ação, recursos de marca e de disciplina sempre que precisar.",
    "gmThirdVisitlandingButtonText": "Entrar no Gateway",
    "gmThirdVisitlandingHeroTextNewToRole": "Parabéns por concluir seus primeiros %{numDays} dias de sua nova função. Você poderá acessar todos os seus itens de ação, recursos de marca e de disciplina sempre que precisar.",
    "gmThirdVisitlandingHeroTextNewToBrand": "Parabéns por concluir seus primeiros %{numDays} dias com sua nova marca. Você poderá acessar todos os seus itens de ação, recursos de marca e de disciplina sempre que precisar.",
    "gmSevenMonthslandingHeroTitle1": "Parece que você tem mais itens de ação para concluir.",
    "gmSevenMonthslandingHeroText": "Ao se aproximar da conclusão de seu primeiro ano, não se esqueça de concluir todos os seus itens de ação e utilizar os recursos de desenvolvimento disponíveis aqui no Digital Learning Zone. Clique em \"Continuar\" para avançar em sua jornada",
    "gmSevenMonthslandingButtonText": "Continuar",
    "gmSevenMonthslandingHeroTitle1NewToRole": "The Gateway – Check-in",
    "gmSevenMonthslandingHeroTextNewToRole": "Ao se aproximar da conclusão de seu primeiro ano em sua nova função, se ainda não fez isto, conclua todos os seus itens de ação e utilize os recursos de desenvolvimento disponíveis aqui no Digital Learning Zone. Clique em \"Continuar\" para avançar em sua jornada",
    "gmSevenMonthslandingHeroTitle1NewToBrand": "The Gateway – Check-in",
    "gmSevenMonthslandingHeroTextNewToBrand": "Ao se aproximar da conclusão de seu primeiro ano com a %{brandName}, se ainda não fez isto, conclua todos os seus itens de ação e utilize os recursos de desenvolvimento disponíveis aqui no Digital Learning Zone. Clique em \"Continuar\" para avançar em sua jornada.",
    "gmOneYearlandingHeroTitle1": "Parabéns pelo 1º ano na %{brandName}!",
    "gmOneYearlandingHeroTitle1NewToRole": "Parabéns pelo 1º ano em sua nova função!",
    "gmOneYearlandingHeroTitle1NewToBrand": "Parabéns pelo 1º ano em sua nova função com a %{brandName}!",
    "gmOneYearlandingHeroText": "A jornada no Gateway pode ter acabado, mas o melhor ainda está por vir. Você pode acessar os recursos na guia Gateway a qualquer momento.",
    "gmOneYearlandingButtonText": "Visualizar o Gateway"
  },
  "resources": {
    "iptTooltip": "Use vírgulas para separar várias localizações",
    "iptAllFunctionTooltip": "Desmarque para selecionar funções de trabalho específicas. Mantenha selecionado para ver o treinamento para todas as funções na localização selecionada",
    "iptAllAWRTooltip": "Desmarque para selecionar as responsabilidades de trabalho adicionais específicas. Mantenha selecionado para visualizar o treinamento para todas as responsabilidades de trabalho adicionais na localização selecionada",
    "editJFTooltip": "Use vírgulas para separar várias entradas"
  },
  "Notification Center: ": "Marcar como não lida",
  "Notification Center: Empty": "Você está atualizado!",
  "Notification Center: Individual Delete": "Tem certeza de que deseja excluir essa notificação? Notificações excluídas são movidas para a seção Excluído no Centro de notificações até que expirem.",
  "Notification Center: Delete All": "Tem certeza de que deseja excluir todas as notificações? Elas serão movidas para a seção Excluído no Centro de notificações até que expirem.",
  "Notification Center: Mark All As Read": "Marcar tudo como lido",
  "Notification Center: Restore All": "Restaurar tudo",
  "Notification Center: Delete Confirmation": "Excluir",
  "Warning Message Text: Remove Job Function or AWR": "Remover Função ou Responsabilidade de trabalho adicional (AWR)?\r\nAo enviar, você está prestes a remover %{Function and/or AWR} de %{count} Aprendizes.",
  "Warning Message Text: Add Job Function or AWR": "Adicionar Função ou Responsabilidade de trabalho adicional (AWR)?\r\nAo enviar, você está prestes a adicionar %{Function and/or AWR} a %{count} Aprendizes.",
  "Button": "Enviar",
  "Success Message Text: Add Job Function and/or AWR": "Pronto\r\nVocê adicionou a %{Job Function and/or AWR} ao(s) associado(s) solicitados em %{location}. \r\n\r\nGostaria de editar as Funções ou Responsabilidades de trabalho adicionais para %{location}?",
  "Message Text:AWR": "Pronto\r\nVocê adicionou as Responsabilidades de trabalho adicionais %{ __} ao(s) associado(s) solicitados em %{ __}.\r\n\r\nGostaria de editar as Funções ou Responsabilidades de trabalho adicionais para %{location, property, learner(s)}?",
  "Success Message Text: Remove Job Function": "Pronto\r\nVocê removeu a %{Job Function and/or AWR} do(s) associado(s) solicitados em %{location}. \r\n\r\nGostaria de editar as Funções ou Responsabilidades de trabalho adicionais para %{location}?",
  "Success Message Text: Remove AWR": "Pronto\r\nVocê removeu a Responsabilidade de trabalho adicional %{ __} do(s) associado(s) solicitados em %{ __}.\r\n\r\nGostaria de editar as Funções ou Responsabilidades de trabalho adicionais para %{location, property, learner(s)}?",
  "Tool Tip - Managed Change Email": "Para alterar seu e-mail, acesse mHUB > HR Direct > Me (Eu) > My Public Info (Minhas informações públicas). Siga as instruções para atualizar seus detalhes de contato. Pode levar 1 a 2 dias úteis para que o e-mail atualizado seja exibido.",
  "Tool Tip - Franchised  Change Email": "Para alterar seu e-mail, entre em contato com seu Gerente e/ou Administrador de franquia EID Tool. Pode levar 1 a 2 dias úteis para que o e-mail atualizado seja exibido.",
  "User Profile: Email Opt-in": "Não, obrigado",
  "User Profile: Email Frequency": "Resumo semanal",
  "Generic Notification Email Subject Line:": "Você possui novas notificações na DLZ",
  "Generic Body Text (<150 Characters)": "Acesse a DLZ para exibir os detalhes.",
  "All Emails: Signature": "Digital Learning Zone - O aprendizado simplificado. Começa com você.",
  "All Emails: Footer": "Você pode cancelar a assinatura das notificações por e-mail a qualquer momento. Acesse Digital Learning Zone/Perfil para atualizar suas preferências.",
  "Overdue Emails: Subject Line: ": "Atribuições da DLZ precisam de sua atenção",
  "Overdue Emails: Body Test": "Você possui atribuições atrasadas. Faça login na DLZ para revisar suas atribuições.",
  "exploreLearning": {
    "sharpenYourSkillsTitle": "Aperfeiçoe suas habilidades",
    "sharpenYourSkillsSubTitle": "Construa seu conhecimento e habilidades para aprimorar sua carreira, agora e no futuro.",
    "growYourCareerTitle": "Desenvolva sua carreira",
    "growYourCareerSubTitle": "Planeje seus próximos passos com habilidades especializadas ou formação transversal que forneçam apoio aos seus interesses e metas.",
    "additionalResourcesTitle": "Recursos adicionais",
    "additionalResourcesSubTitle": "Explore getAbstract® e CultureWizard™. Veja como a DLZ pode continuar a ajudá-lo a crescer.",
    "tourHeader": "Bem-vindo à DLZ atualizada. Agora é possível pesquisar por qualquer coisa que você precisar, na hora em que precisar. E tem mais, você encontrará Explorar aprendizado e Meu plano atualizados e mais personalizados. Faça um tour!",
    "tourBtnSkip": "Descartar",
    "tourBtnNext": "Encerramento (6/6)",
    "tourSearchBar": "Encontre o que precisar, na hora em que precisar, com um recurso de pesquisa novo e mais robusto.",
    "tourBtnBack": "Voltar",
    "tourMyPlan": "O Meu plano agora tem itens que você adicionou em Pesquisa e Explorar para que você possa ter controle total de sua jornada de aprendizado.",
    "tourExploreLearningLink": "Você ainda pode acessar o Explorar aprendizado por aqui também.",
    "tourExploreLearningProfile": "Aproveite ao máximo a sua experiência de aprendizado e verifique de forma rotineira o seu perfil, em particular as funções de trabalho, para manter o Meu plano atualizado.",
    "mhubTraining": "Treinamento do mHUB",
    "growthSkills": "Habilidades de crescimento",
    "leadershipSkills": "Habilidades de liderança",
    "virtualTeamResources": "Recursos da Equipe virtual",
    "jobFunctions": "Funções de trabalho",
    "brands": "Marcas",
    "languageLearning": "Curso de idioma",
    "become": "Torne-se",
    "emeaMentoringProgram": "Programa de orientação da EMEA",
    "learningPathways": "Caminhos de aprendizado",
    "mda": "MDA",
    "takeCareGrow": "TakeCare GROW",
    "getAbstract": "getAbstract®",
    "cultureWizard": "CultureWizard™",
    "takeCareCommunityHub": "TakeCare Community Hub",
    "mhubTrainingDesc": "Encontre caminhos de aprendizado baseados em funções e cursos específicos por tópicos em como usar o mHUB.",
    "growthSkillsDesc": "Obtenha conhecimento prático em funções e tópicos diversos.",
    "leadershipSkillsDesc": "Descubra como gerenciar e treinar uma equipe e se torne um líder mais eficaz.",
    "virtualTeamResourcesDesc": "Aprenda técnicas para promover uma cultura colaborativa e inclusiva nas equipes virtuais.",
    "jobFunctionsDesc": "Amplie o conhecimento de sua função de trabalho atual e aprenda sobre outras funções.",
    "brandsDesc": "Saiba mais sobre as várias marcas da Marriott International.",
    "languageLearningDesc": "Comunique-se de forma eficaz com um público global.",
    "becomeDesc": "Descubra uma abordagem total para o crescimento e o desenvolvimento de carreira: reflita, planeje, aja, refine.",
    "emeaMentoringProgramDesc": "Libere seu potencial com o Programa de orientação da EMEA.",
    "learningPathwaysDesc": "Explore oportunidades de aprendizado específicas selecionadas para o seu nível de liderança.",
    "mdaDesc": "Construa habilidades de liderança e funcionais com a Marriott Development Academy.",
    "takeCareGrowDesc": "Identifique recursos designados para construir uma comunidade inclusiva e inspirada.",
    "getAbstractDesc": "Aprenda algo novo todos os dias com resumos de livros que o ajudam a tomar decisões melhores.",
    "cultureWizardDesc": "Reúna conhecimento valioso para se comunicar de forma eficaz em diversas culturas.",
    "takeCareCommunityHubDesc": "Descubra atividades de formação de equipes da TakeCare, programas de aprendizado e ferramentas.",
    "searchAllLearning": "Pesquisar todo o aprendizado",
    "searchBarPlaceHolder": "Há sempre mais para aprender.",
    "clearFilters": "Limpar filtros",
    "noResults": "Nenhum resultado",
    "hasBeenUnpinned": "{Variable-tile_Name} foi desmarcado.",
    "hasBeenPinned": "{Variable-tile_Name} foi marcado.",
    "lpRemovedFromPlan": "O caminho de aprendizado foi removido do seu plano.",
    "courseRemovedFromPlan": "O curso foi removido do seu plano.",
    "lpAddedToPlan": "O caminho de aprendizado foi adicionado ao seu plano.",
    "courseAddedToPlan": "O curso foi adicionado ao seu plano.",
    "clearSelection": "Limpar seleção",
    "removeFromMyPlan": "Remover do meu plano",
    "addToMyPlan": "Adicionar ao meu plano",
    "loadingResults": "Carregando",
    "viewDetails": "Exibir detalhes",
    "clearAll": "Limpar tudo",
    "noOptions": "Nenhuma opção disponível",
    "viewLess": "Exibir menos",
    "viewMore": "Exibir mais",
    "recentSearch": "Pesquisas recentes",
    "exploreLearningTitle": "Explorar aprendizado",
    "exploreLearningHelpText": "Não tem certeza do que você está procurando?",
    "exploreMore": "Explore mais.",
    "viewAllResults": "Exibir todos os <#> resultados",
    "searchSubTitle": "Pesquise por cursos usando palavras-chave, títulos ou identificação do conteúdo.",
    "pinnedLearningTitle": "Meus aprendizados fixos",
    "landingSubTitle": "Assegure a sua jornada de aprendizado. Explore seus interesses: há novos tópicos e cursos adicionados o tempo todo.",
    "columnTitle": "Cargo",
    "columnDesc": "Descrição",
    "columnType": "Tipo",
    "columnAddedToPlan": "Adicionar/Adicionado ao plano",
    "columnComplete": "Concluído",
    "columnDetails": "Detalhes",
    "additionalSearchFilters": "Filtros de pesquisa adicionais",
    "tourBtnClose": "Fechar",
    "tourBtnLast": "Fechar",
    "resultsFor": "Resultados para ",
    "resultsFortheFilter": "Resultados para o filtro {Filter Name}",
    "no": "Não",
    "results": "Resultados",
    "courseTooltipTitle": "Um item de aprendizado individual que pode conter uma ou mais atividades.<br />Um curso pode ser on-line, como um vídeo ou eLearning, ou ocorrer em uma sala de aula.",
    "gatewayToolTipTitle": "Recursos digitais para novas contratações, para as novas pessoas da gerência e GMs novos na marca.",
    "speciallizationOrPathwayTooltipTitle": "Um conjunto de cursos focados em um tópico específico.",
    "taskTooltipTitle": "Instruções para concluir uma atividade (normalmente no trabalho) e, então, marcá-la como concluída.",
    "webSiteTooltipTitle": "Qualquer site, incluindo sites da Marriott ou externos."
  },
  "dontShowAgain": "Não mostrar novamente",
  "deletionWarningEnabled": "Aviso de exclusão ativado",
  "deletionSelected": "Quando selecionado, você verá um aviso sempre que excluir uma notificação.",
  "manageEmailNotifications": "Gerenciar notificações por e-mail",
  "seeHowDLZContinue": "Veja como a DLZ pode continuar a ajudá-lo a crescer.",
  "Notification Center:Overdue": "Você tem um novo treinamento que expirou em {Variable date ddmmyy}",
  "accesDenied": {
    "title": "O acesso a sua Jornada de aprendizado está em andamento. ",
    "subtitle": "O acesso está sendo criado e deve estar disponível de um a dois dias após a ativação do EID. Se já se passaram mais de dois dias, entre em contato com o mHUB Service Center."
  },
  "jfTour": {
    "title": "Agora você pode ver e editar suas funções de trabalho selecionadas e suas responsabilidades adicionais do trabalho (AWR) diretamente em Meu plano."
  },
  "onboardingPlan": {
    "requiredDescription": "Abaixo estão os cursos necessários para conclusão no período que você selecionou em Meu plano. Trabalhe com seu líder para agendar um horário para concluir o aprendizado necessário à medida que você exerce sua nova função. Você aprenderá sobre uma variedade de tópicos, então tire sempre suas dúvidas com seu líder ou com seus colegas.   ",
    "day1": "Vencimento no dia 14",
    "day2": "Vencimento no dia 30",
    "day3": "Vencimento no dia 60",
    "day4": "Vencimento no dia 90",
    "day5": "Vencimento após 90 dias "
  },
  "onboardingLanding": {
    "gettingStartedDescription": "A Digital Learning Zone é seu ponto de partida para aprendizado e desenvolvimento profissional. Abaixo, você encontrará conteúdo selecionado para ajudá-lo a começar. Todo o aprendizado pode ser encontrado em Meu plano.  ",
    "overallLearningProgress": "Progresso geral do treinamento",
    "learningProgressLabel": "Progresso do treinamento",
    "learningProgressDescription": "Este é o seu progresso no treinamento obrigatório. A barra de progresso inclui o status de todo o treinamento obrigatório. Continuem com o bom trabalho!"
  },
  "Recommended Courses ": "Após os primeiros 90 dias, você encontrará cursos adicionais recomendados pelo seu gerente. Também haverá a opção de explorar os tópicos que lhe interessam. Por enquanto, concentre-se em seu aprendizado de base. ",
  "survey": {
    "v2": {
      "weValueButtonText": "Faça a pesquisa",
      "lbiIntro": "Bem-vindo à verificação de comportamento de luxo!",
      "lbiDescription": `<p>Abaixo, você será convidado a ler alguns breves cenários com os quais você poderá se deparar em sua propriedade. Leia cada cenário e escolha como você responderia a cada situação. Observe o seguinte ao responder:</p>
      <ul>
          <li>
              <p>Talvez você ache que mais de uma das respostas parece correta. Escolha a resposta com a atitude que você provavelmente tomaria no trabalho.</p>
          </li>
          <li>
              <p>Suponha que não haja barreiras de comunicação. O idioma que você fala é o mesmo idioma falado pelo hóspede ou associado no cenário em questão.</p>
          </li>
          <li>
              <p>O cenário pode pedir que você se imagine em uma função de trabalho diferente da função que você exerce atualmente. Responda à pergunta da melhor forma possível, com base em sua experiência como profissional de hospitalidade de luxo.</p>
          </li>
      </ul>
      <p><br></p>
      <p>Observe o seguinte: sua participação nesta verificação é totalmente voluntária (você pode optar por não participar) e suas respostas serão estritamente confidenciais. Os resultados desta verificação serão relatados para grupos de pessoas e não para indivíduos específicos. As respostas individuais serão avaliadas pela equipe de pesquisa e não serão compartilhadas com gerentes de outras pessoas ou líderes.</p>
      <p>Ao participar desta verificação, você concorda com a coleta e o processamento de suas respostas e dados pessoais (por exemplo, tempo de serviço, gênero, cargo), bem como com a transferência transfronteiriça de seus dados pessoais para o Grupo Marriott. Esta verificação está em conformidade com a Declaração de Privacidade de Dados do Associado da Marriott, na qual você também pode encontrar informações e contatos adicionais para referência sobre as Políticas de Privacidade de Dados da Marriott.</p>
      `,
      "checkin": {
        q1Title: "Você está caminhando pelo saguão e percebe uma família com três crianças pequenas. A família tem muitas malas, parece estressada após um longo dia de viagem e as crianças estão chorando e visivelmente inquietas. O que você faria?",
        q2Title: "Você acabou de começar em uma propriedade, em uma região nova para você. Um hóspede pede sugestões atividades na região. O hóspede consultou vários guias, mas está procurando algo mais personalizado. O que você faria? ",
        q3Title: "Você está caminhando pelo lobby quando ouve um hóspede falar ao telefone e descobre que o serviço de traslado dele para o aeroporto está atrasado. Sua propriedade não tem um veículo próprio ou não está disponível. O que você faria? ",
        q4Title: "Você está passando pelo lobby no caminho para finalizar uma tarefa urgente que seu gerente lhe atribuiu. Você percebe que um colega está com dificuldades para terminar uma tarefa que normalmente é realizada por duas pessoas. O que você faria? ",
        q5Title: "Você é o anfitrião no restaurante do hotel. Um hóspede pede para ver o menu enquanto aguarda pela mesa reservada. Depois de olhar o menu, o hóspede fica frustrado porque o restaurante do hotel foi sugerido por outro colega, mas não há nada no menu que atenda às suas necessidades alimentares. O que você faria?",
        q6Title: "Há um grupo grande de hóspedes na área da piscina lotada que não conseguiu encontrar lugares juntos. Como resultado, eles colocaram suas toalhas e pertences pessoais em uma determinada área, obstruindo uma passagem principal e criando perigo aos hóspedes e associados que tentam acessar a área da piscina. A área precisa ser desobstruída, mas você não quer incomodar o grupo. O que você faria? ",
        q1R1Title: "Ajudo a família com a bagagem, a encaminho para o balcão de check-in e sugiro alguns lugares relaxantes próximos com atividades para toda a família. Menciono as comodidades familiares da propriedade.",
        q1R2Title: "Ofereço-me para ajudá-los com a bagagem e encaminho a família para o check-in. Em seguida, providencio um serviço relaxante ou um mimo para os pais e atividades divertidas para as crianças no quarto.",
        q1R3Title: "Encaminho os pais para o check-in e, em seguida, levo pessoalmente as crianças para uma área de estar com lanches enquanto os pais fazem o check-in. Para oferecer conforto, peço à recepção para dar informações aos pais sobre as áreas de lazer e atividades infantis na propriedade. ",
        q1R4Title: "Encaminho a família ao balcão de check-in para um processo de check-in rápido. Indico aos pais as áreas e serviços para crianças para que elas possam desfrutar de uma estadia agradável.",
        q2R1Title: "Pergunto ao hóspede do que ele gosta e, em seguida, converso com um colega para criar uma lista de atividades divertidas para ele. Além disso, sugiro um bom restaurante nas proximidades.",
        q2R2Title: "Converso com o hóspede sobre o que ele gosta. Trabalho com um colega para fazer uma lista especial de atividades divertidas só para ele. Além disso, verifico o perfil Bonvoy do hóspede e envio um mimo personalizado para o quarto.",
        q2R3Title: "Compartilho algumas das minhas próprias atividades favoritas na região até agora. Faço com que minhas sugestões se destaquem contando mais detalhes sobre as experiências. Em seguida, envio uma deliciosa iguaria local para o quarto.",
        q2R4Title: "Digo ao hóspede que sou novo na região e não tenho muitas ideias. Peço desculpas e sugiro que converse com meu colega que tem mais conhecimento. Em seguida, pergunto se ele gostaria que uma iguaria local fosse enviada para o quarto.",
        q3R1Title: "Enquanto o hóspede ainda está ao telefone, levo o meu próprio veículo ou o veículo pessoal do meu colega para a frente da propriedade e informo ao hóspede que o levarei pessoalmente ao aeroporto.",
        q3R2Title: "Enquanto o hóspede ainda estiver ao telefone, preparo uma lista de serviços de traslado aprovados pela propriedade e números de telefone. Quando o hóspede desligar o telefone, apresento a lista e ofereço–me para ajudar a organizar o transporte.",
        q3R3Title: "Espero até que o hóspede desligue o telefone e digo a ele que minha propriedade não oferece serviços de transporte, mas que terei prazer em ajudar a coordenar outras opções de transporte. ",
        q3R4Title: "Enquanto o hóspede ainda estiver ao telefone, encontro um transporte alternativo. Quando ele desligar, informo ao hóspede que providenciei o transporte para o aeroporto, que chegará em breve.",
        q4R1Title: "Vou até meu colega e digo que o ajudarei, mas que primeiro preciso concluir minha tarefa urgente. Prometo retornar rapidamente para ajudá-lo. Certifico-me de conversar sobre a situação com meu gerente.",
        q4R2Title: "Primeiro, encontro rapidamente outro colega para assumir a minha tarefa. Em seguida, ajudo imediatamente meu colega que precisa de ajuda. Ao terminar, informo ao meu gerente que encontrei outra pessoa para finalizar minha tarefa para poder ajudar meu colega. ",
        q4R3Title: "Paro e ajudo meu colega imediatamente. Depois de ajudar, retorno à tarefa atribuída e informo ao meu gerente que atrasei a minha tarefa para ajudar meu colega.",
        q4R4Title: "Vou até o meu colega que precisa de ajuda e explico que estou no meio de uma tarefa urgente. Paramos as duas tarefas, fazemos um brainstorming para resolver as duas tarefas em conjunto e, em seguida, concluímos juntos as duas tarefas.",
        q5R1Title: "Desculpo-me com o hóspede e informo que chamarei o gerente. Certifico-me de explicar a situação para que o gerente possa ajudar o hóspede com suas necessidades alimentares.",
        q5R2Title: "Desculpo-me com o hóspede e pergunto sobre suas preferências alimentares. Converso com a equipe da cozinha para fazer um prato especial só para ele. Além disso, ofereço um aperitivo ou bebida de cortesia para compensar o erro.",
        q5R3Title: "Desculpo-me e digo ao hóspede que compreendo sua situação. Em vez de cancelar a reserva, ofereço-me para conversar com o chef e ver se ele pode fazer algo especial para o hóspede. Dou a ele um mimo ou desconto de cortesia para compensar o problema.",
        q5R4Title: "Desculpo-me e digo ao hóspede que posso ajudá-lo a reservar uma mesa em um restaurante próximo que tenha a comida de que ele gosta. Ofereço informações extras ou ajuda para chegar lá, se necessário.",
        q6R1Title: "Desculpo–me com os hóspedes pela falta de espaço e digo que podem permanecer onde estão, desde que estejam atentos aos outros que passam. ",
        q6R2Title: "Digo aos hóspedes que eles não podem bloquear a passagem e que precisarão encontrar outro lugar, ou esperar até que surja um espaço vago. Enquanto isso, peço que meus colegas tragam assentos adicionais do salão de festas ao ar livre para que eles usem. ",
        q6R3Title: "Digo aos hóspedes que eles não podem ficar onde estão e que devem ir temporariamente para outro local enquanto encontro assentos adicionais. Ofereço espaço no bar/restaurante enquanto organizo isso. ",
        q6R4Title: "Desculpo-me com os hóspedes pelo problema de espaço. Pergunto se eles podem juntar os pertences em um canto para abrir uma pequena passagem para as outras pessoas. Ofereço-me para ajudá-los, garantindo que todos fiquem confortáveis. Envio a eles um petisco especial do bar/restaurante devido à inconveniência."
      }
    }
  },
};