import axios from 'axios';
import { apiURL } from '../config';
import authStore from '../stores/AuthStore';

import cookies from '../util/cookies';

const POST = 'post';
const GET = 'get';
const PUT = 'put';
const DELETE = 'delete';

const initApi = () => {
  const api = axios.create();
  api.defaults.baseURL = apiURL;

  return api;
};

const axiosApi = initApi();

const redirectOn401 = (error) => {
  if (error.response.data) {
    if (error.response.data.ssoUrl) {
      window.location.href = error.response.data.ssoUrl + window.location.pathname + window.location.search;
    }
    else {
      window.location.href = '/login'; // TODO handle redirect after login
    }
  }
};

const getBody = (verb, body) => {
  // https://github.com/axios/axios/issues/897#issuecomment-343715381
  if (body !== undefined && verb === DELETE) {
    return { data: body };
  }

  return body;
};

const execute = async (verb, url, body) => {
  try {
    const config = {
      url,
      method: verb,
      data: getBody(verb, body),
      withCredentials: true // include existing cookies as part of request header
    };
    const { status, data } = await axiosApi.request(config);

    // reset auth session expiry on successful api return
    authStore.resetExpiryDate();

    return {
      status,
      data
    };
  } catch (error) {
    const { status, data } = error.response;
    // handle 401s in the store, payload may contain relevant redirect info
    if (status === 401) {
      if (url !== '/users/me') { // return 401 response on user fetch
        return {
          status,
          data
        };
      } else { // if 401 on any other request other than user fetch, redirect to non-auth page
        redirectOn401(error);
      }
    }

    // CSC - for now re-throwing the error as all of the app code was written to catch exceptions...
    throw error;
    // return { status };
  }
};

class dlzApi {
  async login(username) {
    return execute(POST, '/login', { username, password: 'abcd' });
  }

  async logout() {
    return execute(GET, '/logout');
  }

  async getUserProfile() {
    const impersonationEid = cookies.getCookie('impersonationEid');

    let url = '/users/me';
    if (impersonationEid) {
      url += `?eid=${impersonationEid}`;
    }
    return execute(GET, url);
  }

  async getUserInfo() {
    return execute(GET, '/users/info');
  }

  async updateUserProfile(updatePayload) {
    return execute(PUT, '/users/me', updatePayload);
  }

  async keepAlive() {
    return execute(GET, '/users/me');
  }

  async getConfig() {
    return execute(GET, '/config');
  }

  async getNotifications() {
    return execute(GET, '/notifications')
  }

  async getStaticFileContent(filePath) {
    return fetch(filePath)
      .then(response => response.text())
      .then(text => {
        return text;
      });
  }

  /* user notifications */
  async getUserNotifications() {
    return execute(GET, '/user-notifications?includeAll=true');
  }

  async createUserNotification(notification, systemNotification) {
    const payload  = {
      notification,
      systemNotification
    };
    return execute(POST, '/user-notifications', payload);
  }

  async updateUserNotification(id, payload) {
    return execute(PUT, `/user-notifications/${id}`, payload);
  }

  async deleteUserNotification(id) {
    return execute(DELETE, `user-notifications/${id}`);
  }

  async deleteOne(id) {
    // hard delete
    return execute(DELETE, `user-notifications/${id}?permanent=true`);
  }

  async clearImpersonation() {
    return execute(GET, '/logout-impersonate');
  }

  async markAllNotificationsAsRead(ids) {
    return execute(POST, `/user-notifications/mark-all-read`, { ids: ids });
  }

  /* user notifications */
}

export default new dlzApi();
