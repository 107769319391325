// type Config = {
//   application: string,
//   apiURL: string
// };

const config = {
  local: 'local',
  qa: 'qa',
  dev: 'dev',
  prod: 'prod'
};

function getConfig(env){
  const appConfig = env ?
    require(`./${config[env]}`)
    :
    require(`./${config.local}.js`);

  appConfig.env = env;

  return appConfig;
}

module.exports = getConfig(process.env.REACT_APP_BUILD_ENV);
