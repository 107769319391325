export const application = 'dlz-dev';

export const apiURL = 'https://www.marriott-ld.com/dev/api/api/dlz2';

export const cmdbURL = 'https://www.marriott-ld.com/dev/cmdb/api';

export const logoutEnabled = true;

export const sessionCookieName = 'connect.sid';

export const gaId = 'UA-91016801-7';