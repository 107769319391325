import { decorate, observable, action } from 'mobx';

import CMDBApi from '../api/CMDBApi';

class ProjectStore {
  enabledProjects = [];
  enabledProjectsLoaded = false;

  async getEnabledProjects() {
    if(!this.enabledProjectsLoaded) {
      const { data } = await CMDBApi.getEnabledProjects();

      this.enabledProjects = data;
      this.enabledProjectsLoaded = true;
    }
  }

}

decorate(ProjectStore, {
  enabledProjects: observable,
  enabledProjectsLoaded: observable,
  getEnabledProjects: action
});

export default new ProjectStore();