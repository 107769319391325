export const application = 'dlz';

export const apiURL = 'http://localhost:4000/dev/api/api/dlz2';

export const cmdbURL = 'http://localhost:5200/cmdb/api'

export const idleTimeWarnLogout = 1000 * 60 * 1000;

export const idleTimeLogout = idleTimeWarnLogout + (1000 * 60 * 1000);

export const gaId = 'UA-91016801-5';

export const newRelicLicenseKey = 'NRJS-fc423fe927f4fe0c53f';

export const newRelicApplicationId = '1049002638';
