import { decorate, observable, computed, action } from 'mobx';
import dlzApi from '../api/LDApi';

class ConfigStore {
  async getConfig() {
    if (this.config !== undefined) {
      return;
    }

    const { data } = await dlzApi.getConfig();
    this.config = data;
  }

  get isProd() {
    return this.config
      && this.config.env === 'production';
  }
}

decorate(ConfigStore, {
  config: observable,
  getConfig: action,
  isProd: computed
});

export default new ConfigStore();
