import { action, computed, decorate, extendObservable, observable } from 'mobx';

import Api from '../api/CMDBApi';
import ReactGA from 'react-ga4';
import userStore from './UserStore';

const getLabel = (percentage, isPreAssessment) => {
  let enabled = 0.8;
  let aware = 0.6;

  if (isPreAssessment) {
    enabled = 0.75;
    aware = 0.6;
  }

  if (percentage < aware) {
    return 'Topline: Get in the Know';
  }
  if (percentage >= aware && percentage < enabled) {
    return 'Topline: Aware';
  }
  if (percentage >= enabled) {
    return 'Topline: Enabled';
  }
};

class ToplineStore {
  initializing = false;
  initialized = false;
  preAssessment = null;
  postAssessments = null;
  learningPath = null;
  updatingAssessment = false;
  loadingQuestion = false;
  advanceToPhaseTwo = false;
  toplineAware = false;

  baseData = null;

  get needsPreAssessment() {
    return !userStore.completedToplinePhaseTwo && !this.advanceToPhaseTwo;
  }

  get showAdvanceToPhaseTwo() {
    return this.preAssessment == null || this.preAssessment.status !== 'completed';
  }

  get phaseTwoCourses() {
    return (this.baseData.phase2Resources || []).map(r => {
      r.labels = (r.extra.subdisciplines || []).map(id => this.baseData.subdisciplines.find(s => s.id == id).name);

      return r;
    });
  }

  get phaseThreeCourses() {
    return (this.baseData.phase3Resources || []).filter(r => r.projects.indexOf('topline') !== -1).map(r => {
      r.labels = _.compact(((r.extra.subdisciplines || []).map(id => this.baseData.subdisciplines.find(s => s.id == id) || {}).name));

      return r;
    });
  }

  get isCompleteWithPhase2Course() {
    const completions = this.baseData.phase2Completions;
    const foundIncomplete = this.baseData.phase2Resources.find(course => {
      const found = completions.find(c => c.courseId === course.id);
      return found == null;
    });

    return foundIncomplete == null;
  }

  get learningPathCompleteByFunction() {
    console.log(this.learningPath.extra.courseCompletions);

    console.log(this.learningPath.extra.lpCoursesByFunction[2]);

    const byFunction = {
      1: (this.learningPath.extra.lpCoursesByFunction[1] || []).filter(courseId => (this.learningPath.extra.courseCompletions || []).indexOf(courseId) === -1).length === 0,
      2: (this.learningPath.extra.lpCoursesByFunction[2] || []).filter(courseId => (this.learningPath.extra.courseCompletions || []).indexOf(courseId) === -1).length === 0,
      3: (this.learningPath.extra.lpCoursesByFunction[3] || []).filter(courseId => (this.learningPath.extra.courseCompletions || []).indexOf(courseId) === -1).length === 0,
      4: (this.learningPath.extra.lpCoursesByFunction[4] || []).filter(courseId => (this.learningPath.extra.courseCompletions || []).indexOf(courseId) === -1).length === 0,
      5: (this.learningPath.extra.lpCoursesByFunction[5] || []).filter(courseId => (this.learningPath.extra.courseCompletions || []).indexOf(courseId) === -1).length === 0,
      6: (this.learningPath.extra.lpCoursesByFunction[6] || []).filter(courseId => (this.learningPath.extra.courseCompletions || []).indexOf(courseId) === -1).length === 0,
      7: (this.learningPath.extra.lpCoursesByFunction[7] || []).filter(courseId => (this.learningPath.extra.courseCompletions || []).indexOf(courseId) === -1).length === 0,
      8: (this.learningPath.extra.lpCoursesByFunction[8] || []).filter(courseId => (this.learningPath.extra.courseCompletions || []).indexOf(courseId) === -1).length === 0
    };

    return byFunction;
  }

  get lastPostAssessmentByFunction() {
    const byFunction = {
      1: null,
      2: null,
      3: null,
      4: null,
      5: null,
      6: null,
      7: null,
      8: null
    };

    (this.postAssessments || []).forEach(a => {
      Object.keys(a.extra.assessmentGrades.byFunction).forEach(functionId => {
        byFunction[functionId] = {
          ...a.extra.assessmentGrades.byFunction[functionId],
          label: getLabel(a.extra.assessmentGrades.byFunction[functionId].totalCorrect / a.extra.assessmentGrades.byFunction[functionId].totalQuestions)
        };
      });
    });

    return byFunction;
  }

  get postAssessmentCounts() {
    const byFunction = {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0
    };

    (this.postAssessments || []).forEach(a => {
      Object.keys(a.extra.assessmentGrades.byFunction).forEach(functionId => {
        byFunction[functionId] = byFunction[functionId] + 1;
      });
    });

    return byFunction;
  }

  get mergedBestScores() {
    const byFunction = {
      1: {
        totalCorrect: 0,
        totalQuestions: 0,
        label: ''
      },
      2: {
        totalCorrect: 0,
        totalQuestions: 0,
        label: ''
      },
      3: {
        totalCorrect: 0,
        totalQuestions: 0,
        label: ''
      },
      4: {
        totalCorrect: 0,
        totalQuestions: 0,
        label: ''
      },
      5: {
        totalCorrect: 0,
        totalQuestions: 0,
        label: ''
      },
      6: {
        totalCorrect: 0,
        totalQuestions: 0,
        label: ''
      },
      7: {
        totalCorrect: 0,
        totalQuestions: 0,
        label: ''
      },
      8: {
        totalCorrect: 0,
        totalQuestions: 0,
        label: ''
      }
    };

    const assessments = [this.preAssessment];
    this.postAssessments.forEach(a => assessments.push(a));

    assessments.filter(a => a.extra.assessmentGrades != null).forEach((a, index) => {
      Object.keys(a.extra.assessmentGrades.byFunction).forEach(functionId => {
        const score = a.extra.assessmentGrades.byFunction[functionId];
        byFunction[functionId].totalQuestions = score.totalQuestions;
        if (score.totalCorrect > byFunction[functionId].totalCorrect) {
          byFunction[functionId].totalCorrect = score.totalCorrect;
        }

        const percentage = byFunction[functionId].totalCorrect / byFunction[functionId].totalQuestions;
        byFunction[functionId].label = getLabel(percentage, index === 0);
      });
    });

    return byFunction;
  }

  get allEnabled() {
    const scores = this.mergedBestScores;

    if (!scores) {
      return false;
    }

    let allEnabled = true;

    Object.keys(scores).forEach(fId => {
      if (scores[fId].label !== 'Topline: Enabled') {
        allEnabled = false;
      }
    });    

    return allEnabled;
  }

  get preAssessmentScores() {
    const byFunction = {
      1: {
        totalCorrect: 0,
        totalQuestions: 0,
        label: ''
      },
      2: {
        totalCorrect: 0,
        totalQuestions: 0,
        label: ''
      },
      3: {
        totalCorrect: 0,
        totalQuestions: 0,
        label: ''
      },
      4: {
        totalCorrect: 0,
        totalQuestions: 0,
        label: ''
      },
      5: {
        totalCorrect: 0,
        totalQuestions: 0,
        label: ''
      },
      6: {
        totalCorrect: 0,
        totalQuestions: 0,
        label: ''
      },
      7: {
        totalCorrect: 0,
        totalQuestions: 0,
        label: ''
      },
      8: {
        totalCorrect: 0,
        totalQuestions: 0,
        label: ''
      }
    };

    const assessments = [this.preAssessment];

    assessments.filter(a => a.extra.assessmentGrades != null).forEach((a, index) => {
      Object.keys(a.extra.assessmentGrades.byFunction).forEach(functionId => {
        const score = a.extra.assessmentGrades.byFunction[functionId];
        byFunction[functionId].totalQuestions = score.totalQuestions;
        if (score.totalCorrect > byFunction[functionId].totalCorrect) {
          byFunction[functionId].totalCorrect = score.totalCorrect;
        }

        const percentage = byFunction[functionId].totalCorrect / byFunction[functionId].totalQuestions;
        byFunction[functionId].label = getLabel(percentage, index === 0);
      });
    });

    return byFunction;
  }

  async loadPostAssessments() {
    const { data: postAssessments } = await Api.getToplineAssessments('post');
    this.postAssessments = postAssessments;
  }

  async initialize() {
    if (this.initializing) {
      return;
    }

    this.initializing = true;
    this.viewedVideo = _.get(userStore.user, 'topline.viewedVideo', false);
    this.viewedVideo2 = _.get(userStore.user, 'topline.viewedVideo2', false);

    this.toplineComplete = _.get(userStore.user, 'topline.completedTopline', false);

    try {
      const { data: baseData } = await Api.getToplineBaseData();
      this.baseData = baseData;

      const { data: preAssessments } = await Api.getToplineAssessments('pre');
      this.preAssessment = preAssessments.length > 0 ? preAssessments[0] : null;

      const { data: postAssessments } = await Api.getToplineAssessments('post');
      this.postAssessments = postAssessments;

      if (this.preAssessment != null && this.preAssessment.status === 'completed') {
        const { data: learningPath } = await Api.getToplineLP();
        learningPath.resources = learningPath.resources.map(r => {
          r.labels = (r.extra.subdisciplines || []).map(s => {
            const found = this.baseData.subdisciplines.find(s1 => s1.id === s);
            return found.name;
          });

          return r;
        });
        this.learningPath = learningPath;
      }

      this.initialized = true;
      this.initializing = false;
    } catch (e) {
      console.log(e);
    }
  }

  startPreassessment(functionName) {
    const functionId = this.baseData.toplineFunctions.find(tf => tf.name === functionName).id;
    const functionQuestions = this.preAssessment.extra.competencies.filter(c => c.extra.functionId === functionId);
    this.functionQuestions = functionQuestions;
    this.loadNextQuestion();
  }

  loadNextQuestion() {
    this.loadingQuestion = true;
    let index = 0;

    const found = this.functionQuestions.find((c, i) => {
      index += 1;
      return this.preAssessment.extra.assessmentResponses[c.id] == null;
    });

    this.currentAssessmentQuestion = found;
    this.currentAssessmentQuestionIndex = index;
    this.hasNextQuestion = this.functionQuestions.filter(c => {
      return this.preAssessment.extra.assessmentResponses[c.id] == null;
    }).length > 1;

    this.loadingQuestion = false;

    return this.functionQuestions.filter(c => {
      return this.preAssessment.extra.assessmentResponses[c.id] == null;
    }).length === 0 ? false : true;
  }

  async setPreassessmentResponse(question, response) {
    this.updatingAssessment = true;
    let newAssessment = _.clone(this.preAssessment);
    newAssessment.extra.assessmentResponses[question.id] = response;

    if (Object.keys(newAssessment.extra.assessmentResponses).length === this.preAssessment.extra.competencies.length) {
      newAssessment.status = 'completed';
    }

    const { data: updated } = await Api.updateAssessment(newAssessment);

    this.preAssessment = updated;
    this.updatingAssessment = false;

    if (newAssessment.status === 'completed') {
      await this.initialize();
      // window.location.reload();
    }
  }

  async markPhase2Complete() {
    await Api.markPhase2Complete();
    window.location.reload();
  }

  async markVideoComplete() {
    await Api.markVideoComplete();
    this.viewedVideo = true;
  }

  async markVideo2Complete() {
    await Api.markVideo2Complete();
    this.viewedVideo = true;
  }

  async markToplineComplete() {
    await Api.markToplineComplete();
    this.toplineComplete = true;
  }

  async markCourseComplete(courseId, dontRemove) {
    if (!this.learningPath.extra.courseCompletions) {
      this.learningPath.extra.courseCompletions = [];
    }
    if (this.learningPath.extra.courseCompletions.indexOf(courseId) !== -1) {
      if (!dontRemove) {
        this.learningPath.extra.courseCompletions = this.learningPath.extra.courseCompletions.filter(c => courseId !== c);
      }
    } else {
      this.learningPath.extra.courseCompletions.push(courseId);
    }

    const lp = await Api.updateProgram(this.learningPath);
  }

  setCurrentPostAssessment(toplineFunction) {
    const filtered = _.orderBy(this.postAssessments.filter(p => p.extra.assessmentGrades.byFunction[toplineFunction.id] != null), ['date_created'], ['desc']);
    console.log(filtered);
    this.currentPostAssessment = filtered[0];
  }

  startPostAssessment(toplineFunction) {
    const competencies = this.baseData.competencies.filter(c => c.extra.functionId === toplineFunction.id);

    this.currentPostAssessment = {
      type: 'post',
      project: 'topline',
      status: 'completed',
      username: userStore.user.eid,
      extra: {
        assessmentResponses: {},
        competencies
      }
    };
  }

  loadNextPostQuestion() {
    this.loadingQuestion = true;
    let index = 0;

    const found = this.currentPostAssessment.extra.competencies.find((c, i) => {
      index += 1;
      return this.currentPostAssessment.extra.assessmentResponses[c.id] == null;
    });

    this.currentAssessmentQuestion = found;
    this.currentAssessmentQuestionIndex = index;
    this.hasNextQuestion = this.currentPostAssessment.extra.competencies.filter(c => {
      return this.currentPostAssessment.extra.assessmentResponses[c.id] == null;
    }).length > 1;

    this.loadingQuestion = false;

    return this.currentPostAssessment.extra.competencies.filter(c => {
      return this.currentPostAssessment.extra.assessmentResponses[c.id] == null;
    }).length === 0 ? false : true;
  }

  async setPostAssessmentResponse(question, response) {
    let newAssessment = _.clone(this.currentPostAssessment);
    newAssessment.extra.assessmentResponses[question.id] = response;

    if (Object.keys(newAssessment.extra.assessmentResponses).length === this.currentPostAssessment.extra.competencies.length) {
      newAssessment.status = 'completed';

      const assessmentGrades = {
        totalQuestions: 0,
        totalCorrect: 0,
        totalIncorrect: 0,
        byFunction: {}
      };
      
      this.currentPostAssessment.extra.competencies.forEach(c => {
        if (c.extra.functionId && !assessmentGrades.byFunction[c.extra.functionId]) {
          assessmentGrades.byFunction[c.extra.functionId] = {
            totalQuestions: 0,
            totalCorrect: 0,
            totalIncorrect: 0
          };
        }

        const response = newAssessment.extra.assessmentResponses[c.id];
        let correct = true;

        c.extra.responses.forEach(r => {
          if (r.isCorrect && (!response[r.uuid] || !response[r.uuid] === '-1')) {
            correct = false;
          }
        });

        Object.keys(response).forEach(uuid => {
          const found = c.extra.responses.find(r => r.uuid === uuid);
          if (response[uuid] === true && found && !found.isCorrect) {
            correct = false;
          }
        });

        assessmentGrades.totalQuestions += 1;
        if (correct) {
          assessmentGrades.totalCorrect += 1;
        } else {
          assessmentGrades.totalIncorrect += 1;
        }

        if (c.extra.functionId) {
          assessmentGrades.byFunction[c.extra.functionId].totalQuestions += 1;
          if (correct) {
            assessmentGrades.byFunction[c.extra.functionId].totalCorrect += 1;
          } else {
            assessmentGrades.byFunction[c.extra.functionId].totalIncorrect += 1;
          }
        }
      });

      newAssessment.extra.assessmentGrades = assessmentGrades;

      const { data: updated } = await Api.createAssessment(newAssessment);
 
      this.initializing = false;
      await this.initialize();
    }
  }

  setAdvanceToPhaseTwo() {
    this.advanceToPhaseTwo = true;
  }

  setToplineAware(value) {
    this.toplineAware = value;
  }

  setViewedVideo(value) {
    this.viewedVideo = value
  }

  async setUserCompletion(resourceId, isCompleted) {
    const completion = {
      courseId: resourceId,
      completed: isCompleted
    }
    await Api.createOrUpdateUserCompletion(completion);
  }

  async createAudit(value) {
    await Api.createAudit(userStore.user.eid, 'topline', value);
  }
}

decorate(ToplineStore, {
  initialized: observable,
  initializing: observable,
  baseData: observable,
  preAssessment: observable,
  postAssessments: observable,
  setNeedsPreassessment: action,
  learningPath: observable,
  advanceToPhaseTwo: observable,
  updatingAssessment: observable,
  currentAssessmentQuestion: observable,
  currentAssessmentQuestionIndex: observable,
  hasNextQuestion: observable,
  loadingQuestion: observable,
  functionQuestions: observable,
  currentPostAssessment: observable,
  needsPreAssessment: computed,
  learningPathCompleteByFunction: computed,
  phaseTwoCourses: computed,
  setAdvanceToPhaseTwo: action,
  allEnabled: computed,
  toplineAware: observable,
  lastPostAssessmentByFunction: computed,
  initialize: action,
  setPreassessmentResponse: action,
  loadPostAssessments: action,
  loadNextQuestion: action,
  loadNextPostQuestion: action,
  markPhase2Complete: action,
  markCourseComplete: action,
  setToplineAware: action,
  setViewedVideo: action,
  viewedVideo: observable,
  viewedVideo2: observable,
  toplineComplete: observable,
  isCompleteWithPhase2Course: computed,
  postAssessmentCounts: computed,
  setCurrentPostAssessment: action
});

export default new ToplineStore();
