export default {
  banner: {
    viewDetails: 'Voir les détails',
    requiredCourses: 'Vos cours obligatoires sont dûs dans',
    days: 'jours'
  },
  header: {
    plan: 'Mon plan',
    manager: 'Directeur',
    explore: 'Explorez L\'apprentissage',
    subItem1: 'Article1',
    subItem2: 'Article2'
  },
  footer: {
    plan: 'MON PLAN',
    manager: 'DIRECTEIR',
    explore: 'EXPLOREZ L\'APPRENTISSAGE',
    tagline: 'Apprendre en toute simplicité. Ça commence avec vous.'
  },
  welcome: {
    hi: 'Bonjour',
    intro1: 'Bienvenue dans la zone d\'apprentissage numérique. Nous regroupons tous vos cours d’apprentissage au même endroit et les rendons disponibles où que vous soyez.',
    intro2: 'Notre objectif est de vous aider à rendre votre travail plus simple et productif.',
    intro3: 'Avant de commencer, nous avons besoin d’un peu plus d’informations sur vous.',
    letsGo: 'ALLONS-Y',
    preferredLanguage: 'Quelle est votre langue préférée?',
    step: 'Étape',
    of: 'De',
    previous: 'PRÉCÉDENT',
    next: 'Suivant',
    startDate: 'Quelle est votre date de début d’origine avec Marriott / Starwood?',
    selectYear: 'SÉLECTIONNEZ L\'ANNÉE',
    selectMonth: 'SÉLECTIONNEZ UN MOIS (OPTIONNEL)',
    selectDay: 'SÉLECTIONNER UNE DATE (OPTIONNEL)',
    selectFunction: 'SÉLECTIONNER LA FONCTION',
    chooseYear: 'CHOISISSEZ UNE ANNÉE',
    chooseMonth: 'CHOISISSEZ UN MOIS (OPTIONNEL)',
    chooseDay: 'CHOISISSEZ UNE JOURNÉE (OPTIONNEL)',
    whatDo: 'Que faire?',
    selected: 'CHOISI',
    searchFunctions: 'Rechercher Toutes Les Fonctions'
  },
  landing: {
    started: 'Commençons',
    welcomeBack: 'Bienvenue',
    greatWork: 'Bon travail',
    requiredCourses: 'Vos Cours Obligatoires',
    requiredCoursesDesc: 'Pour que vous soyez à votre meilleur niveau, les cours suivants sont obligatoires.',
    myPlan: 'Mon Plan',
    courses: 'Cours',
    courseComplete: 'accompli',
    viewMore: 'VOIR PLUS DE COURS OBLIGATOIRES',
    viewMoreBtn: 'VOIR PLUS DE COURS',
    showing: 'MONTRANT',
    of: 'DE',
    heroTitle: 'Apprenez quelque chose de nouveau. Habilitez-vous. Acquérez des compétences qui s’appliquent à votre monde.',
    heroParagraph1: 'Suivez facilement tous vos cours requis et recevez des notifications en temps réel pour les suivre.',
    heroParagraph2: 'Profitez d\'expériences d\'apprentissage personnalisées en fonction de votre discipline et de vos intérêts.',
    recommendedCoursesTitle: 'Cours Recommandés',
    recommendedCoursesDesc: 'Aller plus loin. Les cours suivants peuvent être dispensés par votre responsable ou votre spécialiste en ressources humaines.',
    recommendedCoursesBtn: 'VOIR LES COURS RECOMMANDÉS',
    exploreTitle: 'Restez curieux. Explorez l\'apprentissage.',
    exploreDesc: 'Sélectionnez vos sujets d\'intérêt qui vous motivent.',
    exploreBtn: 'VOIR LES COURS',
    managerRecommendation: 'RECOMMANDATION DU GESTIONNAIRE',
    "relatedCourses": "Veuillez cliquer ci-dessous pour sélectionner vos intérêts.",
    "notificationText": "Nouvelle formation mHUB disponible. Choisissez mHUB dans Explore Learning.",
    "mHubHeroTitle1": "Simple. Astucieux. Sûr. Introduction à mHUB.",
    "mHubHeroManagedText1": "mHUB intègre des outils pour l’apprentissage, le développement de carrière, les remises Explore et plus dans une seule plateforme facile à utiliser.",
    "mHubHeroFranchisedText1": "mHUB intègre des outils pour l’apprentissage, les remises Explore et plus dans une seule plateforme facile à utiliser.",
    "mHubHeroVisitLink": "Cliquez ici pour voir d’autres détails ou pièces jointes de l’offre."
  },
  courseBundle: {
    remainingCourses: 'Cours Restants',
    completedCourses: 'Cours Terminés',
    course: 'COURS',
    remainingLabel: 'Il y a {courseCount} cours dans ce parcours d\'apprentissage.',
    recommendButton: 'Recommander à L\'équipe',
    startCourse: 'Commencer Le Cours',
    durationMins: 'MINUTES',
    completeBy: 'COMPLET PAR',
    completed: 'Terminé'
  },
  profile: {
    save: 'Sauvegarder',
    edit: 'Modifier',
    brand: 'Brand',
    startDateLabel: 'Quelle est votre date de début d’origine avec Marriott / Starwood?',
    preferredLanguageLabel: 'Quelle est votre langue préférée?',
    jobFunctionsLabel: 'Que faire?',
    interestsLabel: 'Que voulez-vous apprendre?',
    brandKeyLabel: 'Quelle est votre marque?'
  },
  locationTable: {
    marsha: 'MARSHA',
    locationCode: 'EMPLACEMENT',
    propertyName: 'NOM DE LA PROPRIÉTÉ',
    avp: 'AVP',
    president: 'PRÉSIDENT',
    progress: 'PROGRÉS DU COURS'
  },
  associatesTable: {
    id: 'ID',
    name: 'PRÉNOM',
    functionTypes: 'FONCTION',
    progress: 'PROGRÉS DU COURS'
  },
  table: {
    previous: 'Précédent',
    next: 'Suivant',
    showing: 'MONTRANT',
    of: 'DE',
    results: 'RÉSULTATS'
  }
};