export default {
  "banner": {
    "viewDetails": "विवरण देखें",
    "requiredCourses": "आपके आवश्यक पाठ्यक्रम पूर्ण किए जाने हैंं",
    "days": "दिनों में"
  },
  "header": {
    "plan": "मेरा प्लान",
    "manager": "प्रबंधक",
    "explore": "लर्निंग की खोजबीन करें",
    "reporting": "रिपोर्टिंग",
    "myTeam": "मेरी टीम",
    "title": "Digital Learning Zone"
  },
  "footer": {
    "plan": "मेरा प्लान",
    "manager": "प्रबंधक",
    "explore": "लर्निंग की खोजबीन करें",
    "tagline": "अब सीखना हुआ आसान। यह आपसे शुरू होता है।"
  },
  "welcome": {
    "hi": "नमस्कार",
    "intro1": "Digital Learning Zone में स्वागत है! DLZ, एक व्यक्तिगत शिक्षा प्लेटफ़ॉर्म है जो मैरियट लर्निंग में केंद्रीय उपयोग बिंदु है। अपनी भूमिका के लिए आवश्यक प्रशिक्षण ढूंढें और अपने व्यक्तिगत और पेशेवर विकास के लिए सीखने के अवसरों का पता लगाएं। अपने सीखने के सफ़र की ज़िम्मेदारी संभालें और अपने कौशलों को बढ़ाने के रास्ते पर चलना शुरू करें।",
    "intro2": "इस से पहले कि आप शुरू करें, हमें आपके बारे में कुछ बातें जानने की जरूरत है।",
    "letsGo": "चलिए शुरू करते हैं",
    "preferredLanguage": "आपकी पसंदीदा भाषा कौनसी है?",
    "step": "चरण",
    "of": "का",
    "previous": "पिछला",
    "next": "अगला",
    "startDate": "आपने Marriott International या Starwood में कार्य करना कब शुरू किया था?",
    "selectYear": "वर्ष चुनें",
    "selectMonth": "महीना चुनें",
    "selectDay": "तिथि चुनें",
    "selectFunction": "फ़ंक्शन चुनें",
    "selectInterests": "रुचि चुनें",
    "chooseYear": "एक वर्ष चुनें",
    "chooseMonth": "एक महीना चुनें",
    "chooseDay": "एक दिन चुनें",
    "whatDo": "आपका जॉब फंक्शन क्या है",
    "selected": "चयनित",
    "searchFunctions": "सभी फ़ंक्शन को खोजें",
    "searchInterests": "सभी रुचियां खोजें",
    "additionalJobFunctionsLabel": "आप क्या करते हैं, इसके बारे में हमें और बताएं",
    "managementLevelLabel": "आपका एसोसिएट लेवल क्या है?",
    "preferredBrandLabel": "यदि आप एक से अधिक ब्रांड के लिए काम करते हैं, तो कृपया उन सब को चुनें जो आप पर लागू होते हैं।",
    "additionalJobFunctionsLabelExtra": "कृपया निम्नलिखित की समीक्षा करें और कार्य की कम से कम एक अतिरिक्त जिम्मेदारी का चयन करें।",
    "searchAdditionalFunctions": "सभी अतिरिक्त कार्य की जिम्मेदारियों को खोजें",
    "highGuestContactLabel": "क्या आप प्रमुख अतिथि संपर्क हैं?",
    "yes": "हाँ",
    "no": "नहीं "
  },
  "landing": {
    "started": "आईए शुरू करें",
    "welcomeBack": "पुनः स्वागत है,",
    "greatWork": "बढ़िया काम",
    "requiredCourses": "आपके आवश्यक पाठ्यक्रम",
    "requiredCoursesDesc": "कृपया नियत तिथि से पहले पाठ्यक्रम पूरा करें।",
    "myPlan": "मेरा प्लान",
    "courses": "पाठ्यक्रम",
    "courseComplete": "पाठ्यक्रम समापन",
    "viewMore": "और आवश्यक पाठ्यक्रम देखें",
    "viewMoreBtn": "और पाठ्यक्रम देखें",
    "showing": "दिखा रहे हैं",
    "of": "का",
    "heroTitle": "कुछ नया सीखें। स्वयं को सशक्त बनाएं। आपकी दुनिया में काम आने वाले कौशल सीखें।",
    "heroParagraph1": "अपने सभी आवश्यक पाठ्यक्रमों का आसानी से हिसाब रखें और उन्हें पूरा करने की वास्तविक-समय सूचनाएं पाएं।",
    "heroParagraph2": "आपके जॉब और रुचियों के मुताबिक बनाए गए सीखने के अनुभवों का मज़ा लें।",
    "recommendedCoursesTitle": "सुझावित पाठ्यक्रम",
    "recommendedCoursesDescNoContent": "इस समय आपके लिए कोई सुझावित पाठ्यक्रम नहीं है। सीखने के अन्य अवसरों की खोजबीन के लिए कृपया नीचे देखें।",
    "recommendedCoursesDesc": "देखें कि आपके मैनेजर या एचआर स्पेशलिस्ट ने आपके लिए क्या चुना है।",
    "recommendedCoursesBtn": "सुझावित पाठ्यक्रम देखें",
    "completedCoursesBtn": "पूर्ण हो चुके पाठ्यक्रम देखें",
    "exploreTitle": "उत्सुक बने रहें।",
    "exploreDesc": "अपनी रुचि के टॉपिक चुनें।",
    "jobFunctions": "अनुशासन",
    "growthSkills": "वृद्धि कौशल",
    "leadershipSkills": "नेतृत्व कौशल",
    "brands": "ब्रांड्स",
    "languageLearning": "भाषा सीखना",
    "exploreBtn": "पाठ्यक्रमों को देखें",
    "managerRecommendation": "मैनेजर द्वारा सुझावित",
    "learningRecommendation": "लर्निंग द्वारा सुझावित",
    "marriottRecommendation": "Marriott द्वारा सुझावित",
    "completedCourses": "आपने अपने सभी आवश्यक पाठ्यक्रम पूर्ण कर लिए हैं!",
    "investTitle": "अपने सीखने के सफ़र की ज़िम्मेदारी संभालें",
    "investParagraph1": "हमें पता है कि वर्तमान परिस्थितियां कई मायनों में चुनौतीपूर्ण हैं - डीएलजेड आपके लिए एक संसाधन के रूप में यहां है। अगर आप अपने कौशल को चालू रखना चाहते हैं, कुछ नया सीखना चाहते हैं, अपने तनाव को प्रबंधित करना चाहते हैं, तथा  एक आभासी टीम पर काम करना सीखना चाहते हैं । हमने आपकी सहायता के लिए संसाधन जुटाए हैं ।",
    "updatedTopics": "अद्यतन विषय",
    "workingRemotely": "वर्चुअल टीम संसाधन",
    "relatedCoursesNew": "अपनी रुचियों को अद्यतन करने के लिए नीचे क्लिक करें, क्योंकि हमने नए विषय और पाठ्यक्रम जोड़े हैं।",
    "relatedCourses": "कृपया अपनी रुचियों का चयन करने के लिए नीचे क्लिक करें।",
    "notificationText": "नया mHUB प्रशिक्षण उपलब्ध है। Explore Learning में mHUB चुनें।",
    "mHubHeroTitle1": "आसान। स्मार्ट। सुरक्षित। पेश है mHUB.",
    "mHubHeroManagedText1": "mHUB सीखने, कैरियर के विकास, Explore छूट और अधिक कार्यों के लिए उपकरणों को एक, उपयोग में आसान प्लेटफार्म पर एकीकृत करता है।",
    "mHubHeroFranchisedText1": "mHUB सीखने, छूट का अन्वेषण और अधिक कार्यों  के लिए उपकरणों को एक, उपयोग में आसान प्लेटफार्म पर एकीकृत करता है।",
    "mHubHeroVisitLink": "अतिरिक्त पेशकश विवरण या संलग्नकों को देखने के लिए यहां क्लिक करें।",
    "myGateway": "My Gateway",
    "gatewayReview": "कृपया ध्यान से पढ़ें और पूरा करें।",
    "gatewayProgress": "Gateway प्रगति",
    "complete": "पूर्ण",
    "reviewContent": "संसाधन देखें",
    "gatewayCardTitle": "Gateway संसाधन",
    "gatewayCardDesc": "पहले साल और उससे आगे के लिए अपने बेसिक्स को एक्सेस करें।",
    "gatewayCardDescGms": "पहले साल और उससे आगे के लिए अपने बेसिक्स, कार्य बिंदुओं (एक्शन आइटम्स), ब्रांड संसाधनों और विषय-वार संसाधनों को एक्सेस करें।",
    "gatewayCardDescNew": "अपनी नई भूमिका के पहले साल में अपनी बुनियादी बातों को एक्सेस करें।",
    "gatewayCardDescGmsNew": "अपनी नई भूमिका के पहले साल और उससे आगे के लिए अपने बेसिक्स, कार्य बिंदुओं (एक्शन आइटम्स), ब्रांड संसाधनों और विषय-वार संसाधनों को एक्सेस करें।"
  },
  "courseBundle": {
    "completeBy": "पूर्ण करने का दिनांक",
    "remainingCourses": "शेष पाठ्यक्रम",
    "completedCourses": "पूर्ण हो चुके पाठ्यक्रम",
    "remainingLabel": "इस सीखने के मार्ग में {courseCount} पाठ्यक्रम हैं।",
    "recommendButton": "टीम को सुझाएं",
    "startCourse": "पाठ्यक्रम आरंभ करें",
    "durationMins": "मिनट",
    "completed": "पूरा हो गया",
    "back": "पिछला",
    "additionalLangs1": "अंग्रेज़ी के साथ-साथ यह पाठ्यक्रम अब इस भाषा में भी उपलब्ध है",
    "additionalLangs2": "कृपया पाठ्यक्रम आरंभ करने के बाद ड्रॉप डाउन मेन्यू में से अपनी पसंदीदा भाषा चुनें।",
    "targetAudience": "लक्ष्य वर्ग",
    "all": "सभी",
    "globally": "वैश्विक रूप से",
    "source": "सोर्स",
    "courseType": "पाठ्यक्रम का प्रकार",
    "course": "पाठ्यक्रम"
  },
  "profile": {
    "save": "सेव करें",
    "edit": "संपादित करें",
    "brand": "ब्रांड",
    "brands": "ब्रांड्स",
    "additionalWork": "सभी अतिरिक्त कार्य के उत्तरदायित्व",
    "startDateLabel": "आपने Marriott International या Starwood में कार्य करना कब शुरू किया था?",
    "preferredLanguageLabel": "आपकी पसंदीदा भाषा कौनसी है?",
    "jobFunctionsLabel": "आपकी जॉब भूमिका क्या है?",
    "interestsLabel": "आप क्या सीखना चाहते हैं?",
    "brandsLabel": "आप किस/किन ब्रांड(ड्स) के लिए कार्य करते हैं?",
    "additionalJobFunctionsLabel": "आप कौनसे अतिरिक्त जॉब कार्य करते हैं?",
    "managementLevelLabel": "आपका एसोसिएट स्तर क्या है",
    "highGuestContactLabel": "क्या आप प्रमुख अतिथि संपर्क हैं?"
  },
  "courseDetails": {
    "completeBy": "पूरा किया जाना है",
    "recommendTitlte": "अपनी टीम को पाठ्यक्रम सुझाएं",
    "courseName": "पाठ्यक्रम का नाम",
    "sendRecommendation": "सुझाव भेजें"
  },
  "explore": {
    "relatedCourses": "ये रहे आपकी रुचियों से संबंधित पाठ्यक्रम।",
    "chooseInterests": "अपनी रुचियां चुनें",
    "title": "लर्निंग की खोजबीन करें",
    "chooseInterestsModal": "रुचियां चुनें",
    "oppsTitle": "सीखने के अवसरों की खोजबीन करें",
    "growWithUsTitle": "हमारे साथ बढ़ें",
    "growWithUsDesc": "Marriott ऐसे करियर में विश्वास रखता है जो आपके साथ-साथ फलता-फूलता हो। हम यह भी मानते हैं कि अधिक व्यापक अनुभव प्रायः अधिक समृद्धकारी अनुभव प्रदान करते हैं।",
    "growWithUsBtn": "हमारे साथ बढ़ें देखें",
    "mdaTitle": "Marriott डेवलपमेंट एकेडमी",
    "mdaDesc": "Marriott सफलता और प्रतिबद्धता को सम्मान देता है। हम नेतृत्व और असाधारण सेवा का सम्मान करते हैं और उन्हें बढ़ावा देते हैं। हम सफल रेफ़रल के लिए बोनस भी देते हैं।",
    "mdaBtn": "Marriott डेवलपमेंट एकेडमी देखें",
    "selectInterests": "हमें अपनी रुचि के टॉपिक बताएं।",
    "otherRecommended": "अन्य सुझावित पाठ्यक्रम",
    "searchByName": "अपनी रुचियों को खोजें"
  },
  "plan": {
    "completedRequired": "आपने अपने सभी आवश्यक पाठ्यक्रम पूर्ण कर लिए हैं!",
    "requiredCourses": "आवश्यक पाठ्यक्रम",
    "reqDesc": "कृपया इन्हें इनके अंतिम दिनांकों से पहले पूर्ण कर लें।",
    "complete": "पूर्ण",
    "of": "का",
    "courses": "पाठ्यक्रम",
    "completedCourses": "पूर्ण हो चुके पाठ्यक्रम",
    "completedDesc": "बधाई हो! आपने नीचे लिखे पाठ्यक्रम हाल ही में पूर्ण किए हैं।",
    "remaining": "शेष",
    "myLearningTitle": "मेरी वर्धित लर्निंग ",
    "myLearningSubTitle": "वे आइटम जो मैंने मेरी योजना में जोड़े हैं",
    "history": "इतिहास",
    "myLearningHistoryLinkText": "मेरा पूरा इतिहास देखें",
    "jf": "आपका/के जॉब फंक्शन(फंक्शन्स)",
    "awr": "आपके अतिरिक्त कार्य उत्तरदायित्व (AWR)",
    "jfAwrDesc": "महत्त्वपूर्ण: नौकरी के कार्य और AWR आपको माई प्लान पर सौंपे गए आपके आवश्यक प्रशिक्षणों का निर्धारण करते हैं। अद्यतन करने के लिए ",
    "jfAwrEdit": "अपनी प्रोफ़ाइल संपादित करें",
    "jfAwrEnd": "।",
    "tourPlan": "मेरे प्लान में नियत तिथि तक पूरा करने के लिए आपके सभी आवश्यक और अनुशंसित पाठ्यक्रम शामिल हैं",
    "tourBasics": "मैरियट बेसिक्स में आपको अपनी मैरियट यात्रा शुरू करने के लिए संसाधन शामिल हैं और आपके करियर के दौरान किसी भी समय इनका उपयोग किया जा सकता है। मेरे प्लान में मैरियट बेसिक्स आपके सीखने के पूरक हैं।",
    "noCourses": "आपने इस समय सभी आवश्यक सीखने का कार्य पूरा कर लिया है, बेझिझक आगे की प्रतीक्षा करें और आगामी आवश्यक सीखने का आरंभ करें"
  },
  "team": {
    "title": "अपनी टीम को मैनेज करें",
    "subtitle": "उनके कौशल और करियर को आगे बढ़ाएं।",
    "functions": "फ़ंक्शंस",
    "viewTeam": "टीम देखें",
    "allCourses": "सभी पाठ्यक्रम",
    "downloadExcel": "एक्सेल रिपोर्ट डाउनलोड करें"
  },
  "feedbackButton": {
    "havingTrouble": "सुझाव"
  },
  "recommendation": {
    "dismiss": "क्या आप वाकई इस सिफारिश को खारिज करना चाहते हैं?"
  },
  "table": {
    "filterTable": "Tabel Filter"
  },
  "notifications": {
    "addAssignment2": "%{assignment_name} के लिए एक %{assignment_type} असाइनमेंट आपके %{page location} में जोड़ दिया गया है और %{due} को देय है।",
    "comingDueAssignment": "%{assignment_name} के लिए आपका %{assignment_type} असाइनमेंट जल्द ही %{due} को देय है।",
    "overdueAssignment": "%{assignment_name} के लिए आपका %{assignment_type} असाइनमेंट अब अतिदेय है।",
    "addAssignment": "%{assignment_name} के लिए एक %{assignment_type} असाइनमेंट आपके %{page location} में जोड़ दिया गया है।",
    "profile": "आपके पद नाम या स्थान के लिए एक अद्यतन पाया गया है। कोई भी लागू ब्रांड, कार्य, या अतिरिक्त कार्य उत्तरदायित्व अद्यतन करने के लिए कृपया अपनी प्रोफ़ाइल की समीक्षा करें। सटीक DLZ प्रोफ़ाइल जानकारी यह सुनिश्चित करने में मदद करती है कि आपको सही अधिगम असाइनमेंट प्राप्त हों।",
    "helptip": "सभी सूचनाएं 30 दिनों के बाद समाप्त हो जाती हैं।",
    "markRead": "पढ़े हुए के रूप में चिह्नित करें",
    "delete": "हटाएं",
    "markAllRead": "सभी को पढ़े हुए के रूप में चिह्नित करें",
    "settings": "सेटिंग",
    "tooltip": "पढ़ी हुई सूचनाएं 30 दिनों में हटा दी जाएंगी",
    "reportComplete": "आपकी DLZ रिपोर्ट पूरी हो गई है। डाउनलोड करने के लिए क्लिक करें",
    "dontShowThisAgain": "इसे दोबारा न दिखाएं",
    "warningEnabled": "हटाने की चेतावनी सक्षम कर दी गई है।",
    "manageEmailNotifications": "ईमेल सूचनाएं प्रबंधित करें",
    "warningTooltip": "चुने जाने पर, हर बार जब आप कोई सूचना हटाते हैं तो आपको एक चेतावनी दिखाई देगी।",
    "changeEmailManagedTooltip": "अपना ईमेल बदलने के लिए, mHUB > HR Direct (मानव संसाधन प्रत्यक्ष) > Me (मैं) > My Public Info (मेरी सार्वजनिक जानकारी) में जाएं। अपने संपर्क विवरण अपडेट करने के लिए निर्देशों का पालन करें। अपडेट किए गए ईमेल को प्रदर्शित होने में 1-2 कार्यदिवस लग सकते हैं।",
    "changeEmailFranchisedTooltip": "अपना ईमेल बदलने के लिए, अपने प्रबंधक (मैनेजर) और/या अपने फ़्रेंचाइज़ी EID Tool व्यवस्थापक (एडमिनिस्ट्रेटर) से संपर्क करें। नया ईमेल दिखने में 1-2 कार्य दिनों का समय लग सकता है।",
    "deleteAllNotifications": "सभी अधिसूचनाएं मिटाएं",
    "deleteIndividualHeader": "अधिसूचना मिटाएं",
    "deleteAllConfirmation": "क्या आप निश्चित रूप से सारी अधिसूचनाएं मिटाना चाहते हैं? उन्हें अधिसूचना केंद्र (नोटिफ़िकेशन सेंटर) के मिटाए गए (डिलीटेड) अनुभाग में पहुंचा दिया जाएगा जहां वे उनका समय समाप्त होने तक रहेंगी।",
    "deleteIndividualConfirmation": "क्या आप वाकई इस अधिसूचना को मिटाना चाहते हैं? मिटा दी गईं सूचनाओं को अधिसूचना केंद्र (नोटिफ़िकेशन सेंटर) के मिटाए गए (डिलीटेड) अनुभाग में पहुंचा दिया जाता है जहां वे उनका समय समाप्त होने तक रहती हैं।",
    "permanentDeleteIndividiualConfirmation": "क्या आप निश्चित रूप से यह अधिसूचना स्थायी रूप से मिटाना चाहते हैं? इस क्रिया को पलटा नहीं जा सकता है।",
    "permanentDeleteAllConfirmation": "क्या आप निश्चित रूप से सारी अधिसूचनाएं स्थायी रूप से मिटाना चाहते हैं? इस क्रिया को पलटा नहीं जा सकता है।",
    "notificationDeleted": "अधिसूचना मिटा दी गई है।",
    "notificationsDeleted": "अधिसूचनाएं मिटा दी गई हैं।",
    "notificationRestored": "अधिसूचना बहाल कर दी गई है।",
    "notificationsRestored": "अधिसूचनाएं बहाल कर दी गई हैं।",
    "enableDeletionWarning": "हटाने की चेतावनी सक्षम",
    "warningDisabled": "हटाने की चेतावनी अक्षम कर दी गई है।",
    "viewAll": "सभी देखें",
    "deleteAll": "सभी हटा दें",
    "notificationCenterTitle": "अधिसूचना केंद्र (नोटिफ़िकेशन सेंटर)",
    "markAsRead": "पढ़े हुए के रूप में चिह्नित करें",
    "noDeletedNotifications": "आप अप टू डेट हैं!",
    "restore": "बहाल करें",
    "restoreAll": "सभी बहाल करें",
    "viewCourse": "पाठ्यक्रम देखें",
    "title": "अधिसूचनाएं",
    "noNewNotifications": "आपके लिए कोई अधिसूचनाएं नहीं हैं।",
    "cancel": "रद्द करें",
    "manageNotificationsCopy": "ईमेल अधिसूचनाएं प्रबंधित करें\n\nचुनें कि आपके अतिदेय शिक्षण मदों का सारांश देने वाले साप्ताहिक ईमेल प्राप्त हों या नहीं। अपनी प्राथमिकताओं को संशोधित करने के लिए \"प्रबंधित करें\" चुनें।"
  },
  "transcript": {
    "history": "मेरा इतिहास",
    "completed": "पूर्ण किए गए असाइनमेंट",
    "assignmentName": "असाइनमेंट का नाम",
    "type": "प्रकार",
    "viewAssignment": "असाइनमेंट देखें",
    "assignmentSource": "असाइनमेंट स्रोत",
    "requiredAssignments": "आवश्यक असाइनमेंट",
    "recommendedAssignments": "अनुशंसित असाइनमेंट",
    "voluntary": "स्वैच्छिक",
    "trasscript": "प्रतिलिपि",
    "retraining": "पुनः प्रशिक्षण",
    "completionDate": "पूरा करने की तिथि",
    "assignmentType": "असाइनमेंट प्रकार",
    "contentType": "सामग्री प्रकार",
    "contentId": "सामग्री आईडी"
  },
  "contentLaunch": {
    "review": "अधिगम की समीक्षा करें",
    "start": "अधिगम शुरू करें"
  },
  "survey": {
    "weValue": "हम आप से सुनना चाहेंगे।",
    "weValueDescription": "कृपया अपनी संपत्ति के लिए काम करने के अपने अनुभव के बारे में पाँच त्वरित प्रश्नों के उत्तर दें।",
    "weValueButtonText": "सर्वेक्षण लें",
    "weValueG1Q1A1": "हाँ",
    "weValueG1Q1A2": "नहीं",
    "strongAgree": "पूरी तरह सहमत",
    "agree": "सहमत",
    "slightAgree": "जरा सा सहमत",
    "slightDisagree": "जरा सा असहमत",
    "disagree": "असहमत",
    "strongDisagree": "पूरी तरह असहमत",
    "weValueG1Q1Title": "क्या आपने पिछली पाली में काम करते हुए दैनिक बैठक में भाग लिया था? (Line-Up, What’s Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments, या Daily EDITION)",
    "weValueG1Q2Title": "दैनिक बैठक (Line-Up, What's Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments, या Daily EDITION) मुझे हर दिन लग्जरी सर्विस प्रदान करने के लिए प्रेरित करती है।",
    "weValueG1Q3Title": "मेरा विभाग दोषों को कम करने पर केंद्रित है, यहां तक कि वे दोष भी जिन्हें मेहमानों ने रिपोर्ट नहीं किया है।",
    "weValueG1Q4Title": "मैं पहले अनुमति मांगे बिना अतिथि के मुद्दों को हल करने के लिए सशक्त हूँ।",
    "weValueG1Q5Title": "मैं पहले अनुमति मांगे बिना अतिथि को खुश करने के लिए सशक्त हूँ।",
    "weValueG2Q1Title": "मैं नियमित रूप से देखता हूं कि मेरी संपत्ति पर टीम के सदस्य मेहमानों को उनके द्वारा प्रदान की जाने वाली देखभाल से प्रभावित करने के लिए उम्मीद से भी आगे जाते हैं।",
    "weValueG2Q2Title": "इस विभाग में मेरे अधिगम ने मुझे लग्जरी सेवा देने में सक्षम बनाया है।",
    "weValueG2Q3Title": "अपने ज्ञान और कौशल के साथ लग्जरी सेवा देने के लिए मुझे आत्मविश्वास महसूस होता है।",
    "weValueG2Q4Title": "मेरे विभाग में, हम एक लग्जरी वातावरण प्रदान करते हैं, जो स्वच्छ और दोष मुक्त होता है।",
    "weValueG2Q5Title": "यहां तक कि जब मेरा सामना अपने विभाग से असंबंधित किसी मुद्दे से होता है, तब भी मैं सशक्त हूं कि आगे बढ़ूं और अतिथि की चिंता का स्वामित्व लूं।",
    "weValueG3Q1Title": "मेरी संपत्ति पर, टीम के सदस्यों को अतिथि के समान देखभाल मिलती है।",
    "weValueG3Q2Title": "मेरा विभाग ब्रांड के मूल्यों का प्रतीक है।",
    "weValueG3Q3Title": "जब मैं एक लग्जरी अतिथि अनुभव बनाने के लिए उम्मीद से परे जाता हूं, तो मेरे काम को स्वीकार किया जाता है और सराहना की जाती है।",
    "weValueG3Q4Title": "मैं अपने काम को बेहतर बनाने के लिए हमारे विभाग के प्रयासों में शामिल रहता हूं।",
    "weValueG3Q5Title": "मेरे विभाग में, हम हमेशा अतिथि को प्रथम रखते हैं।",
    "submitButton": "जमा करें",
    "phasetwo": {
      "weValueG1Q1Title": "क्या आपने पिछली पाली में काम करते हुए दैनिक बैठक में भाग लिया था? (Line-Up, What's Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments, या Daily EDITION)?",
      "weValueG1Q2Title": "दैनिक बैठक (Line-Up, What's Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments, या Daily EDITION) टीम सदस्यों को हर दिन लग्जरी सर्विस प्रदान करने के लिए प्रेरित करती है।",
      "weValueG1Q3Title": "मेरी संपत्ति पर, हम दोषों को कम करने पर केंद्रित है, यहां तक कि वे दोष भी जिन्हें मेहमानों ने रिपोर्ट नहीं किया है।",
      "weValueG1Q4Title": "मेरी संपत्ति पर, हम पहले अनुमति मांगे बिना मेहमान के मुद्दों को हल करने के लिए सशक्त हैं।",
      "weValueG1Q5Title": "मेरी संपत्ति पर, हम पहले अनुमति मांगे बिना मेहमानों को प्रसन्न करने के लिए सशक्त हैं।",
      "weValueG2Q1Title": "मेरी संपत्ति पर, हम नियमित रूप से मेहमानों को प्रदान की जाने वाली देखभाल से प्रभावित करने के लिए उम्मीद से भी आगे जाते हैं।",
      "weValueG2Q2Title": "संपत्ति में सीखने के अवसरों ने हमें लक्जरी सेवा देने में सक्षम बनाया है।",
      "weValueG2Q3Title": "मेरी संपत्ति पर, हम अपने ज्ञान और कौशल के साथ लग्जरी सेवा देने के लिए आत्मविश्वास महसूस करते हैं।",
      "weValueG2Q4Title": "मेरी संपत्ति पर, हम एक लग्जरी वातावरण प्रदान करते हैं, जो स्वच्छ और दोष मुक्त होता है।",
      "weValueG2Q5Title": "यहां तक कि जब हमारा सामना अपने विभाग से असंबंधित किसी मुद्दे से होता है, तब भी हम सशक्त होते हैं कि आगे बढ़ें और अतिथि की चिंता का स्वामित्व लें।",
      "weValueG3Q1Title": "मेरी संपत्ति पर, हम एक दूसरे से उतनी ही देखभाल प्राप्त करते हैं जितनी हम मेहमानों को प्रदान करते हैं।",
      "weValueG3Q2Title": "मेरी संपत्ति ब्रांड के मूल्यों का प्रतीक है।",
      "weValueG3Q3Title": "मेरी संपत्ति पर, जब हम एक लग्जरी अतिथि अनुभव बनाने के लिए उम्मीद से परे जाते हैं, तो हमारे काम को स्वीकार किया जाता है और सराहना की जाती है।",
      "weValueG3Q4Title": "मेरी संपत्ति पर, हमारे पास अपने काम को बेहतर बनाने के लिए हमारे विभाग के प्रयासों में शामिल होने का अवसर होता है।",
      "weValueG3Q5Title": "मेरी संपत्ति पर, हम हमेशा मेहमान को पहले रखते हैं।"
    }
  },
  "gateway": {
    "planProgressComplete": "%{label} पूर्ण",
    "gatewayProgressComplete": "GATEWAY प्रगति पूर्ण",
    "heroMobileGatewayVideoText": "Gateway वीडियो",
    "basics": "बुनियादी बातें",
    "brandResources": "ब्रांड संसाधन",
    "disciplineResources": "विषय संसाधन",
    "immersionResourcesHeader": "इमर्ज़न संसाधन",
    "brandCommunicationsHeaader": "ब्रांड संचार",
    "brandContactsHeader": "ब्रांड संपर्क",
    "franchisedActionItemsText": "कार्य बिंदुओं (एक्शन आइटम्स) से आपको व्यवस्थित बने रहने में मदद मिलती है और ये आपको ऑनबोर्डिंग (परिचय) से संबंधित महत्वपूर्ण जानकारी देते हैं। ये बिंदु आवश्यक नहीं हैं और आपको आपकी फ़्रेंचाइज़ प्रबंधन कंपनी से मिल सकने वाली जानकारी के पूरक हैं।",
    "global": "वैश्विक",
    "continent": "महाद्वीप",
    "viewed": "देखा गया",
    "completed": "पूरा हो गया",
    "heading": "Gateway",
    "basicsTab": "बुनियादी बातें",
    "onboardingTab": "कार्य बिंदु",
    "brandTab": "ब्रांड संसाधन",
    "disciplinesTab": "विषय-वार संसाधन",
    "basicsHeader": "Marriott International में स्वागत है",
    "basicsHeaderNewBrandOrRole": "स्वागत है,",
    "basicsText": "हम जानते हैं कि करियर की शुरुआत में सीखने को काफ़ी कुछ होता है। हमने जॉब पर आपके शुरुआती कुछ दिनों के लिए कुछ बेसिक्स (बुनियादी बातें) चुनी हैं। इनमें से कुछ बिंदुओं को ओरिएन्टेशन्स (प्रशिक्षणों) या ब्रांड ऑनबोर्डिंग (परिचय) के दौरान और गहराई से समझा जा सकता है, पर ये बेसिक्स आपको शुरुआत तो करवा ही देंगे।",
    "basicsTextGms": "हम जानते हैं कि करियर की शुरुआत में सीखने को काफ़ी कुछ होता है। हमने जॉब पर आपके शुरुआती कुछ दिनों के लिए कुछ बेसिक्स (बुनियादी बातें) चुनी हैं। इनमें से कुछ बिंदुओं को ओरिएन्टेशन्स (प्रशिक्षणों) या ब्रांड ऑनबोर्डिंग (परिचय) के दौरान और गहराई से समझा जा सकता है, पर ये बेसिक्स आपको शुरुआत तो करवा ही देंगे। जब आप इन्हें पूरा कर लें, तो Gateway के अन्य पेज पर जाएं, जिनमें कार्य बिंदु (एक्शन आइटम्स), ब्रांड संसाधन और विषय-वार संसाधन शामिल हैं।",
    "basicsTextFranchise": "हम जानते हैं कि सीखने को काफ़ी कुछ है और हमने जॉब पर आपके शुरुआती दिनों के लिए कुछ बेसिक्स (बुनियादी बातें) चुनी हैं। ये बिंदु आपके ओरिएन्टेशन्स (शुरुआती प्रशिक्षण), ब्रांड ऑनबोर्डिंग (परिचय) या आपकी फ़्रेंचाइज़ प्रबंधन कंपनी से मिलने वाली जानकारी के पूरक हो सकते हैं।",
    "basicsTextFranchiseGms": "हम जानते हैं कि सीखने को काफ़ी कुछ है और हमने जॉब पर आपके शुरुआती दिनों के लिए कुछ बेसिक्स (बुनियादी बातें) चुनी हैं। ये बिंदु आपके ओरिएन्टेशन्स (शुरुआती प्रशिक्षण), ब्रांड ऑनबोर्डिंग (परिचय) या आपकी फ़्रेंचाइज़ प्रबंधन कंपनी से मिलने वाली जानकारी के पूरक हो सकते हैं। जब आप इन्हें पूरा कर लें, तो Gateway के अन्य पेज पर जाएं, जिनमें कार्य बिंदु (एक्शन आइटम्स), ब्रांड संसाधन और विषय-वार संसाधन शामिल हैं।",
    "basicsTextNewToRole": "हम जानते हैं कि अपनी नई भूमिका की शुरुआत करते समय सीखने को काफ़ी कुछ होता है। हमने जॉब पर आपके शुरुआती कुछ दिनों के लिए कुछ बेसिक्स (बुनियादी बातें) चुनी हैं। इनमें से कुछ बिंदुओं को आपके निर्धारित प्रशिक्षण के दौरान और गहराई से समझा जा सकता है, पर ये बेसिक्स आपको शुरुआत तो करवा ही देंगे।",
    "basicsTextNewToRoleGms": "हम जानते हैं कि अपनी नई भूमिका की शुरुआत करते समय सीखने को काफ़ी कुछ होता है। हमने जॉब पर आपके शुरुआती कुछ दिनों के लिए कुछ बेसिक्स (बुनियादी बातें) चुनी हैं। इनमें से कुछ बिंदुओं को आपके निर्धारित प्रशिक्षण के दौरान और गहराई से समझा जा सकता है, पर ये बेसिक्स आपको शुरुआत तो करवा ही देंगे। जब आप इन्हें पूरा कर लें, तो Gateway के अन्य पेज पर जाएं, जिनमें कार्य बिंदु (एक्शन आइटम्स), ब्रांड संसाधन और विषय-वार संसाधन शामिल हैं।",
    "basicsTextNewToRoleFranchise": "हम जानते हैं कि सीखने को काफ़ी कुछ है और हमने आपकी नई भूमिका में आपके शुरुआती दिनों के लिए कुछ बेसिक्स (बुनियादी बातें) चुनी हैं। ये बिंदु आपकी फ़्रेंचाइज़ प्रबंधन कंपनी से मिलने वाली जानकारी के पूरक हो सकते हैं।",
    "basicsTextNewToRoleFranchiseGms": "हम जानते हैं कि सीखने को काफ़ी कुछ है और हमने आपकी नई भूमिका में आपके शुरुआती दिनों के लिए कुछ बेसिक्स (बुनियादी बातें) चुनी हैं। ये बिंदु आपकी फ़्रेंचाइज़ प्रबंधन कंपनी से मिलने वाली जानकारी के पूरक हो सकते हैं। जब आप इन्हें पूरा कर लें, तो Gateway के अन्य पेज पर जाएं, जिनमें कार्य बिंदु (एक्शन आइटम्स), ब्रांड संसाधन और विषय-वार संसाधन शामिल हैं।",
    "basicsTextNewToBrand": "जब आप %{brandName} के साथ अपनी नई भूमिका की शुरुआत कर रहे हैं, और हम जानते हैं कि सीखने को काफ़ी कुछ है। हमने जॉब पर आपके शुरुआती कुछ दिनों के लिए कुछ बेसिक्स (बुनियादी बातें) चुनी हैं। इनमें से कुछ बिंदुओं को ओरिएन्टेशन्स (प्रशिक्षणों) या ब्रांड ऑनबोर्डिंग (परिचय) के दौरान और गहराई से समझा जा सकता है, पर ये बेसिक्स आपको शुरुआत तो करवा ही देंगे।",
    "basicsTextNewToBrandGms": "जब आप %{brandName} के साथ अपनी नई भूमिका की शुरुआत कर रहे हैं, और हम जानते हैं कि सीखने को काफ़ी कुछ है। हमने जॉब पर आपके शुरुआती कुछ दिनों के लिए कुछ बेसिक्स (बुनियादी बातें) चुनी हैं। इनमें से कुछ बिंदुओं को ओरिएन्टेशन्स (प्रशिक्षणों) या ब्रांड ऑनबोर्डिंग (परिचय) के दौरान और गहराई से समझा जा सकता है, पर ये बेसिक्स आपको शुरुआत तो करवा ही देंगे। जब आप इन्हें पूरा कर लें, तो Gateway के अन्य पेज पर जाएं, जिनमें कार्य बिंदु (एक्शन आइटम्स), ब्रांड संसाधन और विषय-वार संसाधन शामिल हैं।",
    "basicsTextNewToBrandFranchise": "हम जानते हैं कि सीखने को काफ़ी कुछ है और हमने आपकी नई भूमिका में आपके शुरुआती दिनों के लिए कुछ बेसिक्स (बुनियादी बातें) चुनी हैं। ये बिंदु आपके ओरिएन्टेशन्स (शुरुआती प्रशिक्षण), ब्रांड ऑनबोर्डिंग (परिचय) या आपकी फ़्रेंचाइज़ प्रबंधन कंपनी से मिलने वाली जानकारी के पूरक हो सकते हैं।",
    "basicsTextNewToBrandFranchiseGms": "हम जानते हैं कि सीखने को काफ़ी कुछ है और हमने %{brandName} के साथ आपकी नई भूमिका में आपके शुरुआती दिनों के लिए कुछ बेसिक्स (बुनियादी बातें) चुनी हैं। ये बिंदु आपके ओरिएन्टेशन्स (शुरुआती प्रशिक्षण), ब्रांड ऑनबोर्डिंग (परिचय) या आपकी फ़्रेंचाइज़ प्रबंधन कंपनी से मिलने वाली जानकारी के पूरक हो सकते हैं। जब आप इन्हें पूरा कर लें, तो Gateway के अन्य पेज पर जाएं, जिनमें कार्य बिंदु (एक्शन आइटम्स), ब्रांड संसाधन और विषय-वार संसाधन शामिल हैं।",
    "disciplineHeader": "विषय-वार संसाधन",
    "toDo": "कार्यसूची",
    "optional": "वैकल्पिक",
    "overallComplete": "समग्र पूर्ण",
    "keyResourcesDesc": "दैनिक संपत्ति संचालनों, मेहमान अनुभव और व्यावसायिक आवश्यकताओं में सहायता देने वाले मुख्य विषयों के बारे में और जानें।",
    "franchiseFooter": "इस सामग्री में ऐसी अनुशंसाएं और सुझावित कार्यप्रथाएं हैं जो ब्रांड मानकों (ब्रांड स्टेंडर्ड्स) के अनुपालन की संभावना बढ़ा सकती हैं। एक फ़्रेंचाइज़र के रूप में, Marriott किसी भी फ़्रेंचाइज़ी के होटल या व्यवसाय संचालन के किसी भी पहलू के प्रबंधन या संचालन में शामिल नहीं होता है। फ़्रेंचाइज़ी के होटल के दिन-प्रतिदिन के संचालन पर उसी का नियंत्रण बना रहता है और अपने कर्मचारियों और नियुक्ति कार्यप्रथाओं के लिए वही ज़िम्मेदार होता है।",
    "landingHeroTitle1": "Marriott International में स्वागत है।",
    "landingHeroTitle2": "अपनी यात्रा यहां से आरंभ करें।",
    "landingHeroText": "The Gateway एक टूल है जो आपको आपकी नई भूमिका के पहले साल में राह दिखाता है। यह आपको वे जानकारी और संसाधन देगा जो आपको सफल होने के लिए चाहिए।",
    "landingHeroTitle1NewToRole": "The Gateway में स्वागत है।",
    "landingHeroTitle1NewToBrand": "The Gateway में स्वागत है।",
    "landingButtonText": "शुरू करें",
    "twoMonthslandingHeroTitle1": "पुनः स्वागत है!",
    "twoMonthslandingHeroText": "यदि आपने The Gateway में अपनी बुनियादी बातों को अभी तक पूरा नहीं किया है तो उन्हें पूरा करें!",
    "twoMonthslandingButtonText": "Gateway में प्रवेश करें",
    "twoMonthslandingHeroTitle1NewToBrand": "पुनः स्वागत है!",
    "twoMonthslandingHeroTextNewToBrand": "आशा है कि %{brandName} के साथ आपका शुरुआती दिन बहुत अच्छे रहे हैं। अपनी यात्रा जारी रखना न भूलें और आपको जो कुछ भी चाहिए उसकी एक्सेस The Gateway में पाएं।",
    "twoMonthslandingButtonTextNewToBrand": "और चालू रखें",
    "threeMonthslandingHeroTitle1": "आप अपने रास्ते पर हैं!",
    "threeMonthslandingHeroText": "आपको Marriott International के बारे में जो कुछ जानना चाहिए वह सब The Gateway में है।",
    "threeMonthslandingButtonText": "Gateway में प्रवेश करें",
    "threeMonthslandingHeroTitle1NewToRole": "आप अपने रास्ते पर हैं!",
    "threeMonthslandingHeroTextNewToRole": "आपको अपनी नई भूमिका में सफल होने के लिए जो कुछ जानना चाहिए वह सब The Gateway में है।",
    "threeMonthslandingButtonTextNewToRole": "Gateway में प्रवेश करें",
    "threeMonthslandingHeroTitle1NewToBrand": "पुनः स्वागत है!",
    "threeMonthslandingHeroTextNewToBrand": "नए ब्रांड के साथ अपने पहले %{numDays} दिन पूरे करने पर बधाइयां। जब भी आवश्यकता हो आप अपने सभी एक्शन आइटम, ब्रांड और विषय संसाधनों तक पहुंच सकते हैं।",
    "threeMonthslandingButtonTextNewToBrand": "Gateway में प्रवेश करें",
    "fourMonthslandingHeroTitle1": "बहुत बढ़िया! आपने अपनी बुनियादी बातें पूरी कर ली हैं!",
    "fourMonthslandingHeroText": "आपको Marriott International के बारे में जो कुछ जानना चाहिए उन सब की एक्सेस The Gateway में पाएं।",
    "fourMonthslandingButtonText": "Gateway में प्रवेश करें",
    "fourMonthslandingHeroTitle1NewToRole": "बहुत बढ़िया! आपने अपनी बुनियादी बातें पूरी कर ली हैं!",
    "fourMonthslandingHeroTextNewToRole": "आपको अपनी नई भूमिका में सफल होने के लिए जो कुछ चाहिए उन सब की एक्सेस The Gateway में पाएं।",
    "fourMonthslandingButtonTextNewToRole": "Gateway में प्रवेश करें",
    "fourMonthslandingHeroTitle1NewToBrand": "The Gateway - चेक इन करना",
    "fourMonthslandingHeroTextNewToBrand": "जब %{brandName} के साथ आपका पहला साल पूरा होने को हो, और आपने अपने सभी कार्य बिंदु (एक्शन आइटम्स) पूरे न किए हों तो उन्हें पूरा करना न भूलें, और यहां Digital Learning Zone में उपलब्ध विकास संसाधनों को उपयोग में ज़रूर लाएं। अपनी यात्रा में आगे बढ़ने के लिए ‘जारी रखें’ पर क्लिक करें।",
    "fourMonthslandingButtonTextNewToBrand": "जारी रखें",
    "gmSecondVisitlandingHeroTitle1": "पुनः स्वागत है!",
    "gmSecondVisitlandingHeroText": "आशा है कि आपके शुरुआती दिन बहुत अच्छे रहे हैं। अपनी नई भूमिका में बढ़ने में मदद के लिए अपनी यात्रा जारी रखना न भूलें।",
    "gmSecondVisitlandingButtonText": "और चालू रखें",
    "gmSecondVisitlandingHeroTextNewToRole": "आशा है कि आपकी नई भूमिका में आपके शुरुआती दिन बहुत अच्छे रहे हैं। अपनी यात्रा जारी रखना न भूलें और आपको जो कुछ भी चाहिए उसकी एक्सेस The Gateway में पाएं।",
    "gmSecondVisitlandingHeroTextNewToBrand": "आशा है कि %{brandName} के साथ आपका शुरुआती दिन बहुत अच्छे रहे हैं। अपनी यात्रा जारी रखना न भूलें और आपको जो कुछ भी चाहिए उसकी एक्सेस The Gateway में पाएं।",
    "gmThirdVisitlandingHeroTitle1": "पुनः स्वागत है!",
    "gmThirdVisitlandingHeroText": "अपनी नई भर्ती यात्रा के शुरुआती %{numDays} दिन पूरे करने पर बधाइयां। जब भी आपको ज़रूरत हो, आप अपने सभी कार्य बिंदुओं (एक्शन आइटम्स), ब्रांड और विषय संसाधनों को एक्सेस कर सकते हैं।",
    "gmThirdVisitlandingButtonText": "Gateway में प्रवेश करें",
    "gmThirdVisitlandingHeroTextNewToRole": "अपनी नई भूमिका में शुरुआती %{numDays} दिन पूरे करने पर बधाइयां। जब भी आपको ज़रूरत हो, आप अपने सभी कार्य बिंदुओं (एक्शन आइटम्स), ब्रांड और विषय संसाधनों को एक्सेस कर सकते हैं।",
    "gmThirdVisitlandingHeroTextNewToBrand": "अपनी नई ब्रांड के साथ शुरुआती %{numDays} दिन पूरे करने पर बधाइयां। जब भी आपको ज़रूरत हो, आप अपने सभी कार्य बिंदुओं (एक्शन आइटम्स), ब्रांड और विषय संसाधनों को एक्सेस कर सकते हैं।",
    "gmSevenMonthslandingHeroTitle1": "लगता है आपके कुछ कार्य बिंदु (एक्शन आइटम्स) अभी अधूरे हैं।",
    "gmSevenMonthslandingHeroText": "जब आपका पहला साल पूरा होने को हो, तो अपने सभी कार्य बिंदु (एक्शन आइटम्स) पूरे करना न भूलें और यहां Digital Learning Zone में उपलब्ध विकास संसाधनों को उपयोग में ज़रूर लाएं। अपनी यात्रा में आगे बढ़ने के लिए ‘जारी रखें’ पर क्लिक करें",
    "gmSevenMonthslandingButtonText": "जारी रखें",
    "gmSevenMonthslandingHeroTitle1NewToRole": "The Gateway - चेक इन करना",
    "gmSevenMonthslandingHeroTextNewToRole": "जब आपका पहला साल पूरा होने को हो, और आपने अपने सभी कार्य बिंदु (एक्शन आइटम्स) पूरे न किए हों तो उन्हें पूरा करना न भूलें, और यहां Digital Learning Zone में उपलब्ध विकास संसाधनों को उपयोग में ज़रूर लाएं। अपनी यात्रा में आगे बढ़ने के लिए ‘जारी रखें’ पर क्लिक करें",
    "gmSevenMonthslandingHeroTitle1NewToBrand": "The Gateway - चेक इन करना",
    "gmSevenMonthslandingHeroTextNewToBrand": "जब %{brandName} के साथ आपका पहला साल पूरा होने को हो, और आपने अपने सभी कार्य बिंदु (एक्शन आइटम्स) पूरे न किए हों तो उन्हें पूरा करना न भूलें, और यहां Digital Learning Zone में उपलब्ध विकास संसाधनों को उपयोग में ज़रूर लाएं। अपनी यात्रा में आगे बढ़ने के लिए ‘जारी रखें’ पर क्लिक करें।",
    "gmOneYearlandingHeroTitle1": "%{brandName} के साथ 1 साल पूरा करने पर बधाइयां!",
    "gmOneYearlandingHeroTitle1NewToRole": "आपकी नई भूमिका में 1 साल पूरा होने पर बधाइयां!",
    "gmOneYearlandingHeroTitle1NewToBrand": "%{brandName} के साथ आपकी नई भूमिका में 1 साल पूरा होने पर बधाइयां!",
    "gmOneYearlandingHeroText": "भले ही The Gateway यात्रा पूरी हो गई हो पर सर्वोत्तम का आना अभी बाकी है। आप जब चाहें तब The Gateway टैब से संसाधनों को एक्सेस कर सकते हैं।",
    "gmOneYearlandingButtonText": "Gateway देखें"
  },
  "resources": {
    "iptTooltip": "एक से अधिक स्थानों को अलग करने के लिए कॉमा का उपयोग करें",
    "iptAllFunctionTooltip": "विशिष्ट जॉब फ़ंक्शन (एक या अधिक) चुनने के लिए निशान हटा दें। चयनित स्थान के सभी फ़ंक्शन्स का प्रशिक्षण देखने के लिए निशान लगा हुआ रहने दें",
    "iptAllAWRTooltip": "विशिष्ट अतिरिक्त कार्य उत्तरदायित्व चुनने के लिए निशान हटा दें। चयनित स्थान के सभी अतिरिक्त कार्य उत्तरदायित्व प्रशिक्षण देखने के लिए निशान लगा हुआ रहने दें",
    "editJFTooltip": "एक से अधिक प्रविष्टियों को अलग करने के लिए कॉमा का उपयोग करें"
  },
  "Notification Center: ": "नहीं पढ़े हुए के रूप में चिह्नित करें",
  "Notification Center: Empty": "आप अप टू डेट हैं!",
  "Notification Center: Individual Delete": "क्या आप वाकई इस नोटिफ़िकेशन को मिटाना चाहते हैं? मिटा दी गईं सूचनाओं को सूचना केंद्र (नोटिफ़िकेशन सेंटर) के मिटाए गए (डिलीटेड) अनुभाग में पहुंचा दिया जाता है जहां वे उनका समय समाप्त होने तक रहती हैं।",
  "Notification Center: Delete All": "क्या आप निश्चित रूप से सारे नोटिफ़िकेशन मिटाना चाहते हैं? उन्हें सूचना केंद्र (नोटिफ़िकेशन सेंटर) के मिटाए गए (डिलीटेड) अनुभाग में पहुंचा दिया जाएगा जहां वे उनका समय समाप्त होने तक रहेंगी।",
  "Notification Center: Mark All As Read": "सभी को पढ़े हुए के रूप में चिह्नित करें",
  "Notification Center: Restore All": "सभी बहाल करें",
  "Notification Center: Delete Confirmation": "हटाएं",
  "Warning Message Text: Remove Job Function or AWR": "जॉब फ़ंक्शन या अतिरिक्त कार्य उत्तरदायित्व (एडीशनल वर्क रेस्पॉन्सिबिलिटी, AWR) हटाएं?\r\nजमा करने के द्वारा आप %{count} शिक्षार्थियों (लर्नर्स) से %{Function and/or AWR} हटाने वाले हैं।",
  "Warning Message Text: Add Job Function or AWR": "जॉब फ़ंक्शन या अतिरिक्त कार्य उत्तरदायित्व (एडीशनल वर्क रेस्पॉन्सिबिलिटी, AWR) जोड़ें?\r\nजमा करने के द्वारा आप %{count} शिक्षार्थियों (लर्नर्स) में %{Function and/or AWR} जोड़ने वाले हैं।",
  "Button": "जमा करें",
  "Success Message Text: Add Job Function and/or AWR": "सफलता\r\nआपने %{location} के अनुरोधित सहयोगी(गियों) में %{Job Function and/or AWR} जोड़ दिया है। \r\n\r\nक्या आप %{location} के अतिरिक्त जॉब फ़ंक्शन्स या अतिरिक्त कार्य उत्तरदायित्वों (एडीशनल वर्क रेस्पॉन्सिबिलिटीज़) में संपादन करना चाहेंगे?",
  "Message Text:AWR": "सफलता\r\nआपने %{ __} के अनुरोधित सहयोगी(गियों) में %{ __} अतिरिक्त कार्य उत्तरदायित्व (एडीशनल वर्क रेस्पॉन्सिबिलिटीज़) जोड़ दिए हैं।\r\n\r\nक्या आप %{location, property, learner(s)} के अतिरिक्त जॉब फ़ंक्शन्स या अतिरिक्त कार्य उत्तरदायित्वों (एडीशनल वर्क रेस्पॉन्सिबिलिटीज़) में संपादन करना चाहेंगे?",
  "Success Message Text: Remove Job Function": "सफलता\r\nआपने %{location} के अनुरोधित सहयोगी(गियों) में से %{Job Function and/or AWR} हटा दिया है। \r\n\r\nक्या आप %{location} के अतिरिक्त जॉब फ़ंक्शन्स या अतिरिक्त कार्य उत्तरदायित्वों (एडीशनल वर्क रेस्पॉन्सिबिलिटीज़) में संपादन करना चाहेंगे?",
  "Success Message Text: Remove AWR": "सफलता\r\nआपने %{ __} के अनुरोधित सहयोगी(गियों) में से %{ __} अतिरिक्त कार्य उत्तरदायित्व (एडीशनल वर्क रेस्पॉन्सिबिलिटीज़) हटा दिए हैं।\r\n\r\nक्या आप %{location, property, learner(s)} के अतिरिक्त जॉब फ़ंक्शन्स या अतिरिक्त कार्य उत्तरदायित्वों (एडीशनल वर्क रेस्पॉन्सिबिलिटीज़) में संपादन करना चाहेंगे?",
  "Tool Tip - Managed Change Email": "अपना ईमेल बदलने के लिए, mHUB > HR Direct (मानव संसाधन प्रत्यक्ष) > Me (मैं) > My Public Info (मेरी सार्वजनिक जानकारी) में जाएं। अपना संपर्क विवरण अपडेट करने के लिए निर्देशों का पालन करें। नया ईमेल दिखने में 1-2 कार्य दिनों का समय लग सकता है।",
  "Tool Tip - Franchised  Change Email": "अपना ईमेल बदलने के लिए, अपने प्रबंधक (मैनेजर) और/या अपने फ़्रेंचाइज़ी EID Tool व्यवस्थापक (एडमिनिस्ट्रेटर) से संपर्क करें। नया ईमेल दिखने में 1-2 कार्य दिनों का समय लग सकता है।",
  "User Profile: Email Opt-in": "नहीं, धन्यवाद",
  "User Profile: Email Frequency": "साप्ताहिक सारांश",
  "Generic Notification Email Subject Line:": "आपके लिए DLZ में नई सूचनाएं हैं",
  "Generic Body Text (<150 Characters)": "विवरण देखने के लिए कृपया DLZ में जाएं।",
  "All Emails: Signature": "Digital Learning Zone - अब सीखना हुआ आसान। यह आपसे शुरू होता है।",
  "All Emails: Footer": "आप किसी भी समय ईमेल सूचनाओं की अपनी सदस्यता/चयन रद्द कर सकते हैं। अपनी प्राथमिकताएं अपडेट करने के लिए Digital Learning Zone/प्रोफ़ाइल में जाएं।",
  "Overdue Emails: Subject Line: ": "DLZ पर उपलब्ध असाइनमेंट को आपका ध्यान चाहिए",
  "Overdue Emails: Body Test": "आपके कुछ असाइनमेंट्स के अंतिम दिनांक निकल चुके हैं। अपने असाइनमेंट्स की समीक्षा के लिए कृपया DLZ में लॉग इन करें।",
  "exploreLearning": {
    "sharpenYourSkillsTitle": "अपने कौशलों को पैना बनाएँ",
    "sharpenYourSkillsSubTitle": "अपने करियर को अभी और भविष्य में आगे बढ़ाने के लिए अपने ज्ञान और कौशलों को बढ़ाएँ।",
    "growYourCareerTitle": "अपना करियर बढ़ाएँ",
    "growYourCareerSubTitle": "आपकी अनूठी रुचियों और लक्ष्यों में सहयोग देने वाले विशेषज्ञ कौशलों या क्रॉस-प्रशिक्षण के साथ अपने अगले क़दम की योजना बनाएँ।",
    "additionalResourcesTitle": "अतिरिक्त संसाधन",
    "additionalResourcesSubTitle": "getAbstract® और CultureWizard™ को देखें और जानें कि कैसे DLZ आपको बढ़ते रहने में मदद दे सकता है।",
    "tourHeader": "अपडेटेड DLZ में आपका स्वागत है। अब आप अपनी ज़रूरत की हर चीज़ पाने के लिए, जब चाहें तब खोज कर सकते हैं। साथ ही, आपको एक अपडेटेड ‘लर्निंग की खोजबीन करें’ और एक अधिक व्यक्तिगत ‘मेरी योजना’ मिलेगी। दौरा करें!",
    "tourBtnSkip": "खारिज करें",
    "tourBtnNext": "बंद करें (6/6)",
    "tourSearchBar": "नई और ज़्यादा दमदार खोज विशेषता से अपनी ज़रूरत की हर चीज़ पाएँ, तब जब आपको उसकी ज़रूरत हो।",
    "tourBtnBack": "वापस जाएं",
    "tourMyPlan": "‘मेरी योजना’ में अब वे आइटम होते हैं जो आपने ‘खोजें और छानबीन करें’ से जोड़े थे, ताकि आप अपनी लर्निंग यात्रा को पूरी तरह अपना बना सकें!",
    "tourExploreLearningLink": "आप अभी-भी यहाँ से लर्निंग की खोजबीन कर सकते हैं!",
    "tourExploreLearningProfile": "अपने लर्निंग अनुभव से अधिकतम लाभ उठाएँ और अपनी ‘मेरी योजना’ को अप-टू-डेट रखने के लिए अपनी प्रोफ़ाइल, विशेष रूप से जॉब फ़ंक्शन को नियमित अंतराल पर जाँचते रहें।",
    "mhubTraining": "mHUB प्रशिक्षण",
    "growthSkills": "वृद्धि कौशल",
    "leadershipSkills": "नेतृत्व कौशल",
    "virtualTeamResources": "वर्चुअल टीम संसाधन",
    "jobFunctions": "जॉब फंक्शन्स",
    "brands": "ब्रांड्स",
    "languageLearning": "भाषा सीखना",
    "become": "विशेषज्ञ",
    "emeaMentoringProgram": "EMEA परामर्श कार्यक्रम",
    "learningPathways": "सीखने के रास्ते",
    "mda": "MDA",
    "takeCareGrow": "TakeCare GROW",
    "getAbstract": "getAbstract®",
    "cultureWizard": "CultureWizard™",
    "takeCareCommunityHub": "TakeCare समुदाय केंद्र",
    "mhubTrainingDesc": "mHUB का उपयोग कैसे करें इस बारे में भूमिका-आधारित सीखने के मार्ग और टॉपिक-विशिष्ट पाठ्यक्रम पाएँ।",
    "growthSkillsDesc": "विभिन्न फ़ंक्शन और टॉपिक का व्यावहारिक ज्ञान हासिल करें।",
    "leadershipSkillsDesc": "जानें कि टीम का प्रबंधन कैसे करें, उसे कोचिंग कैसे दें, और अधिक प्रभावी लीडर कैसे बनें।",
    "virtualTeamResourcesDesc": "वर्चुअल टीमों के बीच सहयोगी और समावेशी संस्कृति को बढ़ावा देने वाली तकनीकें सीखें।",
    "jobFunctionsDesc": "अपने वर्तमान जॉब फ़ंक्शन का ज्ञान बढ़ाएँ और अन्य फ़ंक्शन के बारे में सीखें।",
    "brandsDesc": "Marriott International की विविध ब्रांड्स के बारे में और जानें।",
    "languageLearningDesc": "वैश्विक लक्ष्य-समूह के साथ प्रभावी ढंग से संवाद करें।",
    "becomeDesc": "करियर वृद्धि और विकास की एक संपूर्ण पद्धति को जानें: चिंतन करें, योजना बनाएँ, कार्य करें, परिशोधन करें।",
    "emeaMentoringProgramDesc": "EMEA परामर्श कार्यक्रम के साथ अपने सामर्थ्य को आज़ाद करें।",
    "learningPathwaysDesc": "आपके लीडरशिप स्तर के लिए विशेष रूप से बनाए गए विशिष्ट लर्निंग अवसरों में खोजबीन करें।",
    "mdaDesc": "Marriott Development Academy के साथ लीडरशिप कौशलों और कार्यात्मक कौशलों का निर्माण करें।",
    "takeCareGrowDesc": "समावेशी और प्रेरित समुदाय की रचना के लिए डिज़ाइन किए गए संसाधनों की पहचान करें।",
    "getAbstractDesc": "बेहतर निर्णय लेने में आपकी मदद करने वाले पुस्तक सारांशों के साथ हर दिन कुछ नया सीखें।",
    "cultureWizardDesc": "विविध संस्कृतियों से प्रभावी संवाद करने के लिए मूल्यवान ज्ञान हासिल करें।",
    "takeCareCommunityHubDesc": "TakeCare टीम-निर्माण गतिविधियों, लर्निंग कार्यक्रमों और टूल्स को जानें।",
    "searchAllLearning": "सभी लर्निंग खोजें",
    "searchBarPlaceHolder": "सीखने के लिए हमेशा और भी बहुत कुछ रहता है।",
    "clearFilters": "फ़िल्टर साफ़ करें",
    "noResults": "कोई परिणाम नहीं",
    "hasBeenUnpinned": "{Variable-tile_Name} को अनपिन कर दिया गया है।",
    "hasBeenPinned": "{Variable-tile_Name} को पिन कर दिया गया है।",
    "lpRemovedFromPlan": "सीखने के रास्ते को आपके प्लान से हटा दिया गया है।",
    "courseRemovedFromPlan": "पाठ्यक्रम को आपके प्लान से हटा दिया गया है",
    "lpAddedToPlan": "सीखने के रास्ते को आपके प्लान में जोड़ दिया गया है।",
    "courseAddedToPlan": "पाठ्यक्रम को आपके प्लान में जोड़ दिया गया है",
    "clearSelection": "चयन साफ़ करें",
    "removeFromMyPlan": "मेरे प्लान से हटाएं",
    "addToMyPlan": "मेरे प्लान में जोड़ें",
    "loadingResults": "लोड हो रहा है",
    "viewDetails": "ब्‍यौरे देखें",
    "clearAll": "सभी साफ़ करें",
    "noOptions": "कोई विकल्प उपलब्ध नहीं है",
    "viewLess": "कम देखें",
    "viewMore": "अधिक देखें",
    "recentSearch": "हालिया खोजें",
    "exploreLearningTitle": "लर्निंग की खोजबीन करें",
    "exploreLearningHelpText": "सुनिश्चित नहीं हैं कि आप क्या खोज रहे हैं?",
    "exploreMore": "और खोजबीन करें।",
    "viewAllResults": "सभी <#> परिणाम देखें",
    "searchSubTitle": "कीवर्ड, शीर्षक या सामग्री आईडी का उपयोग करके पाठ्यक्रम खोजें।",
    "pinnedLearningTitle": "मेरी पिन की हुई लर्निंग्स",
    "landingSubTitle": "अपने सीखने के सफ़र की ज़िम्मेदारी लें। अपनी रुचियों का अन्वेषण करें — इसमें हर समय नए विषय और पाठ्यक्रम जोड़े जाते हैं।",
    "columnTitle": "शीर्षक",
    "columnDesc": "विवरण",
    "columnType": "प्रकार",
    "columnAddedToPlan": "प्लान में जोड़ें(जोड़े)",
    "columnComplete": "पूर्ण",
    "columnDetails": "विवरण",
    "additionalSearchFilters": "अतिरिक्त खोज फ़िल्टर्स",
    "tourBtnClose": "बंद करें",
    "tourBtnLast": "बंद करें",
    "resultsFor": " के लिए परिणाम",
    "resultsFortheFilter": "फ़िल्टर {Filter Name} के लिए परिणाम",
    "no": "नहीं",
    "results": "परिणाम",
    "courseTooltipTitle": "एक व्यक्तिगत शिक्षण आइटम जिसमें एक या अधिक गतिविधियाँ हो सकती हैं।<br />एक पाठ्यक्रम ऑनलाइन हो सकता है, जैसे कोई वीडियो या ई-लर्निंग, या कक्षा में हो सकता है।",
    "gatewayToolTipTitle": "नए कर्मचारियों के लिए, जो प्रबंधन के लिए नए हैं, और वे जीएम जो अपने ब्रांड के लिए नए हैं, के लिए डिजिटल संसाधन।",
    "speciallizationOrPathwayTooltipTitle": "किसी विशेष विषय पर केंद्रित पाठ्यक्रमों का एक सेट।",
    "taskTooltipTitle": "एक गतिविधि को पूरा करने के निर्देश (आमतौर पर नौकरी पर) और फिर चिह्नित करना कि आपने इसे पूरा कर लिया है।",
    "webSiteTooltipTitle": "मैरियट साइटों या बाहरी वेब साइटों सहित कोई भी वेब साइट।"
  },
  "dontShowAgain": "इसे दोबारा न दिखाएं",
  "deletionWarningEnabled": "हटाने की चेतावनी सक्षम",
  "deletionSelected": "चुने जाने पर, हर बार जब आप कोई सूचना हटाते हैं तो आपको एक चेतावनी दिखाई देगी।",
  "manageEmailNotifications": "ईमेल सूचनाएं प्रबंधित करें",
  "seeHowDLZContinue": "देखें कि कैसे DLZ आपको बढ़ते रहने में मदद करना जारी रख सकता है।",
  "Notification Center:Overdue": "आपके पास एक नया प्रशिक्षण है जो {Variable date ddmmyy} को होना था",
  "accesDenied": {
    "title": "आपकी सीखने की यात्रा तक पहुँच जारी है! ",
    "subtitle": "आपकी पहुँच बनाई जा रही है और आपकी ईआईडी सक्रिय होने के 1-2 दिन बाद उपलब्ध होनी चाहिए। यदि 2 दिन से अधिक समय हो गया है, तो कृपया mHUB सेवा केंद्र से संपर्क करें।"
  },
  "jfTour": {
    "title": "अब आप अपने ‘माई प्लान’ से सीधे अपने चयनित जॉब फंक्शन्स और अतिरिक्त कार्य उत्तरदायित्व (AWR) को देखने और संपादित करने में सक्षम हैं।"
  },
  "onboardingPlan": {
    "requiredDescription": "मेरे प्लान के तहत आपके द्वारा चुनी गई समयावधि में पूरा करने के लिए आवश्यक पाठ्यक्रम नीचे दिए गए हैं। जब आप अपनी नई भूमिका को नेवीगेट करते हैं तो अपने आवश्यक शिक्षण को पूरा करने के लिए समय निर्धारित करने के लिए अपने नेता के साथ काम करें। आप विभिन्न विषयों के बारे में जानेंगे, इस सीखने के रास्ते में अपने किसी भी प्रश्न के लिए अपने नेता और साथियों तक पहुँचना सुनिश्चित करें।   ",
    "basicsDescription": "हम जानते हैं कि सीखने को काफ़ी कुछ है और हमने जॉब पर आपके शुरुआती दिनों के लिए कुछ बेसिक्स (बुनियादी बातें) चुनी हैं। ये बिंदु आपके ओरिएन्टेशन्स (शुरुआती प्रशिक्षण), ब्रांड ऑनबोर्डिंग (परिचय) या आपकी फ़्रेंचाइज़ प्रबंधन कंपनी से मिलने वाली जानकारी के पूरक हो सकते हैं। ये हमारे साथ आपके पूरे कैरियर में बेहतरीन संसाधन होंगे और टीम के नए सदस्यों को लाने में मददगार होंगे।",
    "recommendedDescription": "आपके पहले 90 दिनों के बाद, आपको अतिरिक्त पाठ्यक्रम मिलेंगे जिनकी अनुशंसा आपके प्रबंधक ने की है। आप उन विषयों की भी खोजबीन कर सकते हैं जिनमें आपकी रुचि है। अभी के लिए, अपनी मूलभूत शिक्षा पर ध्यान दें। ",
    "day1": "दिन 14 तक देय है",
    "day2": "दिन 30 तक देय है",
    "day3": "दिन 60 तक देय है",
    "day4": "दिन 90 तक देय है",
    "day5": "90 दिनों के बाद देय है "
  },
  "onboardingLanding": {
    "gettingStartedDescription": "Digital Learning Zone पेशेवर सीखने और विकास के लिए आपका शुरुआती बिंदु है। नीचे आपको आरंभ करने में मदद करने के लिए क्यूरेट की गई (चयनित, व्यवस्थित और देखभाल की गई) सामग्री मिलेगी। आपका सारा अध्ययन हमेशा मेरे प्लान के तहत मिल सकता है।  ",
    "overallLearningProgress": "लर्निंग (सीखने) की समग्र प्रगति",
    "learningProgressLabel": "लर्निंग (सीखने) की प्रगति",
    "learningProgressDescription": "यहां बताया गया है कि आप अपनी आवश्यक लर्निंग के माध्यम से कैसे प्रगति कर रहे हैं। इस प्रगति पट्टी में आपके सभी आवश्यक लर्निंग की स्थिति शामिल है। अच्छा काम जारी रखें!"
  },
  "poc": {
    "dlz": "यह सीखने की क्रिया पूरी होने के लिए, प्रबंधकों या लर्निंग कोऑर्डिनेटरों को एमएचयूबी में इस लर्निंग को पूरा करने के लिए चिह्नित करना होगा। इसे कैसे करना है इसके बारे में विवरण कार्यान्वयन मार्गदर्शिका में पाया जा सकता है।",
    "mhub": "फ्रेंचाइजी:  यदि आप अपने स्वयं के बुनियीदी सफाई कार्यक्रम का उपयोग करने का विकल्प चुनते हैं, तो कृपया जब आप समापन चिह्नित करें, कार्यक्रम का नाम और विवरण टिप्पणी अनुभाग में शामिल करें"
  },
  "Recommended Courses ": "आपके पहले 90 दिनों के बाद, आपको अतिरिक्त पाठ्यक्रम मिलेंगे जिनकी अनुशंसा आपके प्रबंधक ने की है। आप उन विषयों की भी खोजबीन कर सकते हैं जिनमें आपकी रुचि है। अभी के लिए, अपनी मूलभूत शिक्षा पर ध्यान दें। ",
  "survey": {
    "v2": {
      "weValueButtonText": "सर्वेक्षण ले",
      "lbiIntro": "लक्जरी बिहेवियर चेक-इन में आपका स्वागत है!",
      "lbiDescription": `<p>नीचे आपको कुछ छोटे परिदृश्यों को पढ़ने के लिए कहा जाएगा, जो आपको अपनी संपत्ति पर मिल सकते हैं। कृपया प्रत्येक परिदृश्य को पढ़ें और चुनें कि आप प्रत्येक स्थिति पर कैसे प्रतिक्रिया देंगे। जब आप जवाब दें तो निम्नलिखित बातों को ध्यान में रखें:</p>
      <ul>
          <li>
              <p>आपको चुनने के लिए एक से अधिक उत्तर सही लग सकते हैं। उस प्रतिक्रिया को चुनें जिसे आप सबसे अधिक संभावना है कि आप काम पर देंगे।</p>
          </li>
          <li>
              <p>मान लें कि कोई संचार अवरोध नहीं हैं। आपके द्वारा बोली जाने वाली भाषा वैसी ही होती है, जैसी कि परिदृश्य में अतिथि या सहयोगी द्वारा बोली जाने वाली भाषा होती है।</p>
          </li>
          <li>
              <p>परिदृश्य आपको उस भूमिका की तुलना में खुद को एक अलग कार्य भूमिका में कल्पना करने के लिए कह सकता है जिसमें आप अभी हैं। एक लक्जरी आतिथ्य पेशेवर के रूप में अपने अनुभव के आधार पर, अपने सर्वोत्तम ज्ञान के लिए प्रश्न का उत्तर दें।</p>
          </li>
      </ul>
      <p><br></p>
      <p>कृपया ध्यान दें: इस चेक-इन में आपकी भागीदारी पूरी तरह से स्वैच्छिक है (आप भाग न लेने का चयन कर सकते हैं), और आपके उत्तर पूरी तरह से गोपनीय होंगे। इस चेक-इन के परिणाम लोगों के समूहों के लिए रिपोर्ट किए जाएंगे, न कि विशिष्ट व्यक्तियों के लिए। अनुसंधान टीम द्वारा व्यक्तिगत प्रतिक्रियाओं का मूल्यांकन किया जाएगा और दूसरों के प्रबंधकों या नेताओं के साथ साझा नहीं किया जाएगा।</p>
      <p>इस चेक को पूरा करके आप अपनी व्यक्तिगत प्रतिक्रियाओं और डेटा (जैसे सेवा की अवधि, लिंग, नौकरी का शीर्षक) के संग्रह और प्रसंस्करण के साथ-साथ Marriott Group को आपके व्यक्तिगत डेटा के सीमा पार हस्तांतरण के लिए सहमत हैं। यह Marriott एसोसिएट डेटा गोपनीयता कथन के अनुसार है, जिसे आप Marriott की डेटा गोपनीयता नीतियों पर संदर्भ के लिए अतिरिक्त जानकारी और संपर्क भी पा सकते हैं।</p>
      `,
      "checkin": {
        q1Title: "आप लॉबी से हो कर गुजर रहे हैं और तीन छोटे बच्चों के साथ एक परिवार को नोटिस करते हैं। परिवार के पास कई बैग हैं, लंबे दिन की यात्रा से थका हुआ दिखता है, और बच्चे रो रहे हैं और स्पष्ट रूप से बेचैन हैं। आप क्या करेंगे?",
        q2Title: "आपने अभी-अभी अपने लिए नए क्षेत्र में एक संपत्ति में शुरूआत की है। एक अतिथि क्षेत्र में करने के लिए चीजों के लिए सुझाव मांगता है। अतिथि ने कई गाइडबुक पढ़ी हैं लेकिन कुछ और व्यक्तिगतकृत की तलाश में हैं। आप क्या करेंगे? ",
        q3Title: "आप लॉबी से हो कर गुजर रहे हैं जब आप एक अतिथि को फोन पर बात करते हुए सुनते हैं और आपको पता चलता है कि हवाई अड्डे के लिए उनकी कार सेवा देर से चल रही है। आपकी संपत्ति में या तो हाउस  कार नहीं है या यह अनुपलब्ध है। आप क्या करेंगे? ",
        q4Title: "आप अपने प्रबंधक द्वारा आपको सौंपे गए एक अत्यावश्यक कार्य को पूरा करने के लिए लॉबी से हो कर गुजर रहे हैं। आप देखते हैं कि एक सहकर्मी एक ऐसे कार्य को पूरा करने के लिए संघर्ष कर रहा है, जो आम तौर पर दो लोगों द्वारा पूरा किया जाता है। आप क्या करेंगे? ",
        q5Title: "आप होटल के रेस्तरां में एक मेजबान हैं। एक अतिथि अपनी आरक्षित टेबल की प्रतीक्षा करते समय मेनू देखने के लिए कहता है। मेनू को देखने के बाद, अतिथि निराश हो जाता है क्योंकि होटल रेस्तरां के बारे में किसी अन्य सहयोगी द्वारा उन्हें सुझाव दिया गया था, लेकिन मेनू में ऐसा कुछ भी नहीं है जो उनकी आहार आवश्यकताओं को पूरा करता है। आप क्या करेंगे?",
        q6Title: "भीड़ भरे पूल क्षेत्र में मेहमानों का एक बड़ा समूह है, जो एक साथ सीटें खोजने में असमर्थ थे। नतीजतन, उन्होंने अपने तौलिए और व्यक्तिगत सामान को इस तरह से रखा है कि एक मुख्य पैदल मार्ग को अवरुद्ध कर दिया है, जिससे पूल क्षेत्र तक पहुंचने की कोशिश करने वाले मेहमानों और सहयोगियों के लिए खतरा पैदा हो रहा है और व्यवधान पैदा हो रहा है। क्षेत्र को साफ़ करने की आवश्यकता है, लेकिन आप समूह को परेशान नहीं करना चाहते हैं। आप क्या करेंगे?",
        q1R1Title: "उनके सामान के साथ परिवार की मदद करें, उन्हें चेक-इन डेस्क की दिशा में इंगित करें, और परिवार के अनुकूल गतिविधियों के साथ कुछ आराम, आस-पास के स्थानों का सुझाव दें। संपत्ति की पारिवारिक सुख-सुविधाओं का उल्लेख करें।",
        q1R2Title: "_उनके सामान और परिवार को चेक-इन करने के लिए मार्गदर्शन में मदद करने के लिए पेशकश करें। फिर, माता-पिता के लिए आरामदायक सेवा या ट्रीट और उनके कमरे में बच्चों के लिए मनोरंजक गतिविधियों की व्यवस्था करें।",
        q1R3Title: "माता-पिता को चेक-इन करने के लिए मार्गदर्शन करें, फिर जब माता-पिता चेक-इन कर रहे हों, तब व्यक्तिगत रूप से बच्चों को स्नैक्स के साथ सीटिंग एरिया में ले जाएं। फ्रंट डेस्क पर माता-पिता को उनके आराम के लिए संपत्ति में खेल के क्षेत्रों और बच्चों की गतिविधियों के बारे में सूचित करें।",
        q1R4Title: "त्वरित चेक-इन प्रक्रिया के लिए परिवार को चेक-इन डेस्क की ओर इंगित करें। माता-पिता को बच्चों के अनुकूल क्षेत्रों और सेवाओं का उल्लेख करें ताकि बच्चे सुखद प्रवास का आनंद ले सकें।",
        q2R1Title: "अतिथि से पूछें कि वे उन्हें किस चीज में आनंद आता है, फिर उनके लिए मजेदार चीजों की एक सूची बनाने के लिए एक सहकर्मी के साथ टीम बनाएं। इसके अतिरिक्त, पास के एक अच्छे रेस्तरां का सुझाव दें।",
        q2R2Title: "आतिथि से बात करें कि उन्हें क्या पसंद है। सिर्फ उनके लिए मजेदार चीजों की एक विशेष सूची बनाने के लिए एक सहकर्मी के साथ काम करें। इसके अतिरिक्त, अतिथि के Bonvoy प्रोफ़ाइल की जांच करें और उनके कमरे में एक व्यक्तिगत ट्रीट भेजें।",
        q2R3Title: "अब तक इस क्षेत्र में करने के लिए अपनी कुछ पसंदीदा चीजों को साझा करके इसे व्यक्तिगत बनाएं। अपने अनुभवों के बारे में अतिरिक्त विवरण साझा करके अपने हट कर सुझाव दें। फिर, उनके कमरे में एक स्वादिष्ट स्थानीय ट्रीट भेजें।",
        q2R4Title: "आतिथि को बताएं कि आप क्षेत्र में नए हैं और आपके पास बहुत सारे विचार नहीं हैं। माफी माँगें और सुझाव दें कि वे आपके सहकर्मी से बात करें, जो अधिक जानता है। फिर, पूछें कि क्या वे उनके कमरे में भेजे गए स्थानीय ट्रीट को पसंद करेंगे।",
        q3R1Title: "जबकि अतिथि अभी भी फोन पर हैं, अपने या अपने सहकर्मी के निजी वाहन को संपत्ति के सामने तक ले कर आएं  और अतिथि को बताएं कि आप उन्हें व्यक्तिगत रूप से उनके हवाई अड्डे पर ले जाएंगे।",
        q3R2Title: "जबकि अतिथि अभी भी फोन पर हैं, संपत्ति-अनुमोदित कार सेवाओं और फोन नंबरों की एक सूची एक साथ रखें। एक बार जब मेहमान का फोन पूरा हो जाता है, तो उन्हें सूची पेश करें और परिवहन की व्यवस्था करने में मदद करने की पेशकश करें।",
        q3R3Title: "अतिथि के फोन बंद होने तक प्रतीक्षा करें और उन्हें बताएं कि आपकी संपत्ति परिवहन सेवाओं की पेशकश नहीं करती है, लेकिन आपको अन्य परिवहन विकल्पों को समन्वित करने में मदद करने में खुशी होगी। ",
        q3R4Title: "जबकि अतिथि अभी भी फोन पर है, अतिथि के लिए वैकल्पिक परिवहन खोजें। एक बार जब वे फोन पूरा कर लें, तो अतिथि को बताएं कि आपने हवाई अड्डे पर उनके परिवहन की व्यवस्था की है और कार जल्द ही आ जाएगी।",
        q4R1Title: "अपने सहकर्मी के पास जाएं और कहें कि आप उनकी मदद करेंगे, लेकिन आपको पहले अपना जरूरी काम पूरा करना होगा। उनकी मदद करने के लिए जल्दी से वापस आने का वादा करें। अपने प्रबंधक के साथ स्थिति पर चर्चा करना सुनिश्चित करें।",
        q4R2Title: "पहले, आपको सौंपे गए कार्य को संभालने के लिए जल्दी से किसी अन्य सहकर्मी को खोजें। फिर, तुरंत अपने सहकर्मी की मदद करें, जिसे मदद की जरूरत है। पूरा होने पर, अपने प्रबंधक को सूचित करें कि आपको अपना कार्य पूरा करने के लिए कोई और मिला ताकि आप अपने सहकर्मी की मदद कर सकें। ",
        q4R3Title: "रुकें और अपने सहकर्मी की तुरंत मदद करें। मदद करने के बाद, अपने आवंटित किए गए कार्य पर लौटें, फिर अपने प्रबंधक को बताएं कि आपने अपने सहकर्मी की मदद करने के लिए अपना कार्य रोक दिया है।",
        q4R4Title: "मदद की ज़रूरत वाले अपने सहकर्मी के पास जाएं और समझाएं कि आप किसी अत्यावश्यक कार्य में संलग्न हैं। दोनों कार्यों को रोकें, अपने दोनों कार्यों को संयुक्त रूप से हल करने का एक तरीका मंथन करें, और फिर दोनों कार्यों को एक साथ पूरा करें।",
        q5R1Title: "अतिथि से माफी माँगें और उन्हें बताएं कि आपसे प्रबंधक मिलेंगे। स्थिति को समझाना सुनिश्चित करें ताकि प्रबंधक अतिथि को उनकी भोजन आवश्यकताओं में मदद कर सकें।",
        q5R2Title: "अतिथि से माफी माँगें और उनकी भोजन वरीयताओं के बारे में पूछें। बस उनके लिए एक विशेष पकवान बनाने के लिए रसोई टीम से बात करें। इसके अलावा, गलती सुधार के लिए एक कंप्लीमेंटरी ऐपेटाइज़र या पेय दें।",
        q5R3Title: "माफी माँगें और उस अतिथि को बताएं जिसे आप समझते हैं। आरक्षण रद्द करने के बजाय, शेफ से बात करने की पेशकश करें और देखें कि क्या वे अतिथि के लिए कुछ खास बना सकते हैं। उन्हें परेशानी से छुटकारे के लिए  एक कंप्लीमेंटरी ट्रीट या छूट दें।",
        q5R4Title: "माफी माँगें और अतिथि को बताएं कि आप उन्हें पास के एक रेस्तरां में एक टेबल बुक करने में मदद कर सकते हैं जिसमें उनकी पसंद का भोजन मौजूद है। अतिरिक्त जानकारी प्रदान करें या यदि आवश्यक हो तो वहां पहुंचने में मदद करें।",
        q6R1Title: "जगह की कमी के लिए मेहमानों से माफ़ी मांगें और उन्हें बताएं कि जब तक वे रास्ते में चल रहे अन्य लोगों का ध्यान रखते हैं, तब तक उनका वहीं रहने के लिए स्वागत है। ",
        q6R2Title: "कृपया मेहमानों को बताएं कि वे पैदल मार्ग को अवरुद्ध नहीं कर सकते हैं, और उन्हें कोई अन्य स्थान ढूंढना होगा या जगह खुलने तक इंतजार करना होगा। इस बीच, अपने सहकर्मियों को उनके उपयोग के लिए बाहर बॉलरूम से अतिरिक्त बैठने की व्यवस्था लाने के लिए नियुक्त करें। ",
        q6R3Title: "मेहमानों को बताएं कि वे अपनी  मौजूदा जगह पर नहीं रह सकते हैं और अतिरिक्त बैठने की जगह  खोजनेके दौरान उन्हें अस्थायी रूप से जाना होगा। इस बीच उन्हें बार/रेस्तरां में स्थान दें। ",
        q6R4Title: "बैठने के मुद्दे के लिए मेहमानों से माफी माँगें। पूछें कि क्या वे अपने सामान को एक साथ रख सकते हैं ताकि दूसरों के चलने के लिए एक छोटा रास्ता बनाया जा सके। उनकी मदद करने की पेशकश करें, यह सुनिश्चित करें कि हर कोई आराम से है। उन्हें उनकी असुविधा के लिए बार/रेस्तरां से एक विशेष ट्रीट भेजें।"
      }
    }
  },
};