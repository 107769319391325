import { decorate, observable, computed, action } from 'mobx';
import lookupStore from './LookupStore';
import userStore from './UserStore';
import Api from '../api/CMDBApi';
import _ from 'lodash';
import moment from 'moment';
import { cmdbURL } from '../config';

// const formatDescription = course => {
//   if (!_.isEmpty(course.description) && _.isEmpty(course.shortDescription)) {
//     return course.description;
//   } else if (_.isEmpty(course.description) && !_.isEmpty(course.shortDescription)) {
//     return course.shortDescription;
//   } else {
//     return `${course.description}\\n\\n${course.shortDescription}`;
//   }
// };

const sourcesToSource = {
  Accenture: 'External Website',
  Adobe: 'External Website',
  'American Hotel & Lodging Association':  'External Website',
  Apple: 'External Website',
  'Ask a Manager': 'External Website',
  'Business Insider': 'External Website',
  'Career Innovation': 'External Website',
  CNBC: 'External Website',
  Codecademy: 'External Website',
  Coursera: 'External Website',
  Databricks: 'External Website',
  Duolingo: 'External Website',
  eCornell: 'External Website',
  edX: 'External Website',
  Entrepreneur: 'External Website',
  'Fast Company': 'External Website',
  Forbes: 'External Website',
  'General Assembly': 'External Website',
  getAbstract: 'External Website',
  Google: 'External Website',
  'Harvard Business Review': 'External Website',
  IBM: 'External Website',
  'LinkedIn Learning': 'External Website',
  Mango: 'External Website',
  meQuilibrium: 'External Website',
  Microsoft: 'External Website',
  myLearning: 'Learning in mHUB',
  'New York Times': 'External Website',
  'Rosetta Stone': 'External Website',
  Rouxbe: 'External Website',
  SalesForce: 'External Website',
  SharePoint: 'External Website',
  'SkillSoft / Percipio':  'External Website',
  'TakeCare GROW': 'Marriott-LD.com',
  TED: 'External Website',
  Udemy: 'External Website',
  'University of Maryland':  'External Website',
  Wellbeats: 'External Website',
  YouTube: 'External Website'
}

let rawAllCourses = [];

const transformSource = source => {
  if (_.isEmpty(source)) {
    return 'Learning in mHUB';
  }

  return sourcesToSource[source] || source;
};

const getCourseLabels = (course) => {
  const labels = [];
  const allLabels = lookupStore.exploreTagsById;
  const groupKeys = ['mhubTopicCategories', 'skills', 'leadership', 'brandTopics', 'languageLearnings', 'disciplines', 'workingRemotely'];

  groupKeys.forEach((groupKey) => {
    if (course[groupKey]) {
      course[groupKey].forEach((id) => labels.push(allLabels[groupKey][parseInt(id, 10)]))
    }
  });

  // D20-122
  if (course.discipline) {
    labels.push(course.discipline);
  }

  const categories = _.compact((course.jobFunctionValues || course.jobFunctions || []).map(jf => (lookupStore.jobFunctions.find(j => j.id.toString() === jf) || {}).categoryId));

  (categories || []).forEach(jfc => {
    const found = lookupStore.jobFunctionCategories.find(jfc1 => jfc1.id === jfc);
    if (found && found.name !== 'Above Property and HQ') {
      labels.push(found.name);
    }
  });

  return _.uniq(_.compact(labels));
};

const getCourseLabelsNoJFs = (course) => {
  const labels = [];
  const allLabels = lookupStore.exploreTagsById;
  const groupKeys = ['mhubTopicCategories', 'skills', 'leadership', 'brandTopics', 'languageLearnings', 'disciplines', 'workingRemotely'];

  groupKeys.forEach((groupKey) => {
    if (course[groupKey]) {
      course[groupKey].forEach((id) => labels.push(allLabels[groupKey][parseInt(id, 10)]))
    }
  });

  // D20-122
  if (course.discipline) {
    // labels.push(course.discipline);
  }

  const categories = _.compact((course.jobFunctionValues || course.jobFunctions || []).map(jf => (lookupStore.jobFunctions.find(j => j.id.toString() === jf) || {}).categoryId));

  (categories || []).forEach(jfc => {
    const found = lookupStore.jobFunctionCategories.find(jfc1 => jfc1.id === jfc);
    if (found && found.name !== 'Above Property and HQ') {
      // labels.push(found.name);
    }
  });

  return _.uniq(_.compact(labels));
};

class CourseStore {
  rawCourses = [];
  rawExploreCourses = [];
  planCourseNameFilter = '';
  exploreCourseNameFilter = '';
  contentGroups = [];
  allRawCourses = [];

  setPlanCourseNameFilter(planCourseNameFilter) {
    this.planCourseNameFilter = planCourseNameFilter;
  }

  setExploreCourseNameFilter(exploreCourseNameFilter) {
    this.exploreCourseNameFilter = exploreCourseNameFilter;
  }

  getTransformedCourse(course) {
    let url = course.url;
    if (course.completionType === 'Mark complete on launch') {
      url = `${cmdbURL}/app/resource/launch?application=dlp&resourceId=${course.id}`;
    }

    const startNew = moment('6/10/2020', 'M/D/YYYY');

    const found = ((this && this.allRawCourses) || []).find(c => c.id === course.id && c.type === course.type);
    if (found) {
      course = found;
    }

    if (course.type === 'DLP Learning Pathway' || course.type === 'DLP Course Bundle' || course.type === 'DLZ Learning Pathway' || course.iptStatus) {
      return {
        id: course.id,
        title: course.name,
        description: course.description,
        fullDescription: course.description,
        shortDescription: course.shortDescription,
        duration: course.duration,
        completionType: '',
        labels: getCourseLabels(course),
        labelsNoJFs: getCourseLabelsNoJFs(course),
        jobFunctionCategories: _.compact((course.jobFunctionValues || []).map(jf => (lookupStore.jobFunctions.find(j => j.id.toString() === jf) || {}).categoryId)),
        isCompleted: course.meta && course.meta.isCompleted, //TODO maybe a q for mike
        dueDate: course.dueDate, //TODO maybe a q for mike
        type: course.type,
        url,
        status: course.iptStatus,
        courseOrderMeta: course.courseOrderMeta,
        courseNumber: course.sourceId,
        isRequired: (course.assignmentTypes.includes('1') && course.iptStatus === 'Active - Assigned'),
        isRecommended: course.assignmentTypes.includes('2'),
        isManagerRecommended: course.meta && course.meta.isManagerRecommended, //TODO maybe a q for mike
        recommendationType: course.meta && course.meta.recommendationType,
        completedDate: course.meta && course.meta.completedOn, //TODO maybe a q for mike
        recertification: (lookupStore.recertifications.find(r => r.id.toString() === course.recertification) || {}).name,
        uuid: course.uuid,
        sourceId: course.sourceId,
        tuition: !_.isEmpty(course.tuition) ? `${course.tuition} USD` : course.tuition,
        excludeFromSearch: course.excludeFromSearch || false
      };
    } else {
      return {
        id: course.id,
        title: course.title,
        duration: course.duration,
        completionType: course.completionType,
        description: course.shortDescription || (typeof course.description === 'string' && course.description.split('.')[0] + '.'),
        fullDescription: course.description,
        shortDescription: course.shortDescription,
        labels: getCourseLabels(course),
        labelsNoJFs: getCourseLabelsNoJFs(course),
        isCompleted: course.meta && course.meta.isCompleted,
        dueDate: course.dueDate,
        supportedLangs: lookupStore.getLanguageLabels(course.languages.map(l => parseInt(l, 10)), true),
        audience: course.audience,
        source: transformSource(course.source),
        type: course.type,
        status: course.status,
        url,
        jobFunctionCategories: _.compact(course.jobFunctions.map(jf => (lookupStore.jobFunctions.find(j => j.id.toString() === jf) || {}).categoryId)),
        courseNumber: course.sourceId,
        isRecommended: course.assignmentTypes.includes('2'),
        isManagerRecommended: course.meta && course.meta.isManagerRecommended,
        recommendationType: course.meta && course.meta.recommendationType,
        isRequired: (course.assignmentTypes.includes('1') && course.status === 'Active - Assigned') || course.isRequiredAdditionalCourse,
        completedDate: (course.meta && course.meta.completedOnDate) ? moment(course.meta.completedOnDate).format('DD/MMM/YYYY') : null,
        recertification: (lookupStore.recertifications.find(r => r.id.toString() === course.recertification) || {}).name,
        uuid: course.uuid,
        program: _.compact(course.programs.map(p1 => (lookupStore.programs.find(p => p.id === parseInt(p1, 10)) || {}).name)).join(', '),
        isNewCourse: moment(course.date_created).isAfter(startNew),
        sourceId: course.sourceId,
        tuition: !_.isEmpty(course.tuition) ? `${course.tuition} USD` : course.tuition,
        excludeFromSearch: course.excludeFromSearch || false
      };
    }
  }

  async getCourses() {
    const { data } = await Api.getCourses();

    const requiredCourses = data.filter(d => d.assignmentTypes.includes('1')).map(c => `${c.id}`);

    const filteredCourses = data.filter(d => {
      if (d.assignmentTypes.includes('2')) {
        return requiredCourses.indexOf(`${d.id}`) === -1;
      }
      return true;
    });

    this.rawCourses = filteredCourses;
    this.allRawCourses = data;
    rawAllCourses = data;
  }

  async dismissRecommendation(courseId) {
    await Api.dismissRecommendation(courseId);
    this.getCourses();
    alert('Recommendation Dismissed');
  }

  async getContentGroups() {
    const { data } = await Api.getContentGroups();
    const { allRawCourses } = this;

    this.contentGroups = data.filter(d => {
      let hasIncompletes = false;

      d.courseOrderMeta.forEach(courseId => {
        const found = allRawCourses.find(c => c.id === courseId);
        if (found && !found.meta.isCompleted) {
          hasIncompletes = true;
        }
      });

      return d.isActive && hasIncompletes;
    });
  }

  async getAllCourses() {
    this.allCoursesLoaded = false;
    const { data } = await Api.getAllCourses();
    const response = await Api.getAllLessons();
    const lpResponse = await Api.getAllLearningPathways();

    this.rawAllCourses = data;
    rawAllCourses = data;
    this.rawAllLessons = response.data;
    this.rawLearningPathways = lpResponse.data;

    this.allCoursesLoaded = true;
  }

  get allCourses() {
    const learningPathways = this.rawLearningPathways;
    const { jobFunctions, coursePlans } = lookupStore;
    const lessons = this.rawAllLessons;
    const resources = this.rawAllCourses;

    const getFunctionsForResource = resource => {
      const foundFunctions = [];
      _.each(learningPathways, l => {
        if (l.courseOrderMeta.indexOf(resource.id) !== -1) {
          foundFunctions.push(_.compact(l.jobFunctionValues.map(jf => {
            const name = (jobFunctions.find(j => parseInt(jf, 10) === j.id) || {}).name;
            if (name === undefined) {
              return null;
            }

            return name;
          })));
        }
      });

      return _.compact(_.flatten(_.uniq(foundFunctions)).filter(j => !_.isEmpty(j)));
    };

    if (!this.allCoursesLoaded) {
      return [];
    }

    return _.compact(resources.filter(r => r.programs.indexOf('6') !== -1 && r.status.indexOf('Active') === 0).map(r => ({
      subprogram: r.coursePlans.map(at => (coursePlans.find(a => a.id === parseInt(at, 10)) || {}).label),
      title: r.title,
      courseDescription: r.description,
      jobFunctionsHtml: getFunctionsForResource(r).join('<br/>'),
      lessons: lessons.filter(l => l.resourceId === r.id).map(l => l.name).join('<br/>'),
      url: r.url
    })));

  }

  async getExploreCourses() {
    this.loadingExploreCourses = true;
    const { data } = await Api.getExploreCourses();

    const { completionInfo } = userStore;
    this.rawExploreCourses = data;
    this.loadingExploreCourses = false;
  }

  setLoadingExploreCourses(loading) {
    this.loadingExploreCourses = loading;
  }

  // async getCourseCompletions() {
  //   const { data } = await Api.getCourseCompletions();
  // }

  get courses() {
    return this.rawCourses.map(this.getTransformedCourse);
  }

  get exploreCourses() {
    return this.rawExploreCourses.map(this.getTransformedCourse);
  }

  get percentComplete() {
    if (!this.requiredCourses) {
      return null;
    }
    return Math.round((this.requiredAndCompletedCourses.length / this.requiredCourses.length) * 100);
  }

  setPercentComplete() {
    const completionInfo = userStore.completionInfo;

    let totalAssignments = {};
    let completedAssignments = {};

    this.rawCourses.filter(r => r.assignmentTypes.includes('1')).forEach(course => {
      if (course.courseOrderMeta) {
        console.log([...course.courseOrderMeta]);
        console.log([...course.courseOrderMetaSourceIds]);
        course.courseOrderMeta.forEach(course => {
          totalAssignments[course] = true;
        });
        (course.courseOrderMetaSourceIds.filter(sourceId => sourceId !== "-") || []).forEach((sourceId, i) => {
          const found = completionInfo.find(comp => [`Lobster_${sourceId}`, sourceId].indexOf(comp.componentID) !== -1);
          if (found) {
            completedAssignments[course.courseOrderMeta[i]] = true;
          } else {
            console.log(course.id);
            // console.log(sourceId)
          }
        });
      } else {
        totalAssignments[course.id] = true;
        // if (!_.isEmpty(course.sourceId)) {
          if (_.isEmpty(course.recertification)) {
            const found = completionInfo.find(comp => course.sourceId ? ([`Lobster_${course.sourceId}`, course.sourceId].indexOf(comp.componentID) !== -1) : ([course.id].indexOf(comp.resourceId) !== -1));
            if (found) {
              completedAssignments[course.id] = true;
            } else {
              console.log(course);
            }
          } else {
            if (course.meta && course.meta.isCompleted) {
              completedAssignments[course.id] = true;
            } else {
              console.log(course);
            }
          }
        // }
      }

      console.log(_.difference(Object.keys(totalAssignments), Object.keys(completedAssignments)))

      // console.log(totalAssignments);
      // console.log(completedAssignments);
    });

    this.requiredCompletionData = {
      numCoursesComplete: Object.keys(completedAssignments).length,
      totalRequiredCourses: Object.keys(totalAssignments).length,
    }

    // console.log(this.requiredCompletionData);
  }

  get recommendedCourses() {
    let ordered = _.orderBy(this.courses.filter((course) => course.isRecommended || course.isManagerRecommended), c => {
      if (c.isCompleted) {
        return -2;
      }

      const isRecommended = _.get(c, 'isManagerRecommended', false);
      return isRecommended ? 1 : -1
    }, ['desc']); //.filter(c => !c.isCompleted);

    // if (userStore.canSeeTopline) {
    //   ordered = [toplineCourse].concat(ordered);
    // }
    return ordered;
  }

  get recommendedAndIncompleteCourses() {
    let ordered =  _.orderBy(this.courses.filter((course) => !course.isCompleted && (course.isRecommended || course.isManagerRecommended)), c => {
      if (c.isCompleted) {
        return -2;
      }

      const isRecommended = _.get(c, 'isManagerRecommended', false);
      return isRecommended ? 1 : -1
    }, ['desc']);

    // if (userStore.canSeeTopline) {
    //   ordered = [toplineCourse].concat(ordered);
    // }
    return ordered;
  }

  get requiredCourses() {
    return this.courses.filter((course) => course.isRequired && course.status === 'Active - Assigned');
  }

  get requiredAndIncompleteCourses() {
    return this.courses.filter((course) => course.isRequired && course.status === 'Active - Assigned' && !course.isCompleted);
  }

  get requiredAndCompletedCourses() {
    const courses = _.orderBy(this.courses.filter((course) => course.isRequired && course.status === 'Active - Assigned' && course.isCompleted), c => {
      if (c.completedDate) {
        return moment(c.completedDate, 'MMM/D/YYYY').unix();
      } else {
        return -1;
      }
    }, ['desc']);
    return courses;
  }

  get completedCourses() {
    return _.orderBy(this.courses.filter((course) => course.isCompleted), c => {
      if (c.completedDate) {
        return moment(c.completedDate, 'MMM/D/YYYY').unix();
      } else {
        return -1;
      }
    }, ['desc']);
  }

  getCourse(id) {
    return this.courses.find((course) => course.id === parseInt(id, 10));
  }

  get exploreCoursesByInterest() {
    const interests = userStore.profile.interests;

    //There may be a day where we need to compare IDs instead of strings
    //but current limitations on the backend (discipline on course object being a string
    //make this difficult to work with
    return interests.map((interest) => {
      let courses = [];
      courses = _.uniqBy(this.exploreCourses.filter((course) => course.labels.includes(interest.name)), u => u.title);

      const unfilteredCourseCount = courses.length;

      if (!_.isEmpty(this.exploreCourseNameFilter)) {
        courses = courses.filter(c => {
          const matchTitle = _.get(c, 'title', '').toLowerCase().indexOf(this.exploreCourseNameFilter.toLowerCase()) !== -1;
          
          let matchShortDesc = false;

          if (c.shortDescription) {
            matchShortDesc = c.shortDescription.toLowerCase().indexOf(this.exploreCourseNameFilter.toLowerCase()) !== -1;
          }

          let matchDesc = false;
          
          if (c.fullDescription) {
            matchDesc = c.fullDescription.toLowerCase().indexOf(this.exploreCourseNameFilter.toLowerCase()) !== -1;
          }
        
          return matchTitle || matchShortDesc || matchDesc;
        });
      }

      return {
        interestName: interest.name,
        forceShow: unfilteredCourseCount > 0,
        courses
      };
    });
  }

  async getRequiredCompletions() {
    // const { status, data } = await Api.getRequiredCompletions();
    // this.requiredCompletionDataLoaded = true;

    // if (data) {
      // this.requiredCompletionData = data[0];
    // } else {
      // console.error('Error while fetching user info. Status', status);
    // }
  }

  get totalRequiredCourses() {
    if (!this.requiredCompletionData && !this.requiredCompletionDataLoaded) {
      return null;
    } else if (!this.requiredCompletionData && this.requiredCompletionDataLoaded) {
      return this.requiredCourses.length;
    }

    return this.requiredCompletionData.totalRequiredCourses;
  }

  get completedRequiredCourse() {
    if (!this.requiredCompletionData) {
      return null;
    }

    return this.requiredCompletionData.numCoursesComplete;
  }

  async loadCommunicationsAssets() {
    this.communicationsLoaded = false;

    try {
      const { data } = await Api.getCommunicationsResources();
      this.communications = data;
      this.communicationsLoaded = true;
    } catch (e) {
      console.log(e);
    }
  }

  async addCourseToPlan(eid, courseId, resourceType) {
    await Api.addCourseToPlan(eid, courseId, resourceType);
  }

  async removeCourseFromPlan(eid, courseId, resourceType) {
    await Api.removeCourseFromPlan(eid, courseId, resourceType);
  }

  isInPlan(row) {
    const { addedToPlanLps = [], addedToPlanResources = []  } = userStore;

    console.log(rawAllCourses);

    const foundInResources = rawAllCourses.find(r => (
      r.id === row.id || 
      (!_.isEmpty(r.sourceId) && r.sourceId === row.sourceId)
    ));
    if (foundInResources) {
      return true;
    }

    if (row.resourceType === 'learning-pathways') {
      return addedToPlanLps.find(lp => parseInt(lp.resourceId) === parseInt(row.id)) != null
    } else {
      return addedToPlanResources.find(r => parseInt(r.resourceId) === parseInt(row.id)) != null
    }
  }

  async findCourses(ids) {
    return Api.findCourses(ids);
  }

  async findLearningPathways(ids) {
    return Api.findLearningPathways(ids);
  }
}

decorate(CourseStore, {
  rawExploreCourses: observable,
  rawCourses: observable,
  contentGroups: observable,
  rawAllCourses: observable,
  exploreCourses: computed,
  courses: computed,
  allCourses: computed,
  loadingExploreCourses: observable,
  allCoursesLoaded: observable,
  planCourseNameFilter: observable,
  exploreCourseNameFilter: observable,
  getCourses: action,
  getAllCourses: action,
  setPercentComplete: action,
  // getCourseCompletions: action,
  getCourse: action,
  getCoursesByInterest: action,
  getRequiredCompletions: action,
  percentComplete: computed,
  recommendedCourses: computed,
  requiredCourses: computed,
  requiredAndIncompleteCourses: computed,
  requiredAndCompletedCourses: computed,
  requiredCompletionData: observable,
  totalRequiredCourses: computed,
  completedRequiredCourse: computed,
  requiredCompletionDataLoaded: observable,
  communicationsLoaded: observable,
  communications: observable,
  loadCommunicationsAssets: action,
  addCourseToPlan: action,
  removeCourseFromPlan: action,
  isInPlan: action,
  findCourses: action,
  findLearningPathways: action
});

export default new CourseStore();
