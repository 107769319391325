export default {
  "banner": {
    "viewDetails": "Xem Chi tiết",
    "requiredCourses": "Các khóa học bắt buộc của bạn đến hạn vào",
    "days": "ngày"
  },
  "header": {
    "plan": "Kế hoạch của Tôi",
    "manager": "NGƯỜI QUẢN LÝ",
    "explore": "KHÁM PHÁ VIỆC HỌC TẬP",
    "reporting": "BÁO CÁO",
    "myTeam": "NHÓM CỦA TÔI",
    "title": "Digital Learning Zone"
  },
  "footer": {
    "plan": "KẾ HOẠCH CỦA TÔI",
    "manager": "NGƯỜI QUẢN LÝ",
    "explore": "KHÁM PHÁ VIỆC HỌC TẬP",
    "tagline": "Học tập trở nên dễ dàng. Bắt đầu từ bạn."
  },
  "welcome": {
    "hi": "Xin chào",
    "intro1": "Chào mừng đến với Digital Learning Zone! DLZ là nền tảng học tập cá nhân hóa, là điểm truy cập trung tâm để học tập tại Marriott. Tìm khóa đào tạo cần thiết cho vai trò của bạn và khám phá các cơ hội học tập để phát triển cá nhân và chuyên môn của bạn. Làm chủ hành trình học tập và bắt đầu con đường phát triển kỹ năng của bạn.",
    "intro2": "Trước khi bắt đầu, chúng tôi cần biết một vài điều về bạn.",
    "letsGo": "BẮT ĐẦU NÀO",
    "preferredLanguage": "Ngôn ngữ ưu tiên của bạn là gì?",
    "step": "BƯỚC",
    "of": "/",
    "previous": "TRƯỚC",
    "next": "TIẾP THEO",
    "startDate": "Bạn bắt đầu làm việc tại Marriott International hoặc Starwood từ khi nào?",
    "selectYear": "CHỌN NĂM",
    "selectMonth": "CHỌN THÁNG",
    "selectDay": "CHỌN NGÀY",
    "selectFunction": "CHỌN CHỨC NĂNG",
    "selectInterests": "CHỌN SỞ THÍCH",
    "chooseYear": "CHỌN MỘT NĂM",
    "chooseMonth": "CHỌN MỘT THÁNG",
    "chooseDay": "CHỌN MỘT NGÀY",
    "whatDo": "Chức năng Công việc của bạn là gì",
    "selected": "ĐÃ CHỌN",
    "searchFunctions": "Tìm kiếm tất cả các chức năng",
    "searchInterests": "Tìm kiếm Tất cả Sở thích",
    "additionalJobFunctionsLabel": "Cho chúng tôi biết nhiều hơn về công việc của bạn",
    "managementLevelLabel": "Cấp bậc nhân viên của bạn là gì?",
    "preferredBrandLabel": "Nếu bạn làm việc cho nhiều hơn một thương hiệu, vui lòng chọn tất cả đáp án phù hợp với bạn.",
    "additionalJobFunctionsLabelExtra": "Vui lòng xem xét các đáp án sau và CHỌN ÍT NHẤT MỘT trách nhiệm công việc bổ sung.",
    "searchAdditionalFunctions": "Tìm kiếm tất cả các trách nhiệm công việc bổ sung",
    "highGuestContactLabel": "Bạn có thường xuyên tiếp xúc với khách hàng không?",
    "yes": "Có",
    "no": "Không "
  },
  "landing": {
    "started": "Cùng bắt đầu nào",
    "welcomeBack": "Chào mừng bạn quay trở lại,",
    "greatWork": "Làm tốt lắm",
    "requiredCourses": "Các Khóa học Bắt buộc của Bạn",
    "requiredCoursesDesc": "Vui lòng hoàn thành các khóa học trước ngày đến hạn.",
    "myPlan": "Kế hoạch của Tôi",
    "courses": "Khóa học",
    "courseComplete": "KHÓA HỌC HOÀN THÀNH",
    "viewMore": "XEM THÊM CÁC KHÓA HỌC BẮT BUỘC",
    "viewMoreBtn": "XEM THÊM CÁC KHÓA HỌC",
    "showing": "HIỂN THỊ",
    "of": "/",
    "heroTitle": "Học điều mới. Nâng cao khả năng của bản thân. Đạt được các kỹ năng áp dụng vào thế giới của bạn.",
    "heroParagraph1": "Dễ dàng theo dõi tất cả các khóa học bắt buộc của bạn và nhận thông báo thời gian thực để hoàn thành các khóa học này.",
    "heroParagraph2": "Tận hưởng trải nghiệm học tập tùy chỉnh dựa trên công việc và sở thích của bạn.",
    "recommendedCoursesTitle": "Các Khóa học được Đề xuất",
    "recommendedCoursesDescNoContent": "Bạn không có bất kỳ khóa học được đề xuất nào tại thời điểm này. Vui lòng xem bên dưới để khám phá các cơ hội học tập khác.",
    "recommendedCoursesDesc": "Xem những khóa học mà người quản lý hoặc chuyên gia nhân sự (HR) của bạn đã chọn cho bạn.",
    "recommendedCoursesBtn": "XEM CÁC KHÓA HỌC ĐƯỢC ĐỀ XUẤT",
    "completedCoursesBtn": "XEM CÁC KHÓA HỌC ĐÃ HOÀN THÀNH",
    "exploreTitle": "Hãy luôn tò mò.",
    "exploreDesc": "Chọn chủ đề bạn quan tâm.",
    "jobFunctions": "Kỷ luật",
    "growthSkills": "Kỹ năng Phát triển",
    "leadershipSkills": "Kỹ năng Lãnh đạo",
    "brands": "Các thương hiệu",
    "languageLearning": "Học Ngoại ngữ",
    "exploreBtn": "XEM CÁC KHÓA HỌC",
    "managerRecommendation": "ĐỀ XUẤT CỦA NGƯỜI QUẢN LÝ",
    "learningRecommendation": "ĐỀ XUẤT VỀ HỌC TẬP",
    "marriottRecommendation": "ĐỀ XUẤT CỦA MARRIOTT",
    "completedCourses": "Bạn đã hoàn thành tất cả các khóa học bắt buộc của mình!",
    "investTitle": "Làm chủ Hành trình Học tập của Bạn",
    "investParagraph1": "Chúng tôi nhận thấy hoàn cảnh hiện tại đang có nhiều thách thức khác nhau - các DLZ ở đây là một nguồn tài nguyên cho bạn. Cho dù bạn muốn giữ kỹ năng hiện tại của bạn, tìm hiểu điều gì đó mới, quản lý tâm trạng căng thẳng của bạn, hoặc tìm hiểu để làm việc trên một nhóm ảo. Chúng tôi đã thu thập tài nguyên để giúp đỡ bạn.",
    "updatedTopics": "Những chủ đề được cập nhật",
    "workingRemotely": "Những nguồn tài nguyên nhóm ảo",
    "relatedCoursesNew": "Nhấp vào bên dưới để cập nhật sở thích của bạn, như chúng tôi đã thêm các chủ đề và khóa học mới.",
    "relatedCourses": "Vui lòng nhấp vào bên dưới để chọn sở thích của bạn.",
    "notificationText": "Chương trình đào tạo mHUB mới có sẵn. Chọn mHUB trong Explore Learning.",
    "mHubHeroTitle1": "Đơn giản. Thông minh. An toàn. Giới thiệu mHUB.",
    "mHubHeroManagedText1": "mHUB tích hợp các công cụ để học tập, phát triển nghề nghiệp, chiết khấu Explore và nhiều nữa vào một nền tảng dễ sử dụng.",
    "mHubHeroFranchisedText1": "mHUB tích hợp các công cụ để học tập, chiết khấu Explore và nhiều nữa vào một nền tảng dễ sử dụng.",
    "mHubHeroVisitLink": "Nhấp vào đây để xem thêm các chi tiết hoặc tập tin đính kèm chương trình.",
    "myGateway": "My Gateway",
    "gatewayReview": "Vui lòng xem lại và hoàn thành.",
    "gatewayProgress": "Tiến độ Gateway",
    "complete": "HOÀN THIỆN",
    "reviewContent": "Xem Nguồn lực",
    "gatewayCardTitle": "Các Nguồn lực Gateway",
    "gatewayCardDesc": "Truy cập Kiến thức cơ bản của quý vị trong năm đầu tiên và hơn thế nữa.",
    "gatewayCardDescGms": "Truy cập Kiến thức cơ bản, Mục Hành động, các Nguồn lực Thương hiệu và Nguồn lực Kỷ luật của quý vị trong năm đầu tiên và hơn thế nữa.",
    "gatewayCardDescNew": "Truy cập Kiến thức cơ bản của quý vị cho năm đầu tiên trong vai trò mới của mình.",
    "gatewayCardDescGmsNew": "Truy cập Kiến thức cơ bản, Mục Hành động, các Nguồn lực Thương hiệu và Nguồn lực Kỷ luật của quý vị cho năm đầu tiên trong vai trò mới của mình và hơn thế nữa."
  },
  "courseBundle": {
    "completeBy": "NGƯỜI HOÀN THÀNH",
    "remainingCourses": "Các Khóa học Còn lại",
    "completedCourses": "Các Khóa học đã Hoàn thành",
    "remainingLabel": "Có {courseCount} khóa học trên lộ trình học tập này.",
    "recommendButton": "Đề xuất Cho Nhóm",
    "startCourse": "Bắt đầu Khóa học",
    "durationMins": "PHÚT",
    "completed": "Đã hoàn thành",
    "back": "Quay lại",
    "additionalLangs1": "Ngoài việc được cung cấp bằng tiếng Anh, khóa học này còn được cung cấp bằng",
    "additionalLangs2": "Vui lòng chọn ngôn ngữ ưu tiên của bạn từ menu thả xuống sau khi khởi chạy khóa học.",
    "targetAudience": "Khán giả mục tiêu",
    "all": "Tất cả",
    "globally": "toàn cầu",
    "source": "Nguồn",
    "courseType": "Loại Khóa học",
    "course": "Khóa học"
  },
  "profile": {
    "save": "Lưu",
    "edit": "Chỉnh sửa",
    "brand": "Thương hiệu",
    "brands": "Các thương hiệu",
    "additionalWork": "Tất cả các Trách nhiệm Công việc Bổ sung",
    "startDateLabel": "Bạn bắt đầu làm việc tại Marriott International hoặc Starwood từ khi nào?",
    "preferredLanguageLabel": "Ngôn ngữ ưu tiên của bạn là gì?",
    "jobFunctionsLabel": "Vai trò công việc của bạn là gì?",
    "interestsLabel": "Bạn muốn học điều gì?",
    "brandsLabel": "Bạn làm việc cho (các) thương hiệu nào?",
    "additionalJobFunctionsLabel": "Bạn thực hiện thêm những chức năng công việc nào?",
    "managementLevelLabel": "Cấp bậc Nhân viên của bạn là gì",
    "highGuestContactLabel": "Bạn có thường xuyên tiếp xúc với khách hàng không?"
  },
  "courseDetails": {
    "completeBy": "Cần được Hoàn thành",
    "recommendTitlte": "Đề xuất Khóa học cho Nhóm của Bạn",
    "courseName": "Tên Khóa học",
    "sendRecommendation": "Gửi Đề xuất"
  },
  "explore": {
    "relatedCourses": "Dưới đây là các khóa học liên quan đến các lĩnh vực bạn quan tâm.",
    "chooseInterests": "Chọn sở thích của bạn",
    "title": "Khám phá Việc học tập",
    "chooseInterestsModal": "Chọn Sở thích",
    "oppsTitle": "Khám phá các Cơ hội Học tập",
    "growWithUsTitle": "Phát triển Cùng Chúng tôi",
    "growWithUsDesc": "Marriott tin vào một sự nghiệp giúp bạn thăng hoa. Chúng tôi cũng tin rằng trải nghiệm rộng hơn sẽ làm giàu thêm vốn kinh nghiệm.",
    "growWithUsBtn": "XEM PHÁT TRIỂN CÙNG CHÚNG TÔI",
    "mdaTitle": "Marriott Development Academy",
    "mdaDesc": "Marriott ghi nhận thành công và cam kết. Chúng tôi tôn vinh và khuyến khích sự lãnh đạo và cống hiến xuất chúng. Chúng tôi cũng trao thưởng cho những người giới thiệu nhân viên thành công.",
    "mdaBtn": "XEM MARRIOTT DEVELOPMENT ACADEMY",
    "selectInterests": "Cho chúng tôi biết chủ đề bạn quan tâm.",
    "otherRecommended": "Các Khóa học được Đề xuất Khác",
    "searchByName": "Tìm kiếm những nội dung quan tâm của bạn"
  },
  "plan": {
    "completedRequired": "Bạn đã hoàn thành tất cả các khóa học bắt buộc của mình!",
    "requiredCourses": "Khóa học Bắt buộc",
    "reqDesc": "Vui lòng hoàn thành những khóa học này trước ngày đến hạn.",
    "complete": "HOÀN THÀNH",
    "of": "/",
    "courses": "KHÓA HỌC",
    "completedCourses": "Các Khóa học đã Hoàn thành",
    "completedDesc": "Chúc mừng! Gần đây bạn đã hoàn thành các khóa học bên dưới.",
    "remaining": "còn lại",
    "myLearningTitle": "Học tập đã Thêm của Tôi ",
    "myLearningSubTitle": "Các mục tôi đã thêm vào Kế hoạch của Tôi",
    "history": "Lịch sử",
    "myLearningHistoryLinkText": "Xem toàn bộ Lịch sử của tôi",
    "jf": "(Các) Chức năng Công việc của Bạn",
    "awr": "Các Trách nhiệm Công việc Bổ sung (AWR) của bạn",
    "jfAwrDesc": "Quan trọng: chức năng công việc và AWR xác định các khóa đào tạo bắt buộc của bạn được chỉ định trong Kế hoạch của Tôi. ",
    "jfAwrEdit": "CHỈNH SỬA hồ sơ của bạn",
    "jfAwrEnd": "để cập nhật.",
    "tourPlan": "Kế hoạch của Tôi bao gồm tất cả các khóa học bắt buộc và được đề xuất của bạn để hoàn thành trước hạn",
    "tourBasics": "Marriott Basics bao gồm các nguồn lực giúp bạn bắt đầu hành trình tại Marriott và có thể truy cập bất kỳ lúc nào trong suốt sự nghiệp của bạn. Marriott Basics bổ sung cho chương trình học của bạn trong Kế hoạch của Tôi.",
    "noCourses": "Hiện tại, bạn đã bắt kịp chương trình học bắt buộc, hãy nghĩ về tương lai và bắt đầu chương trình học bắt buộc sắp tới"
  },
  "team": {
    "title": "Quản lý Nhóm của Bạn",
    "subtitle": "Phát triển kỹ năng và sự nghiệp của họ.",
    "functions": "Chức năng",
    "viewTeam": "Xem Nhóm",
    "allCourses": "Tất cả Khóa học",
    "downloadExcel": "Tải xuống Báo cáo dạng Excel"
  },
  "feedbackButton": {
    "havingTrouble": "Đề xuất"
  },
  "recommendation": {
    "dismiss": "Bạn có chắc muốn bỏ qua đề xuất này không?"
  },
  "notifications": {
    "addAssignment2": "Nhiệm vụ %{assignment_type} cho %{assignment_name} đã được thêm vào %{page location} của bạn và sẽ hết hạn vào %{due}.",
    "comingDueAssignment": "Nhiệm vụ %{assignment_type} của bạn cho %{assignment_name} sắp hết hạn vào %{due}.",
    "overdueAssignment": "Nhiệm vụ %{assignment_type} của bạn cho %{assignment_name} hiện đã quá hạn.",
    "addAssignment": "Nhiệm vụ %{assignment_type} cho %{assignment_name} đã được thêm vào %{page location} của bạn.",
    "profile": "Đã phát hiện bản cập nhật cho chức danh hoặc vị trí công việc của bạn. Vui lòng xem Hồ sơ của bạn để thực hiện các cập nhật hiện hành về Thương hiệu, Chức năng hoặc Trách nhiệm Công việc Bổ sung. Thông tin hồ sơ DLZ chính xác sẽ giúp đảm bảo bạn nhận được các nhiệm vụ học tập phù hợp.",
    "helptip": "Tất cả các thông báo sẽ hết hạn sau 30 ngày.",
    "markRead": "Đánh dấu là đã đọc",
    "delete": "Xóa",
    "markAllRead": "Đánh dấu tất cả là đã đọc",
    "settings": "Cài đặt",
    "tooltip": "Các thông báo đã đọc sẽ bị xóa trong 30 ngày",
    "reportComplete": "Báo cáo DLZ của quý vị đã hoàn thành. Nhấp chuột để tải xuống",
    "dontShowThisAgain": "Không hiển thị lại",
    "warningEnabled": "Cảnh báo trước khi Xóa đã được bật.",
    "manageEmailNotifications": "Quản lý Thông báo Email",
    "warningTooltip": "Khi được chọn, bạn sẽ thấy một cảnh báo mỗi khi xóa một thông báo.",
    "changeEmailManagedTooltip": "Để thay đổi email của bạn, hãy truy cập mHUB > HR Direct > Tôi > Thông tin Công khai của Tôi. Làm theo hướng dẫn để cập nhật thông tin liên hệ của bạn. Có thể mất 1-2 ngày làm việc để hiển thị email đã cập nhật.",
    "changeEmailFranchisedTooltip": "Để thay đổi email của bạn, hãy liên hệ với Người quản lý của bạn và/hoặc Quản trị viên EID Tool của Cơ sở của bạn. Có thể mất 1-2 ngày làm việc để hiển thị email đã cập nhật.",
    "deleteAllNotifications": "XÓA TẤT CẢ CÁC THÔNG BÁO",
    "deleteIndividualHeader": "Xóa Thông báo",
    "deleteAllConfirmation": "Bạn có chắc chắn muốn xóa tất cả các thông báo không? Thông báo đã xóa được chuyển đến phần Đã xóa trên Trung tâm Thông báo cho đến khi hết hạn.",
    "deleteIndividualConfirmation": "Bạn có chắc chắn muốn xóa thông báo này không? Những thông báo đã xóa được chuyển đến phần Đã xóa trên Trung tâm Thông báo cho đến khi hết hạn.",
    "permanentDeleteIndividiualConfirmation": "Bạn có chắc chắn muốn xóa vĩnh viễn thông báo này không? Hành động này không thể được hoàn tác.",
    "permanentDeleteAllConfirmation": "Bạn có chắc chắn muốn xóa vĩnh viễn tất cả các thông báo không? Hành động này không thể được hoàn tác.",
    "notificationDeleted": "Thông báo đã bị xóa.",
    "notificationsDeleted": "Các thông báo đã bị xóa.",
    "notificationRestored": "Thông báo đã được khôi phục.",
    "notificationsRestored": "Các thông báo đã được khôi phục.",
    "enableDeletionWarning": "Đã Bật Cảnh báo trước khi Xóa",
    "warningDisabled": "Cảnh báo trước khi Xóa đã bị tắt.",
    "viewAll": "Xem Tất cả",
    "deleteAll": "Xóa Tất cả",
    "notificationCenterTitle": "Trung tâm Thông báo",
    "markAsRead": "Đánh dấu Là Đã đọc",
    "noDeletedNotifications": "Bạn đã cập nhật hết!",
    "restore": "Khôi phục",
    "restoreAll": "Khôi phục lại Tất cả",
    "viewCourse": "Xem Khóa học",
    "title": "Thông báo",
    "noNewNotifications": "Bạn không có thông báo nào.",
    "cancel": "Huỷ",
    "manageNotificationsCopy": "Quản lý các Thông báo qua Email\n\nLựa chọn có nhận email hàng tuần tóm tắt các mục học tập quá hạn của bạn hay không. Chọn “Quản lý (Manage)” để điều chỉnh các tùy chọn của bạn."
  },
  "transcript": {
    "history": "Lịch sử của tôi",
    "completed": "Nhiệm vụ Đã hoàn thành",
    "assignmentName": "Tên Nhiệm vụ",
    "type": "Loại",
    "viewAssignment": "Xem Nhiệm vụ",
    "assignmentSource": "Nguồn Nhiệm vụ",
    "requiredAssignments": "Nhiệm vụ Bắt buộc",
    "recommendedAssignments": "Nhiệm vụ Khuyến nghị",
    "voluntary": "Tình nguyện",
    "trasscript": "Bản ghi",
    "retraining": "Đào tạo lại",
    "completionDate": "Ngày Hoàn thành",
    "assignmentType": "Loại Nhiệm vụ",
    "contentType": "Loại Nội dung",
    "contentId": "ID Nội dung"
  },
  "contentLaunch": {
    "review": "Xem lại Học tập",
    "start": "Bắt đầu Học tập"
  },
  "survey": {
    "weValue": "Chúng tôi muốn lắng nghe ý kiến của bạn.",
    "weValueDescription": "Vui lòng trả lời năm câu hỏi nhanh về kinh nghiệm làm việc cho cơ sở của bạn.",
    "weValueButtonText": "Làm khảo sát",
    "weValueG1Q1A1": "Có",
    "weValueG1Q1A2": "Không",
    "strongAgree": "Hoàn toàn Đồng ý",
    "agree": "Đồng ý",
    "slightAgree": "Phần nào Đồng ý",
    "slightDisagree": "Phần nào Không đồng ý",
    "disagree": "Không đồng ý",
    "strongDisagree": "Hoàn toàn Không đồng ý",
    "weValueG1Q1Title": "Bạn có tham dự cuộc họp hàng ngày trong ca làm việc gần nhất của mình không? (Line-Up, What’s Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments, hoặc Daily EDITION)",
    "weValueG1Q2Title": "Cuộc họp hàng ngày (Line-Up, What's Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments, hoặc Daily EDITION) truyền cảm hứng cho tôi đem lại dịch vụ sang trọng mỗi ngày.",
    "weValueG1Q3Title": "Bộ phận của tôi tập trung vào việc giảm thiểu các khiếm khuyết, ngay cả những lỗi không được khách báo cáo.",
    "weValueG1Q4Title": "Tôi được trao quyền giải quyết các vấn đề của khách hàng mà không cần phải xin phép trước.",
    "weValueG1Q5Title": "Tôi được trao quyền phục vụ hài lòng khách hàng mà không cần phải xin phép trước.",
    "weValueG2Q1Title": "Tôi thường xuyên thấy các thành viên trong nhóm làm việc tại cơ sở của tôi vượt quá mong đợi để gây ấn tượng với khách bằng dịch vụ chăm sóc mà họ cung cấp.",
    "weValueG2Q2Title": "Việc học hỏi của tôi ở bộ phận này đã giúp tôi có thể cung cấp dịch vụ sang trọng.",
    "weValueG2Q3Title": "Tôi cảm thấy tự tin về kiến thức và kỹ năng của mình để cung cấp dịch vụ sang trọng.",
    "weValueG2Q4Title": "Trong bộ phận của tôi, chúng tôi đem lại một môi trường sang trọng, sạch sẽ và không có khiếm khuyết.",
    "weValueG2Q5Title": "Ngay cả khi tôi đối mặt với vấn đề không liên quan đến bộ phận của mình, tôi được trao quyền can dự và làm chủ mối quan tâm của khách hàng.",
    "weValueG3Q1Title": "Ở cơ sở của tôi, các thành viên trong nhóm nhận được mức độ dịch vụ chăm sóc tương đương với khách hàng.",
    "weValueG3Q2Title": "Bộ phận của tôi thể hiện các giá trị của thương hiệu.",
    "weValueG3Q3Title": "Khi tôi vượt ngoài mong đợi để tạo trải nghiệm sang trọng cho khách hàng, công việc của tôi được ghi nhận và đánh giá cao.",
    "weValueG3Q4Title": "Tôi tham gia vào nỗ lực cải thiện công việc của bộ phận mình.",
    "weValueG3Q5Title": "Ở bộ phận của tôi, chúng tôi luôn đặt khách hàng lên trên hết.",
    "submitButton": "Nộp",
    "phasetwo": {
      "weValueG1Q1Title": "Bạn có tham dự cuộc họp hàng ngày trong ca làm việc gần nhất của mình không? (Line-Up, What’s Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments, hoặc Daily EDITION)?",
      "weValueG1Q2Title": "Cuộc họp hàng ngày (Line-Up, What's Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments, hoặc Daily EDITION) truyền cảm hứng cho các thành viên nhóm đem lại dịch vụ sang trọng mỗi ngày.",
      "weValueG1Q3Title": "Tại khách sạn của tôi, chúng tôi tập trung vào việc giảm thiểu các khiếm khuyết, ngay cả những lỗi không được khách báo cáo.",
      "weValueG1Q4Title": "Tại khách sạn của tôi, chúng tôi được trao quyền giải quyết các vấn đề của khách hàng mà không cần phải xin phép trước.",
      "weValueG1Q5Title": "Tại khách sạn của tôi, chúng tôi được trao quyền phục vụ hài lòng khách hàng mà không cần phải xin phép trước.",
      "weValueG2Q1Title": "Tại khách sạn của tôi, chúng tôi thường xuyên vượt lên mong đợi để gây ấn tượng với khách bằng dịch vụ chăm sóc mà chúng tôi cung cấp.",
      "weValueG2Q2Title": "Các cơ hội học tập tại khách sạn đã cho phép chúng tôi cung cấp dịch vụ sang trọng.",
      "weValueG2Q3Title": "Tại khách sạn của tôi, chúng tôi cảm thấy tự tin về kiến thức và kỹ năng của mình để cung cấp dịch vụ sang trọng.",
      "weValueG2Q4Title": "Tại khách sạn của tôi, chúng tôi đem lại một môi trường sang trọng, sạch sẽ và không có khiếm khuyết.",
      "weValueG2Q5Title": "Ngay cả khi chúng tôi đối mặt với vấn đề không liên quan đến bộ phận của mình, chúng tôi vẫn được trao quyền can dự và đảm đương mối quan tâm của khách hàng.",
      "weValueG3Q1Title": "Tại khách sạn của tôi, chúng tôi chăm sóc lẫn nhau giống như mức độ chăm sóc mà chúng tôi cung cấp cho khách hàng.",
      "weValueG3Q2Title": "Khách sạn của tôi thể hiện các giá trị của thương hiệu.",
      "weValueG3Q3Title": "Tại khách sạn của tôi, khi chúng tôi vượt lên mong đợi để tạo trải nghiệm sang trọng cho khách hàng, công việc của tôi được ghi nhận và đánh giá cao.",
      "weValueG3Q4Title": "Tại khách sạn của tôi, chúng tôi có cơ hội tham gia vào nỗ lực của bộ phận để cải thiện công việc của chính mình.",
      "weValueG3Q5Title": "Tại khách sạn của tôi, chúng tôi luôn đặt khách hàng lên hàng đầu."
    }
  },
  "gateway": {
    "planProgressComplete": "%{label} HOÀN THÀNH",
    "gatewayProgressComplete": "TIẾN ĐỘ GATEWAY HOÀN THÀNH",
    "heroMobileGatewayVideoText": "Phát Video Gateway",
    "basics": "KIẾN THỨC CƠ BẢN",
    "brandResources": "NGUỒN LỰC THƯƠNG HIỆU",
    "disciplineResources": "NGUỒN LỰC KỶ LUẬT",
    "immersionResourcesHeader": "Nguồn lực Định hướng",
    "brandCommunicationsHeaader": "Truyền thông Thương hiệu",
    "brandContactsHeader": "Thông tin liên hệ của Thương hiệu",
    "franchisedActionItemsText": "Các mục hành động giúp quý vị luôn có sự chuẩn bị và cung cấp cho quý vị những thông tin giới thiệu chính. Những mục này không bắt buộc và bổ sung cho những điều quý vị có thể nhận được từ công ty quản lý nhượng quyền của mình.",
    "global": "TOÀN CẦU",
    "continent": "LỤC ĐỊA",
    "viewed": "ĐÃ XEM",
    "completed": "ĐÃ HOÀN THÀNH",
    "heading": "Gateway",
    "basicsTab": "KIẾN THỨC CƠ BẢN",
    "onboardingTab": "Mục Hành động",
    "brandTab": "Nguồn lực Thương hiệu",
    "disciplinesTab": "NGUỒN LỰC THEO KỶ LUẬT",
    "basicsHeader": "Chào mừng đến với Marriott International",
    "basicsHeaderNewBrandOrRole": "Chào mừng,",
    "basicsText": "Khi quý vị bắt đầu sự nghiệp của mình, chúng tôi biết có rất nhiều điều quý vị cần học hỏi. Chúng tôi đã chọn một số kiến thức cơ bản cho những ngày làm việc đầu tiên của quý vị. Một số mục này có thể được xem xét chuyên sâu hơn trong các buổi định hướng hoặc giới thiệu thương hiệu, nhưng điều này sẽ giúp quý vị bắt đầu.",
    "basicsTextGms": "Khi quý vị bắt đầu sự nghiệp của mình, chúng tôi biết có rất nhiều điều quý vị cần học hỏi. Chúng tôi đã chọn một số kiến thức cơ bản cho những ngày làm việc đầu tiên của quý vị. Một số mục này có thể được xem xét chuyên sâu hơn trong các buổi định hướng hoặc giới thiệu thương hiệu, nhưng điều này sẽ giúp quý vị bắt đầu. Khi quý vị đã hoàn tất, hãy chuyển sang các trang Gateway khác, bao gồm Mục Hành động, Nguồn lực Thương hiệu và Nguồn lực theo Kỷ luật.",
    "basicsTextFranchise": "Chúng tôi biết có rất nhiều điều quý vị cần học hỏi, và Chúng tôi đã chọn một số kiến thức cơ bản cho những ngày làm việc đầu tiên của quý vị. Những mục này có thể bổ sung cho các buổi định hướng, giới thiệu thương hiệu hoặc thông tin từ công ty quản lý nhượng quyền của quý vị.",
    "basicsTextFranchiseGms": "Chúng tôi biết có rất nhiều điều quý vị cần học hỏi, và Chúng tôi đã chọn một số kiến thức cơ bản cho những ngày làm việc đầu tiên của quý vị. Những mục này có thể bổ sung cho các buổi định hướng, giới thiệu thương hiệu hoặc thông tin từ công ty quản lý nhượng quyền của quý vị. Khi quý vị đã hoàn tất, hãy chuyển sang các trang Gateway khác, bao gồm Mục Hành động, Nguồn lực Thương hiệu và Nguồn lực theo Kỷ luật.",
    "basicsTextNewToRole": "Khi quý vị bắt đầu vai trò mới của mình, chúng tôi biết có rất nhiều điều quý vị cần học hỏi. Chúng tôi đã chọn một số kiến thức cơ bản cho những ngày làm việc đầu tiên của quý vị. Một số mục này có thể được xem xét chuyên sâu hơn trong khóa đào tạo được chỉ định của quý vị, nhưng điều này sẽ giúp quý vị bắt đầu.",
    "basicsTextNewToRoleGms": "Khi quý vị bắt đầu vai trò mới của mình, chúng tôi biết có rất nhiều điều quý vị cần học hỏi. Chúng tôi đã chọn một số kiến thức cơ bản cho những ngày làm việc đầu tiên của quý vị. Một số mục này có thể được xem xét chuyên sâu hơn trong khóa đào tạo được chỉ định của quý vị, nhưng điều này sẽ giúp quý vị bắt đầu. Khi quý vị đã hoàn tất, hãy chuyển sang các trang Gateway khác, bao gồm Mục Hành động, Nguồn lực Thương hiệu và Nguồn lực theo Kỷ luật.",
    "basicsTextNewToRoleFranchise": "Chúng tôi biết có rất nhiều điều quý vị cần học hỏi, và Chúng tôi đã chọn một số kiến thức cơ bản cho những ngày đầu tiên đảm nhận vai trò mới của quý vị. Những mục này có thể bổ sung cho thông tin từ công ty quản lý nhượng quyền của quý vị.",
    "basicsTextNewToRoleFranchiseGms": "Chúng tôi biết có rất nhiều điều quý vị cần học hỏi, và Chúng tôi đã chọn một số kiến thức cơ bản cho những ngày đầu tiên đảm nhận vai trò mới của quý vị. Những mục này có thể bổ sung cho thông tin từ công ty quản lý nhượng quyền của quý vị. Khi quý vị đã hoàn tất, hãy chuyển sang các trang Gateway khác, bao gồm Mục Hành động, Nguồn lực Thương hiệu và Nguồn lực theo Kỷ luật.",
    "basicsTextNewToBrand": "Khi quý vị bắt đầu vai trò mới của mình với %{brandName}, chúng tôi biết có rất nhiều điều quý vị cần học hỏi. Chúng tôi đã chọn một số kiến thức cơ bản cho những ngày làm việc đầu tiên của quý vị. Một số mục này có thể được xem xét chuyên sâu hơn trong các buổi định hướng hoặc giới thiệu thương hiệu, nhưng điều này sẽ giúp quý vị bắt đầu.",
    "basicsTextNewToBrandGms": "Khi quý vị bắt đầu vai trò mới của mình với %{brandName}, chúng tôi biết có rất nhiều điều quý vị cần học hỏi. Chúng tôi đã chọn một số kiến thức cơ bản cho những ngày làm việc đầu tiên của quý vị. Một số mục này có thể được xem xét chuyên sâu hơn trong các buổi định hướng hoặc giới thiệu thương hiệu, nhưng điều này sẽ giúp quý vị bắt đầu. Khi quý vị đã hoàn tất, hãy chuyển sang các trang Gateway khác, bao gồm Mục Hành động, Nguồn lực Thương hiệu và Nguồn lực theo Kỷ luật.",
    "basicsTextNewToBrandFranchise": "Chúng tôi biết có rất nhiều điều quý vị cần học hỏi, và Chúng tôi đã chọn một số kiến thức cơ bản cho những ngày đầu tiên đảm nhận vai trò mới của quý vị. Những mục này có thể bổ sung cho các buổi định hướng, giới thiệu thương hiệu hoặc thông tin từ công ty quản lý nhượng quyền của quý vị.",
    "basicsTextNewToBrandFranchiseGms": "Chúng tôi biết có rất nhiều điều quý vị cần học hỏi và chúng tôi đã chọn một số kiến thức cơ bản cho những ngày đầu tiên đảm nhận vai trò mới của quý vị với %{brandName}. Những mục này có thể bổ sung cho các buổi định hướng, giới thiệu thương hiệu hoặc thông tin từ công ty quản lý nhượng quyền của quý vị. Khi quý vị đã hoàn tất, hãy chuyển sang các trang Gateway khác, bao gồm Mục Hành động, Nguồn lực Thương hiệu và Nguồn lực theo Kỷ luật.",
    "disciplineHeader": "Nguồn lực Theo Kỷ luật",
    "toDo": "BẮT BUỘC",
    "optional": "TÙY CHỌN",
    "overallComplete": "TỔNG THỂ HOÀN THÀNH",
    "keyResourcesDesc": "Tìm hiểu thêm về các kỷ luật chính hỗ trợ các hoạt động tại khách sạn hàng ngày, trải nghiệm của khách và các nhu cầu kinh doanh.",
    "franchiseFooter": "Tài liệu này bao gồm các khuyến nghị và thực tiễn được đề xuất để có thể nâng cao khả năng tuân thủ các Tiêu chuẩn về Thương hiệu. Với tư cách là bên nhượng quyền, Marriott không tham gia vào quá trình quản lý hoặc vận hành bất kỳ khía cạnh nào trong hoạt động kinh doanh hoặc khách sạn của bên nhận nhượng quyền. Bên nhận nhượng quyền sẽ giữ quyền kiểm soát vận hành hàng ngày của khách sạn của mình và chịu trách nhiệm về các nhân viên và các thực tiễn công việc của nhân viên.",
    "landingHeroTitle1": "Chào mừng đến với Marriott International.",
    "landingHeroTitle2": "Bắt đầu hành trình của quý vị tại đây.",
    "landingHeroText": "The Gateway là một công cụ hướng dẫn quý vị trong năm đầu tiên ở vai trò mới. The Gateway sẽ cung cấp cho quý vị các thông tin và nguồn lực mà quý vị cần để thành công.",
    "landingHeroTitle1NewToRole": "Chào mừng đến với The Gateway.",
    "landingHeroTitle1NewToBrand": "Chào mừng đến với The Gateway.",
    "landingButtonText": "Bắt đầu",
    "twoMonthslandingHeroTitle1": "Chào mừng Trở lại!",
    "twoMonthslandingHeroText": "Nếu quý vị chưa hoàn thành, hãy hoàn thành tất cả Kiến thức cơ bản của quý vị trong The Gateway!",
    "twoMonthslandingButtonText": "Vào Gateway",
    "twoMonthslandingHeroTitle1NewToBrand": "Chào mừng Trở lại!",
    "twoMonthslandingHeroTextNewToBrand": "Hy vọng quý vị có những ngày đầu tiên tuyệt vời với %{brandName}. Đừng quên tiếp tục hành trình của quý vị và truy cập vào tất cả những gì quý vị cần trong The Gateway",
    "twoMonthslandingButtonTextNewToBrand": "Tiếp tục",
    "threeMonthslandingHeroTitle1": "Quý vị đang đi đúng hướng!",
    "threeMonthslandingHeroText": "Tất cả những gì quý vị cần biết về Marriott International đều có trong The Gateway.",
    "threeMonthslandingButtonText": "Vào Gateway",
    "threeMonthslandingHeroTitle1NewToRole": "Quý vị đang đi đúng hướng!",
    "threeMonthslandingHeroTextNewToRole": "Tất cả những gì quý vị cần biết về vai trò mới của mình đều có trong The Gateway.",
    "threeMonthslandingButtonTextNewToRole": "Vào Gateway",
    "threeMonthslandingHeroTitle1NewToBrand": "Chào mừng Trở lại!",
    "threeMonthslandingHeroTextNewToBrand": "Chúc mừng quý vị đã hoàn thành %{numDays} ngày đầu tiên với thương hiệu mới của mình Quý vị có thể truy cập toàn bộ các Mục Hành động, Nguồn lực Thương hiệu và Kỷ luật bất cứ khi nào quý vị cần.",
    "threeMonthslandingButtonTextNewToBrand": "Vào Gateway",
    "fourMonthslandingHeroTitle1": "Quý vị đã làm rất tốt! Quý vị đã hoàn thành Kiến thức cơ bản của mình!",
    "fourMonthslandingHeroText": "Truy cập vào tất cả những gì quý vị cần biết về Marriott International tại The Gateway.",
    "fourMonthslandingButtonText": "Vào Gateway",
    "fourMonthslandingHeroTitle1NewToRole": "Quý vị đã làm rất tốt! Quý vị đã hoàn thành Kiến thức cơ bản của mình!",
    "fourMonthslandingHeroTextNewToRole": "Nhận quyền truy cập vào tất cả những gì quý vị cần để thành công với vai trò mới của mình trong The Gateway.",
    "fourMonthslandingButtonTextNewToRole": "Vào Gateway",
    "fourMonthslandingHeroTitle1NewToBrand": "The Gateway - Đăng ký",
    "fourMonthslandingHeroTextNewToBrand": "Khi quý vị sắp kết thúc năm đầu tiên của mình với %{brandName}, nếu quý vị chưa hoàn thành, hãy hoàn thành tất cả các Mục Hành động của quý vị và sử dụng các nguồn lực phát triển sẵn có tại đây trong Digital Learning Zone. Nhấp vào 'Tiếp tục' để tiếp tục hành trình của quý vị.",
    "fourMonthslandingButtonTextNewToBrand": "Tiếp tục",
    "gmSecondVisitlandingHeroTitle1": "Chào mừng Trở lại!",
    "gmSecondVisitlandingHeroText": "Hy vọng quý vị có những ngày đầu tiên tốt đẹp. Đừng quên tiếp tục hành trình giúp quý vị phát triển trong vai trò mới.",
    "gmSecondVisitlandingButtonText": "Tiếp tục",
    "gmSecondVisitlandingHeroTextNewToRole": "Hy vọng quý vị có những ngày đầu tiên tuyệt vời trong vai trò mới. Đừng quên tiếp tục hành trình của quý vị và truy cập vào tất cả những gì quý vị cần trong The Gateway.",
    "gmSecondVisitlandingHeroTextNewToBrand": "Hy vọng quý vị có những ngày đầu tiên tuyệt vời với %{brandName}. Đừng quên tiếp tục hành trình của quý vị và truy cập vào tất cả những gì quý vị cần trong The Gateway",
    "gmThirdVisitlandingHeroTitle1": "Chào mừng Trở lại!",
    "gmThirdVisitlandingHeroText": "Chúc mừng quý vị đã hoàn thành %{numDays} ngày đầu tiên trong hành trình tuyển dụng mới của mình. Quý vị có thể truy cập tất cả các Mục Hành động, Nguồn lực Thương hiệu và Kỷ luật của mình bất cứ khi nào quý vị cần.",
    "gmThirdVisitlandingButtonText": "Vào Gateway",
    "gmThirdVisitlandingHeroTextNewToRole": "Chúc mừng quý vị đã hoàn thành %{numDays} ngày đầu tiên trong vai trò mới của mình. Quý vị có thể truy cập tất cả các Mục Hành động, Nguồn lực Thương hiệu và Kỷ luật của mình bất cứ khi nào quý vị cần.",
    "gmThirdVisitlandingHeroTextNewToBrand": "Chúc mừng quý vị đã hoàn thành %{numDays} ngày đầu tiên với thương hiệu mới của mình. Quý vị có thể truy cập tất cả các Mục Hành động, Nguồn lực Thương hiệu và Kỷ luật của mình bất cứ khi nào quý vị cần.",
    "gmSevenMonthslandingHeroTitle1": "Có vẻ như quý vị còn nhiều Mục Hành động cần hoàn thành.",
    "gmSevenMonthslandingHeroText": "Khi quý vị sắp kết thúc năm đầu tiên của mình, đừng quên hoàn thành tất cả các Mục Hành động của mình và sử dụng các nguồn lực phát triển sẵn có tại đây trong Digital Learning Zone. Nhấp vào 'Tiếp tục' để tiếp tục hành trình của quý vị",
    "gmSevenMonthslandingButtonText": "Tiếp tục",
    "gmSevenMonthslandingHeroTitle1NewToRole": "The Gateway - Đăng ký",
    "gmSevenMonthslandingHeroTextNewToRole": "Khi quý vị sắp kết thúc năm đầu tiên trong vai trò mới, nếu quý vị chưa hoàn thành, hãy hoàn thành tất cả các Mục Hành động của quý vị và sử dụng các nguồn lực phát triển sẵn có tại đây trong Digital Learning Zone. Nhấp vào 'Tiếp tục' để tiếp tục hành trình của quý vị",
    "gmSevenMonthslandingHeroTitle1NewToBrand": "The Gateway - Đăng ký",
    "gmSevenMonthslandingHeroTextNewToBrand": "Khi quý vị sắp kết thúc năm đầu tiên của mình với %{brandName}, nếu quý vị chưa hoàn thành, hãy hoàn thành tất cả các Mục Hành động của quý vị và sử dụng các nguồn lực phát triển sẵn có tại đây trong Digital Learning Zone. Nhấp vào 'Tiếp tục' để tiếp tục hành trình của quý vị.",
    "gmOneYearlandingHeroTitle1": "Chúc mừng 1 năm với %{brandName}!",
    "gmOneYearlandingHeroTitle1NewToRole": "Xin chúc mừng 1 năm trong vai trò mới của quý vị!",
    "gmOneYearlandingHeroTitle1NewToBrand": "Xin chúc mừng 1 năm trong vai trò mới với %{brandName} của quý vị!",
    "gmOneYearlandingHeroText": "Hành trình The Gateway có thể đã kết thúc nhưng vẫn còn nhiều điều tốt đẹp phía trước. Quý vị có thể truy cập các nguồn lực từ thẻ Gateway bất kỳ lúc nào.",
    "gmOneYearlandingButtonText": "Xem Gateway"
  },
  "resources": {
    "iptTooltip": "Sử dụng dấu phẩy để phân tách nhiều vị trí",
    "iptAllFunctionTooltip": "Bỏ tích chọn để lựa chọn (các) Chức năng Công việc cụ thể. Giữ tích chọn để xem đào tạo cho tất cả các chức năng tại vị trí đã chọn",
    "iptAllAWRTooltip": "Bỏ tích chọn để lựa chọn các Trách nhiệm Công việc Bổ sung cụ thể. Giữ tích chọn để xem đào tạo cho tất cả các Trách nhiệm Công việc Bổ sung cho vị trí đã chọn",
    "editJFTooltip": "Sử dụng dấu phẩy để phân tách nhiều mục nhập"
  },
  "Notification Center: ": "Đánh dấu là Đã đọc",
  "Notification Center: Empty": "Bạn đã cập nhật hết!",
  "Notification Center: Individual Delete": "Bạn có chắc chắn muốn xóa thông báo này không? Những thông báo đã xóa được chuyển đến phần Đã xóa trên Trung tâm Thông báo cho đến khi hết hạn.",
  "Notification Center: Delete All": "Bạn có chắc chắn muốn xóa tất cả các thông báo không? Thông báo đã xóa được chuyển đến phần Đã xóa trên Trung tâm Thông báo cho đến khi hết hạn.",
  "Notification Center: Mark All As Read": "Đánh dấu Tất cả Là Đã đọc",
  "Notification Center: Restore All": "Khôi phục lại Tất cả",
  "Notification Center: Delete Confirmation": "Xóa",
  "Warning Message Text: Remove Job Function or AWR": "Xóa Chức năng Công việc hoặc Trách nhiệm Công việc Bổ sung (AWR)?\r\nNếu Gửi, bạn sẽ xóa %{Function and/or AWR} từ %{count} Người học.",
  "Warning Message Text: Add Job Function or AWR": "Thêm Chức năng Công việc hoặc Trách nhiệm Công việc Bổ sung (AWR)?\r\nNếu Gửi, bạn sẽ thêm %{Function and/or AWR} cho %{count} Người học.",
  "Button": "Gửi",
  "Success Message Text: Add Job Function and/or AWR": "Thành công\r\nBạn đã thêm %{Job Function and/or AWR} cho (các) nhân viên được yêu cầu tại %{location}. \r\n\r\nBạn có muốn chỉnh sửa các Chức năng Công việc bổ sung hoặc Các Trách nhiệm Công việc Bổ sung cho %{location} không?",
  "Message Text:AWR": "Thành công\r\nBạn đã thêm các Trách nhiệm Công việc Bổ sung %{ __} cho (các) nhân viên được yêu cầu tại %{ __}.\r\n\r\nBạn có muốn chỉnh sửa các Chức năng Công việc bổ sung hoặc các Trách nhiệm Công việc Bổ sung cho %{location, property, learner(s)} không?",
  "Success Message Text: Remove Job Function": "Thành công\r\nBạn đã xóa%{Job Function and/or AWR} khỏi (các) nhân viên được yêu cầu tại %{location}. \r\n\r\nBạn có muốn chỉnh sửa các Chức năng Công việc bổ sung hoặc Các Trách nhiệm Công việc Bổ sung cho %{location} không?",
  "Success Message Text: Remove AWR": "Thành công\r\nBạn đã xóa Trách nhiệm Công việc Bổ sung%{ __} khỏi (các) nhân viên được yêu cầu tại %{ __} .\r\n\r\nBạn có muốn chỉnh sửa các Chức năng Công việc bổ sung hoặc các Trách nhiệm Công việc Bổ sung cho %{location, property, learner(s)} không?",
  "Tool Tip - Managed Change Email": "Để thay đổi email của bạn, hãy truy cập mHUB > HR Direct > Tôi > Thông tin Công khai của Tôi. Làm theo hướng dẫn để cập nhật thông tin liên hệ của bạn. Có thể mất 1-2 ngày làm việc để hiển thị email đã cập nhật.",
  "Tool Tip - Franchised  Change Email": "Để thay đổi email của bạn, hãy liên hệ với Người quản lý của bạn và/hoặc Quản trị viên EID Tool của Cơ sở của bạn. Có thể mất 1-2 ngày làm việc để hiển thị email đã cập nhật.",
  "User Profile: Email Opt-in": "Không, cảm ơn",
  "User Profile: Email Frequency": "Tổng hợp Hàng tuần",
  "Generic Notification Email Subject Line:": "Bạn có thông báo mới trong DLZ",
  "Generic Body Text (<150 Characters)": "Vui lòng truy cập DLZ để xem chi tiết.",
  "All Emails: Signature": "Digital Learning Zone - Học tập trở nên đơn giản. Mọi thứ bắt đầu với bạn.",
  "All Emails: Footer": "Bạn có thể hủy đăng ký/chọn không nhận thông báo qua email bất kỳ lúc nào. Truy cập Digital Learning Zone/Hồ sơ để cập nhật theo mong muốn của bạn.",
  "Overdue Emails: Subject Line: ": "Bài tập trong DLZ Cần Sự chú ý của Bạn",
  "Overdue Emails: Body Test": "Bạn có Bài tập Quá hạn. Vui lòng đăng nhập vào DLZ để xem lại các bài tập của bạn.",
  "exploreLearning": {
    "sharpenYourSkillsTitle": "Nâng cao Kỹ năng của Bạn",
    "sharpenYourSkillsSubTitle": "Xây dựng kiến thức và kỹ năng của bạn để nâng cao sự nghiệp của bạn, ở cả hiện tại và trong tương lai.",
    "growYourCareerTitle": "Phát triển Sự nghiệp của Bạn",
    "growYourCareerSubTitle": "Lập kế hoạch cho bước đi tiếp theo của bạn với các kỹ năng chuyên biệt hoặc đào tạo chéo hỗ trợ các sở thích và mục tiêu riêng của bạn.",
    "additionalResourcesTitle": "Các Nguồn lực Bổ sung",
    "additionalResourcesSubTitle": "Khám phá getAbstract® và CultureWizard™. Xem cách DLZ có thể tiếp tục giúp bạn phát triển như thế nào.",
    "tourHeader": "Chào mừng bạn đến với DLZ đã được cập nhật. Bây giờ bạn có thể tìm kiếm để tra cứu bất cứ điều gì bạn cần, khi bạn cần. Ngoài ra, bạn sẽ tìm thấy một bản cập nhật Khám phá Việc học tập và mục Kế hoạch của Tôi được cá nhân hóa hơn. Tham gia chuyến tham quan!",
    "tourBtnSkip": "Bỏ qua",
    "tourBtnNext": "Đóng (6/6)",
    "tourSearchBar": "Tìm những gì bạn cần, khi bạn cần, bằng tính năng Tìm kiếm mới và mạnh mẽ hơn.",
    "tourBtnBack": "Quay lại",
    "tourMyPlan": "Kế hoạch của Tôi hiện có các mục bạn đã thêm từ Tìm kiếm và Khám phá để bạn có thể làm chủ toàn bộ hành trình học tập của mình!",
    "tourExploreLearningLink": "Bạn cũng vẫn có thể truy cập Khám phá Việc học tập từ đây!",
    "tourExploreLearningProfile": "Tận dụng tối đa kinh nghiệm học tập của bạn và kiểm tra hồ sơ của bạn, đặc biệt là các chức năng công việc, thường xuyên để cập nhật Kế hoạch của Tôi.",
    "mhubTraining": "Đào tạo mHUB",
    "growthSkills": "Kỹ năng Phát triển",
    "leadershipSkills": "Kỹ năng Lãnh đạo",
    "virtualTeamResources": "Tài nguyên Nhóm Ảo",
    "jobFunctions": "Chức năng Công việc",
    "brands": "Thương hiệu",
    "languageLearning": "Học Ngôn ngữ",
    "become": "Become",
    "emeaMentoringProgram": "Chương trình Cố vấn EMEA",
    "learningPathways": "Lộ trình Học tập",
    "mda": "MDA",
    "takeCareGrow": "TakeCare GROW",
    "getAbstract": "getAbstract®",
    "cultureWizard": "CultureWizard™",
    "takeCareCommunityHub": "Trung tâm Cộng đồng TakeCare",
    "mhubTrainingDesc": "Tìm các lộ trình học tập dựa trên vai trò và các khóa học theo chủ đề cụ thể về cách sử dụng mHUB.",
    "growthSkillsDesc": "Có được kiến thức thực tế xuyên khắp các chức năng và chủ đề khác nhau.",
    "leadershipSkillsDesc": "Khám phá cách quản lý và huấn luyện một nhóm và trở thành một nhà lãnh đạo hiệu quả hơn.",
    "virtualTeamResourcesDesc": "Tìm hiểu các kỹ thuật để thúc đẩy văn hóa cộng tác và hòa nhập trong các nhóm ảo.",
    "jobFunctionsDesc": "Mở rộng kiến thức về chức năng công việc hiện tại của bạn và tìm hiểu về các chức năng khác.",
    "brandsDesc": "Tìm hiểu thêm về các thương hiệu đa dạng của Marriott International.",
    "languageLearningDesc": "Giao tiếp hiệu quả với khán giả toàn cầu.",
    "becomeDesc": "Khám phá một cách tiếp cận tổng thể để tăng trưởng và phát triển nghề nghiệp: phản ánh, lên kế hoạch, hành động, tinh chỉnh.",
    "emeaMentoringProgramDesc": "Khai phá tiềm năng của bạn với Chương trình Cố vấn EMEA.",
    "learningPathwaysDesc": "Khám phá các cơ hội học tập cụ thể được sắp xếp cho cấp lãnh đạo của bạn.",
    "mdaDesc": "Xây dựng kỹ năng lãnh đạo và kỹ năng theo chức năng với Marriott Development Academy.",
    "takeCareGrowDesc": "Xác định các nguồn lực được thiết kế để xây dựng một cộng đồng hòa nhập và được truyền cảm hứng.",
    "getAbstractDesc": "Học điều gì đó mới mẻ mỗi ngày với các bản tóm tắt nội dung sách giúp bạn đưa ra quyết định tốt hơn.",
    "cultureWizardDesc": "Thu thập kiến thức quý giá để giao tiếp hiệu quả giữa các nền văn hóa đa dạng.",
    "takeCareCommunityHubDesc": "Khám phá các hoạt động xây dựng nhóm, chương trình học tập và công cụ của TakeCare.",
    "searchAllLearning": "Tìm kiếm Tất cả Khóa học",
    "searchBarPlaceHolder": "Luôn có sẵn nhiều khóa học để học tập.",
    "clearFilters": "Xóa Bộ lọc",
    "noResults": "Không tìm thấy Kết quả",
    "hasBeenUnpinned": "{Variable-tile_Name} đã được bỏ ghim.",
    "hasBeenPinned": "{Variable-tile_Name} đã được ghim.",
    "lpRemovedFromPlan": "Lộ trình Học tập đã được bỏ khỏi kế hoạch của bạn.",
    "courseRemovedFromPlan": "Khóa học đã được bỏ khỏi kế hoạch của bạn.",
    "lpAddedToPlan": "Lộ trình Học tập đã được thêm vào kế hoạch của bạn.",
    "courseAddedToPlan": "Khóa học đã được thêm vào kế hoạch của bạn.",
    "clearSelection": "Xóa lựa chọn",
    "removeFromMyPlan": "Bỏ khỏi Kế hoạch của Tôi",
    "addToMyPlan": "Thêm vào Kế hoạch của Tôi",
    "loadingResults": "Đang tải...",
    "viewDetails": "Xem Thông tin chi tiết",
    "clearAll": "Xóa Tất cả",
    "noOptions": "Không có lựa chọn nào sẵn có",
    "viewLess": "Ẩn Bớt",
    "viewMore": "Hiện Thêm",
    "recentSearch": "Các Tìm kiếm Gần đây",
    "exploreLearningTitle": "Khám phá Việc học tập",
    "exploreLearningHelpText": "Bạn không chắc chắn đang tìm kiếm điều gì?",
    "exploreMore": "Khám phá Thêm.",
    "viewAllResults": "Xem Tất cả <#> Kết quả",
    "searchSubTitle": "Tìm kiếm các khóa học bằng cách sử dụng các từ khóa, tiêu đề hoặc ID nội dung.",
    "pinnedLearningTitle": "Các Khóa học Đã ghim của Tôi",
    "landingSubTitle": "Làm chủ hành trình học tập của bạn. Khám phá các mục yêu thích của bạn — luôn có sẵn nhiều chủ đề và khóa học mới.",
    "columnTitle": "Tiêu đề",
    "columnDesc": "Mô tả",
    "columnType": "Loại",
    "columnAddedToPlan": "(Đã) Thêm Vào Kế hoạch",
    "columnComplete": "Hoàn thành",
    "columnDetails": "Thông tin chi tiết",
    "additionalSearchFilters": "Các Bộ lọc Tìm kiếm Bổ sung",
    "tourBtnClose": "Đóng",
    "tourBtnLast": "Đóng",
    "resultsFor": "Kết quả cho ",
    "resultsFortheFilter": "Kết quả cho bộ lọc {Filter Name}",
    "no": "Không",
    "results": "Kết quả",
    "courseTooltipTitle": "Một mục học tập cá nhân có thể chứa một hoặc nhiều hoạt động.<br /> Một khóa học có thể diễn ra trực tuyến, chẳng hạn như một video hoặc eLearning, hoặc diễn ra trong lớp học.",
    "gatewayToolTipTitle": "Các tài nguyên kỹ thuật số cho những nhân viên mới, những người mới làm quen với quản lý và những Tổng Giám đốc mới làm quen với thương hiệu của họ.",
    "speciallizationOrPathwayTooltipTitle": "Một tập hợp các khóa học tập trung vào một chủ đề cụ thể.",
    "taskTooltipTitle": "Các hướng dẫn để hoàn thành một hoạt động (thường là trong công việc) và sau đó đánh dấu rằng bạn đã hoàn thành hoạt động đó.",
    "webSiteTooltipTitle": "Bất kỳ trang web nào, bao gồm các trang web của Marriott hoặc các trang web bên ngoài."
  },
  "dontShowAgain": "Không hiển thị lại",
  "deletionWarningEnabled": "Đã bật cảnh báo trước khi xóa",
  "deletionSelected": "Khi được chọn, bạn sẽ thấy một cảnh báo mỗi khi xóa một thông báo.",
  "manageEmailNotifications": "Quản lý Thông báo Email",
  "seeHowDLZContinue": "Xem DLZ có thể tiếp tục giúp bạn phát triển như thế nào.",
  "Notification Center:Overdue": "Bạn có một khóa đào tạo mới đã hết hạn vào {Variable date ddmmyy}",
  "accesDenied": {
    "title": "Quyền truy cập vào Hành trình Học tập của bạn đang được tiến hành! ",
    "subtitle": "Quyền truy cập của bạn đang được tạo và sẽ khả dụng 1-2 ngày sau khi EID của bạn hoạt động. Nếu mất lâu hơn 2 ngày, vui lòng liên hệ Trung tâm Dịch vụ mHUB."
  },
  "jfTour": {
    "title": "Giờ đây, bạn có thể xem và chỉnh sửa các chức năng công việc đã chọn và các trách nhiệm công việc bổ sung (additional work responsibilities, AWR) trực tiếp từ Kế hoạch của Tôi."
  },
  "onboardingPlan": {
    "requiredDescription": "Dưới đây là các khóa học bắt buộc phải hoàn thành trong khoảng thời gian bạn đã chọn tại Kế hoạch của Tôi. Hãy làm việc với trưởng nhóm của bạn để sắp xếp thời gian hoàn thành các bài học bắt buộc khi bạn chuyển sang vai trò mới của mình. Bạn sẽ tìm hiểu về nhiều chủ đề khác nhau, hãy đảm bảo liên hệ với trưởng nhóm và đồng nghiệp của bạn nếu bạn có bất kỳ thắc mắc nào trong quá trình làm việc.   ",
    "basicsDescription": "Chúng tôi biết có rất nhiều điều mà bạn cần học hỏi và chúng tôi đã chọn một số kiến thức cơ bản cho những ngày làm việc đầu tiên của bạn. Những mục này có thể bổ sung cho các buổi định hướng, giới thiệu nhập môn về thương hiệu hoặc thông tin từ công ty quản lý nhượng quyền của bạn. Đây sẽ là nguồn tài nguyên tuyệt vời trong suốt thời gian bạn làm việc với chúng tôi và hữu ích khi bạn giúp các thành viên mới trong nhóm nâng cao kiến thức.",
    "recommendedDescription": "Sau 90 ngày đầu tiên, bạn sẽ tìm thấy các khóa học bổ sung do người quản lý của bạn đề xuất. Bạn cũng có thể tự mình khám phá các chủ đề mà bạn quan tâm. Hiện tại, hãy tập trung vào việc học kiến thức nền tảng của bạn. ",
    "day1": "Đến hạn chậm nhất vào ngày 14",
    "day2": "Đến hạn chậm nhất vào ngày 30",
    "day3": "Đến hạn chậm nhất vào ngày 60",
    "day4": "Đến hạn chậm nhất vào ngày 90",
    "day5": "Đến hạn sau 90 ngày "
  },
  "onboardingLanding": {
    "gettingStartedDescription": "Digital Learning Zone là điểm khởi đầu của bạn để học tập và phát triển chuyên môn. Dưới đây, bạn sẽ tìm thấy nội dung đã được sắp xếp để giúp bạn bắt đầu. Tất cả bài học của bạn luôn có thể được tìm thấy trong Kế hoạch của Tôi.  ",
    "overallLearningProgress": "Tiến độ Học tập Tổng thể",
    "learningProgressLabel": "Tiến độ Học tập",
    "learningProgressDescription": "Đây là tiến độ của bạn qua chương trình học tập bắt buộc. Thanh tiến độ này bao gồm trạng thái của tất cả các chương trình học tập bắt buộc của bạn. Hãy tiếp tục phát huy!"
  },
  "poc": {
    "dlz": "Để hoàn thành quá trình học này, Người quản lý hoặc Điều phối viên Học tập phải đánh dấu hoàn thành quá trình học trong mHUB. Chi tiết về cách thực hiện có thể được tìm thấy trong Hướng dẫn Triển khai.",
    "mhub": "Bên nhận nhượng quyền: nếu bạn chọn sử dụng chương trình dọn dẹp cơ bản của riêng mình, vui lòng điền tên chương trình và thông tin chi tiết trong phần nhận xét khi bạn đánh dấu hoàn thành"
  },
  "Recommended Courses ": "Sau 90 ngày đầu tiên, bạn sẽ tìm thấy các khóa học bổ sung do người quản lý của bạn đề xuất. Bạn cũng có thể tự mình khám phá các chủ đề mà bạn quan tâm. Hiện tại, hãy tập trung vào việc học kiến thức nền tảng của bạn. ",
  "survey": {
    "v2": {
      "weValueButtonText": "Làm khảo sát",
      "lbiIntro": "Chào mừng bạn đến với chương trình Kiểm tra Hành vi Sang trọng!",
      "lbiDescription": `<p>Dưới đây bạn sẽ được yêu cầu đọc một vài tình huống ngắn mà bạn có thể gặp phải tại cơ sở của mình. Vui lòng đọc từng tình huống và chọn cách bạn sẽ phản ứng với từng tình huống. Hãy ghi nhớ những điều sau đây khi bạn trả lời:</p>
      <ul>
          <li>
              <p>Bạn có thể thấy nhiều hơn một câu trả lời trông có vẻ đúng. Hãy chọn câu trả lời mà bạn có thể sẽ trả lời tại nơi làm việc.</p>
          </li>
          <li>
              <p>Giả sử không có rào cản về giao tiếp. Bạn nói cùng một ngôn ngữ với khách hoặc nhân viên trong tình huống.</p>
          </li>
          <li>
              <p>Tình huống này có thể yêu cầu bạn tưởng tượng bản thân trong một vai trò công việc khác với vai trò hiện tại của bạn. Trả lời câu hỏi theo tất cả hiểu biết của bạn, dựa trên kinh nghiệm của bạn với tư cách là một chuyên gia khách sạn sang trọng.</p>
          </li>
      </ul>
      <p><br></p>
      <p>Xin lưu ý: Việc bạn tham gia bài kiểm tra này là hoàn toàn tự nguyện (bạn có thể chọn không tham gia) và các câu trả lời của bạn sẽ được bảo mật nghiêm ngặt. Kết quả kiểm tra này sẽ được báo cáo cho các nhóm người chứ không phải cho các cá nhân cụ thể. Các câu trả lời cá nhân sẽ được nhóm nghiên cứu đánh giá và sẽ không được chia sẻ với Quản lý Khác hoặc các trưởng nhóm.
      </p>
      <p>Bằng cách hoàn thành bài kiểm tra này, bạn đồng ý với việc thu thập và xử lý các câu trả lời và dữ liệu cá nhân của bạn (ví dụ: thời gian làm việc, giới tính, chức danh công việc) cũng như việc chuyển dữ liệu cá nhân của bạn qua biên giới cho Tập đoàn Marriott. Điều này phù hợp với Tuyên bố về Bảo mật Dữ liệu của Nhân viên Marriott, bạn cũng có thể tìm thêm thông tin và liên hệ để tham khảo về Chính sách Bảo mật Dữ liệu của Marriott.
      </p>
      `,
      "checkin": {
        q1Title: "Bạn đang đi bộ qua sảnh và nhận thấy một gia đình có ba đứa trẻ nhỏ. Gia đình có nhiều hành lý, trông có vẻ căng thẳng sau một ngày dài đi du lịch, còn lũ trẻ thì than vãn và bồn chồn thấy rõ. Bạn sẽ làm gì?",
        q2Title: "Bạn vừa mới bắt đầu tại một khách sạn ở một khu vực mới đối với bạn. Một vị khách yêu cầu bạn gợi ý về những điều thú vị nên làm trong khu vực. Vị khách đã đọc một số sách hướng dẫn nhưng đang tìm kiếm thứ gì đó phù hợp với họ hơn. Bạn sẽ làm gì?",
        q3Title: "Bạn đang đi bộ qua sảnh thì nghe thấy một vị khách đang nói chuyện điện thoại và biết rằng dịch vụ xe đưa đón đến sân bay của họ đang bị trễ. Khách sạn của bạn không có xe hơi hoặc hiện không có sẵn. Bạn sẽ làm gì? ",
        q4Title: "Bạn đang đi qua sảnh để hoàn thành một nhiệm vụ khẩn cấp mà quản lý đã giao cho bạn. Bạn nhận thấy một đồng nghiệp đang vật lộn để hoàn thành một nhiệm vụ thường được hai người hoàn thành. Bạn sẽ làm gì? ",
        q5Title: "Bạn là người dẫn chương trình tại nhà hàng của khách sạn. Một vị khách yêu cầu xem thực đơn trong khi chờ bàn đã đặt trước. Sau khi nhìn vào thực đơn, vị khách thất vọng vì nhà hàng của khách sạn đã được một đồng nghiệp khác gợi ý cho họ, nhưng không có gì trên thực đơn đáp ứng nhu cầu ăn uống của họ. Bạn sẽ làm gì?",
        q6Title: "Có một nhóm khách lớn trong khu vực hồ bơi đông đúc không thể tìm thấy chỗ ngồi cùng nhau. Kết quả là, họ đã đặt khăn tắm và đồ dùng cá nhân của mình ra ngoài làm chặn lối đi chính, tạo ra mối nguy hiểm và gián đoạn cho khách và nhân viên cố gắng tiếp cận khu vực bể bơi. Khu vực này cần được dọn dẹp, nhưng bạn không muốn làm nhóm khách đó khó chịu. Bạn sẽ làm gì? ",
        q1R1Title: "Giúp gia đình mang hành lý, chỉ cho họ chỗ quầy lễ tân, và gợi ý một vài nơi thư giãn gần đó với các hoạt động thân thiện với gia đình. Đề cập đến các tiện nghi gia đình của khách sạn. ",
        q1R2Title: "Đề nghị được giúp họ mang hành lý và dẫn gia đình đến quầy lễ tân. Sau đó, sắp xếp một dịch vụ thư giãn hoặc ăn uống cho cha mẹ và các hoạt động vui chơi cho trẻ em trong phòng.",
        q1R3Title: "Dẫn cha mẹ đến quầy lễ tân, sau đó đích thân đưa trẻ đến khu vực chỗ ngồi có đồ ăn nhẹ trong khi cha mẹ làm thủ tục nhận phòng. Yêu cầu quầy lễ tân thông báo cho cha mẹ về khu vui chơi và các hoạt động của trẻ em tại khách sạn để họ thấy thoải mái. ",
        q1R4Title: "Chỉ gia đình đến quầy lễ tân để làm thủ tục nhận phòng nhanh chóng. Đề cập đến các khu vực và dịch vụ thân thiện với trẻ em cho cha mẹ để trẻ có thể tận hưởng một kỳ nghỉ thú vị.",
        q2R1Title: "Hỏi vị khách xem họ thích gì, sau đó hợp tác với một đồng nghiệp để tạo ra một danh sách về những điều thú vị để họ làm. Ngoài ra, hãy gợi ý một nhà hàng tuyệt vời gần đó.",
        q2R2Title: "Nói chuyện với vị khách về những gì họ thích. Làm việc với đồng nghiệp để lập một danh sách đặc biệt về những điều thú vị dành riêng cho họ. Ngoài ra, hãy kiểm tra hồ sơ Bonvoy của khách và gửi một món quà phù hợp đến phòng của họ.",
        q2R3Title: "Cá nhân hóa bằng cách chia sẻ một số điều yêu thích để làm của riêng bạn trong khu vực cho đến nay. Làm cho đề xuất của bạn nổi bật bằng cách chia sẻ thêm chi tiết về trải nghiệm của bạn. Sau đó, gửi một món ăn địa phương ngon miệng đến phòng của họ.",
        q2R4Title: "Cho khách biết bạn là người mới đến khu vực này và không có nhiều ý tưởng. Xin lỗi và đề nghị họ nói chuyện với đồng nghiệp của bạn, những người biết nhiều hơn. Sau đó, hỏi xem họ có muốn một món ăn địa phương được gửi đến phòng của họ không.",
        q3R1Title: "Trong khi khách vẫn đang nói chuyện điện thoại, hãy lái xe cá nhân của bạn hoặc đồng nghiệp của bạn lên phía trước khách sạn và cho khách biết rằng bạn sẽ đích thân đưa họ đến sân bay.",
        q3R2Title: "Trong khi khách vẫn đang nói chuyện điện thoại, hãy tổng hợp một danh sách các dịch vụ xe hơi và số điện thoại đã được khách sạn phê duyệt. Khi khách đã ngắt điện thoại, hãy đưa cho họ danh sách và đề nghị được giúp họ sắp xếp phương tiện đi lại.",
        q3R3Title: "Đợi cho đến khi khách ngắt điện thoại và nói với họ rằng khách sạn của bạn không cung cấp dịch vụ đưa đón, nhưng bạn sẽ sẵn lòng giúp đỡ sắp xếp các lựa chọn đưa đón khác. ",
        q3R4Title: "Trong khi khách vẫn đang nói chuyện điện thoại, hãy tìm phương tiện đi lại thay thế cho khách. Sau khi họ ngắt điện thoại, hãy cho khách biết rằng bạn đã sắp xếp phương tiện đi lại đưa họ đến sân bay và xe sẽ đến sớm.",
        q4R1Title: "Đến gặp đồng nghiệp của bạn và nói rằng bạn sẽ giúp họ, nhưng bạn cần hoàn thành nhiệm vụ khẩn cấp của mình trước. Hứa sẽ quay trở lại nhanh chóng để hỗ trợ họ. Đảm bảo thảo luận tình huống này với quản lý của bạn.",
        q4R2Title: "Trước tiên, nhanh chóng tìm một đồng nghiệp khác để đảm nhận nhiệm vụ mà bạn được giao. Sau đó, ngay lập tức giúp đỡ đồng nghiệp cần hỗ trợ. Khi hoàn thành, hãy thông báo cho quản lý của bạn rằng bạn đã nhờ người khác hoàn thành nhiệm vụ của mình để bạn có thể giúp đỡ đồng nghiệp.",
        q4R3Title: "Dừng lại và giúp đỡ đồng nghiệp của bạn ngay lập tức. Sau khi giúp đỡ, hãy quay lại nhiệm vụ được giao, sau đó cho quản lý của bạn biết rằng bạn đã tạm dừng nhiệm vụ của mình để giúp đỡ đồng nghiệp.",
        q4R4Title: "Đến gặp đồng nghiệp cần giúp đỡ và giải thích rằng bạn đang làm một nhiệm vụ khẩn cấp. Dừng cả hai nhiệm vụ, cùng nhau suy nghĩ về một cách để giải quyết cả hai nhiệm vụ và sau đó cùng nhau hoàn thành cả hai nhiệm vụ.",
        q5R1Title: "Xin lỗi khách và cho họ biết bạn sẽ gặp quản lý. Đảm bảo giải thích tình huống để người quản lý có thể giúp khách đáp ứng nhu cầu ăn uống của họ.",
        q5R2Title: "Xin lỗi khách và hỏi về sở thích ăn uống của họ. Nói chuyện với nhóm làm bếp để làm một món ăn đặc biệt dành riêng cho họ. Ngoài ra, hãy tặng họ món khai vị hoặc đồ uống miễn phí để bù đắp cho sai lầm đó.",
        q5R3Title: "Xin lỗi và nói với khách rằng bạn hiểu. Thay vì hủy đặt chỗ, hãy đề nghị được nói chuyện với đầu bếp và xem họ có thể làm gì đó đặc biệt cho khách không. Tặng họ một bữa ăn miễn phí hoặc giảm giá để bù đắp cho sự cố.",
        q5R4Title: "Xin lỗi và nói với khách rằng bạn có thể giúp họ đặt bàn tại một nhà hàng gần đó có đồ ăn mà họ thích. Cung cấp thêm thông tin hoặc trợ giúp để đạt được điều đó nếu cần.",
        q6R1Title: "Xin lỗi khách vì thiếu không gian và nói với họ rằng họ được hoan nghênh ở lại nơi họ đang ngồi miễn là họ chú ý đến những người khác đi qua. ",
        q6R2Title: "Nói thẳng với khách rằng họ không thể chặn lối đi và họ sẽ cần tìm một chỗ khác hoặc đợi cho đến khi có đủ chỗ. Trong khi đó, hãy nhờ các đồng nghiệp của bạn mang thêm ghế ngồi từ phòng khiêu vũ ra ngoài để họ sử dụng. ",
        q6R3Title: "Cho khách biết rằng họ không thể ở lại vị trí hiện tại và họ phải tạm thời di chuyển trong khi bạn tìm thêm ghế ngồi. Để họ ngồi tại quầy bar/nhà hàng trong thời gian chờ đợi. ",
        q6R4Title: "Xin lỗi khách về vấn đề chỗ ngồi. Hỏi xem họ có thể để gọn đồ đạc lại để tạo một lối đi nhỏ cho những người khác đi qua không. Đề nghị được giúp đỡ họ, đảm bảo mọi người đều cảm thấy thoải mái. Gửi cho họ một món đặc biệt từ quầy bar/nhà hàng để xin lỗi vì sự bất tiện này."
      }
    }
  },
};