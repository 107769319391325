import { decorate, observable, action } from 'mobx';
import Api from '../api/CMDBApi';
import _ from 'lodash';

class IPTStore {
  loadedPlan = false;
  activeStepByGroup = {}

  incrementStep(group) {
    const byGroup = _.clone(this.activeStepByGroup);
    byGroup[group] += 1;

    this.activeStepByGroup = byGroup;
  }

  decrementStep(group) {
    const byGroup = _.clone(this.activeStepByGroup);
    byGroup[group] -= 1;

    this.activeStepByGroup = byGroup;
  }

  async getPlan(locationCodes, jobFunctions, additionalWorkResponsibilities = [], newHireOnly, requiredTraining, recommendedTraining, allFunctionsSelected, allAWRsSelected) {
    try {
      this.loadedPlan = false;
      const { data } = await Api.getIptPlan(locationCodes, jobFunctions, additionalWorkResponsibilities, newHireOnly, requiredTraining, recommendedTraining, allFunctionsSelected, allAWRsSelected);
      this.plan = data;
      
      const self = this;
      Object.keys(data).forEach(k => {
        self.activeStepByGroup[k] = 0;
      });
      console.log(self.activeStepByGroup);

      this.loadedPlan = true;
    } catch (e) {
      console.error(e);
    }
  }
}

decorate(IPTStore, {
  loadedPlan: observable,
  activeStepByGroup: observable,
  plan: observable,

  getPlan: action,
  incrementStep: action,
  decrementStep: action
});

export default new IPTStore();
