// @flow
import { decorate, observable, action } from 'mobx';
import moment from 'moment';

const getNewExpiryDateFromNow = () => {
  // keep this in sync with backend
  // TODO possibly use value from config instead of hardcoding here
  return moment().add(15, 'minutes');
};

class AuthStore {
  expiryDate = null;
  checker;

  start() {

  }

  stop() {
    clearInterval(this.checker);
    this.expiryDate = null;
  }

  resetExpiryDate() {
    // reset the session timeout if it already exists
    if (this.expiryDate !== null) {
      this.expiryDate = getNewExpiryDateFromNow();
    }
  }
}

decorate(AuthStore, {
  expiryDate: observable,
  checker: observable,
  start: action,
  stop: action,
  resetExpiryDate: action

});

export default new AuthStore();
