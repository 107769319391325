import { decorate, observable, action, computed } from 'mobx';
import _ from 'lodash';
import lookupStore from './LookupStore';
import languageStore from './LanguageStore';
import CMDBApi from '../api/CMDBApi';
import { cmdbURL } from '../config';

class ReportingStore {
  reportingData = {}
  isLoading = false
  functionReportDownloadUrl = ''
  functionReportCacheKey = ''

  allLocationCodes = []

  locationServerData = []
  locationTableConfig = {
    limit: 25,
    offset: 0,
    filter: {

    }
  }

  get jobFunctionOverview() {
    return this.reportingData.byFunction ? this.reportingData.byFunction.map(jf => ({
      id: jf.categoryId,
      type: jf.type,
      percentComplete: jf.assignedCourseCount > 0 ? Math.round((jf.completeCount / jf.assignedCourseCount) * 100) : 100,
      amount: jf.numAssociates
    })) : [];
  }

  get locationData() {
    return this.reportingData.byLocation ? this.reportingData.byLocation.map((location) => ({
      id: location.locationDetails.locationCode,
      marshaCode: location.locationDetails.marshaCode,
      locationCode: location.locationDetails.locationCode,
      managedFranchised: location.locationDetails.managedFranchised === 'F' ? 'Franchised' : 'Managed',
      propertyName: location.locationDetails.propertyName,
      avp: location.locationDetails.avp,
      coo: location.locationDetails.coo,
      region: location.locationDetails.region,
      brand: location.locationDetails.brand,
      progress: location.assignedCourseCount > 0 ? Math.round((location.completeCount / location.assignedCourseCount) * 100) : 100,
      numAssociates: location.numAssociates
    })) : [];
  }

  get associateData() {
    return this.reportingData.associateDetails ? this.reportingData.associateDetails.map((associate) => ({
      id: associate.eid,
      eid: associate.eid,
      name: associate.name,
      functionTypes: lookupStore.getJobFunctionLabels(associate.jobFunctions, true).join(', '),
      numCoursesComplete: associate.numCoursesComplete,
      totalRequiredCourses: associate.totalRequiredCourses,
      progress: associate.totalRequiredCourses > 0 ? Math.round((associate.numCoursesComplete / associate.totalRequiredCourses) * 100) : 100
    })) : [];
  }

  get filters() {
    return [
      {
        groupName: languageStore.lang.t('filters.metric'),
        groupKey: 'metric',
        tooltip: languageStore.lang.t('filters.metricTooltip'),
        items: [
          {
            id: 1,
            name: languageStore.lang.t('filters.allAssociatesProgress'),
            tooltip: languageStore.lang.t('reporting.helpProgress')
          },
          {
            id: 2,
            name: languageStore.lang.t('filters.allCourseCompletion'),
            tooltip: languageStore.lang.t('reporting.helpCompletion')
          },
          {
            id: 3,
            name: languageStore.lang.t('filters.jobFunctionSelected'),
            tooltip: languageStore.lang.t('reporting.helpJF')
          },
          {
            id: 4,
            name: languageStore.lang.t('filters.newHireCompletion'),
            comingSoon: true
          },
          {
            id: 5,
            name: languageStore.lang.t('filters.newHireProgress'),
            comingSoon: true
          },
          {
            id: 6,
            name: languageStore.lang.t('filters.newHireCompliance'),
            comingSoon: true
          }
        ]
      },
      {
        groupName: languageStore.lang.t('filters.courseType'),
        groupKey: 'assignmentType',
        items: [
          {
            id: lookupStore.assignmentTypes.find(at =>  at.name === 'Required').id,
            name: languageStore.lang.t('filters.requiredCourses'),
          },
          {
            id: lookupStore.assignmentTypes.find(at =>  at.name === 'Recommended').id,
            name: languageStore.lang.t('filters.recommendedCourses'),
          },
          {
            id: lookupStore.assignmentTypes.find(at =>  at.name === 'Gateway Resources').id,
            name: languageStore.lang.t('filters.gatewayResources'),
          },
        ]
      },
      {
        groupName: languageStore.lang.t('filters.program'),
        tooltip: languageStore.lang.t('reporting.helpProgram'),
        groupKey: 'program',
        items: lookupStore.programs,
        drilldownItems: lookupStore.coursePlansWithCategories,
        isCategoryBased: true
      },
      {
        groupName: languageStore.lang.t('filters.function'),
        tooltip: languageStore.lang.t('filters.functionTooltip'),
        groupKey: 'jobFunction',
        items: [
          {
            id: 1,
            name: languageStore.lang.t('filters.generalManager'),
            itemIds: [1],
            isFauxFilter: true
          },
          {
            id: 2,
            name: languageStore.lang.t('filters.finance'),
            itemIds: [10, 11],
            isFauxFilter: true
          },
          {
            id: 3,
            name: languageStore.lang.t('filters.targetedFrontOffice'),
            itemIds: [18, 23, 21, 22, 20, 17],
            isFauxFilter: true
          },
          {
            id: 4,
            name: languageStore.lang.t('filters.sales'),
            itemIds: [41, 42, 43, 44, 45],
            isFauxFilter: true
          },
          {
            id: 5,
            name: languageStore.lang.t('filters.eventManagement'),
            itemIds: [45, 6, 7, 8],
            isFauxFilter: true
          },
          {
            id: 6,
            name: languageStore.lang.t('filters.revenueManagement'),
            itemIds: [37, 38, 39, 40],
            isFauxFilter: true
          },
          {
            id: 7,
            name: languageStore.lang.t('filters.housekeeping'),
            itemIds: [25, 26],
            isFauxFilter: true
          },
          {
            id: 8,
            name: languageStore.lang.t('filters.reservations'),
            itemIds: [32, 33],
            isFauxFilter: true
          },
          {
            id: 9,
            name: languageStore.lang.t('filters.other'),
            itemIds: lookupStore.jobFunctions.map(jf => {
              const excludeIds = [18, 23, 21, 22, 20, 17, 37, 38, 39, 40, 41, 42, 43, 44, 45, 6, 7, 8, 1, 10, 11, 25, 26, 32, 33];
              if (excludeIds.includes(jf.id)) {
                return null;
              } else {
                return jf.id;
              }
            }).filter(Boolean),
            isFauxFilter: true
          }
        ],
        drilldownItems: lookupStore.jobFunctionsWithCategories,
        isCategoryBased: true
      }
    ]
  }

  async wait(ms) {
    return new Promise(resolve => {
      setTimeout(resolve, ms);
    });
  }

  getFiltersFromFauxFilters(allFilters) {
    const fauxFilters = [];
    let expandedFilters = allFilters.map(filter => {
      if (filter.isFauxFilter) {
        fauxFilters.push(filter);
        return null;
      } else {
        return { ...filter };
      }
    }).filter(Boolean);

    fauxFilters.forEach(filter => {
      const group = this.filters.find(f => f.groupKey === filter.groupKey);
      const item = group.items.find(i => i.id === filter.itemId);
      item.itemIds.forEach(id => expandedFilters.push({ groupKey: group.groupKey, itemId: id }));
    });

    return expandedFilters;
  }


  async getFilteredData(filters = [], query = {}) {
    this.isLoading = true;
    const fauxFilters = [];
    let expandedFilters = filters.map(filter => {
      if (filter.isFauxFilter) {
        fauxFilters.push(filter);
        return null;
      } else {
        return { ...filter };
      }
    }).filter(Boolean);

    fauxFilters.forEach(filter => {
      const group = this.filters.find(f => f.groupKey === filter.groupKey);
      const item = group.items.find(i => i.id === filter.itemId);
      item.itemIds.forEach(id => expandedFilters.push({ groupKey: group.groupKey, itemId: id }));
    });

    try {
      let response, poll = true;
      while (poll) {
        response = await CMDBApi.postCourseFilters(expandedFilters, query, fauxFilters);
        await this.wait(1000);
        poll = response.data.poll;
        if (poll) {
          query.polling = true;
        }
      }
      this.isLoading = false;
      this.reportingData = response.data;
    } catch (e) {
      let response, poll = true;
      while (poll) {
        response = await CMDBApi.postCourseFilters(expandedFilters, query, fauxFilters);
        await this.wait(1000);
        poll = response.data.poll;
        if (poll) {
          query.polling = true;
        }
      }
      this.isLoading = false;
      this.reportingData = response.data;
    }
  }

  setLocationTableConfig({ offset = null, limit = null, filter = null }) {
    if (offset !== null) {
      this.locationTableConfig.offset = offset;
    }

    if (limit !== null) {
      this.locationTableConfig.limit = limit;
    }

    if (filter !== null) {
      this.locationTableConfig.filter = filter;
    }
  }

  resetLocationFilters() {
    this.locationTableConfig = {
      limit: 25,
      offset: 0,
      sorting: {

      },
      filter: {

      }
    }

    this.getLocationData();
  }

  async getLocationData(filters) {
    this.loadingLocationData = true;
    const { limit, offset, filter, sorting } = this.locationTableConfig;

    const { data, headers } = await CMDBApi.getLocationData(filter, limit, offset, filters, sorting);
    const { brands, regions } = lookupStore;

    const { locations, allLocationCodes } = data;

    this.allLocationCodes = allLocationCodes;

    this.locationServerData = locations.map(d => {
      d.brand = (brands.find(b => d.brandId === b.id) || {}).label;
      d.region = (regions.find(r => r.id === d.regionId) || {}).label;
      d.id = d.locationCode;

      return d;
    });
    this.totalLocations = parseInt(headers['x-total-result-count'], 10);
    this.loadingLocationData = false;

    this.loadingLocationProgress = true;

    let expandedFilters = this.getFiltersFromFauxFilters(filters);

    const progressResponse = await CMDBApi.getLocationProgress(locations.map(d => d.locationCode), expandedFilters);

    progressResponse.data.forEach((locationData) => {
      const index = this.locationServerData.findIndex(lsd => lsd.locationCode === locationData._id);
      const progress = locationData.assignedCourseCount > 0 ? Math.round((locationData.completeCount / locationData.assignedCourseCount) * 100) : 100;

      if (index !== -1) {
        this.locationServerData[index].progress = progress
      }
    });

    this.locationServerData = this.locationServerData.filter(l => l.progress !== undefined)

    this.loadingLocationProgress = false;
  }

  async getFunctionReport() {
    this.functionReportDownloadUrl = null;

    const run = async () => {
      if (!this.functionReportCacheKey) {
        const { data } = await CMDBApi.downloadFunctionReport();
        this.functionReportCacheKey = data.cacheKey;
        return null;
      } else {
        const { data } = await CMDBApi.downloadFunctionReport(this.functionReportCacheKey);
        if (data.status === 'ready') {
          return `${cmdbURL}/app/manager/reporting/function-selection-report?application=dlp&download=true&cacheKey=${this.functionReportCacheKey}`;
        } else {
          return null;
        }
      }
    }

    await run();

    while (_.isEmpty(this.functionReportDownloadUrl)) {
      await this.wait(15000);
      const downloadUrl = await run();
      if (downloadUrl) {
        this.functionReportDownloadUrl = downloadUrl;
      }
    }
  }
}

decorate(ReportingStore, {
  reportingData: observable,
  getData: action,
  jobFunctionOverview: computed,
  locationData: computed,
  isLoading: observable,
  getFiltersFromFauxFilters: action,
  locationFilter: observable,
  locationTableConfig: observable,
  loadingLocationData: observable,
  loadingLocationProgress: observable,
  locationServerData: observable,
  totalLocations: observable,
  getLocationData: action,
  setLocationTableConfig: action,
  resetLocationFilters: action,
  getFunctionReport: action,
  functionReportDownloadUrl: observable,
  allLocationCodes: observable
});

export default new ReportingStore();
